import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useNavigate, useParams } from "react-router-dom"
import { SidebarMenu } from "../../components/SidebarMenu"
import { Skeleton } from "../../components/Skeleton"
import { Column, Row, Tile, TileRow } from "../../components/UI"
import { isAccessReadOnly } from "../../interfaces"
import { getProject } from "../../lib/api"
import { compactNumber, percentage } from "../../lib/numbers"
import { settings } from "../../lib/settings"
import * as cloud from "../../messages/cloud.pb"

const ProjectPage = () => {
	const params = useParams<{ id: string }>()
	const [project, setProject] = useState<cloud.Project>()
	const [stats, setStats] = useState<cloud.GetProjectStatsResponse>()

	useEffect(() => {
		if (!params.id) {
			return
		}
		getProject(params.id).then(setProject)
	}, [params.id])

	useEffect(() => {
		if (!project) {
			return
		}
		cloud.GetProjectStats({ projectId: project.id }).then((r) => {
			setStats(r)
		})
	}, [project])

	const nav = useNavigate()

	if (!project) {
		return null
	}

	return (
		<>
			<Helmet>
				<title>
					{project.name} – {settings.appName}
				</title>
			</Helmet>
			<Skeleton project={project}>
				<SidebarMenu project={project} />
				<Column className="flex-1 h-full items-center p-4">
					<Column className="w-full max-w-[1000px]">
						<Column>
							<Row className="!justify-start !items-start gap-4 text-sm">
								<Tile
									full
									onClick={() => {
										nav(`/projects/${project.id}/waitlist`)
									}}
								>
									<TileRow number={compactNumber(stats?.waitlist ?? 0)} description="Users in the waitlist" />
								</Tile>
								<Tile
									full
									onClick={() => {
										nav(`/projects/${project.id}/invites`)
									}}
								>
									<TileRow number={stats?.invited ?? 0} description="Invites sent" />
								</Tile>
							</Row>
							<Row className="gap-2">
								<Tile full empty>
									<TileRow number={compactNumber(stats?.signups ?? 0)} description="Total signups" />
								</Tile>
								<Tile full empty>
									<TileRow
										number={percentage(stats?.invited || 0, stats?.signups || 0)}
										description="Invited percentage"
									/>
								</Tile>
								<Tile full empty>
									<TileRow
										number={percentage(stats?.accepted || 0, stats?.invited || 0)}
										description="Acceptance rate"
									/>
								</Tile>
							</Row>
						</Column>
					</Column>
				</Column>
			</Skeleton>
		</>
	)
}

export default ProjectPage
