import classNames from "classnames"
import { motion } from "framer-motion"
import clamp from "lodash.clamp"
import { useEffect, useMemo, useRef, useState } from "react"

type Props = {
	from: number
	to: number
	value: number
	onChange: (value: number) => void
	className?: string
}

export function Slider({ className, from, to, value, onChange }: Props) {
	const container = useRef<HTMLDivElement | null>(null)
	const [position, setPosition] = useState<number | null>(null)
	const [isDragging, setIsDragging] = useState(false)

	const total = useMemo(() => to - from, [from, to])
	const buckets = []
	for (let i = from; i <= to; i++) {
		buckets.push(i)
	}

	const index = useMemo(() => (value - from) % total, [value, from, total])

	useEffect(() => {
		if (!container.current) {
			return
		}
		const { width: containerWidth } = container.current.getBoundingClientRect()
		setPosition(((buckets[index] - from) / total) * containerWidth)
	}, [])

	useEffect(() => {
		function listener() {
			if (!container.current) {
				return
			}

			const { width: containerWidth } = container.current.getBoundingClientRect()
			const segmentWidth = containerWidth / total
			setPosition((buckets[value] / total) * segmentWidth)
		}
		window.addEventListener("resize", listener)
		return () => window.removeEventListener("resize", listener)
	}, [value])

	return (
		<div
			className={classNames(
				"relative z-0 w-[120px] flex justify-center items-center touch-none",
				isDragging ? "cursor-ew-resize" : "cursor-pointer",
				className
			)}
			ref={container}
		>
			<div className={classNames("w-full py-3 flex gap-1")}>
				<div className="bg-stone-200 flex-1 h-2 rounded" />
			</div>
			{position != null && (
				<motion.div
					tabIndex={0}
					className={classNames(
						"z-10 absolute left-0 top-1/2 w-6 h-6 rounded-full shadow select-none outline-offset-8 transition-colors cursor-ew-resize",
						"flex items-center justify-center",
						{
							"bg-stone-300 hover:bg-stone-300": buckets[index] === 0,
							"bg-rose-500 hover:bg-rose-500": buckets[index] < 0,
							"bg-emerald-500 hover:bg-emerald-500": buckets[index] > 0,
						}
					)}
					initial={false}
					animate={{
						x: position - 14,
						y: "-50%",
					}}
					transition={{
						type: "tween",
						ease: [0.165, 0.84, 0.44, 1],
						duration: 0.4,
					}}
					onFocus={() => setIsDragging(true)}
					onBlur={() => setIsDragging(false)}
					onPointerDown={(e) => {
						const { ownerDocument } = e.currentTarget

						setIsDragging(true)

						function onPointerMove(e: PointerEvent) {
							if (!container.current) {
								return
							}

							const { width: containerWidth, left: containerLeft } = container.current.getBoundingClientRect()

							const segmentWidth = containerWidth / total
							const index = Math.round((e.clientX - containerLeft) / segmentWidth)
							const clampedIndex = clamp(index, 0, total)
							setPosition(clampedIndex * segmentWidth)
							onChange(buckets[clampedIndex])
						}

						function onPointerUp(e: PointerEvent) {
							setIsDragging(false)
							ownerDocument.removeEventListener("pointermove", onPointerMove)
						}

						ownerDocument.addEventListener("pointermove", onPointerMove)
						ownerDocument.addEventListener("pointerup", onPointerUp)
					}}
				>
					<div className={classNames("text-sm font-mono font-medium text-white")}>{buckets[index]}</div>
				</motion.div>
			)}
		</div>
	)
}
