// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
// Source: forms.proto
/* eslint-disable */

import type { ByteSource } from "protoscript";
import { BinaryReader, BinaryWriter } from "protoscript";
import { JSONrequest, PBrequest } from "twirpscript";
// This is the minimum version supported by the current runtime.
// If this line fails typechecking, breaking changes have been introduced and this
// file needs to be regenerated by running `npx twirpscript`.
export { MIN_SUPPORTED_VERSION_0_0_56 } from "twirpscript";
import type { ClientConfiguration } from "twirpscript";
import * as types from "./types.pb";

//========================================//
//                 Types                  //
//========================================//

export interface GetPublicFormDataRequest {
  formId: string;
}

export interface FormBlockMessage {
  text: string;
}

export interface Option {
  id: string;
  text: string;
  karma?: number | null | undefined;
}

export interface FormBlockSingleChoice {
  title: string;
  options: Option[];
}

export interface FormBlockMultipleChoice {
  title: string;
  options: Option[];
}

export interface FormBlockTextInput {
  title: string;
}

export interface FormBlockAccessCode {
  title: string;
}

export interface FormBlockContent {
  type: string;
  message?: FormBlockMessage | null | undefined;
  singleChoice?: FormBlockSingleChoice | null | undefined;
  multipleChoice?: FormBlockMultipleChoice | null | undefined;
  textInput?: FormBlockTextInput | null | undefined;
  accessCode?: FormBlockAccessCode | null | undefined;
}

export interface FormBlock {
  id: string;
  content: FormBlockContent;
  position: number;
  placeholder?: boolean | null | undefined;
}

export interface PublicFormData {
  projectName: string;
  formBlocks: FormBlock[];
  css?: string | null | undefined;
}

export interface GetPublicFormDataResponse {
  formData: PublicFormData;
}

export interface Answer {
  blockId: string;
  singleChoice?: number | null | undefined;
  multipleChoice: number[];
  textInput?: string | null | undefined;
  accessCode?: string | null | undefined;
}

export interface CompleteFormRequest {
  formId: string;
  answers: Answer[];
  email: string;
  referral?: string | null | undefined;
}

export interface CompleteFormResponse {}

//========================================//
//         Forms Protobuf Client          //
//========================================//

/**
 * Util
 */
export async function Ping(
  pingRequest: types.PingRequest,
  config?: ClientConfiguration
): Promise<types.PingResponse> {
  const response = await PBrequest(
    "/Forms/Ping",
    types.PingRequest.encode(pingRequest),
    config
  );
  return types.PingResponse.decode(response);
}

/**
 * Forms
 */
export async function GetPublicFormData(
  getPublicFormDataRequest: GetPublicFormDataRequest,
  config?: ClientConfiguration
): Promise<GetPublicFormDataResponse> {
  const response = await PBrequest(
    "/Forms/GetPublicFormData",
    GetPublicFormDataRequest.encode(getPublicFormDataRequest),
    config
  );
  return GetPublicFormDataResponse.decode(response);
}

export async function CompleteForm(
  completeFormRequest: CompleteFormRequest,
  config?: ClientConfiguration
): Promise<CompleteFormResponse> {
  const response = await PBrequest(
    "/Forms/CompleteForm",
    CompleteFormRequest.encode(completeFormRequest),
    config
  );
  return CompleteFormResponse.decode(response);
}

//========================================//
//           Forms JSON Client            //
//========================================//

/**
 * Util
 */
export async function PingJSON(
  pingRequest: types.PingRequest,
  config?: ClientConfiguration
): Promise<types.PingResponse> {
  const response = await JSONrequest(
    "/Forms/Ping",
    types.PingRequestJSON.encode(pingRequest),
    config
  );
  return types.PingResponseJSON.decode(response);
}

/**
 * Forms
 */
export async function GetPublicFormDataJSON(
  getPublicFormDataRequest: GetPublicFormDataRequest,
  config?: ClientConfiguration
): Promise<GetPublicFormDataResponse> {
  const response = await JSONrequest(
    "/Forms/GetPublicFormData",
    GetPublicFormDataRequestJSON.encode(getPublicFormDataRequest),
    config
  );
  return GetPublicFormDataResponseJSON.decode(response);
}

export async function CompleteFormJSON(
  completeFormRequest: CompleteFormRequest,
  config?: ClientConfiguration
): Promise<CompleteFormResponse> {
  const response = await JSONrequest(
    "/Forms/CompleteForm",
    CompleteFormRequestJSON.encode(completeFormRequest),
    config
  );
  return CompleteFormResponseJSON.decode(response);
}

//========================================//
//                 Forms                  //
//========================================//

export interface Forms<Context = unknown> {
  /**
   * Util
   */
  Ping: (
    pingRequest: types.PingRequest,
    context: Context
  ) => Promise<types.PingResponse> | types.PingResponse;
  /**
   * Forms
   */
  GetPublicFormData: (
    getPublicFormDataRequest: GetPublicFormDataRequest,
    context: Context
  ) => Promise<GetPublicFormDataResponse> | GetPublicFormDataResponse;
  CompleteForm: (
    completeFormRequest: CompleteFormRequest,
    context: Context
  ) => Promise<CompleteFormResponse> | CompleteFormResponse;
}

export function createForms<Context>(service: Forms<Context>) {
  return {
    name: "Forms",
    methods: {
      Ping: {
        name: "Ping",
        handler: service.Ping,
        input: { protobuf: types.PingRequest, json: types.PingRequestJSON },
        output: { protobuf: types.PingResponse, json: types.PingResponseJSON },
      },
      GetPublicFormData: {
        name: "GetPublicFormData",
        handler: service.GetPublicFormData,
        input: {
          protobuf: GetPublicFormDataRequest,
          json: GetPublicFormDataRequestJSON,
        },
        output: {
          protobuf: GetPublicFormDataResponse,
          json: GetPublicFormDataResponseJSON,
        },
      },
      CompleteForm: {
        name: "CompleteForm",
        handler: service.CompleteForm,
        input: { protobuf: CompleteFormRequest, json: CompleteFormRequestJSON },
        output: {
          protobuf: CompleteFormResponse,
          json: CompleteFormResponseJSON,
        },
      },
    },
  } as const;
}

//========================================//
//        Protobuf Encode / Decode        //
//========================================//

export const GetPublicFormDataRequest = {
  /**
   * Serializes GetPublicFormDataRequest to protobuf.
   */
  encode: function (msg: Partial<GetPublicFormDataRequest>): Uint8Array {
    return GetPublicFormDataRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetPublicFormDataRequest from protobuf.
   */
  decode: function (bytes: ByteSource): GetPublicFormDataRequest {
    return GetPublicFormDataRequest._readMessage(
      GetPublicFormDataRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetPublicFormDataRequest with all fields set to their default value.
   */
  initialize: function (): GetPublicFormDataRequest {
    return {
      formId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetPublicFormDataRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.formId) {
      writer.writeString(1, msg.formId);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetPublicFormDataRequest,
    reader: BinaryReader
  ): GetPublicFormDataRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.formId = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const FormBlockMessage = {
  /**
   * Serializes FormBlockMessage to protobuf.
   */
  encode: function (msg: Partial<FormBlockMessage>): Uint8Array {
    return FormBlockMessage._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes FormBlockMessage from protobuf.
   */
  decode: function (bytes: ByteSource): FormBlockMessage {
    return FormBlockMessage._readMessage(
      FormBlockMessage.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes FormBlockMessage with all fields set to their default value.
   */
  initialize: function (): FormBlockMessage {
    return {
      text: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<FormBlockMessage>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.text) {
      writer.writeString(1, msg.text);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: FormBlockMessage,
    reader: BinaryReader
  ): FormBlockMessage {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.text = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const Option = {
  /**
   * Serializes Option to protobuf.
   */
  encode: function (msg: Partial<Option>): Uint8Array {
    return Option._writeMessage(msg, new BinaryWriter()).getResultBuffer();
  },

  /**
   * Deserializes Option from protobuf.
   */
  decode: function (bytes: ByteSource): Option {
    return Option._readMessage(Option.initialize(), new BinaryReader(bytes));
  },

  /**
   * Initializes Option with all fields set to their default value.
   */
  initialize: function (): Option {
    return {
      id: "",
      text: "",
      karma: undefined,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<Option>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.id) {
      writer.writeString(1, msg.id);
    }
    if (msg.text) {
      writer.writeString(2, msg.text);
    }
    if (msg.karma != undefined) {
      writer.writeInt32(3, msg.karma);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (msg: Option, reader: BinaryReader): Option {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.id = reader.readString();
          break;
        }
        case 2: {
          msg.text = reader.readString();
          break;
        }
        case 3: {
          msg.karma = reader.readInt32();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const FormBlockSingleChoice = {
  /**
   * Serializes FormBlockSingleChoice to protobuf.
   */
  encode: function (msg: Partial<FormBlockSingleChoice>): Uint8Array {
    return FormBlockSingleChoice._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes FormBlockSingleChoice from protobuf.
   */
  decode: function (bytes: ByteSource): FormBlockSingleChoice {
    return FormBlockSingleChoice._readMessage(
      FormBlockSingleChoice.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes FormBlockSingleChoice with all fields set to their default value.
   */
  initialize: function (): FormBlockSingleChoice {
    return {
      title: "",
      options: [],
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<FormBlockSingleChoice>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.title) {
      writer.writeString(1, msg.title);
    }
    if (msg.options?.length) {
      writer.writeRepeatedMessage(2, msg.options as any, Option._writeMessage);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: FormBlockSingleChoice,
    reader: BinaryReader
  ): FormBlockSingleChoice {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.title = reader.readString();
          break;
        }
        case 2: {
          const m = Option.initialize();
          reader.readMessage(m, Option._readMessage);
          msg.options.push(m);
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const FormBlockMultipleChoice = {
  /**
   * Serializes FormBlockMultipleChoice to protobuf.
   */
  encode: function (msg: Partial<FormBlockMultipleChoice>): Uint8Array {
    return FormBlockMultipleChoice._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes FormBlockMultipleChoice from protobuf.
   */
  decode: function (bytes: ByteSource): FormBlockMultipleChoice {
    return FormBlockMultipleChoice._readMessage(
      FormBlockMultipleChoice.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes FormBlockMultipleChoice with all fields set to their default value.
   */
  initialize: function (): FormBlockMultipleChoice {
    return {
      title: "",
      options: [],
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<FormBlockMultipleChoice>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.title) {
      writer.writeString(1, msg.title);
    }
    if (msg.options?.length) {
      writer.writeRepeatedMessage(2, msg.options as any, Option._writeMessage);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: FormBlockMultipleChoice,
    reader: BinaryReader
  ): FormBlockMultipleChoice {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.title = reader.readString();
          break;
        }
        case 2: {
          const m = Option.initialize();
          reader.readMessage(m, Option._readMessage);
          msg.options.push(m);
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const FormBlockTextInput = {
  /**
   * Serializes FormBlockTextInput to protobuf.
   */
  encode: function (msg: Partial<FormBlockTextInput>): Uint8Array {
    return FormBlockTextInput._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes FormBlockTextInput from protobuf.
   */
  decode: function (bytes: ByteSource): FormBlockTextInput {
    return FormBlockTextInput._readMessage(
      FormBlockTextInput.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes FormBlockTextInput with all fields set to their default value.
   */
  initialize: function (): FormBlockTextInput {
    return {
      title: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<FormBlockTextInput>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.title) {
      writer.writeString(1, msg.title);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: FormBlockTextInput,
    reader: BinaryReader
  ): FormBlockTextInput {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.title = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const FormBlockAccessCode = {
  /**
   * Serializes FormBlockAccessCode to protobuf.
   */
  encode: function (msg: Partial<FormBlockAccessCode>): Uint8Array {
    return FormBlockAccessCode._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes FormBlockAccessCode from protobuf.
   */
  decode: function (bytes: ByteSource): FormBlockAccessCode {
    return FormBlockAccessCode._readMessage(
      FormBlockAccessCode.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes FormBlockAccessCode with all fields set to their default value.
   */
  initialize: function (): FormBlockAccessCode {
    return {
      title: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<FormBlockAccessCode>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.title) {
      writer.writeString(1, msg.title);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: FormBlockAccessCode,
    reader: BinaryReader
  ): FormBlockAccessCode {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.title = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const FormBlockContent = {
  /**
   * Serializes FormBlockContent to protobuf.
   */
  encode: function (msg: Partial<FormBlockContent>): Uint8Array {
    return FormBlockContent._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes FormBlockContent from protobuf.
   */
  decode: function (bytes: ByteSource): FormBlockContent {
    return FormBlockContent._readMessage(
      FormBlockContent.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes FormBlockContent with all fields set to their default value.
   */
  initialize: function (): FormBlockContent {
    return {
      type: "",
      message: undefined,
      singleChoice: undefined,
      multipleChoice: undefined,
      textInput: undefined,
      accessCode: undefined,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<FormBlockContent>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.type) {
      writer.writeString(1, msg.type);
    }
    if (msg.message != undefined) {
      writer.writeMessage(2, msg.message, FormBlockMessage._writeMessage);
    }
    if (msg.singleChoice != undefined) {
      writer.writeMessage(
        3,
        msg.singleChoice,
        FormBlockSingleChoice._writeMessage
      );
    }
    if (msg.multipleChoice != undefined) {
      writer.writeMessage(
        4,
        msg.multipleChoice,
        FormBlockMultipleChoice._writeMessage
      );
    }
    if (msg.textInput != undefined) {
      writer.writeMessage(5, msg.textInput, FormBlockTextInput._writeMessage);
    }
    if (msg.accessCode != undefined) {
      writer.writeMessage(6, msg.accessCode, FormBlockAccessCode._writeMessage);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: FormBlockContent,
    reader: BinaryReader
  ): FormBlockContent {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.type = reader.readString();
          break;
        }
        case 2: {
          msg.message = FormBlockMessage.initialize();
          reader.readMessage(msg.message, FormBlockMessage._readMessage);
          break;
        }
        case 3: {
          msg.singleChoice = FormBlockSingleChoice.initialize();
          reader.readMessage(
            msg.singleChoice,
            FormBlockSingleChoice._readMessage
          );
          break;
        }
        case 4: {
          msg.multipleChoice = FormBlockMultipleChoice.initialize();
          reader.readMessage(
            msg.multipleChoice,
            FormBlockMultipleChoice._readMessage
          );
          break;
        }
        case 5: {
          msg.textInput = FormBlockTextInput.initialize();
          reader.readMessage(msg.textInput, FormBlockTextInput._readMessage);
          break;
        }
        case 6: {
          msg.accessCode = FormBlockAccessCode.initialize();
          reader.readMessage(msg.accessCode, FormBlockAccessCode._readMessage);
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const FormBlock = {
  /**
   * Serializes FormBlock to protobuf.
   */
  encode: function (msg: Partial<FormBlock>): Uint8Array {
    return FormBlock._writeMessage(msg, new BinaryWriter()).getResultBuffer();
  },

  /**
   * Deserializes FormBlock from protobuf.
   */
  decode: function (bytes: ByteSource): FormBlock {
    return FormBlock._readMessage(
      FormBlock.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes FormBlock with all fields set to their default value.
   */
  initialize: function (): FormBlock {
    return {
      id: "",
      content: FormBlockContent.initialize(),
      position: 0,
      placeholder: undefined,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<FormBlock>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.id) {
      writer.writeString(1, msg.id);
    }
    if (msg.content) {
      writer.writeMessage(2, msg.content, FormBlockContent._writeMessage);
    }
    if (msg.position) {
      writer.writeInt32(3, msg.position);
    }
    if (msg.placeholder != undefined) {
      writer.writeBool(4, msg.placeholder);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (msg: FormBlock, reader: BinaryReader): FormBlock {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.id = reader.readString();
          break;
        }
        case 2: {
          reader.readMessage(msg.content, FormBlockContent._readMessage);
          break;
        }
        case 3: {
          msg.position = reader.readInt32();
          break;
        }
        case 4: {
          msg.placeholder = reader.readBool();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const PublicFormData = {
  /**
   * Serializes PublicFormData to protobuf.
   */
  encode: function (msg: Partial<PublicFormData>): Uint8Array {
    return PublicFormData._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes PublicFormData from protobuf.
   */
  decode: function (bytes: ByteSource): PublicFormData {
    return PublicFormData._readMessage(
      PublicFormData.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes PublicFormData with all fields set to their default value.
   */
  initialize: function (): PublicFormData {
    return {
      projectName: "",
      formBlocks: [],
      css: undefined,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<PublicFormData>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectName) {
      writer.writeString(1, msg.projectName);
    }
    if (msg.formBlocks?.length) {
      writer.writeRepeatedMessage(
        2,
        msg.formBlocks as any,
        FormBlock._writeMessage
      );
    }
    if (msg.css != undefined) {
      writer.writeString(3, msg.css);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: PublicFormData,
    reader: BinaryReader
  ): PublicFormData {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectName = reader.readString();
          break;
        }
        case 2: {
          const m = FormBlock.initialize();
          reader.readMessage(m, FormBlock._readMessage);
          msg.formBlocks.push(m);
          break;
        }
        case 3: {
          msg.css = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const GetPublicFormDataResponse = {
  /**
   * Serializes GetPublicFormDataResponse to protobuf.
   */
  encode: function (msg: Partial<GetPublicFormDataResponse>): Uint8Array {
    return GetPublicFormDataResponse._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetPublicFormDataResponse from protobuf.
   */
  decode: function (bytes: ByteSource): GetPublicFormDataResponse {
    return GetPublicFormDataResponse._readMessage(
      GetPublicFormDataResponse.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetPublicFormDataResponse with all fields set to their default value.
   */
  initialize: function (): GetPublicFormDataResponse {
    return {
      formData: PublicFormData.initialize(),
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetPublicFormDataResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.formData) {
      writer.writeMessage(1, msg.formData, PublicFormData._writeMessage);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetPublicFormDataResponse,
    reader: BinaryReader
  ): GetPublicFormDataResponse {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          reader.readMessage(msg.formData, PublicFormData._readMessage);
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const Answer = {
  /**
   * Serializes Answer to protobuf.
   */
  encode: function (msg: Partial<Answer>): Uint8Array {
    return Answer._writeMessage(msg, new BinaryWriter()).getResultBuffer();
  },

  /**
   * Deserializes Answer from protobuf.
   */
  decode: function (bytes: ByteSource): Answer {
    return Answer._readMessage(Answer.initialize(), new BinaryReader(bytes));
  },

  /**
   * Initializes Answer with all fields set to their default value.
   */
  initialize: function (): Answer {
    return {
      blockId: "",
      singleChoice: undefined,
      multipleChoice: [],
      textInput: undefined,
      accessCode: undefined,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<Answer>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.blockId) {
      writer.writeString(1, msg.blockId);
    }
    if (msg.singleChoice != undefined) {
      writer.writeInt32(2, msg.singleChoice);
    }
    if (msg.multipleChoice?.length) {
      writer.writePackedInt32(3, msg.multipleChoice);
    }
    if (msg.textInput != undefined) {
      writer.writeString(4, msg.textInput);
    }
    if (msg.accessCode != undefined) {
      writer.writeString(5, msg.accessCode);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (msg: Answer, reader: BinaryReader): Answer {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.blockId = reader.readString();
          break;
        }
        case 2: {
          msg.singleChoice = reader.readInt32();
          break;
        }
        case 3: {
          if (reader.isDelimited()) {
            msg.multipleChoice.push(...reader.readPackedInt32());
          } else {
            msg.multipleChoice.push(reader.readInt32());
          }
          break;
        }
        case 4: {
          msg.textInput = reader.readString();
          break;
        }
        case 5: {
          msg.accessCode = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const CompleteFormRequest = {
  /**
   * Serializes CompleteFormRequest to protobuf.
   */
  encode: function (msg: Partial<CompleteFormRequest>): Uint8Array {
    return CompleteFormRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes CompleteFormRequest from protobuf.
   */
  decode: function (bytes: ByteSource): CompleteFormRequest {
    return CompleteFormRequest._readMessage(
      CompleteFormRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes CompleteFormRequest with all fields set to their default value.
   */
  initialize: function (): CompleteFormRequest {
    return {
      formId: "",
      answers: [],
      email: "",
      referral: undefined,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<CompleteFormRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.formId) {
      writer.writeString(1, msg.formId);
    }
    if (msg.answers?.length) {
      writer.writeRepeatedMessage(2, msg.answers as any, Answer._writeMessage);
    }
    if (msg.email) {
      writer.writeString(3, msg.email);
    }
    if (msg.referral != undefined) {
      writer.writeString(4, msg.referral);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: CompleteFormRequest,
    reader: BinaryReader
  ): CompleteFormRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.formId = reader.readString();
          break;
        }
        case 2: {
          const m = Answer.initialize();
          reader.readMessage(m, Answer._readMessage);
          msg.answers.push(m);
          break;
        }
        case 3: {
          msg.email = reader.readString();
          break;
        }
        case 4: {
          msg.referral = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const CompleteFormResponse = {
  /**
   * Serializes CompleteFormResponse to protobuf.
   */
  encode: function (_msg?: Partial<CompleteFormResponse>): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes CompleteFormResponse from protobuf.
   */
  decode: function (_bytes?: ByteSource): CompleteFormResponse {
    return {};
  },

  /**
   * Initializes CompleteFormResponse with all fields set to their default value.
   */
  initialize: function (): CompleteFormResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<CompleteFormResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: CompleteFormResponse,
    _reader: BinaryReader
  ): CompleteFormResponse {
    return _msg;
  },
};

//========================================//
//          JSON Encode / Decode          //
//========================================//

export const GetPublicFormDataRequestJSON = {
  /**
   * Serializes GetPublicFormDataRequest to JSON.
   */
  encode: function (msg: Partial<GetPublicFormDataRequest>): string {
    return JSON.stringify(GetPublicFormDataRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes GetPublicFormDataRequest from JSON.
   */
  decode: function (json: string): GetPublicFormDataRequest {
    return GetPublicFormDataRequestJSON._readMessage(
      GetPublicFormDataRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetPublicFormDataRequest with all fields set to their default value.
   */
  initialize: function (): GetPublicFormDataRequest {
    return {
      formId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetPublicFormDataRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.formId) {
      json["formId"] = msg.formId;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetPublicFormDataRequest,
    json: any
  ): GetPublicFormDataRequest {
    const _formId_ = json["formId"];
    if (_formId_) {
      msg.formId = _formId_;
    }
    return msg;
  },
};

export const FormBlockMessageJSON = {
  /**
   * Serializes FormBlockMessage to JSON.
   */
  encode: function (msg: Partial<FormBlockMessage>): string {
    return JSON.stringify(FormBlockMessageJSON._writeMessage(msg));
  },

  /**
   * Deserializes FormBlockMessage from JSON.
   */
  decode: function (json: string): FormBlockMessage {
    return FormBlockMessageJSON._readMessage(
      FormBlockMessageJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes FormBlockMessage with all fields set to their default value.
   */
  initialize: function (): FormBlockMessage {
    return {
      text: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<FormBlockMessage>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.text) {
      json["text"] = msg.text;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (msg: FormBlockMessage, json: any): FormBlockMessage {
    const _text_ = json["text"];
    if (_text_) {
      msg.text = _text_;
    }
    return msg;
  },
};

export const OptionJSON = {
  /**
   * Serializes Option to JSON.
   */
  encode: function (msg: Partial<Option>): string {
    return JSON.stringify(OptionJSON._writeMessage(msg));
  },

  /**
   * Deserializes Option from JSON.
   */
  decode: function (json: string): Option {
    return OptionJSON._readMessage(OptionJSON.initialize(), JSON.parse(json));
  },

  /**
   * Initializes Option with all fields set to their default value.
   */
  initialize: function (): Option {
    return {
      id: "",
      text: "",
      karma: undefined,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (msg: Partial<Option>): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.id) {
      json["id"] = msg.id;
    }
    if (msg.text) {
      json["text"] = msg.text;
    }
    if (msg.karma != undefined) {
      json["karma"] = msg.karma;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (msg: Option, json: any): Option {
    const _id_ = json["id"];
    if (_id_) {
      msg.id = _id_;
    }
    const _text_ = json["text"];
    if (_text_) {
      msg.text = _text_;
    }
    const _karma_ = json["karma"];
    if (_karma_) {
      msg.karma = _karma_;
    }
    return msg;
  },
};

export const FormBlockSingleChoiceJSON = {
  /**
   * Serializes FormBlockSingleChoice to JSON.
   */
  encode: function (msg: Partial<FormBlockSingleChoice>): string {
    return JSON.stringify(FormBlockSingleChoiceJSON._writeMessage(msg));
  },

  /**
   * Deserializes FormBlockSingleChoice from JSON.
   */
  decode: function (json: string): FormBlockSingleChoice {
    return FormBlockSingleChoiceJSON._readMessage(
      FormBlockSingleChoiceJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes FormBlockSingleChoice with all fields set to their default value.
   */
  initialize: function (): FormBlockSingleChoice {
    return {
      title: "",
      options: [],
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<FormBlockSingleChoice>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.title) {
      json["title"] = msg.title;
    }
    if (msg.options?.length) {
      json["options"] = msg.options.map(OptionJSON._writeMessage);
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: FormBlockSingleChoice,
    json: any
  ): FormBlockSingleChoice {
    const _title_ = json["title"];
    if (_title_) {
      msg.title = _title_;
    }
    const _options_ = json["options"];
    if (_options_) {
      for (const item of _options_) {
        const m = Option.initialize();
        OptionJSON._readMessage(m, item);
        msg.options.push(m);
      }
    }
    return msg;
  },
};

export const FormBlockMultipleChoiceJSON = {
  /**
   * Serializes FormBlockMultipleChoice to JSON.
   */
  encode: function (msg: Partial<FormBlockMultipleChoice>): string {
    return JSON.stringify(FormBlockMultipleChoiceJSON._writeMessage(msg));
  },

  /**
   * Deserializes FormBlockMultipleChoice from JSON.
   */
  decode: function (json: string): FormBlockMultipleChoice {
    return FormBlockMultipleChoiceJSON._readMessage(
      FormBlockMultipleChoiceJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes FormBlockMultipleChoice with all fields set to their default value.
   */
  initialize: function (): FormBlockMultipleChoice {
    return {
      title: "",
      options: [],
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<FormBlockMultipleChoice>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.title) {
      json["title"] = msg.title;
    }
    if (msg.options?.length) {
      json["options"] = msg.options.map(OptionJSON._writeMessage);
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: FormBlockMultipleChoice,
    json: any
  ): FormBlockMultipleChoice {
    const _title_ = json["title"];
    if (_title_) {
      msg.title = _title_;
    }
    const _options_ = json["options"];
    if (_options_) {
      for (const item of _options_) {
        const m = Option.initialize();
        OptionJSON._readMessage(m, item);
        msg.options.push(m);
      }
    }
    return msg;
  },
};

export const FormBlockTextInputJSON = {
  /**
   * Serializes FormBlockTextInput to JSON.
   */
  encode: function (msg: Partial<FormBlockTextInput>): string {
    return JSON.stringify(FormBlockTextInputJSON._writeMessage(msg));
  },

  /**
   * Deserializes FormBlockTextInput from JSON.
   */
  decode: function (json: string): FormBlockTextInput {
    return FormBlockTextInputJSON._readMessage(
      FormBlockTextInputJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes FormBlockTextInput with all fields set to their default value.
   */
  initialize: function (): FormBlockTextInput {
    return {
      title: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<FormBlockTextInput>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.title) {
      json["title"] = msg.title;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: FormBlockTextInput,
    json: any
  ): FormBlockTextInput {
    const _title_ = json["title"];
    if (_title_) {
      msg.title = _title_;
    }
    return msg;
  },
};

export const FormBlockAccessCodeJSON = {
  /**
   * Serializes FormBlockAccessCode to JSON.
   */
  encode: function (msg: Partial<FormBlockAccessCode>): string {
    return JSON.stringify(FormBlockAccessCodeJSON._writeMessage(msg));
  },

  /**
   * Deserializes FormBlockAccessCode from JSON.
   */
  decode: function (json: string): FormBlockAccessCode {
    return FormBlockAccessCodeJSON._readMessage(
      FormBlockAccessCodeJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes FormBlockAccessCode with all fields set to their default value.
   */
  initialize: function (): FormBlockAccessCode {
    return {
      title: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<FormBlockAccessCode>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.title) {
      json["title"] = msg.title;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: FormBlockAccessCode,
    json: any
  ): FormBlockAccessCode {
    const _title_ = json["title"];
    if (_title_) {
      msg.title = _title_;
    }
    return msg;
  },
};

export const FormBlockContentJSON = {
  /**
   * Serializes FormBlockContent to JSON.
   */
  encode: function (msg: Partial<FormBlockContent>): string {
    return JSON.stringify(FormBlockContentJSON._writeMessage(msg));
  },

  /**
   * Deserializes FormBlockContent from JSON.
   */
  decode: function (json: string): FormBlockContent {
    return FormBlockContentJSON._readMessage(
      FormBlockContentJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes FormBlockContent with all fields set to their default value.
   */
  initialize: function (): FormBlockContent {
    return {
      type: "",
      message: undefined,
      singleChoice: undefined,
      multipleChoice: undefined,
      textInput: undefined,
      accessCode: undefined,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<FormBlockContent>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.type) {
      json["type"] = msg.type;
    }
    if (msg.message != undefined) {
      const _message_ = FormBlockMessageJSON._writeMessage(msg.message);
      json["message"] = _message_;
    }
    if (msg.singleChoice != undefined) {
      const _singleChoice_ = FormBlockSingleChoiceJSON._writeMessage(
        msg.singleChoice
      );
      json["singleChoice"] = _singleChoice_;
    }
    if (msg.multipleChoice != undefined) {
      const _multipleChoice_ = FormBlockMultipleChoiceJSON._writeMessage(
        msg.multipleChoice
      );
      json["multipleChoice"] = _multipleChoice_;
    }
    if (msg.textInput != undefined) {
      const _textInput_ = FormBlockTextInputJSON._writeMessage(msg.textInput);
      json["textInput"] = _textInput_;
    }
    if (msg.accessCode != undefined) {
      const _accessCode_ = FormBlockAccessCodeJSON._writeMessage(
        msg.accessCode
      );
      json["accessCode"] = _accessCode_;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (msg: FormBlockContent, json: any): FormBlockContent {
    const _type_ = json["type"];
    if (_type_) {
      msg.type = _type_;
    }
    const _message_ = json["message"];
    if (_message_) {
      const m = FormBlockMessage.initialize();
      FormBlockMessageJSON._readMessage(m, _message_);
      msg.message = m;
    }
    const _singleChoice_ = json["singleChoice"];
    if (_singleChoice_) {
      const m = FormBlockSingleChoice.initialize();
      FormBlockSingleChoiceJSON._readMessage(m, _singleChoice_);
      msg.singleChoice = m;
    }
    const _multipleChoice_ = json["multipleChoice"];
    if (_multipleChoice_) {
      const m = FormBlockMultipleChoice.initialize();
      FormBlockMultipleChoiceJSON._readMessage(m, _multipleChoice_);
      msg.multipleChoice = m;
    }
    const _textInput_ = json["textInput"];
    if (_textInput_) {
      const m = FormBlockTextInput.initialize();
      FormBlockTextInputJSON._readMessage(m, _textInput_);
      msg.textInput = m;
    }
    const _accessCode_ = json["accessCode"];
    if (_accessCode_) {
      const m = FormBlockAccessCode.initialize();
      FormBlockAccessCodeJSON._readMessage(m, _accessCode_);
      msg.accessCode = m;
    }
    return msg;
  },
};

export const FormBlockJSON = {
  /**
   * Serializes FormBlock to JSON.
   */
  encode: function (msg: Partial<FormBlock>): string {
    return JSON.stringify(FormBlockJSON._writeMessage(msg));
  },

  /**
   * Deserializes FormBlock from JSON.
   */
  decode: function (json: string): FormBlock {
    return FormBlockJSON._readMessage(
      FormBlockJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes FormBlock with all fields set to their default value.
   */
  initialize: function (): FormBlock {
    return {
      id: "",
      content: FormBlockContentJSON.initialize(),
      position: 0,
      placeholder: undefined,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (msg: Partial<FormBlock>): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.id) {
      json["id"] = msg.id;
    }
    if (msg.content) {
      const _content_ = FormBlockContentJSON._writeMessage(msg.content);
      if (Object.keys(_content_).length > 0) {
        json["content"] = _content_;
      }
    }
    if (msg.position) {
      json["position"] = msg.position;
    }
    if (msg.placeholder != undefined) {
      json["placeholder"] = msg.placeholder;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (msg: FormBlock, json: any): FormBlock {
    const _id_ = json["id"];
    if (_id_) {
      msg.id = _id_;
    }
    const _content_ = json["content"];
    if (_content_) {
      const m = FormBlockContent.initialize();
      FormBlockContentJSON._readMessage(m, _content_);
      msg.content = m;
    }
    const _position_ = json["position"];
    if (_position_) {
      msg.position = _position_;
    }
    const _placeholder_ = json["placeholder"];
    if (_placeholder_) {
      msg.placeholder = _placeholder_;
    }
    return msg;
  },
};

export const PublicFormDataJSON = {
  /**
   * Serializes PublicFormData to JSON.
   */
  encode: function (msg: Partial<PublicFormData>): string {
    return JSON.stringify(PublicFormDataJSON._writeMessage(msg));
  },

  /**
   * Deserializes PublicFormData from JSON.
   */
  decode: function (json: string): PublicFormData {
    return PublicFormDataJSON._readMessage(
      PublicFormDataJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes PublicFormData with all fields set to their default value.
   */
  initialize: function (): PublicFormData {
    return {
      projectName: "",
      formBlocks: [],
      css: undefined,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<PublicFormData>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectName) {
      json["projectName"] = msg.projectName;
    }
    if (msg.formBlocks?.length) {
      json["formBlocks"] = msg.formBlocks.map(FormBlockJSON._writeMessage);
    }
    if (msg.css != undefined) {
      json["css"] = msg.css;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (msg: PublicFormData, json: any): PublicFormData {
    const _projectName_ = json["projectName"];
    if (_projectName_) {
      msg.projectName = _projectName_;
    }
    const _formBlocks_ = json["formBlocks"];
    if (_formBlocks_) {
      for (const item of _formBlocks_) {
        const m = FormBlock.initialize();
        FormBlockJSON._readMessage(m, item);
        msg.formBlocks.push(m);
      }
    }
    const _css_ = json["css"];
    if (_css_) {
      msg.css = _css_;
    }
    return msg;
  },
};

export const GetPublicFormDataResponseJSON = {
  /**
   * Serializes GetPublicFormDataResponse to JSON.
   */
  encode: function (msg: Partial<GetPublicFormDataResponse>): string {
    return JSON.stringify(GetPublicFormDataResponseJSON._writeMessage(msg));
  },

  /**
   * Deserializes GetPublicFormDataResponse from JSON.
   */
  decode: function (json: string): GetPublicFormDataResponse {
    return GetPublicFormDataResponseJSON._readMessage(
      GetPublicFormDataResponseJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetPublicFormDataResponse with all fields set to their default value.
   */
  initialize: function (): GetPublicFormDataResponse {
    return {
      formData: PublicFormDataJSON.initialize(),
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetPublicFormDataResponse>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.formData) {
      const _formData_ = PublicFormDataJSON._writeMessage(msg.formData);
      if (Object.keys(_formData_).length > 0) {
        json["formData"] = _formData_;
      }
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetPublicFormDataResponse,
    json: any
  ): GetPublicFormDataResponse {
    const _formData_ = json["formData"];
    if (_formData_) {
      const m = PublicFormData.initialize();
      PublicFormDataJSON._readMessage(m, _formData_);
      msg.formData = m;
    }
    return msg;
  },
};

export const AnswerJSON = {
  /**
   * Serializes Answer to JSON.
   */
  encode: function (msg: Partial<Answer>): string {
    return JSON.stringify(AnswerJSON._writeMessage(msg));
  },

  /**
   * Deserializes Answer from JSON.
   */
  decode: function (json: string): Answer {
    return AnswerJSON._readMessage(AnswerJSON.initialize(), JSON.parse(json));
  },

  /**
   * Initializes Answer with all fields set to their default value.
   */
  initialize: function (): Answer {
    return {
      blockId: "",
      singleChoice: undefined,
      multipleChoice: [],
      textInput: undefined,
      accessCode: undefined,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (msg: Partial<Answer>): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.blockId) {
      json["blockId"] = msg.blockId;
    }
    if (msg.singleChoice != undefined) {
      json["singleChoice"] = msg.singleChoice;
    }
    if (msg.multipleChoice?.length) {
      json["multipleChoice"] = msg.multipleChoice;
    }
    if (msg.textInput != undefined) {
      json["textInput"] = msg.textInput;
    }
    if (msg.accessCode != undefined) {
      json["accessCode"] = msg.accessCode;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (msg: Answer, json: any): Answer {
    const _blockId_ = json["blockId"];
    if (_blockId_) {
      msg.blockId = _blockId_;
    }
    const _singleChoice_ = json["singleChoice"];
    if (_singleChoice_) {
      msg.singleChoice = _singleChoice_;
    }
    const _multipleChoice_ = json["multipleChoice"];
    if (_multipleChoice_) {
      msg.multipleChoice = _multipleChoice_;
    }
    const _textInput_ = json["textInput"];
    if (_textInput_) {
      msg.textInput = _textInput_;
    }
    const _accessCode_ = json["accessCode"];
    if (_accessCode_) {
      msg.accessCode = _accessCode_;
    }
    return msg;
  },
};

export const CompleteFormRequestJSON = {
  /**
   * Serializes CompleteFormRequest to JSON.
   */
  encode: function (msg: Partial<CompleteFormRequest>): string {
    return JSON.stringify(CompleteFormRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes CompleteFormRequest from JSON.
   */
  decode: function (json: string): CompleteFormRequest {
    return CompleteFormRequestJSON._readMessage(
      CompleteFormRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes CompleteFormRequest with all fields set to their default value.
   */
  initialize: function (): CompleteFormRequest {
    return {
      formId: "",
      answers: [],
      email: "",
      referral: undefined,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<CompleteFormRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.formId) {
      json["formId"] = msg.formId;
    }
    if (msg.answers?.length) {
      json["answers"] = msg.answers.map(AnswerJSON._writeMessage);
    }
    if (msg.email) {
      json["email"] = msg.email;
    }
    if (msg.referral != undefined) {
      json["referral"] = msg.referral;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: CompleteFormRequest,
    json: any
  ): CompleteFormRequest {
    const _formId_ = json["formId"];
    if (_formId_) {
      msg.formId = _formId_;
    }
    const _answers_ = json["answers"];
    if (_answers_) {
      for (const item of _answers_) {
        const m = Answer.initialize();
        AnswerJSON._readMessage(m, item);
        msg.answers.push(m);
      }
    }
    const _email_ = json["email"];
    if (_email_) {
      msg.email = _email_;
    }
    const _referral_ = json["referral"];
    if (_referral_) {
      msg.referral = _referral_;
    }
    return msg;
  },
};

export const CompleteFormResponseJSON = {
  /**
   * Serializes CompleteFormResponse to JSON.
   */
  encode: function (_msg?: Partial<CompleteFormResponse>): string {
    return "{}";
  },

  /**
   * Deserializes CompleteFormResponse from JSON.
   */
  decode: function (_json?: string): CompleteFormResponse {
    return {};
  },

  /**
   * Initializes CompleteFormResponse with all fields set to their default value.
   */
  initialize: function (): CompleteFormResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<CompleteFormResponse>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: CompleteFormResponse,
    _json: any
  ): CompleteFormResponse {
    return msg;
  },
};
