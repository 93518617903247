import classNames from "classnames"
import { PartyPopper, ThumbsUp } from "lucide-react"
import { useCallback, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { Spinner } from "../components/Spinner"
import { Button, Column } from "../components/UI"
import { settings } from "../lib/settings"
import * as cloud from "../messages/cloud.pb"

const AcceptInviteCallback = () => {
	const [id, setId] = useState<string | null>(null)
	const [signup, setSignup] = useState<cloud.GetInvitedSignupResponse | null>(null)
	const [accepting, setAccepting] = useState(false)
	const [success, setSuccess] = useState(false)
	const [resp, setResp] = useState<cloud.AcceptInviteResponse | null>(null)

	useEffect(() => {
		const params = new URLSearchParams(window.location.search)
		const id = params.get("id")
		setId(id)
	}, [])

	useEffect(() => {
		if (!id) {
			return
		}
		cloud.GetInvitedSignup({ inviteId: id }).then((r) => {
			setSignup(r)
		})
	}, [id])

	const acceptInvite = useCallback(() => {
		if (!id) {
			return
		}
		setAccepting(true)
		cloud
			.AcceptInvite({ inviteId: id })
			.then((r) => {
				setResp(r)
				setSuccess(true)
			})
			.finally(() => setAccepting(false))
	}, [id])

	if (!id) {
		return null
	}

	if (resp != null && resp.redirectTo != null) {
		window.location.replace(resp.redirectTo)
	}

	return (
		<>
			<Helmet>
				<title>{settings.appName}</title>
			</Helmet>
			<Column className={classNames("items-center justify-center h-full")}>
				{!signup ? (
					<Spinner />
				) : success ? (
					<>
						<ThumbsUp />
						{(resp != null && resp.message) || "Hooray!"}
					</>
				) : (
					<Column className="items-center text-center gap-8">
						<PartyPopper />
						<p>
							Great news!
							<br />
							You've been invited to {signup.project}
						</p>
						<Button onClick={acceptInvite} disabled={accepting}>
							Accept invite
						</Button>
					</Column>
				)}
			</Column>
		</>
	)
}

export default AcceptInviteCallback
