import React, { ReactNode } from "react"

type Element = ReactNode | (() => ReactNode)

function getElement(element: Element): ReactNode {
	if (React.isValidElement(element)) {
		return element
	}
	if (typeof element === "function") {
		return element()
	}
	return element
}

export function when(condition: boolean, element: Element): ReactNode {
	if (!condition) {
		return null
	}
	return getElement(element)
}

export function unless(condition: boolean, element: Element): ReactNode {
	if (!condition) {
		return null
	}
	return getElement(element)
}

export function either(condition: boolean, left: Element, right: Element): ReactNode {
	if (!condition) {
		return getElement(left)
	}
	return getElement(right)
}
