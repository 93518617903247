import classNames from "classnames"
import { motion } from "framer-motion"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { ContextMenuOption, useAppStore } from "../lib/appStore"
import { buttonWhileTap } from "./UI"

export const ContextMenuContainer = () => {
	const contextMenu = useAppStore((store) => store.contextMenu)
	const setContextMenu = useAppStore((store) => store.setContextMenu)

	const onClickOption = useCallback(
		(opt: ContextMenuOption) => (e: React.MouseEvent) => {
			e.stopPropagation()
			if (opt.onClick) {
				opt.onClick().then(() => {
					setContextMenu(null)
				})
			}
		},
		[setContextMenu]
	)

	const ref = useRef<HTMLDivElement | null>()

	const [x, setX] = useState(0)
	const [y, setY] = useState(0)

	useEffect(() => {
		if (!ref || !ref.current) {
			return
		}
		if (!contextMenu) {
			return
		}
		const padding = 20
		let px = contextMenu.position.x
		let py = contextMenu.position.y
		if (px + ref.current.offsetWidth > window.innerWidth - padding) {
			px = window.innerWidth - ref.current.offsetWidth - padding
		}
		if (py + ref.current.offsetHeight > window.innerHeight - padding) {
			py = window.innerWidth - ref.current.offsetHeight - padding
		}
		setX(px)
		setY(py)
	}, [ref, contextMenu])

	if (!contextMenu) {
		return null
	}

	return (
		<div
			className="z-40 select-none fixed top-0 left-0 w-screen h-screen cursor-default"
			onClick={() => {
				setContextMenu(null)
			}}
		>
			<motion.div
				initial={{ scale: 0 }}
				animate={{ scale: 1 }}
				ref={ref}
				className={classNames("gap-0 p-1 rounded shadow-menu absolute text-sm", "bg-white border")}
				style={{
					left: x,
					top: y,
				}}
			>
				{contextMenu.options.map((opt, index) => {
					if (opt.separator) {
						return <div key={index} className="border-b border-neutral-100 my-1" />
					}
					return (
						<motion.div
							key={index}
							whileTap={buttonWhileTap}
							onClick={onClickOption(opt)}
							className={classNames("rounded px-2 py-1 min-w-[80px]", {
								"hover:bg-blue-500 hover:text-white": opt.onClick && !opt.danger,
								"hover:bg-red-500 hover:text-white": opt.danger,
								"text-neutral-400": !opt.onClick,
							})}
						>
							{opt.text}
						</motion.div>
					)
				})}
			</motion.div>
		</div>
	)
}

export interface ContextMenuPosition {
	x: number
	y: number
}

export const getRelativeContextMenuPosition = (e: HTMLDivElement | null): ContextMenuPosition => {
	if (e == null) {
		return { x: 0, y: 0 }
	}
	const rect = e.getBoundingClientRect()
	const width = rect.width
	return { x: rect.x + width / 2, y: rect.y + width / 2 }
}
