import classNames from "classnames"
import { Clock, FileText, Globe, Key, Mail, PieChart, Send, Settings, Share2, Tags, Users } from "lucide-react"
import { useNavigate } from "react-router-dom"
import * as cloud from "../messages/cloud.pb"
import { Column, Row } from "./UI"

export const SidebarMenu = ({ project }: { project: cloud.Project }) => {
	return (
		<Column className={classNames("w-[250px] p-4 border-r min-h-full", "border-stone-200")}>
			<Item title="Overview" icon={<PieChart />} link={`/projects/${project.id}`} />
			<Item title="Waitlist" icon={<Users />} link={`/projects/${project.id}/waitlist`} />
			<Item title="Invited" icon={<Send />} link={`/projects/${project.id}/invites`} />
			<Item title="Signup Form" icon={<FileText />} link={`/projects/${project.id}/form`} />
			<Item title="Invite Schedules" icon={<Clock />} link={`/projects/${project.id}/schedules`} />
			<Item title="Webhooks" icon={<Share2 />} link={`/projects/${project.id}/webhooks`} />
			<Item title="Email Templates" icon={<Mail />} link={`/projects/${project.id}/email-templates`} />
			<Item title="Domains" icon={<Globe />} link={`/projects/${project.id}/domains`} />
			<Item title="Access Codes" icon={<Tags />} link={`/projects/${project.id}/access-codes`} />
			<Item title="API Keys" icon={<Key />} link={`/projects/${project.id}/api-keys`} />
			<Item title="Members" icon={<Users />} link={`/projects/${project.id}/members`} />
			<Item title="Settings" icon={<Settings />} link={`/projects/${project.id}/settings`} />
		</Column>
	)
}

const Item = ({ title, icon, link }: { title: string; icon: JSX.Element; link: string }) => {
	const nav = useNavigate()
	const current = window.location.href.endsWith(link)
	return (
		<a href={link} className="transition no-underline !text-inherit">
			<Row
				className={classNames(
					"transition p-3 rounded-lg !justify-start gap-2 select-none cursor-pointer svg:w-[20px] svg:h-[20px]",
					"hover:bg-black hover:text-white ",
					{
						"bg-stone-200 text-black  ": current,
					}
				)}
				onClick={(e) => {
					e.preventDefault()
					nav(link)
				}}
			>
				{icon}
				{title}
			</Row>
		</a>
	)
}
