import { Column } from "../components/UI"

const NotFoundPage = () => {
	return (
		<Column className="h-full items-center justify-center">
			<h1>404</h1>
			<div className="text-neutral-400">¯\_(ツ)_/¯</div>
		</Column>
	)
}

export default NotFoundPage
