import * as uuid from "uuid"
import * as cloud from "../messages/cloud.pb"
import { FormBlockContent, Option } from "../messages/forms.pb"

export const blockTypePrettyName = (content: FormBlockContent): string => {
	switch (content.type) {
		case "message":
			return "Message"
		case "singleChoice":
			return "Single choice"
		case "multipleChoice":
			return "Multiple choice"
		case "textInput":
			return "Text input"
		case "accessCode":
			return "Access code"
		default:
			throw new Error("cannot get pretty name")
	}
}

export function newOption(): Option {
	return {
		id: uuid.v4(),
		text: "",
		karma: 0,
	}
}

export function newBlockContentMessage(): FormBlockContent {
	return {
		type: "message",
		message: {
			text: "",
		},
	}
}

export function newBlockContentSingleChoice(): FormBlockContent {
	return {
		type: "singleChoice",
		singleChoice: {
			title: "",
			options: [
				{ text: "", karma: 0, id: uuid.v4() },
				{ text: "", karma: 0, id: uuid.v4() },
				{ text: "", karma: 0, id: uuid.v4() },
			],
		},
	}
}

export function newBlockContentMultipleChoice(): FormBlockContent {
	return {
		type: "multipleChoice",
		multipleChoice: {
			title: "",
			options: [
				{ text: "", karma: 0, id: uuid.v4() },
				{ text: "", karma: 0, id: uuid.v4() },
				{ text: "", karma: 0, id: uuid.v4() },
			],
		},
	}
}

export function newBlockContentTextInput(): FormBlockContent {
	return {
		type: "textInput",
		textInput: {
			title: "",
		},
	}
}

export function newBlockContentAccessCode(): FormBlockContent {
	return {
		type: "accessCode",
		accessCode: {
			title: "",
		},
	}
}

export const accessCodeDestinations = {
	invite: "invite",
	waitlist: "waitlist",
}

export const permissionAccessName = (access: number): string => {
	switch (access) {
		case 1:
			return "Read"
		case 2:
			return "Write"
		case 3:
			return "Owner"
		default:
			return "No access"
	}
}

export const isAccessReadOnly = (project: cloud.Project): boolean => {
	return project.access === 1
}

export const isAccessWrite = (project: cloud.Project): boolean => {
	return project.access === 2
}

export const isAccessOwner = (project: cloud.Project): boolean => {
	return project.access === 3
}
