import classNames from "classnames"
import prettify from "html-prettify"
import { highlight, languages } from "prismjs/components/prism-core"
import "prismjs/components/prism-markup"
import "prismjs/themes/prism.css" //Example style, you can use another
import { useCallback, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useParams } from "react-router-dom"
import Editor from "react-simple-code-editor"
import { TrixEditor } from "react-trix"
import "trix/dist/trix.esm"
import { useDebouncedCallback } from "use-debounce"
import { SidebarMenu } from "../../components/SidebarMenu"
import { Skeleton } from "../../components/Skeleton"
import { Spinner } from "../../components/Spinner"
import { Button, Column, Mono, Row, Tile } from "../../components/UI"
import { isAccessReadOnly } from "../../interfaces"
import { getProject } from "../../lib/api"
import { settings } from "../../lib/settings"
import * as cloud from "../../messages/cloud.pb"
import "../../styles/trix.css"

// Trix.config.blockAttributes.button = { tagName: "button", nestable: false }

window.addEventListener("trix-initialize", (event: any) => {
	const buttonHTML =
		'<button type="button" class="trix-button" data-trix-attribute="button" title="Custom Button">Button</button>'
	const groupElement = event.target.toolbarElement.querySelector(".trix-button-group--block-tools")
	groupElement.insertAdjacentHTML("beforeend", buttonHTML)

	const { toolbarElement } = event.target
	const input = toolbarElement.querySelector("input[name=href]")
	// Change the input type from "url" to "text"
	input.type = "text"
})

const ProjectEmailTemplatesPage = () => {
	const params = useParams<{ id: string }>()
	const [project, setProject] = useState<cloud.Project>()

	useEffect(() => {
		if (!params.id) {
			return
		}
		getProject(params.id).then(setProject)
	}, [params.id])

	if (!project) {
		return null
	}

	return (
		<>
			<Helmet>
				<title>
					{project.name} – {settings.appName}
				</title>
			</Helmet>
			<Skeleton project={project}>
				<SidebarMenu project={project} />
				<Column className="flex-1 h-full items-center p-4">
					<Column className="w-full max-w-[1000px]">
						<SectionEmailTemplates project={project} />
					</Column>
				</Column>
			</Skeleton>
		</>
	)
}

type Mode = "wysiwyg" | "html"

const SectionEmailTemplates = ({ project }: { project: cloud.Project }) => {
	const [templates, setTemplates] = useState<cloud.EmailTemplate[] | null>()
	const getTemplates = useCallback(() => {
		cloud.GetProjectEmailTemplates({ projectId: project.id }).then((r) => {
			setTemplates(
				r.templates
					.sort((a, b) => a.type.localeCompare(b.type))
					.map((t) => {
						return { ...t, content: sanitizeContent(t.content) }
					})
			)
		})
	}, [project])
	useEffect(() => {
		getTemplates()
	}, [getTemplates])
	const niceTemplateType = (typ: string): string => {
		switch (typ) {
			case "invite":
				return "Signup invite"
			case "signup_confirm_email":
				return "Waitlist signup confirmation"
			case "signup_success":
				return "Waitlist signup success (post-confirm)"
			default:
				return typ
		}
	}
	const descriptions = (typ: string): string => {
		switch (typ) {
			case "invite":
				return "Email sent when a signup gets invited."
			case "signup_confirm_email":
				return "Email sent to somebody that signs up to the waitlist and needs to confirm his/her email."
			case "signup_success":
				return "Email sent to somebody that verifies his/her email after signing up to the waitlist."
			default:
				return typ
		}
	}
	const [editing, setEditing] = useState<string | null>(null)
	if (templates == null) {
		return <Spinner />
	}
	return (
		<Column>
			{templates.map((t, index) => {
				const selected = editing === t.type
				return (
					<Tile
						key={index}
						className={classNames({
							"opacity-25": editing != null && !selected,
						})}
					>
						<Row className="gap-2">
							<Column className="gap-2">
								<div>
									<Mono className="text-xs !p-1">{niceTemplateType(t.type)}</Mono>
								</div>
								<div className="text-sm">{descriptions(t.type)}</div>
							</Column>
							<Button
								disabled={isAccessReadOnly(project)}
								onClick={() => {
									setEditing(editing === t.type ? null : t.type)
								}}
							>
								Edit
							</Button>
						</Row>
						{editing === t.type && <TemplateEditor template={t} project={project} onSave={getTemplates} />}
					</Tile>
				)
			})}
		</Column>
	)
}

const TemplateEditor = ({
	template,
	project,
	onSave,
}: {
	template: cloud.EmailTemplate
	project: cloud.Project
	onSave: () => void
}) => {
	const [mode, setMode] = useState<Mode>("wysiwyg")
	const [changed, setChanged] = useState(false)
	const [content, setContent] = useState<string>(sanitizeContent(template.content))
	const updateTemplate = useDebouncedCallback((t: cloud.EmailTemplate, e: string) => {
		cloud
			.UpsertProjectEmailTemplate({
				projectId: project.id,
				templateId: t.id,
				content: sanitizeContent(e),
				type: t.type,
			})
			.then(() => {
				setChanged(false)
				onSave()
			})
	}, 100)

	return (
		<>
			<Row>
				<Row className="gap-2">
					<Button color={mode === "wysiwyg" ? "primary" : "secondary"} onClick={() => setMode("wysiwyg")} size="small">
						Simple editor
					</Button>
					<Button color={mode === "html" ? "primary" : "secondary"} onClick={() => setMode("html")} size="small">
						HTML editor
					</Button>
				</Row>
				<Row className="gap-2">
					<Row className="text-sm !justify-start gap-2 bg-sky-200  border border-sky-300  rounded p-1 text-sky-700 ">
						<Row className="gap-2">
							Variables:
							{Object.keys(template.variables)
								.sort((a, b) => a.localeCompare(b))
								.map((v, index) => {
									return (
										<span
											key={`var-${index}`}
											className="font-mono text-xs bg-sky-600 rounded p-1 text-white font-medium cursor-help"
											title={template.variables[v]}
										>
											{v}
										</span>
									)
								})}
						</Row>
					</Row>
					<Button
						size="small"
						color={!changed ? "secondary" : "warning"}
						disabled={!changed}
						onClick={() => {
							updateTemplate(template, content)
						}}
					>
						Save
					</Button>
				</Row>
			</Row>
			{mode === "wysiwyg" && (
				<>
					<TrixEditor
						className={classNames("rounded p-3 h-[300px] overflow-scroll", "bg-white", "border border-stone-200 ")}
						value={content}
						onChange={(e) => {
							setChanged(true)
							setContent(e)
						}}
						onEditorReady={() => {}}
						mergeTags={[]}
					/>
					<PreviewIframe content={content} />
				</>
			)}
			{mode === "html" && (
				<Row className="!items-stretch min-h-[300px] gap-2">
					<Editor
						value={content}
						onValueChange={(e) => {
							setChanged(true)
							setContent(e)
						}}
						tabSize={4}
						highlight={(code) => highlight(code, languages.markup)}
						className="border w-full h-full overflow-scroll p-4 rounded bg-white font-mono text-sm"
					/>
					<PreviewIframe content={content} />
				</Row>
			)}
			<div>
				<Button
					size="small"
					color="secondary"
					onClick={() => {
						cloud.GetDefaultEmailTemplate({ projectId: project.id, type: template.type }).then((r) => {
							setContent(r.template.content)
							if (mode === "wysiwyg") {
								const editor = (document.querySelector("trix-editor") as any).editor
								editor.setSelectedRange([0, 0])
								editor.insertHTML(r.template.content)
							}
						})
					}}
				>
					Restore default template
				</Button>
			</div>
		</>
	)
}

const sanitizeContent = (c: string) => {
	return prettify(c.replace(/<!--block-->/g, "\n"))
}

const PreviewIframe = (props: { content: string }) => {
	const writeHTML = (frame: HTMLIFrameElement) => {
		if (!frame) {
			return
		}
		let doc = frame.contentDocument
		doc.open()
		doc.write(props.content)
		doc.close()
		frame.style.width = "100%"
		frame.style.fontFamily = "sans-serif"
		frame.style.height = `${frame.contentWindow.document.body.scrollHeight}px`
	}
	return <iframe className="border !h-full rounded" title="preview" src="about:blank" ref={writeHTML} />
}

export default ProjectEmailTemplatesPage
