import { Edit, X } from "lucide-react"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useNavigate, useParams } from "react-router-dom"
import { SidebarMenu } from "../../components/SidebarMenu"
import { Skeleton } from "../../components/Skeleton"
import { Button, Column, Mono, Row } from "../../components/UI"
import { isAccessReadOnly } from "../../interfaces"
import { getProject } from "../../lib/api"
import { useAppStore } from "../../lib/appStore"
import { settings } from "../../lib/settings"
import * as cloud from "../../messages/cloud.pb"

const ProjectSettingsPage = () => {
	const params = useParams<{ id: string }>()
	const [project, setProject] = useState<cloud.Project>()

	useEffect(() => {
		if (!params.id) {
			return
		}
		getProject(params.id).then(setProject)
	}, [params.id])

	if (!project) {
		return null
	}

	return (
		<>
			<Helmet>
				<title>
					{project.name} – {settings.appName}
				</title>
			</Helmet>
			<Skeleton project={project}>
				<SidebarMenu project={project} />
				<Column className="flex-1 h-full items-center p-4">
					<Column className="w-full max-w-[1000px]">
						<Column className="gap-8">
							<SectionGeneral project={project} getProject={() => getProject(project.id, true).then(setProject)} />
						</Column>
					</Column>
				</Column>
			</Skeleton>
		</>
	)
}

const Item = ({
	title,
	description,
	value,
	editable,
	clearable,
	onCheck,
	onClickEdit,
	onClickClear,
	action,
	project,
}: {
	title: string
	description: string
	value?: string | boolean
	editable?: boolean
	clearable?: boolean
	onCheck?: (v: boolean) => void
	onClickEdit?: () => void
	onClickClear?: () => void
	action?: JSX.Element
	project: cloud.Project
}) => {
	return (
		<Row className="!items-start">
			<Column className="flex-[0.7]">
				{title}
				<div className="text-neutral-500 text-sm">{description}</div>
			</Column>
			{action ? (
				action
			) : (
				<Row className="gap-4 flex-1 !justify-end">
					{typeof value === "boolean" ? (
						<>
							<input
								disabled={isAccessReadOnly(project)}
								type="checkbox"
								checked={value}
								onChange={(e) => onCheck(e.target.checked)}
							/>
						</>
					) : (
						<>
							{editable && !isAccessReadOnly(project) && (
								<Button size="small" aspect="square" color="empty" onClick={onClickEdit}>
									<Edit />
								</Button>
							)}
							<Mono>{value}</Mono>
							{clearable && !isAccessReadOnly(project) && !!value && (
								<Button size="small" aspect="square" color="empty" onClick={onClickClear}>
									<X />
								</Button>
							)}
						</>
					)}
				</Row>
			)}
		</Row>
	)
}

const SectionGeneral = ({ project, getProject }: { project: cloud.Project; getProject: () => void }) => {
	const setDialog = useAppStore((store) => store.setDialog)
	const nav = useNavigate()

	return (
		<>
			<Item project={project} title="ID" description="The unique ID of your project." value={project.id} />
			<Item
				project={project}
				title="Name"
				description="The name of your project."
				value={project.name}
				editable={true}
				onClickEdit={() => {
					const newName = window.prompt("New name", project.name)
					cloud.UpdateProjectName({ projectId: project.id, newName }).then(() => {
						getProject()
					})
				}}
			/>
			<Item
				project={project}
				title="Handle"
				description="This is a unique handle for your project. It's used to identify your project's form and in the emails sent to
					your users."
				value={project.handle}
				editable={true}
				onClickEdit={() => {
					const newHandle = window.prompt("New handle", project.handle)
					cloud.UpdateProjectHandle({ projectId: project.id, newHandle }).then(() => {
						getProject()
					})
				}}
			/>
			<Item
				project={project}
				title="From email"
				description="This email address is based on your project handle and will appear as the sender field in any email sent to your users."
				value={project.fromEmail}
			/>
			<Item
				project={project}
				title="Reply-To"
				description="Users in the waitlist that receive emails (e.g., upon signing up) will be able to reply to this address."
				value={project.emailReplyTo || ""}
				editable={true}
				clearable={true}
				onClickClear={() => {
					cloud
						.UpdateProjectEmailReplyTo({
							projectId: project.id,
						})
						.then(getProject)
				}}
				onClickEdit={() => {
					const newEmailReplyTo = window.prompt("New Reply-To", project.emailReplyTo).trim()
					if (!newEmailReplyTo) {
						return
					}
					cloud
						.UpdateProjectEmailReplyTo({
							projectId: project.id,
							newEmailReplyTo: newEmailReplyTo.toLocaleLowerCase(),
						})
						.then(getProject)
				}}
			/>
			<Item
				project={project}
				title="Require access codes"
				description="If checked, waitlist signups need to insert a valid access code."
				value={project.requiresAccessCode}
				onCheck={(value) => {
					cloud.UpdateProjectRequiresAccessCode({ projectId: project.id, value }).then(() => getProject())
				}}
			/>
			<Item
				project={project}
				title="Invite accepted callback"
				description="A request is sent to this URL when a user accepts an invite. It can return a redirect link and a message."
				value={project.inviteAcceptedCallback || ""}
				editable={true}
				clearable={true}
				onClickClear={() => {
					cloud
						.UpdateProjectInviteAcceptedCallback({
							projectId: project.id,
						})
						.then(getProject)
				}}
				onClickEdit={() => {
					const url = window.prompt("New callback", project.inviteAcceptedCallback).trim()
					if (!url) {
						return
					}
					cloud
						.UpdateProjectInviteAcceptedCallback({
							projectId: project.id,
							url: url.toLowerCase(),
						})
						.then(getProject)
				}}
			/>
			<Item
				project={project}
				title="Invite accepted redirect URL"
				description="If no other redirect is set with the callback above, an user that accepts an invite is redirected to this URL."
				value={project.inviteRedirectUrl || ""}
				editable={true}
				clearable={true}
				onClickClear={() => {
					cloud
						.UpdateProjectInviteRedirectUrl({
							projectId: project.id,
						})
						.then(getProject)
				}}
				onClickEdit={() => {
					const url = window.prompt("New redirect URL", project.inviteRedirectUrl).trim()
					if (!url) {
						return
					}
					cloud
						.UpdateProjectInviteRedirectUrl({
							projectId: project.id,
							url: url.toLowerCase(),
						})
						.then(getProject)
				}}
			/>

			<Column className="p-5 border border-red-600 rounded">
				<Item
					project={project}
					title="Delete project"
					description="Delete this project immediately, and all the related signups and invites."
					action={
						<Button
							disabled={isAccessReadOnly(project)}
							color="danger"
							onClick={() => {
								setDialog({
									danger: true,
									text: "Are you sure?",
									onConfirm: () => {
										cloud.DeleteProject({ projectId: project.id }).then((r) => {
											nav(`/`)
										})
									},
								})
							}}
						>
							Delete project
						</Button>
					}
				/>
			</Column>
		</>
	)
}

export default ProjectSettingsPage
