import classNames from "classnames"
import { ChevronLeft } from "lucide-react"
import moment from "moment"
import pluralize from "pluralize"
import { useCallback, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useNavigate, useParams } from "react-router-dom"
import { SidebarMenu } from "../../components/SidebarMenu"
import { Skeleton } from "../../components/Skeleton"
import { Button, Column, Row, Tile } from "../../components/UI"
import { settings } from "../../lib/settings"
import * as cloud from "../../messages/cloud.pb"

const ProjectSignupDetailsPage = () => {
	const params = useParams<{ id: string; signupId: string }>()
	const [details, setDetails] = useState<cloud.ProjectSignupDetails>()

	useEffect(() => {
		if (!params.id) {
			return
		}
		if (!params.signupId) {
			return
		}
		cloud.GetProjectSignupDetails({ projectId: params.id, signupId: params.signupId }).then((r) => {
			setDetails(r.details)
		})
	}, [params])

	const sendInvite = useCallback(
		(signup: cloud.ProjectSignup) => () => {
			cloud.SendInvite({ projectId: signup.projectId, signupId: signup.id }).then((r) => {
				window.location.reload()
			})
		},
		[]
	)

	if (!details) {
		return null
	}

	return (
		<>
			<Helmet>
				<title>
					{details.project.name} – {settings.appName}
				</title>
			</Helmet>
			<Skeleton title={details.project.name} backLink={`/projects/${details.project.id}/waitlist`}>
				<Column className="flex-1 h-full items-center p-4">
					<Column className="w-full max-w-[1000px]">
						<Column className="gap-4">
							<Column className="gap-4">
								<Row>
									<div>ID</div>
									<div className="font-mono text-sm">{details.signup.id}</div>
								</Row>
								<Row>
									<div>Email</div>
									<div className="font-medium">{details.signup.email}</div>
								</Row>
								{(details.signup.position && (
									<Row>
										<div>Position</div>
										<div className="font-medium font-mono">{details.signup.position}</div>
									</Row>
								)) ||
									null}
								<Row>
									<div>Karma</div>
									<div
										className={classNames("font-medium font-mono", {
											"text-green-500": details.signup.karma > 0,
											"text-red-500": details.signup.karma < 0,
										})}
									>
										{details.signup.karma > 0 && "+"}
										{details.signup.karma}
									</div>
								</Row>
								<Row>
									<div>Signed up</div>
									<div>{moment.unix(details.signup.createdAt).fromNow()}</div>
								</Row>
								<Row>
									<div>Invited</div>
									<div>
										{details.signup.invitedAt ? (
											moment.unix(details.signup.invitedAt).fromNow()
										) : (
											<Row className="gap-4">
												<div>Not yet</div>
												<Button size="small" onClick={sendInvite(details.signup)}>
													Invite
												</Button>
											</Row>
										)}
									</div>
								</Row>
								{details.signup.invitedAt ? (
									<Row>
										<div>Accepted</div>
										<div className={classNames({ "text-orange-500": !details.signup.acceptedAt })}>
											{details.signup.acceptedAt ? moment.unix(details.signup.acceptedAt).fromNow() : "Not yet"}
										</div>
									</Row>
								) : null}
							</Column>
							{details.answers.length > 0 && (
								<Column>
									<h1>Answers</h1>
									<Column>
										{details.answers.map((a) => {
											const block = details.blocks.find((b) => b.id === a.blockId)
											return (
												<Column key={a.blockId} className="rounded p-3 bg-neutral-100 ">
													<div className="italic">
														{block.content.singleChoice?.title ||
															block.content.multipleChoice?.title ||
															block.content.textInput?.title}
													</div>
													<Column className="font-mono gap-2">
														{a.singleChoice !== undefined && block.content.singleChoice && (
															<Answer
																text={block.content.singleChoice.options[a.singleChoice].text}
																karma={block.content.singleChoice.options[a.singleChoice].karma}
															/>
														)}
														{a.multipleChoice &&
															block.content.multipleChoice &&
															block.content.multipleChoice.options
																.filter((_, index) => a.multipleChoice.includes(index))
																.map((o) => <Answer key={o.id} text={o.text} karma={o.karma} />)}
														{a.textInput && <Answer text={a.textInput} />}
													</Column>
												</Column>
											)
										})}
									</Column>
								</Column>
							)}
							<Column>
								<h1>Referrals</h1>
								<div>
									This signup generated <strong>{details.referrals.length}</strong>{" "}
									{pluralize("referral", details.referrals.length)}.
								</div>
								{details.referrals.map((r) => {
									return (
										<Tile key={r.id} size="small">
											<Row>
												<div>{r.email}</div>
												<Karma karma={100} />
											</Row>
										</Tile>
									)
								})}
							</Column>
						</Column>
					</Column>
				</Column>
			</Skeleton>
		</>
	)
}

const Answer = ({ text, karma }: { text: string; karma?: number }) => {
	return (
		<Row className="gap-2 items-stretch">
			<div className="bg-neutral-200  p-3 border  rounded-lg flex-1">{text}</div>
			{karma !== undefined && <Karma karma={karma} />}
		</Row>
	)
}

const Karma = ({ karma }: { karma: number }) => {
	return (
		<Column
			className={classNames("items-center justify-center font-mono w-[80px] border  rounded-lg text-sm", {
				"bg-green-500 text-white": karma > 0,
				"bg-red-500 text-white": karma < 0,
				"text-neutral-400": karma === 0,
			})}
		>
			{karma > 0 && "+"}
			{karma}
		</Column>
	)
}

export default ProjectSignupDetailsPage
