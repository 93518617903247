import { PartyPopper } from "lucide-react"
import { useCallback, useEffect, useState } from "react"
import { Spinner } from "../components/Spinner"
import { Button, Column } from "../components/UI"
import * as cloud from "../messages/cloud.pb"

const ConfirmPage = () => {
	const [signup, setSignup] = useState<string | null>(null)
	const [done, setDone] = useState<boolean | null>(null)

	useEffect(() => {
		const params = new URLSearchParams(window.location.search)
		const signup = params.get("signup")
		setSignup(signup)
	}, [])

	const confirm = useCallback(() => {
		cloud
			.ConfirmSignupEmail({ code: signup })
			.then(() => {
				setDone(true)
			})
			.catch(() => {
				setDone(false)
			})
	}, [signup])

	if (!signup) {
		return <Spinner />
	}

	return (
		<Column className="h-full items-center justify-center gap-6">
			{done === true ? (
				<>
					<PartyPopper />
					<div>It worked! You can now close this window.</div>
				</>
			) : done === null ? (
				<>
					<Button onClick={confirm}>Confirm your email address</Button>
				</>
			) : (
				<>Something went wrong.</>
			)}
		</Column>
	)
}

export default ConfirmPage
