import { Plus } from "lucide-react"
import moment from "moment"
import { useCallback, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useParams } from "react-router-dom"
import { SidebarMenu } from "../../components/SidebarMenu"
import { Skeleton } from "../../components/Skeleton"
import { Spinner } from "../../components/Spinner"
import { Button, Column, Mono, Row, Tile } from "../../components/UI"
import { isAccessReadOnly } from "../../interfaces"
import { getProject } from "../../lib/api"
import { settings } from "../../lib/settings"
import * as cloud from "../../messages/cloud.pb"

const ProjectAPIKeysPage = () => {
	const params = useParams<{ id: string }>()
	const [project, setProject] = useState<cloud.Project>()

	useEffect(() => {
		if (!params.id) {
			return
		}
		getProject(params.id).then(setProject)
	}, [params.id])

	if (!project) {
		return null
	}

	return (
		<>
			<Helmet>
				<title>
					{project.name} – {settings.appName}
				</title>
			</Helmet>
			<Skeleton project={project}>
				<SidebarMenu project={project} />
				<Column className="flex-1 h-full items-center p-4">
					<Column className="w-full max-w-[1000px]">
						<SectionApiKeys project={project} />
					</Column>
				</Column>
			</Skeleton>
		</>
	)
}

const SectionApiKeys = ({ project }: { project: cloud.Project }) => {
	const [keys, setKeys] = useState<cloud.APIKey[] | null>(null)

	const getKeys = useCallback(() => {
		cloud.GetAPIKeys({ projectId: project.id }).then((r) => setKeys(r.keys))
	}, [project])

	useEffect(() => getKeys(), [getKeys])

	const addKey = useCallback(() => {
		cloud.CreateAPIKey({ projectId: project.id }).then(getKeys)
	}, [getKeys, project])
	const deleteKey = useCallback(
		(id: string) => () => {
			const ok = window.confirm("Are you sure?")
			if (!ok) {
				return
			}
			cloud.DeleteAPIKey({ projectId: project.id, apiKeyId: id }).then(getKeys)
		},
		[getKeys, project]
	)

	if (keys == null) {
		return <Spinner />
	}

	return (
		<Column>
			{keys.map((k) => (
				<Tile key={k.id} size="small">
					<Row>
						<Row className="gap-4">
							<Mono className="select-text">{k.key}</Mono>
							<div className="text-sm opacity-50">
								Last used: {k.lastUsed == null ? "never" : moment.unix(k.createdAt).fromNow()}
							</div>
						</Row>
						<Row className="gap-4">
							<div className="text-sm opacity-50">Created {moment.unix(k.createdAt).fromNow()}</div>
							<Button disabled={isAccessReadOnly(project)} color="danger" onClick={deleteKey(k.id)}>
								Delete
							</Button>
						</Row>
					</Row>
				</Tile>
			))}
			<Row className="!justify-center">
				<Button disabled={isAccessReadOnly(project)} onClick={addKey}>
					<Plus /> Add
				</Button>
			</Row>
		</Column>
	)
}

export default ProjectAPIKeysPage
