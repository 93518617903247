import classNames from "classnames"
import { Reorder } from "framer-motion"
import {
	AlignLeft,
	CheckSquare,
	ChevronDown,
	ChevronUp,
	Edit,
	Eye,
	GripVertical,
	List,
	Lock,
	MessageCircle,
	MoreHorizontal,
	Plus,
	Send,
	Trash2,
} from "lucide-react"
import { highlight, languages } from "prismjs/components/prism-core"
import "prismjs/components/prism-css"
import "prismjs/themes/prism.css" //Example style, you can use another
import { useEffect, useMemo, useState } from "react"
import { Helmet } from "react-helmet"
import ReactMarkdown from "react-markdown"
import { useParams } from "react-router-dom"
import Editor from "react-simple-code-editor"
import { useDebouncedCallback } from "use-debounce"
import * as uuid from "uuid"
import { SidebarMenu } from "../../components/SidebarMenu"
import { Skeleton } from "../../components/Skeleton"
import { Slider } from "../../components/Slider"
import { Spinner } from "../../components/Spinner"
import { Button, Column, Mono, Row, Tile } from "../../components/UI"
import {
	blockTypePrettyName,
	isAccessReadOnly,
	newBlockContentAccessCode,
	newBlockContentMessage,
	newBlockContentMultipleChoice,
	newBlockContentSingleChoice,
	newBlockContentTextInput,
} from "../../interfaces"
import { getProject } from "../../lib/api"
import { useAppStore } from "../../lib/appStore"
import { when } from "../../lib/conditionals"
import { settings } from "../../lib/settings"
import * as cloud from "../../messages/cloud.pb"
import * as forms from "../../messages/forms.pb"

export const ProjectFormPage = () => {
	const params = useParams<{ id: string }>()
	const [project, setProject] = useState<cloud.Project>()
	const [published, setPublished] = useState(false)
	const [blocks, setBlocks] = useState<forms.FormBlock[] | null>(null)
	const [reorderingBlocks, setReorderingBlocks] = useState(false)
	const [currentBlockId, setCurrentBlockId] = useState<string | null>(null)
	const [customCSS, setCustomCSS] = useState<string>("")
	const [editCSS, setEditCSS] = useState(false)
	const [draggable, setDraggable] = useState(false)
	const [draggingBlock, setDraggingBlock] = useState<string | null>(null)
	const addToast = useAppStore((s) => s.addToast)

	const setContextMenu = useAppStore((store) => store.setContextMenu)

	useEffect(() => {
		if (!params.id) {
			return
		}
		getProject(params.id).then(setProject)
	}, [params.id])

	useEffect(() => {
		if (!project) {
			return
		}
		getBlocks(project, (blocks, published) => {
			setBlocks(blocks)
			setPublished(published)
		})
	}, [project])

	const currentBlock = useMemo(() => {
		if (!currentBlockId) {
			return null
		}
		const b = blocks?.find((b) => b.id === currentBlockId)
		if (!b) {
			return null
		}
		return b
	}, [currentBlockId, blocks])

	useEffect(() => {
		if (blocks && blocks.length > 0 && !currentBlockId) {
			setCurrentBlockId(blocks[0].id)
		} else if (blocks && blocks.length > 0 && !blocks.some((b) => b.id === currentBlockId)) {
			setCurrentBlockId(blocks[0].id)
		}
	}, [blocks, currentBlockId])

	const saveCSS = useDebouncedCallback((newCss: string) => {
		if (!project) {
			return
		}
		cloud.UpdateFormCSS({ projectId: project.id, css: newCss })
	}, 1000)

	const formLink = useMemo(() => {
		if (!project) {
			return ""
		}
		return `${settings.formsHost}/${project.formId}`
	}, [project])

	if (!project) {
		return null
	}
	if (blocks == null) {
		return <Spinner />
	}

	return (
		<>
			<Helmet>
				<title>
					{project.name} – {settings.appName}
				</title>
			</Helmet>
			<Skeleton project={project}>
				<SidebarMenu project={project} />
				<Column className="flex-1 h-full items-center p-4">
					<Column className="w-full max-w-[1000px]">
						<Column className="gap-4">
							<Tile size="small">
								<Row>
									<Row className="gap-2">
										<div>
											This form is <strong>{published ? "published" : "not published"}</strong>.
										</div>
										<Button
											disabled={isAccessReadOnly(project)}
											onClick={() => {
												cloud.UpdatePublishProjectForm({ projectId: project.id, published: !published }).then(() => {
													getBlocks(project, (_, published) => setPublished(published))
												})
											}}
										>
											{published ? "Unpublish it" : "Publish it"}
										</Button>
									</Row>
									<Row className="gap-2">
										{published && (
											<Button
												className="font-mono !font-normal border-none text-xs"
												color="empty"
												onClick={() => {
													window.navigator.clipboard.writeText(formLink)
													addToast({
														id: "form-link-copied",
														text: "Copied to clipboard",
														type: "info",
													})
												}}
											>
												{formLink}
											</Button>
										)}
										<Row>
											<Button onClick={() => window.open(formLink)}>
												<Send /> Open
											</Button>
										</Row>
									</Row>
								</Row>
							</Tile>
							<Tile size="small">
								<Row>
									<div>Custom CSS</div>
									<Button
										color="empty"
										aspect="square"
										size="small"
										onClick={() => {
											setEditCSS((e) => !e)
										}}
									>
										{editCSS ? (
											<ChevronUp />
										) : (
											<>
												<ChevronDown />
											</>
										)}
									</Button>
								</Row>
								{editCSS && (
									<CSSEditor
										customCSS={customCSS}
										onSave={(e) => {
											setCustomCSS(e)
											saveCSS(e)
										}}
									/>
								)}
							</Tile>
							<Tile size="small">
								<Row>
									<div>Blocks</div>
									<Button
										disabled={isAccessReadOnly(project)}
										onClick={(e) => {
											const options = [
												{
													text: "Message",
													onClick: async () => {
														const newId = uuid.v4()
														setBlocks([...blocks, { id: newId, position: 0, content: newBlockContentMessage() }])
														setCurrentBlockId(newId)
													},
												},
												{
													text: "Single choice",
													onClick: async () => {
														const newId = uuid.v4()
														setBlocks([...blocks, { id: newId, position: 0, content: newBlockContentSingleChoice() }])
														setCurrentBlockId(newId)
													},
												},
												{
													text: "Multiple choice",
													onClick: async () => {
														const newId = uuid.v4()
														setBlocks([...blocks, { id: newId, position: 0, content: newBlockContentMultipleChoice() }])
														setCurrentBlockId(newId)
													},
												},
												{
													text: "Text input",
													onClick: async () => {
														const newId = uuid.v4()
														setBlocks([...blocks, { id: newId, position: 0, content: newBlockContentTextInput() }])
														setCurrentBlockId(newId)
													},
												},
											]
											if (project.requiresAccessCode) {
												options.push({
													text: "Access code",
													onClick: async () => {
														const newId = uuid.v4()
														setBlocks([...blocks, { id: newId, position: 0, content: newBlockContentAccessCode() }])
														setCurrentBlockId(newId)
													},
												})
											}
											setContextMenu({
												id: "user",
												position: { x: e.clientX, y: e.clientY },
												options: options,
											})
										}}
									>
										<Plus /> Block
									</Button>
								</Row>
								{blocks.length === 0 ? (
									<div>This form has no blocks yet.</div>
								) : (
									<Row className="gap-2 !items-stretch">
										<Reorder.Group
											id="blocks-list"
											axis="y"
											values={blocks}
											onMouseUp={() => {
												setReorderingBlocks(false)
												setBlocks(blocks.map(updatePositions(project)))
											}}
											onReorder={(blocks) => {
												setReorderingBlocks(true)
												setBlocks(blocks)
											}}
											className={classNames("flex-[0.3]")}
										>
											<Column className={classNames("flex-1")}>
												{blocks.map((b) => {
													return (
														<Reorder.Item
															key={b.id}
															value={b}
															onMouseDown={() => setReorderingBlocks(true)}
															dragListener={draggable}
														>
															<Row className="gap-2">
																<GripVertical
																	onMouseEnter={() => setDraggable(true)}
																	onMouseLeave={() => setDraggable(false)} // retain this for better animation
																	onTouchStart={() => setDraggable(true)} // for mobile: need to set draggable to `false` in `onDragEnd` prop, not `onTouchEnd`
																	onPointerDown={() => setDraggingBlock(b.id)}
																	onPointerUp={() => setDraggingBlock(null)}
																	className={classNames("text-neutral-300  w-[20px]", {
																		"cursor-grab": !draggingBlock,
																		"cursor-grabbing": draggingBlock,
																	})}
																/>
																<BlockItem
																	draggingThis={draggingBlock === b.id}
																	project={project}
																	block={b}
																	getBlocks={() => getBlocks(project, setBlocks)}
																	reordering={reorderingBlocks}
																	selected={currentBlockId === b.id}
																	onClick={() => {
																		getBlocks(project, (blocks) => {
																			setBlocks(blocks)
																			setCurrentBlockId(b.id)
																		})
																	}}
																/>
															</Row>
														</Reorder.Item>
													)
												})}
											</Column>
										</Reorder.Group>
										{when(
											currentBlock != null,
											<Column className={classNames("border bg-white p-4 flex-1 rounded-lg")}>
												<BlockContent
													project={project}
													block={currentBlock}
													onSave={(content) => {
														cloud
															.UpsertProjectFormBlock({
																blockId: currentBlock.id,
																projectId: project.id,
																formId: project.formId,
																content,
																position: currentBlock.position,
															})
															.then((r) => {
																setBlocks(blocks.map((b) => (b.id === currentBlock.id ? r.block : b)))
															})
													}}
												/>
											</Column>
										)}
									</Row>
								)}
							</Tile>
						</Column>
					</Column>
				</Column>
			</Skeleton>
		</>
	)
}

const CSSEditor = ({ customCSS, onSave }: { customCSS: string; onSave: (e: string) => void }) => {
	const [value, setValue] = useState(customCSS)
	const [changed, setChanged] = useState(false)
	useEffect(() => {
		setChanged(false)
		setValue(customCSS)
	}, [customCSS])
	useEffect(() => {
		if (value === customCSS) {
			setChanged(false)
		}
	}, [value, customCSS])
	return (
		<>
			<Editor
				value={value}
				onValueChange={(e) => {
					setValue(e)
					setChanged(true)
				}}
				tabSize={4}
				padding={16}
				placeholder={"Put your custom CSS stylesheet here"}
				highlight={(code) => highlight(code, languages.css)}
				className="border w-full h-full overflow-scroll rounded bg-white font-mono text-sm"
			/>
			<Row>
				<Row className="text-sm gap-2">
					Available properties:
					<Row className="gap-2">
						<Mono className="text-xs">.Body</Mono>
						<Mono className="text-xs">.Title</Mono>
						<Mono className="text-xs">.AnswerWrapper</Mono>
						<Mono className="text-xs">.Button</Mono>
					</Row>
				</Row>
				<Button disabled={!changed} onClick={() => onSave(value.trim())}>
					Save
				</Button>
			</Row>
		</>
	)
}

const BlockContent = ({
	block,
	onSave,
	project,
}: {
	project: cloud.Project
	block: forms.FormBlock
	onSave: (c: forms.FormBlockContent) => void
}) => {
	return (
		<Column>
			{block.content.type === "message" && (
				<BlockContentMessage
					onSave={(message) => onSave({ type: "message", message: { text: message } })}
					message={block.content.message.text}
				/>
			)}
			{block.content.type === "singleChoice" && (
				<BlockContentChoices
					project={project}
					onSave={(singleChoice) => onSave({ type: "singleChoice", singleChoice })}
					choices={block.content.singleChoice}
				/>
			)}
			{block.content.type === "multipleChoice" && (
				<BlockContentChoices
					project={project}
					onSave={(multipleChoice) => onSave({ type: "multipleChoice", multipleChoice })}
					choices={block.content.multipleChoice}
				/>
			)}
			{block.content.type === "textInput" && (
				<BlockContentTextInput
					onSave={(title) => onSave({ type: "textInput", textInput: { title } })}
					title={block.content.textInput.title}
				/>
			)}
			{block.content.type === "accessCode" && (
				<BlockContentAccessCode
					onSave={(title) => onSave({ type: "accessCode", accessCode: { title } })}
					title={block.content.accessCode.title}
				/>
			)}
		</Column>
	)
}

const BlockContentMessage = ({ onSave, message }: { onSave: (message: string) => void; message: string }) => {
	const [value, setValue] = useState<string | null>(null)
	useEffect(() => {
		setValue(message)
	}, [message])

	const save = useDebouncedCallback((value: string) => {
		onSave(value)
	}, 500)

	const [preview, setPreview] = useState(false)

	if (value == null) {
		return null
	}

	return (
		<Column>
			<Row>
				Message
				<Button size="small" color="empty" aspect="square" onClick={() => setPreview(!preview)}>
					{preview ? <Edit /> : <Eye />}
				</Button>
			</Row>
			<span className="text-neutral-400  text-sm">Markdown syntax is available.</span>
			{preview ? (
				<div className="border  w-full min-h-[150px] p-4 select-none rounded-lg bg-white ">
					<ReactMarkdown>{value}</ReactMarkdown>
				</div>
			) : (
				<textarea
					className="border w-full min-h-[150px] p-4 resize-none rounded-lg bg-white  font-mono text-sm"
					onKeyUp={() => save(value)}
					onChange={(e) => {
						setValue(e.target.value)
					}}
					value={value}
				/>
			)}
		</Column>
	)
}

const BlockContentTextInput = ({ onSave, title }: { onSave: (title: string) => void; title: string }) => {
	const [value, setValue] = useState<string | null>(null)
	useEffect(() => {
		setValue(title)
	}, [title])

	const save = useDebouncedCallback((value: string) => {
		onSave(value)
	}, 500)

	if (value == null) {
		return null
	}

	return (
		<Column>
			Text input
			<input
				type="text"
				className="input-text p-4 rounded-lg"
				onKeyUp={() => save(value)}
				onChange={(e) => {
					setValue(e.target.value)
				}}
				value={value}
			/>
		</Column>
	)
}

const BlockContentAccessCode = ({ onSave, title }: { onSave: (title: string) => void; title: string }) => {
	const [value, setValue] = useState<string | null>(null)
	useEffect(() => {
		setValue(title)
	}, [title])

	const save = useDebouncedCallback((value: string) => {
		onSave(value)
	}, 500)

	if (value == null) {
		return null
	}

	return (
		<Column>
			Access code
			<input
				type="text"
				placeholder="Please enter your access code"
				className="input-text p-4 rounded-lg"
				onKeyUp={() => save(value)}
				onChange={(e) => {
					setValue(e.target.value)
				}}
				value={value}
			/>
		</Column>
	)
}

const BlockContentChoices = ({
	onSave,
	choices,
	project,
}: {
	onSave: (choices: forms.FormBlockSingleChoice | forms.FormBlockMultipleChoice) => void
	choices: forms.FormBlockSingleChoice | forms.FormBlockMultipleChoice
	project: cloud.Project
}) => {
	const [title, setTitle] = useState<string | null>(null)
	const [options, setOptions] = useState<forms.Option[]>([])
	useEffect(() => {
		setTitle(choices.title)
		setOptions(choices.options.map((o) => ({ ...o, id: !o.id ? uuid.v4() : o.id })))
	}, [choices])

	const save = useDebouncedCallback((value: forms.FormBlockSingleChoice) => {
		onSave(value)
	}, 1000)

	const [draggable, setDraggable] = useState(false)
	const [draggingOption, setDraggingOption] = useState<string | null>(null)

	if (title == null) {
		return null
	}

	return (
		<Column>
			Title
			<input
				type="text"
				className="input-text"
				placeholder="Title"
				value={title}
				onKeyUp={() => save({ title, options })}
				onChange={(e) => setTitle(e.target.value)}
			/>
			Options
			<Reorder.Group
				id="options"
				axis="y"
				values={options}
				onReorder={(newOptions) => {
					setOptions(newOptions)
				}}
			>
				<Column>
					{options.map((opt, index) => {
						return (
							<Reorder.Item
								value={opt}
								key={opt.id}
								dragListener={draggable}
								onDragEnd={() => {
									setDraggable(false)
									save({ title, options })
								}}
							>
								<Row className="gap-2">
									<GripVertical
										onMouseEnter={() => setDraggable(true)}
										onMouseLeave={() => setDraggable(false)} // retain this for better animation
										onTouchStart={() => setDraggable(true)} // for mobile: need to set draggable to `false` in `onDragEnd` prop, not `onTouchEnd`
										onPointerDown={() => setDraggingOption(opt.id)}
										onPointerUp={() => setDraggingOption(null)}
										className={classNames("text-neutral-300  w-[20px]", {
											"cursor-grab": !draggingOption,
											"cursor-grabbing": draggingOption,
										})}
									/>
									<Row
										className={classNames("flex-1 p-2 rounded-lg  gap-4", {
											"shadow-xl": draggingOption === opt.id,
										})}
									>
										<input
											type="text"
											className="input-text flex-1"
											placeholder={`Option ${index + 1}`}
											value={opt.text}
											onChange={(e) => {
												const newOptions = options.map((o, i) => {
													return i !== index ? o : { ...o, text: e.target.value }
												})
												setOptions(newOptions)
												save({ title, options: newOptions })
											}}
										/>
										<Slider
											from={-10}
											to={10}
											value={opt.karma ?? 0}
											onChange={(e) => {
												const newOptions = options.map((o, i) => {
													return i !== index ? o : { ...o, karma: e }
												})
												setOptions(newOptions)
												save({ title, options: newOptions })
											}}
										/>
										<Button
											disabled={isAccessReadOnly(project)}
											color="danger"
											aspect="square"
											size="small"
											onClick={() => {
												const newOptions = options.filter((_, i) => i !== index)
												setOptions(newOptions)
												save({ title, options: newOptions })
											}}
										>
											<Trash2 />
										</Button>
									</Row>
								</Row>
							</Reorder.Item>
						)
					})}
				</Column>
			</Reorder.Group>
			<Button
				disabled={isAccessReadOnly(project)}
				onClick={() => {
					const newOptions = [...options, { text: "", karma: 0, id: uuid.v4() }]
					setOptions(newOptions)
					save({ title, options: newOptions })
				}}
			>
				<Plus />
			</Button>
		</Column>
	)
}

const BlockItem = ({
	project,
	block,
	selected,
	onClick,
	getBlocks,
	draggingThis,
	reordering,
}: {
	project: cloud.Project
	block: forms.FormBlock
	selected: boolean
	draggingThis: boolean
	getBlocks: () => void
	onClick: () => void
	reordering: boolean
}) => {
	const { contextMenu, setContextMenu } = useAppStore(({ contextMenu, setContextMenu }) => ({
		contextMenu,
		setContextMenu,
	}))
	return (
		<Tile
			onClick={onClick}
			className={classNames("w-[240px] !p-3", {
				"shadow-xl": draggingThis,
				"cursor-grabbing": reordering,
				"!bg-blue-500 !text-white": selected,
				"border-transparent hover:!border-transparent": selected,
			})}
		>
			<Row>
				<Column className="gap-0 flex-1">
					<Row className="!justify-start items-start gap-2">
						<div className="">{blockTypeIcon(block.content)}</div>
						<div className="">{blockTypePrettyName(block.content)}</div>
					</Row>
					<Row className="!justify-start items-start gap-2">
						<div className="w-[25px]" />
						<div
							className={classNames("text-xs truncate max-w-[122px]", {
								"text-stone-400": !selected,
							})}
						>
							{blockPreview(block.content)}
						</div>
					</Row>
				</Column>
				<MoreHorizontal
					className={classNames("cursor-default group-hover:visible w-[20px]", {
						invisible: contextMenu?.id !== `block-${block.id}`,
						visible: contextMenu?.id === `block-${block.id}`,
					})}
					onClick={(e) => {
						e.stopPropagation()
						setContextMenu({
							id: `block-${block.id}`,
							position: { x: e.clientX, y: e.clientY },
							options: [
								{
									text: "Delete",
									danger: true,
									onClick: async () =>
										cloud
											.DeleteProjectFormBlock({
												projectId: project.id,
												formId: project.formId,
												blockId: block.id,
											})
											.then(() => {
												getBlocks()
											}),
								},
							],
						})
					}}
				/>
			</Row>
		</Tile>
	)
}

export const blockPreview = (content: forms.FormBlockContent): string => {
	switch (content.type) {
		case "message":
			return content.message.text.replace("\n", " ").replace(/ +/, " ").trim()
		case "singleChoice":
			return content.singleChoice.title.trim()
		case "multipleChoice":
			return content.multipleChoice.title.trim()
		case "textInput":
			return content.textInput.title
		case "accessCode":
			return "Access code"
		default:
			throw new Error("cannot get block preview")
	}
}

export const blockTypeIcon = (content: forms.FormBlockContent) => {
	switch (content.type) {
		case "message":
			return <MessageCircle />
		case "singleChoice":
			return <CheckSquare />
		case "multipleChoice":
			return <List />
		case "textInput":
			return <AlignLeft />
		case "accessCode":
			return <Lock />
		default:
			throw new Error("cannot get block icon")
	}
}

const updatePositions = (project: cloud.Project) => (b: forms.FormBlock, index: number) => {
	if (b.position !== index + 1 && !b.placeholder) {
		cloud.UpsertProjectFormBlock({
			blockId: b.id,
			projectId: project.id,
			formId: project.formId,
			content: b.content,
			position: index + 1,
		})
	}
	return { ...b, position: index + 1 }
}

const getBlocks = (project: cloud.Project, cb: (blocks: forms.FormBlock[], published: boolean) => void) => {
	cloud.GetProjectFormBlocks({ projectId: project.id, formId: project.formId }).then((r) => {
		const sorted = r.blocks.sort((a, b) => (a.position < b.position ? -1 : a.position > b.position ? 1 : 0))
		cb(sorted.map(updatePositions(project)), r.published)
	})
}
