import cronstrue from "cronstrue"
import { CheckCircle2, CircleDot, CircleEllipsis, MoreHorizontal, Plus, XCircle } from "lucide-react"
import moment from "moment"
import pluralize from "pluralize"
import { useCallback, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useNavigate, useParams } from "react-router-dom"
import TimeAgo from "react-timeago"
import { SidebarMenu } from "../../components/SidebarMenu"
import { Skeleton } from "../../components/Skeleton"
import { Spinner } from "../../components/Spinner"
import { Button, Column, Row, Tile } from "../../components/UI"
import { isAccessReadOnly } from "../../interfaces"
import { getProject } from "../../lib/api"
import { ContextMenuOption, useAppStore } from "../../lib/appStore"
import { settings } from "../../lib/settings"
import * as cloud from "../../messages/cloud.pb"

export const ProjectSchedulesPage = () => {
	const params = useParams<{ id: string }>()
	const [project, setProject] = useState<cloud.Project>()

	useEffect(() => {
		if (!params.id) {
			return
		}
		getProject(params.id).then(setProject)
	}, [params.id])

	const setContextMenu = useAppStore((store) => store.setContextMenu)
	const [schedules, setSchedules] = useState<cloud.ProjectSchedule[] | null>(null)
	const [lastGetSchedules, setLastGetSchedules] = useState<Date>(new Date())
	const getSchedules = useCallback(() => {
		if (!project) {
			return
		}
		cloud.GetProjectSchedules({ projectId: project.id }).then((r) => {
			setSchedules(r.schedules)
			setLastGetSchedules(new Date())
		})
	}, [project, setSchedules, setLastGetSchedules])
	const nav = useNavigate()
	const hideSchedule = useCallback(
		(s: cloud.ProjectSchedule) => {
			cloud.HideProjectSchedule({ projectId: project.id, scheduleId: s.id }).then(getSchedules)
		},
		[getSchedules, project]
	)
	const cancelSchedule = useCallback(
		(s: cloud.ProjectSchedule) => {
			cloud.CancelProjectSchedule({ projectId: project.id, scheduleId: s.id }).then(getSchedules)
		},
		[getSchedules, project]
	)
	const resumeSchedule = useCallback(
		(s: cloud.ProjectSchedule) => {
			cloud.ResumeProjectSchedule({ projectId: project.id, scheduleId: s.id }).then(getSchedules)
		},
		[getSchedules, project]
	)
	const replaySchedule = useCallback(
		(s: cloud.ProjectSchedule) => {
			cloud.ReplayProjectSchedule({ projectId: project.id, scheduleId: s.id }).then(getSchedules)
		},
		[getSchedules, project]
	)

	const openMenu = useCallback(
		(s: cloud.ProjectSchedule) => (e: React.MouseEvent) => {
			const options: ContextMenuOption[] = []
			if (s.done || (s.at && s.canceled)) {
				options.push({ text: "Replay", onClick: async () => replaySchedule(s) })
			}
			if (s.canceled && s.repeat) {
				options.push({ text: "Resume", onClick: async () => resumeSchedule(s) })
			}
			if (s.done || s.canceled) {
				options.push({ text: "Hide", onClick: async () => hideSchedule(s) })
			}
			if (!s.canceled && !s.done) {
				options.push({ text: "Cancel", onClick: async () => cancelSchedule(s) })
			}
			setContextMenu({
				id: "schedule",
				position: {
					x: e.clientX,
					y: e.clientY,
				},
				options,
			})
		},
		[replaySchedule, resumeSchedule, cancelSchedule, hideSchedule, setContextMenu]
	)

	const [perc, setPerc] = useState(0)

	useEffect(() => {
		const k = setInterval(() => {
			const v = -moment(lastGetSchedules).diff(moment(), "s")
			const bound = 60 - 3
			const int = v / 30
			setPerc(3 + bound * int)
		}, 1000)
		return function () {
			clearInterval(k)
		}
	}, [lastGetSchedules])
	useEffect(() => {
		getSchedules()
		const t = setInterval(getSchedules, 30_000)
		return function () {
			clearInterval(t)
		}
	}, [getSchedules, project, setPerc])

	if (!project) {
		return null
	}
	if (!schedules) {
		return <Spinner />
	}

	return (
		<>
			<Helmet>
				<title>
					{project.name} – {settings.appName}
				</title>
			</Helmet>
			<Skeleton project={project}>
				<SidebarMenu project={project} />
				<Column className="flex-1 h-full items-center p-4">
					<Column className="w-full max-w-[1000px]">
						<Column>
							<Row className="gap-1">
								<div className="text-neutral-400 flex items-center justify-center" onClick={getSchedules}>
									<div className="flex items-center justify-center ">
										<svg className="transform -rotate-90 w-[42px] h-[42px] ">
											<circle
												cx="21"
												cy="21"
												r="9"
												stroke="currentColor"
												strokeWidth="3"
												fill="transparent"
												className="text-gray-50"
											/>

											<circle
												cx="21"
												cy="21"
												r="9"
												stroke="currentColor"
												strokeWidth="3"
												fill="transparent"
												strokeDasharray={60}
												strokeDashoffset={perc}
												className="text-gray-200"
											/>
										</svg>
									</div>
								</div>
								<Button
									disabled={isAccessReadOnly(project)}
									onClick={() => {
										nav(`/projects/${project.id}/create-schedule`)
									}}
								>
									<Plus />
									Schedule
								</Button>
							</Row>
							{schedules == null ? (
								<Spinner />
							) : (
								<Column>
									{schedules.length === 0 ? (
										<span className="text-sm text-neutral-400">No schedules yet.</span>
									) : (
										schedules.map((s) => {
											return (
												<Tile key={s.id} empty={s.canceled || s.done} size="small">
													<Row>
														<Row className="w-[100px]">
															{s.at ? (
																s.canceled ? (
																	<Row className="gap-2">
																		<XCircle className="w-[16px] text-gray-500" />
																		Canceled
																	</Row>
																) : s.done ? (
																	<Row className="gap-2">
																		<CheckCircle2 className="w-[16px] text-teal-500" />
																		Complete
																	</Row>
																) : (
																	<Row className="gap-2">
																		<CircleEllipsis className="w-[16px] text-amber-500" />
																		Pending
																	</Row>
																)
															) : s.canceled ? (
																<Row className="gap-2">
																	<XCircle className="w-[16px] text-gray-500" />
																	Canceled
																</Row>
															) : (
																<Row className="gap-2">
																	<CircleDot className="w-[16px] text-purple-500" />
																	Active
																</Row>
															)}
														</Row>
														<div className="text-sm flex-[0.5]">
															<span className="cursor-help" title={moment.unix(s.at).format("YYYY-MM-DD HH:mm:ss")}>
																<TimeAgo date={moment.unix(s.at).toDate()} />
															</span>
															{s.repeat && `${cronstrue.toString(s.repeat)}`}
														</div>
														<div className="text-sm flex-[0.3]">
															{s.successScheduledCount || 0} {pluralize("signup", s.successScheduledCount || 0)} sent
														</div>
														<Button aspect="square" size="small" color="empty" onClick={openMenu(s)}>
															<MoreHorizontal />
														</Button>
													</Row>
												</Tile>
											)
										})
									)}
								</Column>
							)}
						</Column>
					</Column>
				</Column>
			</Skeleton>
		</>
	)
}
