import classNames from "classnames"
import { Plus, RefreshCcw } from "lucide-react"
import { useCallback, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useParams } from "react-router-dom"
import { SidebarMenu } from "../../components/SidebarMenu"
import { Skeleton } from "../../components/Skeleton"
import { Spinner } from "../../components/Spinner"
import { Button, Column, Mono, Row, Tile } from "../../components/UI"
import { accessCodeDestinations, isAccessReadOnly } from "../../interfaces"
import { getProject } from "../../lib/api"
import { settings } from "../../lib/settings"
import * as cloud from "../../messages/cloud.pb"

const ProjectAccessCodesPage = () => {
	const params = useParams<{ id: string }>()
	const [project, setProject] = useState<cloud.Project>()
	useEffect(() => {
		if (!params.id) {
			return
		}
		getProject(params.id).then(setProject)
	}, [params.id])

	if (!project) {
		return null
	}

	return (
		<>
			<Helmet>
				<title>
					{project.name} – {settings.appName}
				</title>
			</Helmet>
			<Skeleton project={project}>
				<SidebarMenu project={project} />
				<Column className="flex-1 h-full items-center p-4">
					<Column className="w-full max-w-[1000px]">
						<SectionAccessCodes project={project} />
					</Column>
				</Column>
			</Skeleton>
		</>
	)
}

const SectionAccessCodes = ({ project }: { project: cloud.Project }) => {
	const [codes, setCodes] = useState<cloud.AccessCode[] | null>(null)
	const getCodes = useCallback(() => {
		cloud.GetProjectAccessCodes({ projectId: project.id }).then((r) => {
			setCodes(r.codes.sort((a, b) => (a.createdAt < b.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0)))
		})
	}, [project])
	useEffect(() => {
		getCodes()
	}, [getCodes])
	const deleteCode = useCallback(
		(codeId: string) => () => {
			const ok = window.confirm("Are you sure?")
			if (!ok) {
				return
			}
			cloud.DeleteProjectAccessCode({ projectId: project.id, codeId }).then(() => getCodes())
		},
		[project, getCodes]
	)
	const generateCodes = useCallback(() => {
		const n = window.prompt("How many?", "1")
		if (!n) {
			return
		}
		const count = parseInt(n)
		cloud
			.GenerateProjectAccessCodes({
				projectId: project.id,
				destination: accessCodeDestinations.invite,
				count,
			})
			.then(() => getCodes())
	}, [project, getCodes])
	if (codes == null) {
		return <Spinner />
	}
	return (
		<Column className="gap-4">
			{!project.requiresAccessCode && (
				<Tile empty className="text-red-500 !border-red-500">
					<div>
						To enable access codes make sure to set <Mono>Require access codes</Mono> in the project settings.
					</div>
				</Tile>
			)}
			<Row>
				<Button onClick={generateCodes} disabled={isAccessReadOnly(project)}>
					<Plus />
					Generate
				</Button>
				<Button onClick={getCodes}>
					<RefreshCcw />
					Refresh
				</Button>
			</Row>
			<table className="table-auto">
				<thead className="select-none">
					<tr>
						<th className="text-left p-2">Code</th>
						<th className="text-left p-2">Used</th>
						<th className="text-left p-2">Max uses</th>
						<th className="text-left p-2">Destination</th>
						<th className="text-left p-2"></th>
					</tr>
				</thead>
				<tbody>
					{codes.map((c, index) => {
						return (
							<tr
								key={c.id}
								className={classNames(
									"border border-neutral-200 ",
									{
										"bg-neutral-100 ": !(index % 2),
										"bg-neutral-50 ": index % 2,
									},
									"hover:bg-yellow-100 ",
									{
										"opacity-50": c.used >= c.maxUses,
									}
								)}
							>
								<td>
									<Mono className={classNames({ "line-through opacity-50": c.used >= c.maxUses })}>{c.code}</Mono>
								</td>
								<td>{c.used}</td>
								<td>{c.maxUses}</td>
								<td>
									<Mono>{c.destination}</Mono>
								</td>
								<td className="flex gap-4">
									<Button size="small" disabled={true}>
										Send
									</Button>
									<Button disabled={isAccessReadOnly(project)} color="danger" size="small" onClick={deleteCode(c.id)}>
										Delete
									</Button>
								</td>
							</tr>
						)
					})}
				</tbody>
			</table>
		</Column>
	)
}

export default ProjectAccessCodesPage
