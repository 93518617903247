import { useEffect, useState } from "react"
import * as cloud from "../messages/cloud.pb"

const SignInCallback = () => {
	const [id, setId] = useState<string | null>(null)

	useEffect(() => {
		const params = new URLSearchParams(window.location.search)
		const id = params.get("id")
		setId(id)
	}, [])

	useEffect(() => {
		if (!id) {
			return
		}
		cloud
			.Signin({ Token: id })
			.then(() => {
				window.location.replace("/")
			})
			.catch(() => {
				window.location.replace("/signin")
			})
	}, [id])

	if (!id) {
		return null
	}

	return <div />
}

export default SignInCallback
