import * as cloud from "../messages/cloud.pb"
import { useAppStore } from "./appStore"

export const getProject = async (projectId: string, clear?: boolean): Promise<cloud.Project> => {
	const cur = useAppStore.getState().project
	if (cur != null && !clear) {
		return cur
	}
	const r = await cloud.GetProject({ projectId })
	useAppStore.setState({ project: r.project })
	return r.project
}

export const clearProject = () => {
	useAppStore.setState({ project: null })
}
