import create from "zustand"
import { devtools } from "zustand/middleware"
import { ContextMenuPosition } from "../components/ContextMenuContainer"
import * as cloud from "../messages/cloud.pb"
import { client } from "twirpscript"
import { settings } from "./settings"
import { v4 } from "uuid"

client.baseURL = settings.cloudHost
client.rpcTransport = (url, opts) => {
	return fetch(url, { ...opts, credentials: "include" })
}
client.on("error", (ctx, twirpError) => {
	const state = useAppStore.getState()

	if (twirpError.code === "unauthenticated" && state.user != null) {
		state.signout()
		return
	}

	switch (twirpError.code) {
		case "unauthenticated":
			return
	}

	state.addToast(newToast(twirpError.msg, "error"))
})
client.use((ctx, next) => {
	return next(ctx)
})

export type Dialog = {
	text: string
	danger?: boolean
	onConfirm?: () => void
	onCancel?: () => void
}

export type ContextMenuOption = {
	text?: string
	separator?: boolean
	onClick?: () => Promise<void>
	danger?: boolean
}

export type ContextMenu = {
	id: string
	position: ContextMenuPosition
	options: ContextMenuOption[]
}

export type Toast = {
	id: string
	text: string
	type: "info" | "error" | "warning"
}

export function newToast(text: string, type: "info" | "error" | "warning"): Toast {
	return {
		id: v4(),
		text,
		type,
	}
}

export interface AppStore {
	user: cloud.User | null
	ready: boolean
	signout: () => void
	dialog: Dialog | null
	setDialog: (dialog: Dialog | null) => void
	contextMenu: ContextMenu | null
	setContextMenu: (menu: ContextMenu | null) => void
	toasts: Toast[]
	addToast: (t: Toast | null) => void
	removeToast: (t: Toast | null) => void
	project: cloud.Project | null
	setProject: (p: cloud.Project | null) => void
}

export const useAppStore = create<AppStore>()(
	devtools(
		(set) => {
			const setReady = () => set(() => ({ ready: true }))
			const setUser = (user: cloud.User | null) => set(() => ({ user }))
			cloud
				.Me({})
				.then((r) => setUser(r.user))
				.finally(setReady)

			return {
				user: null,
				setUser,
				ready: false,
				signout: () => {
					cloud.Signout({})
					setUser(null)
				},
				dialog: null,
				setDialog: (dialog: Dialog | null) =>
					set(() => ({
						dialog: dialog,
					})),
				contextMenu: null,
				setContextMenu: (contextMenu: ContextMenu | null) =>
					set(() => ({
						contextMenu: contextMenu,
					})),
				toasts: [],
				addToast: (toast: Toast | null) =>
					set((store) => ({
						toasts: [...store.toasts, toast],
					})),
				removeToast: (toast: Toast | null) =>
					set((store) => ({
						toasts: store.toasts.filter((t) => t.id !== toast.id),
					})),
				project: null,
				setProject: (project: cloud.Project | null) =>
					set(() => ({
						project,
					})),
			}
		},
		{
			name: "app-store",
		}
	)
)
