import classNames from "classnames"
import { ChevronLeft, LayoutGrid } from "lucide-react"
import { useCallback } from "react"
import Gravatar from "react-gravatar"
import { useNavigate } from "react-router-dom"
import { useAppStore } from "../lib/appStore"
import { ContextMenuContainer } from "./ContextMenuContainer"
import { DialogContainer } from "./DialogContainer"
import { Logo } from "./Logo"
import { ToastContainer } from "./ToastContainer"
import { Button, Column, Row } from "./UI"
import * as cloud from "../messages/cloud.pb"

export const Skeleton = ({
	title,
	project,
	children,
	className,
	backLink,
}: {
	title?: string
	project?: cloud.Project
	children: any
	className?: string
	backLink?: string
}) => {
	return (
		<Column className={classNames("h-full !gap-0 items-center", className)}>
			<TopBar backLink={backLink} title={title} project={project} />
			<Row className={classNames("flex-1 w-full !items-start")}>{children}</Row>
			<DialogContainer />
			<ContextMenuContainer />
			<ToastContainer />
		</Column>
	)
}

const TopBar = ({ title, backLink, project }: { title?: string; backLink?: string; project?: cloud.Project }) => {
	const { user, signout } = useAppStore(({ user, signout }) => ({ user, signout }))
	const setContextMenu = useAppStore((s) => s.setContextMenu)

	const openMenu = useCallback(
		(e: React.MouseEvent) => {
			if (!user) {
				return
			}
			setContextMenu({
				id: "user",
				position: { x: e.clientX, y: e.clientY },
				options: [
					{ text: user.email },
					{ separator: true },
					{
						text: "Sign out…",
						onClick: async () => {
							signout()
						},
					},
				],
			})
		},
		[setContextMenu, signout, user]
	)

	const nav = useNavigate()

	if (!user) {
		return null
	}

	return (
		<Row className={classNames("p-4 border-b w-full select-none cursor-default", "border-stone-200 ")}>
			<div className={classNames("font-bold w-[235px]", "text-stone-400")}>
				{backLink ? (
					<>
						<a href={backLink} className="text-inherit no-underline">
							<Button
								color="empty"
								size="small"
								onClick={(e) => {
									e.preventDefault()
									nav(backLink)
								}}
							>
								<ChevronLeft />
								Back
							</Button>
						</a>
					</>
				) : window.location.pathname === "/" ? (
					<Logo />
				) : (
					<a href="/" className="text-inherit no-underline">
						<Button
							className="!bg-white !shadow-none border text-neutral-400 font-normal text-sm !gap-2 hover:text-neutral-600"
							onClick={(e) => {
								e.preventDefault()
								nav(`/`)
							}}
						>
							<LayoutGrid />
							Dashboard
						</Button>
					</a>
				)}
			</div>
			{title && <div className="font-bold">{title}</div>}
			{project && (
				<Row>
					<div className="font-bold">{project.name}</div>
					{project.access < 2 && <div className="bg-orange-200 rounded px-1 text-xs ml-2">read only</div>}
				</Row>
			)}
			<Row className="w-[235px] !justify-end">
				<Gravatar
					onClick={openMenu}
					email={user.email}
					className={classNames("w-[30px] h-[30px] rounded-full cursor-pointer", "hover:shadow-lg")}
				/>
			</Row>
		</Row>
	)
}
