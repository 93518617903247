import { useEffect, useState } from "react"
import * as cloud from "../messages/cloud.pb"
import { Column } from "../components/UI"

const SignupsCodePage = () => {
	const [signup, setSignup] = useState<cloud.GetSignupPositionResponse>()
	useEffect(() => {
		const params = new URLSearchParams(window.location.search)
		const code = params.get("code")
		cloud.GetSignupPosition({ signupCode: code }).then((r) => {
			setSignup(r)
		})
	}, [])

	if (!signup) {
		return null
	}

	return (
		<Column className="h-full items-center justify-center gap-4">
			<div>Hey there!</div>
			<div>You're in position in the {signup.projectName} waiting list is:</div>
			<div className="text-6xl font-black p-10 border my-8 rounded-lg shadow-xl">{signup.position.toString()}</div>
			<div>Hold tight! An invite will be sent to you soon.</div>
			<div>
				You generated <strong>{signup.referrals}</strong> referrals.
			</div>
		</Column>
	)
}

export default SignupsCodePage
