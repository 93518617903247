import classNames from "classnames"
import { Loader } from "lucide-react"

export const Spinner = () => {
	return (
		<div className={classNames("text-neutral-300  flex items-center w-full justify-center")}>
			<Loader className="animate-spin" />
		</div>
	)
}
