import classNames from "classnames"
import { motion } from "framer-motion"
import React, { useCallback, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { CheckYourEmail } from "../components/CheckYourEmail"
import { Button, Column } from "../components/UI"
import { settings } from "../lib/settings"
import * as cloud from "../messages/cloud.pb"

const SignInPage = () => {
	const [email, setEmail] = useState<string>("")
	const [sent, setSent] = useState(false)

	const [message, setMessage] = useState<string | null>(null)

	useEffect(() => {
		const params = new URLSearchParams(window.location.search)
		const accepted = params.get("accepted")
		if (accepted === "1") {
			setMessage("You're in! You can now sign in with your email address.")
		}
	}, [])

	const handleSubmit = useCallback(
		(e: React.FormEvent) => {
			e.preventDefault()
			if (!email) {
				return
			}
			cloud.CreateSigninToken({ Email: email }).then((r) => {
				setSent(true)
			})
		},
		[email]
	)

	if (sent) {
		return <CheckYourEmail />
	}

	return (
		<>
			<Helmet>
				<title>Sign in - {settings.appName}</title>
			</Helmet>
			<Column className="h-full items-center justify-center">
				{message != null && (
					<motion.div
						initial={{ opacity: 0, scale: 0.5 }}
						animate={{ opacity: 1, scale: 1 }}
						className={classNames("py-3 px-4 rounded-full shadow-lg", "bg-teal-200 ")}
					>
						{message}
					</motion.div>
				)}
				<form className="flex flex-col gap-2 w-full max-w-[300px]" onSubmit={handleSubmit}>
					<input
						className="input-text"
						type="email"
						value={email}
						onChange={(v) => setEmail(v.target.value)}
						placeholder="alfred@batman.co"
					/>
					<Button type="submit">Sign in</Button>
				</form>
			</Column>
		</>
	)
}

export default SignInPage
