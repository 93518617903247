import { CheckCircle2, Plus, XCircle } from "lucide-react"
import moment from "moment"
import { useCallback, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useParams } from "react-router-dom"
import TimeAgo from "react-timeago"
import { SidebarMenu } from "../../components/SidebarMenu"
import { Skeleton } from "../../components/Skeleton"
import { Spinner } from "../../components/Spinner"
import { Button, Column, Mono, Preformatted, Row } from "../../components/UI"
import { isAccessReadOnly } from "../../interfaces"
import { getProject } from "../../lib/api"
import { settings } from "../../lib/settings"
import * as cloud from "../../messages/cloud.pb"

const ProjectDomainsPage = () => {
	const params = useParams<{ id: string }>()
	const [project, setProject] = useState<cloud.Project>()

	useEffect(() => {
		if (!params.id) {
			return
		}
		getProject(params.id).then(setProject)
	}, [params.id])

	if (!project) {
		return null
	}

	return (
		<>
			<Helmet>
				<title>
					{project.name} – {settings.appName}
				</title>
			</Helmet>
			<Skeleton project={project}>
				<SidebarMenu project={project} />
				<Column className="flex-1 h-full items-center p-4">
					<Column className="w-full max-w-[1000px]">
						<SectionDomains project={project} />
					</Column>
				</Column>
			</Skeleton>
		</>
	)
}

const SectionDomains = ({ project }: { project: cloud.Project }) => {
	const [domain, setDomain] = useState<cloud.Domain | null>()
	const [working, setWorking] = useState(false)
	const getDomain = useCallback(() => {
		cloud.GetProjectDomain({ projectId: project.id }).then((r) => {
			setDomain(r.domain || null)
		})
	}, [project])
	useEffect(() => {
		getDomain()
	}, [getDomain])
	if (domain === undefined) {
		return <Spinner />
	}
	if (domain === null) {
		return (
			<Row>
				No domains added to this project yet.
				<Button
					disabled={working || isAccessReadOnly(project)}
					onClick={() => {
						setWorking(true)
						const domainName = window.prompt("Domain name")
						if (!domainName) {
							setWorking(false)
							return
						}
						cloud
							.AddProjectDomain({ projectId: project.id, domainName })
							.then(() => {
								getDomain()
							})
							.finally(() => setWorking(false))
					}}
				>
					<Plus />
					Add domain
				</Button>
			</Row>
		)
	}
	return (
		<Column>
			<Row className="gap-10">
				<Row className="gap-4">
					<h2>{domain.domainName}</h2>
					{domain.verified ? (
						<Row className="gap-2 text-green-500">
							<CheckCircle2 /> Verified
						</Row>
					) : (
						<Row className="gap-2 text-red-500">
							<XCircle /> Not verified yet
						</Row>
					)}
				</Row>
				{domain.verified && (
					<Row>
						<Button
							disabled={isAccessReadOnly(project)}
							color="danger"
							size="small"
							onClick={() => {
								const ok = window.confirm("Are you sure?")
								if (!ok) {
									return
								}
								cloud.RemoveProjectDomain({ domainId: domain.id, projectId: project.id }).then(() => {
									getDomain()
								})
							}}
						>
							Delete domain
						</Button>
					</Row>
				)}
			</Row>
			{!domain.verified && (
				<Column>
					<div>
						To verify your domain, create a new <Mono>TXT</Mono> record in your domain's DNS settings.
					</div>
					<div>
						Your new record should be for the <Mono>@</Mono> host and the value must be:
						<Preformatted>{domain.verificationCode}</Preformatted>
					</div>
					<Row>
						<div>
							Last checked: {domain.lastCheckedAt ? <TimeAgo date={moment.unix(domain.lastCheckedAt)} /> : "never"}
						</div>
						<Button
							size="small"
							color="secondary"
							onClick={() => {
								if (domain.lastCheckedAt && moment().diff(moment.unix(domain.lastCheckedAt)) < 60000) {
									return
								}
								cloud.CheckProjectDomainVerified({ domainId: domain.id, projectId: project.id }).then(() => getDomain())
							}}
						>
							Check now
						</Button>
					</Row>
				</Column>
			)}
		</Column>
	)
}

export default ProjectDomainsPage
