import classNames from "classnames"
import { Plus, Users } from "lucide-react"
import moment from "moment"
import pluralize from "pluralize"
import { useEffect, useMemo, useState } from "react"
import { Helmet } from "react-helmet"
import { useNavigate } from "react-router-dom"
import { Skeleton } from "../components/Skeleton"
import { Spinner } from "../components/Spinner"
import { useAppStore } from "../lib/appStore"
import { compactNumber } from "../lib/numbers"
import { settings } from "../lib/settings"
import * as cloud from "../messages/cloud.pb"
import { Button, Column, Row, Tile } from "../components/UI"
import { clearProject } from "../lib/api"
import { permissionAccessName } from "../interfaces"

const DashboardPage = () => {
	const { user } = useAppStore(({ user }) => ({ user }))
	const nav = useNavigate()

	const [projects, setProjects] = useState<cloud.ProjectWithSignups[] | null>(null)
	const loadingProjects = useMemo(() => projects == null, [projects])

	useEffect(() => {
		cloud.ListProjects({}).then((r) => {
			setProjects(r.projects)
		})
		clearProject()
	}, [])

	if (!user) {
		return null
	}
	return (
		<>
			<Helmet>
				<title>{settings.appName}</title>
			</Helmet>
			<Skeleton>
				<Column className="flex-1 h-full items-center p-4">
					<Column className="w-full max-w-[1000px]">
						<Row>
							<h1>Projects</h1>
							<div>
								<Button
									onClick={() => {
										nav("/create-project")
									}}
								>
									<Plus />
									New
								</Button>
							</div>
						</Row>
						{loadingProjects ? <Spinner /> : <ProjectsList projects={projects} />}
					</Column>
				</Column>
			</Skeleton>
		</>
	)
}

const ProjectsList = ({ projects }: { projects: cloud.ProjectWithSignups[] | null }) => {
	if (projects == null) {
		return null
	}
	return (
		<Column>
			{projects.map((p) => {
				return <ProjectsListItem key={p.project.id} project={p.project} signupsCount={p.signups} members={p.members} />
			})}
		</Column>
	)
}

const ProjectsListItem = ({
	project,
	signupsCount,
	members,
}: {
	project: cloud.Project
	signupsCount: number
	members: cloud.Member[]
}) => {
	const { user } = useAppStore(({ user }) => ({ user }))
	const access = members.find((m) => m.userId === user.id).access
	const nav = useNavigate()
	return (
		<Tile
			onClick={() => {
				nav(`/projects/${project.id}`)
			}}
		>
			<Row>
				<Row>{project.name}</Row>
				<Row className={classNames("gap-4 text-neutral-400  group-hover:text-white text-sm")}>
					{(signupsCount && (
						<div>
							{compactNumber(signupsCount)} {pluralize("signup", signupsCount)}
						</div>
					)) ||
						"No signups yet"}
					<div>Created {moment.unix(project.createdAt).fromNow()}</div>
					{members.length > 1 && (
						<>
							<div
								className={classNames("text-xs font-medium rounded p-1 w-[48px] text-center", {
									"text-orange-900 bg-orange-200": access < 2,
									"text-green-900 bg-green-200": access === 2,
									"text-pink-900 bg-pink-200": access === 3,
								})}
							>
								{permissionAccessName(access)}
							</div>
							<div>
								<Users className="w-[16px]" />
							</div>
						</>
					)}
				</Row>
			</Row>
		</Tile>
	)
}

export default DashboardPage
