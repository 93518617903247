import classNames from "classnames"
import { Send } from "lucide-react"
import { Column } from "./UI"

export const CheckYourEmail = () => {
	return (
		<Column className={classNames("h-screen gap-4 items-center justify-center")}>
			<Send />
			<Column className="items-center">
				<div>We sent a signin link to your email.</div>
				<div>You can close this window now.</div>
			</Column>
		</Column>
	)
}
