export const compactNumber = (n: number, disable?: boolean): string => {
	if (disable) {
		return `${n}`
	}
	return Intl.NumberFormat("en", { notation: "compact" }).format(n)
}

export const percentage = (n: number, total: number): string => {
	if (total === 0) {
		return "0%"
	}
	const diff = (n / total) * 100
	const fixed = diff.toFixed(0)
	return `${fixed}%`
}
