import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom"
import AuthorizedRoute from "./components/AuthorizedRoute"
import UI from "./components/UI"
import UnauthorizedRoute from "./components/UnauthorizedRoute"
import AcceptInviteCallback from "./pages/AcceptInviteCallback"
import ConfirmPage from "./pages/ConfirmPage"
import CreateProjectPage from "./pages/CreateProjectPage"
import CreateSchedulePage from "./pages/CreateSchedulePage"
import DashboardPage from "./pages/DashboardPage"
import NotFoundPage from "./pages/NotFoundPage"
import ProjectAccessCodesPage from "./pages/projects/access-codes"
import ProjectAPIKeysPage from "./pages/projects/api-keys"
import ProjectDomainsPage from "./pages/projects/domains"
import ProjectEmailTemplatesPage from "./pages/projects/email-templates"
import { ProjectFormPage } from "./pages/projects/form"
import ProjectInvitesPage from "./pages/projects/invites"
import ProjectMembersPage from "./pages/projects/members"
import ProjectPage from "./pages/projects"
import { ProjectSchedulesPage } from "./pages/projects/schedules"
import ProjectSettingsPage from "./pages/projects/settings"
import ProjectSignupDetailsPage from "./pages/projects/signup-details"
import ProjectWaitlistPage from "./pages/projects/waitlist"
import ProjectWebhooksPage from "./pages/projects/webhooks"
import SignInCallback from "./pages/SignInCallback"
import SignInPage from "./pages/SignInPage"
import SignUpCallback from "./pages/SignUpCallback"
import SignupsCodePage from "./pages/SignupsCodePage"

const App = () => {
	return (
		<Router>
			<Routes>
				{/* Authorized */}
				<Route path="/" element={<AuthorizedRoute element={<DashboardPage />} />} />
				<Route path="/create-project" element={<AuthorizedRoute element={<CreateProjectPage />} />} />
				<Route path="/projects" element={<AuthorizedRoute element={<Navigate to="/" />} />} />
				<Route path="/projects/:id" element={<AuthorizedRoute element={<ProjectPage />} />} />
				<Route path="/projects/:id/waitlist" element={<AuthorizedRoute element={<ProjectWaitlistPage />} />} />
				<Route path="/projects/:id/invites" element={<AuthorizedRoute element={<ProjectInvitesPage />} />} />
				<Route path="/projects/:id/form" element={<AuthorizedRoute element={<ProjectFormPage />} />} />
				<Route path="/projects/:id/schedules" element={<AuthorizedRoute element={<ProjectSchedulesPage />} />} />
				<Route path="/projects/:id/create-schedule" element={<AuthorizedRoute element={<CreateSchedulePage />} />} />
				<Route path="/projects/:id/settings" element={<AuthorizedRoute element={<ProjectSettingsPage />} />} />
				<Route path="/projects/:id/domains" element={<AuthorizedRoute element={<ProjectDomainsPage />} />} />
				<Route path="/projects/:id/webhooks" element={<AuthorizedRoute element={<ProjectWebhooksPage />} />} />
				<Route path="/projects/:id/api-keys" element={<AuthorizedRoute element={<ProjectAPIKeysPage />} />} />
				<Route path="/projects/:id/members" element={<AuthorizedRoute element={<ProjectMembersPage />} />} />
				<Route path="/projects/:id/access-codes" element={<AuthorizedRoute element={<ProjectAccessCodesPage />} />} />
				<Route
					path="/projects/:id/email-templates"
					element={<AuthorizedRoute element={<ProjectEmailTemplatesPage />} />}
				/>
				<Route
					path="/projects/:id/signups/:signupId"
					element={<AuthorizedRoute element={<ProjectSignupDetailsPage />} />}
				/>
				{/* Unauthorized */}
				<Route path="/signin" element={<UnauthorizedRoute element={<SignInPage />} />} />
				<Route path="/auth/signin" element={<UnauthorizedRoute element={<SignInCallback />} />} />
				<Route path="/auth/signup" element={<UnauthorizedRoute element={<SignUpCallback />} />} />
				{/* Public */}
				<Route path="/invites/accept" element={<AcceptInviteCallback />} />
				<Route path="/signups" element={<SignupsCodePage />} />
				<Route path="/ui" element={<UI />} />
				<Route path="/confirm" element={<ConfirmPage />} />
				{/* Not found */}
				<Route path="*" element={<NotFoundPage />} />
			</Routes>
		</Router>
	)
}

export default App
