import classNames from "classnames"
import { ArrowDown, ArrowUp, ChevronLeft, ChevronRight, CircleEllipsis, Verified } from "lucide-react"
import moment from "moment"
import pluralize from "pluralize"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Helmet } from "react-helmet"
import { useNavigate, useParams } from "react-router-dom"
import { SidebarMenu } from "../../components/SidebarMenu"
import { Skeleton } from "../../components/Skeleton"
import { Spinner } from "../../components/Spinner"
import { Button, Column, Mono, Row, Tile } from "../../components/UI"
import { getProject } from "../../lib/api"
import { settings } from "../../lib/settings"
import * as cloud from "../../messages/cloud.pb"

const ProjectWaitlistPage = () => {
	const params = useParams<{ id: string }>()
	const [project, setProject] = useState<cloud.Project>()

	useEffect(() => {
		if (!params.id) {
			return
		}
		getProject(params.id).then(setProject)
	}, [params.id])

	if (!project) {
		return null
	}

	return (
		<>
			<Helmet>
				<title>
					{project.name} – {settings.appName}
				</title>
			</Helmet>
			<Skeleton project={project}>
				<SidebarMenu project={project} />
				<Column className="flex-1 h-full items-center p-4">
					<Column className="w-full max-w-[1000px]">
						<SignupsList project={project} />
					</Column>
				</Column>
			</Skeleton>
		</>
	)
}

const sortByPosition = (direction: number) => (a: cloud.ProjectSignup, b: cloud.ProjectSignup) => {
	return direction * (a.position < b.position ? -1 : a.position > b.position ? 1 : 0)
}

const sortByKarma = (direction: number) => (a: cloud.ProjectSignup, b: cloud.ProjectSignup) => {
	return direction * (a.karma > b.karma ? -1 : a.karma < b.karma ? 1 : 0)
}

type SortStrategy = "position" | "karma"

const getSortBy = (strategy: SortStrategy, direction: number) => {
	switch (strategy) {
		case "karma":
			return sortByKarma(direction)
		case "position":
			return sortByPosition(direction)
		default:
			const _exhaustiveCheck: never = strategy
			throw new Error("cannot get sorting function")
	}
}

const SignupsList = ({ project }: { project: cloud.Project }) => {
	const [waitlist, setWaitlist] = useState<cloud.ProjectSignup[] | null>(null)
	const [perPage] = useState(25) // TODO set per page
	const [page, setPage] = useState(0)
	const [search, setSearch] = useState("")
	const [sortBy, setSortBy] = useState<SortStrategy>("position")
	const [sortDirection, setSortDirection] = useState<number>(1)

	const pageFrom = useMemo(() => page * perPage, [page, perPage])
	const pageTo = useMemo(() => pageFrom + perPage, [pageFrom, perPage])

	const getWaitlist = useCallback(() => {
		cloud.GetProjectWaitlist({ projectId: project.id }).then((r) => {
			setWaitlist(r.signups)
		})
	}, [project, setWaitlist])

	useEffect(() => {
		getWaitlist()
	}, [getWaitlist])

	const sendInvite = useCallback(
		(signup: cloud.ProjectSignup) => () => {
			if (signup.pending) {
				return
			}
			cloud.SendInvite({ projectId: signup.projectId, signupId: signup.id }).then((r) => {
				setWaitlist(
					(waitlist || []).map((s) => {
						if (s.id !== signup.id) {
							return s
						}
						return { ...s, invitedAt: Date.now() }
					})
				)
			})
		},
		[waitlist, setWaitlist]
	)

	const count = useMemo(() => {
		if (!waitlist) {
			return 0
		}
		return waitlist.filter((e) => e.invitedAt == null).length
	}, [waitlist])

	const filtered = useMemo(() => {
		if (!waitlist) {
			return []
		}
		return waitlist.sort(getSortBy(sortBy, sortDirection)).filter((w) => {
			if (!search) {
				return w
			}
			return w.email.includes(search)
		})
	}, [waitlist, search, sortBy, sortDirection])

	const prevPage = useCallback(() => {
		if (page === 0) {
			return
		}
		setPage(page - 1)
	}, [page, setPage])
	const nextPage = useCallback(() => {
		if (!filtered) {
			return
		}
		if (pageTo + 1 > filtered.length) {
			return
		}
		setPage(page + 1)
	}, [page, setPage, pageTo, filtered])
	const pages = useMemo(() => {
		return Math.ceil(filtered.length / perPage)
	}, [filtered, perPage])

	useEffect(() => {
		setPage(0)
	}, [search])

	const nav = useNavigate()

	if (waitlist == null) {
		return <Spinner />
	}
	if (waitlist.length === 0) {
		return <div>No signups yet.</div>
	}

	return (
		<Column className="gap-8">
			<Tile>
				<div>
					<span className="font-black">{count}</span> {pluralize("user", count)} {count !== 1 ? "are" : "is"} currently
					in the waitlist, ready to be invited.
				</div>
			</Tile>
			<Column>
				<Row className="gap-4">
					<input
						className="input-text flex-1"
						type="text"
						placeholder="Search…"
						value={search}
						onChange={(e) => setSearch(e.target.value)}
					/>
					<Row className="gap-2 select-none">
						<Button aspect="square" size="small" color="secondary" onClick={prevPage}>
							<ChevronLeft />
						</Button>
						{page + 1} / {pages}
						<Button aspect="square" size="small" color="secondary" onClick={nextPage}>
							<ChevronRight />
						</Button>
					</Row>
				</Row>
				<table className="table-auto">
					<thead className="select-none">
						<tr>
							<th className="p-2"></th>
							<th
								className={classNames("cursor-pointer p-2 w-[60px]", {
									"text-purple-500 ": sortBy === "position",
								})}
								onClick={() => {
									if (sortBy === "position") {
										setSortDirection(-sortDirection)
									} else {
										setSortBy("position")
										setSortDirection(1)
									}
								}}
							>
								<Row className="!justify-start gap-1">
									#
									{sortBy === "position" ? (
										sortDirection > 0 ? (
											<ArrowUp className="w-[15px]" />
										) : (
											<ArrowDown className="w-[15px]" />
										)
									) : null}
								</Row>
							</th>
							<th className="p-2 w-[340px]">Email</th>
							<th
								className={classNames("cursor-pointer p-2", {
									"text-purple-500 ": sortBy === "karma",
								})}
								onClick={() => {
									if (sortBy === "karma") {
										setSortDirection(-sortDirection)
									} else {
										setSortBy("karma")
										setSortDirection(1)
									}
								}}
							>
								<Row className="!justify-start gap-1">
									Karma
									{sortBy === "karma" ? (
										sortDirection > 0 ? (
											<ArrowDown className="w-[15px]" />
										) : (
											<ArrowUp className="w-[15px]" />
										)
									) : null}
								</Row>
							</th>
							<th className="p-2">Age</th>
							<th className="p-2">Via</th>
							<th className="p-2">Actions</th>
						</tr>
					</thead>
					<tbody>
						{filtered.slice(pageFrom, pageTo).map((s, index) => {
							return (
								<tr
									key={s.id}
									className={classNames(
										"border border-neutral-200 ",
										{
											"bg-neutral-100 ": !(index % 2),
											"bg-neutral-50 ": index % 2,
										},
										"hover:bg-yellow-100 "
										// { "opacity-50": s.pending }
									)}
								>
									<td className="select-none p-2 text-sm">
										{s.pending ? (
											<div title={`Expires ${moment.unix(s.expiresAt).fromNow()}`} className="cursor-help">
												<CircleEllipsis className="text-orange-500" />
											</div>
										) : (
											<Verified className="text-teal-500" />
										)}
									</td>
									<td className="select-none p-2 font-mono text-sm text-neutral-400">{s.position}</td>
									<td className="p-2 text-sm">{s.email}</td>
									<td
										className={classNames("select-none p-2 font-mono text-sm", {
											"text-neutral-400": s.karma === 0,
											"text-teal-500": s.karma > 0,
											"text-red-500": s.karma < 0,
										})}
									>
										{s.karma}
									</td>
									<td className="select-none p-2 text-sm">{moment.unix(s.createdAt).fromNow(true)}</td>
									<td className="select-none p-2 text-sm">
										{s.accessCode ? <Mono className="text-x">{s.accessCode}</Mono> : "–"}
									</td>
									<td className="select-none p-2">
										<Row className="gap-1">
											{s.invitedAt ? (
												<Button className="w-full" size="small" disabled={true} color="secondary">
													Invited
												</Button>
											) : (
												<Button className="w-full" size="small" onClick={sendInvite(s)} disabled={s.pending}>
													Invite
												</Button>
											)}
											<Button
												size="small"
												color="secondary"
												onClick={() => {
													nav(`/projects/${project.id}/signups/${s.id}`)
												}}
											>
												View
											</Button>
										</Row>
									</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			</Column>
		</Column>
	)
}

export default ProjectWaitlistPage
