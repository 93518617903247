import { Shield } from "lucide-react"
import { useCallback, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useParams } from "react-router-dom"
import { SidebarMenu } from "../../components/SidebarMenu"
import { Skeleton } from "../../components/Skeleton"
import { Spinner } from "../../components/Spinner"
import { Button, Column, Mono, Row, Tile } from "../../components/UI"
import { isAccessReadOnly } from "../../interfaces"
import { getProject } from "../../lib/api"
import { settings } from "../../lib/settings"
import * as cloud from "../../messages/cloud.pb"

const ProjectWebhooksPage = () => {
	const params = useParams<{ id: string }>()
	const [project, setProject] = useState<cloud.Project>()

	useEffect(() => {
		if (!params.id) {
			return
		}
		getProject(params.id).then(setProject)
	}, [params.id])

	if (!project) {
		return null
	}

	return (
		<>
			<Helmet>
				<title>
					{project.name} – {settings.appName}
				</title>
			</Helmet>
			<Skeleton project={project}>
				<SidebarMenu project={project} />
				<Column className="flex-1 h-full items-center p-4">
					<Column className="w-full max-w-[1000px]">
						<SectionWebhooks project={project} />
					</Column>
				</Column>
			</Skeleton>
		</>
	)
}

const SectionWebhooks = ({ project }: { project: cloud.Project }) => {
	const [hooks, setHooks] = useState<cloud.GetProjectWebhooksResponse | null>(null)

	const [unsaved, setUnsaved] = useState<{ [key: string]: boolean }>({})

	const getWebhooks = useCallback(() => {
		cloud.GetProjectWebhooks({ projectId: project.id }).then(setHooks)
	}, [project])

	useEffect(() => getWebhooks(), [getWebhooks])

	const save = useCallback(
		({ event, url }: { event: string; url: string | null }) =>
			() => {
				cloud
					.UpsertProjectWebhook({
						projectId: project.id,
						event,
						url: url != null && url.length > 0 ? url.trim() : null,
					})
					.then(() => {
						getWebhooks()
						setUnsaved({ ...unsaved, [event]: false })
					})
			},
		[getWebhooks, project, unsaved]
	)

	if (hooks == null) {
		return <Spinner />
	}
	return (
		<Column>
			<Tile empty>
				<Row className="!items-start gap-4">
					<Shield />
					<Column className="flex-1">
						<div>
							Webhook requests for this project will be sent with the <Mono>X-Webhook-Secret</Mono> header set to
						</div>
						<Mono className="text-center select-text">{project.webhookSecret}</Mono>
						<div>You can use this secret value to validate the authenticity of the requests.</div>
					</Column>
				</Row>
			</Tile>
			<Tile>
				<Row>
					<div className="flex-1">Invite accepted</div>
					<Row className="flex-1">
						<input
							disabled={isAccessReadOnly(project)}
							type="text"
							className="input-text flex-1"
							value={hooks.webhookInviteAccepted || ""}
							onChange={(e) => {
								setUnsaved({ ...unsaved, invite_accepted: true })
								setHooks({ ...hooks, webhookInviteAccepted: e.target.value })
							}}
						/>
					</Row>
				</Row>
				<Row>
					<div className="text-sm text-stone-400 ">
						When an invite is accepted, this webhook will fire containing the signup information.
					</div>
					<Button
						disabled={!unsaved["invite_accepted"]}
						onClick={save({ event: "invite_accepted", url: hooks.webhookInviteAccepted || null })}
					>
						Save
					</Button>
				</Row>
			</Tile>
		</Column>
	)
}

export default ProjectWebhooksPage
