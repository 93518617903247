import classNames from "classnames"
import moment from "moment"
import pluralize from "pluralize"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Helmet } from "react-helmet"
import { useNavigate, useParams } from "react-router-dom"
import { SidebarMenu } from "../../components/SidebarMenu"
import { Skeleton } from "../../components/Skeleton"
import { Spinner } from "../../components/Spinner"
import { Column, Mono, Row, Tile } from "../../components/UI"
import { getProject } from "../../lib/api"
import { settings } from "../../lib/settings"
import * as cloud from "../../messages/cloud.pb"

const ProjectInvitesPage = () => {
	const params = useParams<{ id: string }>()
	const [project, setProject] = useState<cloud.Project>()

	useEffect(() => {
		if (!params.id) {
			return
		}
		getProject(params.id).then(setProject)
	}, [params.id])

	if (!project) {
		return null
	}

	return (
		<>
			<Helmet>
				<title>
					{project.name} – {settings.appName}
				</title>
			</Helmet>
			<Skeleton project={project}>
				<SidebarMenu project={project} />
				<Column className="flex-1 h-full items-center p-4">
					<Column className="w-full max-w-[1000px]">
						<SignupsList project={project} />
					</Column>
				</Column>
			</Skeleton>
		</>
	)
}

const SignupsList = ({ project }: { project: cloud.Project }) => {
	const [invited, setInvited] = useState<cloud.ProjectSignup[] | null>(null)
	const nav = useNavigate()

	const getInvited = useCallback(() => {
		cloud.GetProjectInvites({ projectId: project.id }).then((r) => {
			setInvited(r.signups)
		})
	}, [project, setInvited])

	useEffect(() => {
		getInvited()
	}, [getInvited])

	const accepted = useMemo(() => {
		if (!invited) {
			return 0
		}
		return invited.filter((e) => e.acceptedAt != null).length
	}, [invited])

	if (invited == null) {
		return <Spinner />
	}
	if (invited.length === 0) {
		return <div>No invites yet.</div>
	}

	return (
		<>
			<Column className="gap-8">
				<Tile>
					<div>
						<span className="font-black">{invited.length}</span> {pluralize("user", invited.length)}{" "}
						{invited.length !== 1 ? "were" : "was"} invited, <span className="font-black">{accepted}</span> accepted the
						invite and <span className="font-black">{invited.length - accepted}</span>{" "}
						{invited.length - accepted !== 1 ? "are" : "is"} still pending.
					</div>
				</Tile>
				<Column>
					{invited.map((s) => {
						return (
							<Tile
								size="small"
								key={s.id}
								onClick={() => {
									nav(`/projects/${s.projectId}/signups/${s.id}`)
								}}
							>
								<Row>
									<div className="flex-1">{s.email}</div>
									{s.accessCode && (
										<div className="flex-1">
											<Mono className="text-xs">{s.accessCode}</Mono>
										</div>
									)}
									<div className="flex-[0.3]">{moment.unix(s.createdAt).fromNow()}</div>
									<div className="flex-[0.3]">
										{s.acceptedAt ? (
											<span className={classNames("text-sm text-teal-500")}>Accepted</span>
										) : (
											<span className={classNames("text-sm text-orange-500")}>Not accepted yet</span>
										)}
									</div>
								</Row>
							</Tile>
						)
					})}
				</Column>
			</Column>
		</>
	)
}

export default ProjectInvitesPage
