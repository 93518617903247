import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import { X } from "lucide-react"
import { useMemo } from "react"
import { useAppStore } from "../lib/appStore"
import { Button, Column, Row } from "./UI"

export const ToastContainer = () => {
	const toasts = useAppStore((store) => store.toasts)
	const removeToast = useAppStore((store) => store.removeToast)
	const uniqueToasts = useMemo(() => {
		return toasts
			.map((t) => t.id)
			.filter((t, index, self) => self.indexOf(t) === index)
			.map((id) => toasts.find((t) => t.id === id))
	}, [toasts])

	return (
		<Column className="z-30 select-none fixed bottom-5 max-h-[20%] overflow-y-auto mx-auto h-auto cursor-default items-center gap-4 text-sm">
			<AnimatePresence>
				{uniqueToasts.map((t) => {
					return (
						<motion.div
							key={t.id}
							initial={{ scale: 0, opacity: 0 }}
							animate={{
								scale: 1,
								opacity: 1,
							}}
							exit={{
								scale: 0,
								opacity: 0,
							}}
							className={classNames("max-w-[400px] rounded-full shadow-lg p-2 pl-4", "text-white", {
								"bg-teal-600": t.type === "info",
								"bg-amber-600": t.type === "warning",
								"bg-red-600": t.type === "error",
							})}
						>
							<Row className="gap-2">
								{t.text}
								<Button
									color="empty"
									className="border-none !text-white"
									size="small"
									aspect="square"
									shape="round"
									onClick={() => removeToast(t)}
								>
									<X />
								</Button>
							</Row>
						</motion.div>
					)
				})}
			</AnimatePresence>
		</Column>
	)
}
