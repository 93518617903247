import { motion } from "framer-motion"
import { useCallback, useEffect } from "react"
import { useAppStore } from "../lib/appStore"
import { Button, Column, Row } from "./UI"

export const DialogContainer = () => {
	const dialog = useAppStore((store) => store.dialog)
	const setDialog = useAppStore((store) => store.setDialog)
	useEffect(() => {
		if (!dialog) {
			return
		}
		const handler = (e: KeyboardEvent) => {
			if (e.key === "Escape") {
				setDialog(null)
			}
		}
		window.addEventListener("keyup", handler)
		return function () {
			window.removeEventListener("keyup", handler)
		}
	}, [setDialog, dialog])

	const onCancel = useCallback(() => {
		if (dialog.onCancel) {
			dialog.onCancel()
		}
		setDialog(null)
	}, [dialog, setDialog])

	const onConfirm = useCallback(() => {
		if (dialog.onConfirm) {
			dialog.onConfirm()
		}
		setDialog(null)
	}, [dialog, setDialog])

	if (!dialog) {
		return null
	}

	return (
		<motion.div
			initial={{ opacity: 0.5 }}
			animate={{ opacity: 1 }}
			className="z-50 select-none fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-25 flex items-center justify-center backdrop-blur-sm !text-black"
		>
			<motion.div
				initial={{ scale: 0.5 }}
				animate={{ scale: 1 }}
				className="bg-white p-8 rounded-lg shadow-lg w-full max-w-[300px]"
			>
				<Column className="gap-4">
					<div>{dialog.text}</div>
					<Row>
						<Button color="secondary" onClick={onCancel}>
							Cancel
						</Button>
						<Button color={dialog.danger ? "danger" : "primary"} onClick={onConfirm}>
							Confirm
						</Button>
					</Row>
				</Column>
			</motion.div>
		</motion.div>
	)
}
