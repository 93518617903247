import classNames from "classnames"
import { motion } from "framer-motion"
import { Plus } from "lucide-react"
import { useMemo } from "react"
import { Helmet } from "react-helmet"

// ...

const UI = () => {
	return (
		<>
			<Helmet>
				<title>UI</title>
			</Helmet>
			<Column className="w-1/2 mx-auto">
				<Row className="items-stretch">
					Button
					<Row className="gap-2 items-stretch">
						<Column className="gap-2">
							Regular
							<Button color="primary">Button</Button>
							<Button color="secondary">Button</Button>
							<Button color="warning">Button</Button>
							<Button color="danger">Button</Button>
							<Button color="empty">Button</Button>
						</Column>
						<Column className="gap-2">
							Small
							<Button color="primary" size="small">
								Button
							</Button>
							<Button color="secondary" size="small">
								Button
							</Button>
							<Button color="warning" size="small">
								Button
							</Button>
							<Button color="danger" size="small">
								Button
							</Button>
							<Button color="empty" size="small">
								Button
							</Button>
						</Column>
						<Column className="gap-2">
							Icon
							<div>
								<Button color="primary">
									<Plus />
									Button
								</Button>
							</div>
							<div>
								<Button color="primary" size="small">
									<Plus /> Button
								</Button>
							</div>
							<div>
								<Button color="primary" size="small" aspect="square">
									<Plus />
								</Button>
							</div>
						</Column>
					</Row>
				</Row>
				<Row className="items-stretch">
					Tile
					<Row className="gap-2">
						<Row className="gap-2 items-stretch">
							<Column className="gap-2">
								Clickable
								<Tile onClick={() => {}}>
									<TileRow number={42} description="This is the description" iconClass="bg-stone-300 " />
								</Tile>
								<Tile onClick={() => {}} empty>
									<TileRow number={42} description="This is the description" iconClass="bg-stone-300 " />
								</Tile>
								<Tile onClick={() => {}}>
									<TileRow number={42} description="This is the description" />
									<TileRow number={42} description="This is the description" />
									<TileRow number={42} description="This is the description" />
								</Tile>
							</Column>
						</Row>
						<Row className="gap-2 items-stretch">
							<Column className="gap-2">
								Not clickable
								<Tile>
									<TileRow number={42} description="This is the description" iconClass="bg-stone-300 " />
								</Tile>
								<Tile empty>
									<TileRow number={42} description="This is the description" iconClass="bg-stone-300 " />
								</Tile>
								<Tile>
									<TileRow number={42} description="This is the description" />
									<TileRow number={42} description="This is the description" />
									<TileRow number={42} description="This is the description" />
								</Tile>
							</Column>
						</Row>
					</Row>
				</Row>
			</Column>
		</>
	)
}

export const buttonWhileTap = {
	scale: 0.98,
	opacity: 0.7,
	transition: {
		duration: 0.06,
	},
}

export const Button = (
	props: {
		color?: "primary" | "secondary" | "danger" | "warning" | "empty"
		size?: "regular" | "small"
		aspect?: "regular" | "square"
		shape?: "regular" | "round"
	} & JSX.IntrinsicElements["button"]
) => {
	return (
		<motion.button
			whileTap={buttonWhileTap}
			onClick={props.onClick}
			disabled={props.disabled}
			tabIndex={-1}
			className={classNames(
				"rounded-lg font-semibold flex items-center justify-center gap-1 select-none",
				{
					"text-white": props.color !== "empty",
					shadow: props.color !== "empty",
					"border border-stone-300 text-stone-600": props.color === "empty",
				},
				{
					"rounded-full": props.shape === "round",
				},
				{
					"py-2 px-4": !props.size || props.size === "regular",
					"py-1 px-2": props.size === "small",
				},
				{
					"bg-blue-500": !props.color || props.color === "primary",
					"bg-amber-500": props.color === "warning",
					"bg-stone-200 text-stone-500 ": props.color === "secondary",
					"bg-red-500": props.color === "danger",
					"bg-transparent": props.color === "empty",
				},
				{
					"text-sm": props.size === "small",
					"hover:text-stone-600 ": props.color === "empty",
				},
				{
					"svg:w-[18px] svg:h-[18px]": !props.size || props.size === "regular",
					"svg:w-[15px] svg:h-[15px]": props.size === "small",
				},
				{
					"aspect-square": props.aspect === "square",
				},
				{
					"!opacity-50 !cursor-not-allowed": props.disabled,
				},
				props.className
			)}
		>
			{props.children}
		</motion.button>
	)
}

export const Tile = (
	props: { full?: boolean; size?: "default" | "small"; empty?: boolean } & JSX.IntrinsicElements["div"]
) => {
	return (
		<Column
			className={classNames(
				"select-none gap-4 justify-center",
				{
					"p-6 rounded-xl": !props.size || props.size === "default",
					"px-4 py-2 rounded-lg": props.size === "small",
				},
				{ "flex-1 w-full": props.full },
				{ "bg-stone-100  border-transparent": !props.empty },
				{ "group cursor-pointer": props.onClick },
				{
					"hover:bg-black  hover:border-black": props.onClick,
					"div:hover:text-white": props.onClick,
				},
				"border border-stone-200 ",
				props.className
			)}
			onClick={props.onClick}
		>
			{props.children}
		</Column>
	)
}

export const TileRow = (props: { number: string | number; description: string; iconClass?: string }) => {
	return (
		<Row className="items-stretch gap-10 group-hover:text-white">
			<Column className="gap-0">
				<div className="font-semibold text-2xl">{props.number}</div>
				<div className="text-stone-400 group-hover:text-white">{props.description}</div>
			</Column>
		</Row>
	)
}

export const Mono = (props: {} & JSX.IntrinsicElements["span"]) => {
	return (
		<span className={classNames("font-mono text-sm rounded p-1", "bg-stone-200 ", "text-purple-600 ", props.className)}>
			{props.children}
		</span>
	)
}

export const Preformatted = (props: {} & JSX.IntrinsicElements["pre"]) => {
	return (
		<pre
			className={classNames(
				"font-mono text-sm rounded p-5 m-3",
				"bg-stone-200 ",
				"text-purple-600 ",
				"border border-stone-300"
			)}
		>
			{props.children}
		</pre>
	)
}

export const Row = (props: {} & JSX.IntrinsicElements["div"]) => {
	const mergedProps = useMemo(
		() => ({
			...props,
			className: classNames("flex items-center justify-between", props.className),
		}),
		[props]
	)
	return <motion.div {...(mergedProps as any)} layout="position" />
}

export const Column = (props: {} & JSX.IntrinsicElements["div"]) => {
	const mergedProps = useMemo(
		() => ({
			...props,
			className: classNames("flex flex-col gap-2", props.className),
		}),
		[props]
	)
	return <motion.div {...(mergedProps as any)} layout="position" />
}

export default UI
