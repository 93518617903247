// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
// Source: cloud.proto
/* eslint-disable */

import type { ByteSource } from "protoscript";
import {
  BinaryReader,
  BinaryWriter,
  encodeBase64Bytes,
  decodeBase64Bytes,
} from "protoscript";
import { JSONrequest, PBrequest } from "twirpscript";
// This is the minimum version supported by the current runtime.
// If this line fails typechecking, breaking changes have been introduced and this
// file needs to be regenerated by running `npx twirpscript`.
export { MIN_SUPPORTED_VERSION_0_0_56 } from "twirpscript";
import type { ClientConfiguration } from "twirpscript";
import * as forms from "./forms.pb";
import * as types from "./types.pb";

//========================================//
//                 Types                  //
//========================================//

export interface SignedToken {
  signature: Uint8Array;
  token: Uint8Array;
}

export interface Token {
  expiresAt: number;
  user: User;
  version: number;
}

export interface User {
  id: string;
  email: string;
}

export interface Member {
  id: string;
  userId?: string | null | undefined;
  email?: string | null | undefined;
  access: number;
}

export interface SigninRequest {
  Token: string;
}

export interface SigninResponse {}

export interface SignoutRequest {}

export interface SignoutResponse {}

export interface SignupRequest {
  Token: string;
}

export interface SignupResponse {}

export interface CreateSignupTokenRequest {
  Email: string;
}

export interface CreateSignupTokenResponse {}

export interface CreateSigninTokenRequest {
  Email: string;
}

export interface CreateSigninTokenResponse {}

export interface MeRequest {}

export interface MeResponse {
  user: User;
}

export interface GetInvitedSignupRequest {
  inviteId: string;
}

export interface GetInvitedSignupResponse {
  email: string;
  project: string;
}

export interface AcceptInviteRequest {
  inviteId: string;
}

export interface AcceptInviteResponse {
  ok: boolean;
  redirectTo?: string | null | undefined;
  message?: string | null | undefined;
}

export interface Project {
  id: string;
  name: string;
  formId: string;
  createdAt: number;
  updatedAt: number;
  handle: string;
  fromEmail: string;
  emailReplyTo?: string | null | undefined;
  webhookSecret: string;
  requiresAccessCode: boolean;
  inviteRedirectUrl?: string | null | undefined;
  inviteAcceptedCallback?: string | null | undefined;
  access: number;
}

export interface ProjectWithSignups {
  project: Project;
  signups: number;
  members: Member[];
}

export interface ListProjectsRequest {}

export interface ListProjectsResponse {
  projects: ProjectWithSignups[];
}

export interface CreateProjectRequest {
  name: string;
}

export interface CreateProjectResponse {
  project: Project;
}

export interface GetProjectRequest {
  projectId: string;
}

export interface GetProjectResponse {
  project: Project;
}

export interface DeleteProjectRequest {
  projectId: string;
}

export interface DeleteProjectResponse {}

export interface GetProjectStatsRequest {
  projectId: string;
}

export interface GetProjectStatsResponse {
  signups: number;
  invited: number;
  waitlist: number;
  accepted: number;
}

export interface ProjectSignup {
  id: string;
  email: string;
  createdAt: number;
  karma: number;
  projectId: string;
  invitedAt?: number | null | undefined;
  acceptedAt?: number | null | undefined;
  position: number;
  referral: string;
  pending: boolean;
  expiresAt?: number | null | undefined;
  accessCode?: string | null | undefined;
}

export interface GetProjectSignupsRequest {
  projectId: string;
}

export interface GetProjectSignupsResponse {
  signups: ProjectSignup[];
}

export interface GetProjectWaitlistRequest {
  projectId: string;
}

export interface GetProjectWaitlistResponse {
  signups: ProjectSignup[];
}

export interface GetProjectInvitesRequest {
  projectId: string;
}

export interface GetProjectInvitesResponse {
  signups: ProjectSignup[];
}

export interface SendInviteRequest {
  projectId: string;
  signupId: string;
}

export interface SendInviteResponse {}

export interface GetProjectFormBlocksRequest {
  projectId: string;
  formId: string;
}

export interface GetProjectFormBlocksResponse {
  blocks: forms.FormBlock[];
  published: boolean;
}

export interface UpsertProjectFormBlockRequest {
  blockId: string;
  projectId: string;
  formId: string;
  content: forms.FormBlockContent;
  position: number;
}

export interface UpsertProjectFormBlockResponse {
  block: forms.FormBlock;
}

export interface DeleteProjectFormBlockRequest {
  projectId: string;
  formId: string;
  blockId: string;
}

export interface DeleteProjectFormBlockResponse {}

export interface ProjectSchedule {
  id: string;
  at?: number | null | undefined;
  done?: boolean | null | undefined;
  repeat?: string | null | undefined;
  projectId: string;
  limit?: number | null | undefined;
  positionLt?: number | null | undefined;
  ageLt?: string | null | undefined;
  karmaGt?: number | null | undefined;
  successScheduledCount?: number | null | undefined;
  createdAt: number;
  updatedAt: number;
  canceled: boolean;
}

export interface GetProjectSchedulesRequest {
  projectId: string;
}

export interface GetProjectSchedulesResponse {
  schedules: ProjectSchedule[];
}

export interface CreateProjectScheduleRequest {
  projectId: string;
  at?: number | null | undefined;
  repeat?: string | null | undefined;
  positionLt?: number | null | undefined;
  ageLt?: string | null | undefined;
  karmaGt?: number | null | undefined;
  limit?: number | null | undefined;
}

export interface CreateProjectScheduleResponse {}

export interface HideProjectScheduleRequest {
  projectId: string;
  scheduleId: string;
}

export interface HideProjectScheduleResponse {}

export interface CancelProjectScheduleRequest {
  projectId: string;
  scheduleId: string;
}

export interface CancelProjectScheduleResponse {}

export interface ResumeProjectScheduleRequest {
  projectId: string;
  scheduleId: string;
}

export interface ResumeProjectScheduleResponse {}

export interface ReplayProjectScheduleRequest {
  projectId: string;
  scheduleId: string;
}

export interface ReplayProjectScheduleResponse {}

export interface UpdateProjectHandleRequest {
  projectId: string;
  newHandle: string;
}

export interface UpdateProjectHandleResponse {}

export interface UpdateProjectEmailReplyToRequest {
  projectId: string;
  newEmailReplyTo?: string | null | undefined;
}

export interface UpdateProjectEmailReplyToResponse {}

export interface UpdateProjectNameRequest {
  projectId: string;
  newName: string;
}

export interface UpdateProjectNameResponse {}

export interface FormBlockContentWithBlockID {
  id: string;
  content: forms.FormBlockContent;
}

export interface ProjectSignupDetails {
  signup: ProjectSignup;
  project: Project;
  answers: forms.Answer[];
  blocks: FormBlockContentWithBlockID[];
  referrals: ProjectSignup[];
}

export interface GetProjectSignupDetailsRequest {
  projectId: string;
  signupId: string;
}

export interface GetProjectSignupDetailsResponse {
  details: ProjectSignupDetails;
}

export interface GetSignupPositionRequest {
  signupCode: string;
}

export interface GetSignupPositionResponse {
  projectName: string;
  position: bigint;
  referrals: number;
}

export interface AddProjectDomainRequest {
  projectId: string;
  domainName: string;
}

export interface AddProjectDomainResponse {}

export interface RemoveProjectDomainRequest {
  projectId: string;
  domainId: string;
}

export interface RemoveProjectDomainResponse {}

export interface GetProjectDomainRequest {
  projectId: string;
}

export interface Domain {
  id: string;
  domainName: string;
  verified: boolean;
  verificationCode: string;
  lastCheckedAt: number;
}

export interface GetProjectDomainResponse {
  domain?: Domain | null | undefined;
}

export interface CheckProjectDomainVerifiedRequest {
  domainId: string;
  projectId: string;
}

export interface CheckProjectDomainVerifiedResponse {
  verified: boolean;
}

export interface EmailTemplate {
  id: string;
  type: string;
  content: string;
  default: boolean;
  variables: Record<string, EmailTemplate.Variables["value"] | undefined>;
}

export declare namespace EmailTemplate {
  interface Variables {
    key: string;
    value: string;
  }
}

export interface GetProjectEmailTemplatesRequest {
  projectId: string;
}

export interface GetProjectEmailTemplatesResponse {
  templates: EmailTemplate[];
}

export interface UpsertProjectEmailTemplateRequest {
  projectId: string;
  templateId: string;
  content: string;
  type: string;
}

export interface UpsertProjectEmailTemplateResponse {}

export interface GetProjectWebhooksRequest {
  projectId: string;
}

export interface GetProjectWebhooksResponse {
  webhookInviteAccepted?: string | null | undefined;
}

export interface UpsertProjectWebhookRequest {
  projectId: string;
  event: string;
  url?: string | null | undefined;
}

export interface UpsertProjectWebhookResponse {}

export interface GetAPIKeysRequest {
  projectId: string;
}

export interface APIKey {
  id: string;
  key: string;
  createdAt: number;
  lastUsed?: number | null | undefined;
}

export interface GetAPIKeysResponse {
  keys: APIKey[];
}

export interface CreateAPIKeyRequest {
  projectId: string;
}

export interface CreateAPIKeyResponse {}

export interface DeleteAPIKeyRequest {
  projectId: string;
  apiKeyId: string;
}

export interface DeleteAPIKeyResponse {}

export interface ConfirmSignupEmailRequest {
  code: string;
}

export interface ConfirmSignupEmailResponse {}

export interface AccessCode {
  id: string;
  code: string;
  maxUses: number;
  used: number;
  createdAt: number;
  destination: string;
}

export interface GetProjectAccessCodesRequest {
  projectId: string;
}

export interface GetProjectAccessCodesResponse {
  codes: AccessCode[];
}

export interface GenerateProjectAccessCodesRequest {
  projectId: string;
  count: number;
  destination: string;
}

export interface GenerateProjectAccessCodesResponse {
  codes: AccessCode[];
}

export interface DeleteProjectAccessCodeRequest {
  projectId: string;
  codeId: string;
}

export interface DeleteProjectAccessCodeResponse {}

export interface UpdateProjectRequiresAccessCodeRequest {
  projectId: string;
  value: boolean;
}

export interface UpdateProjectRequiresAccessCodeResponse {}

export interface UpdateProjectInviteRedirectUrlRequest {
  projectId: string;
  url?: string | null | undefined;
}

export interface UpdateProjectInviteRedirectUrlResponse {}

export interface UpdateProjectInviteAcceptedCallbackRequest {
  projectId: string;
  url?: string | null | undefined;
}

export interface UpdateProjectInviteAcceptedCallbackResponse {}

export interface UpdatePublishProjectFormRequest {
  projectId: string;
  published: boolean;
}

export interface UpdatePublishProjectFormResponse {}

export interface GetProjectMembersRequest {
  projectId: string;
}

export interface GetProjectMembersResponse {
  members: Member[];
}

export interface DeleteProjectMemberRequest {
  projectId: string;
  id: string;
}

export interface DeleteProjectMemberResponse {}

export interface AddProjectMemberRequest {
  projectId: string;
  email: string;
  access: number;
}

export interface AddProjectMemberResponse {}

export interface UpdateProjectMemberAccessRequest {
  projectId: string;
  memberId: string;
  access: number;
}

export interface UpdateProjectMemberAccessResponse {}

export interface GetDefaultEmailTemplateRequest {
  projectId: string;
  type: string;
}

export interface GetDefaultEmailTemplateResponse {
  template: EmailTemplate;
}

export interface UpdateFormCSSRequest {
  projectId: string;
  css: string;
}

export interface UpdateFormCSSResponse {
  ok: boolean;
}

//========================================//
//         Cloud Protobuf Client          //
//========================================//

/**
 * Util
 */
export async function Ping(
  pingRequest: types.PingRequest,
  config?: ClientConfiguration
): Promise<types.PingResponse> {
  const response = await PBrequest(
    "/Cloud/Ping",
    types.PingRequest.encode(pingRequest),
    config
  );
  return types.PingResponse.decode(response);
}

/**
 * Auth
 */
export async function Me(
  meRequest: MeRequest,
  config?: ClientConfiguration
): Promise<MeResponse> {
  const response = await PBrequest(
    "/Cloud/Me",
    MeRequest.encode(meRequest),
    config
  );
  return MeResponse.decode(response);
}

export async function Signin(
  signinRequest: SigninRequest,
  config?: ClientConfiguration
): Promise<SigninResponse> {
  const response = await PBrequest(
    "/Cloud/Signin",
    SigninRequest.encode(signinRequest),
    config
  );
  return SigninResponse.decode(response);
}

export async function Signout(
  signoutRequest: SignoutRequest,
  config?: ClientConfiguration
): Promise<SignoutResponse> {
  const response = await PBrequest(
    "/Cloud/Signout",
    SignoutRequest.encode(signoutRequest),
    config
  );
  return SignoutResponse.decode(response);
}

export async function Signup(
  signupRequest: SignupRequest,
  config?: ClientConfiguration
): Promise<SignupResponse> {
  const response = await PBrequest(
    "/Cloud/Signup",
    SignupRequest.encode(signupRequest),
    config
  );
  return SignupResponse.decode(response);
}

export async function CreateSignupToken(
  createSignupTokenRequest: CreateSignupTokenRequest,
  config?: ClientConfiguration
): Promise<CreateSignupTokenResponse> {
  const response = await PBrequest(
    "/Cloud/CreateSignupToken",
    CreateSignupTokenRequest.encode(createSignupTokenRequest),
    config
  );
  return CreateSignupTokenResponse.decode(response);
}

export async function CreateSigninToken(
  createSigninTokenRequest: CreateSigninTokenRequest,
  config?: ClientConfiguration
): Promise<CreateSigninTokenResponse> {
  const response = await PBrequest(
    "/Cloud/CreateSigninToken",
    CreateSigninTokenRequest.encode(createSigninTokenRequest),
    config
  );
  return CreateSigninTokenResponse.decode(response);
}

/**
 * Signups
 */
export async function GetSignupPosition(
  getSignupPositionRequest: GetSignupPositionRequest,
  config?: ClientConfiguration
): Promise<GetSignupPositionResponse> {
  const response = await PBrequest(
    "/Cloud/GetSignupPosition",
    GetSignupPositionRequest.encode(getSignupPositionRequest),
    config
  );
  return GetSignupPositionResponse.decode(response);
}

export async function ConfirmSignupEmail(
  confirmSignupEmailRequest: ConfirmSignupEmailRequest,
  config?: ClientConfiguration
): Promise<ConfirmSignupEmailResponse> {
  const response = await PBrequest(
    "/Cloud/ConfirmSignupEmail",
    ConfirmSignupEmailRequest.encode(confirmSignupEmailRequest),
    config
  );
  return ConfirmSignupEmailResponse.decode(response);
}

/**
 * Invites
 */
export async function GetInvitedSignup(
  getInvitedSignupRequest: GetInvitedSignupRequest,
  config?: ClientConfiguration
): Promise<GetInvitedSignupResponse> {
  const response = await PBrequest(
    "/Cloud/GetInvitedSignup",
    GetInvitedSignupRequest.encode(getInvitedSignupRequest),
    config
  );
  return GetInvitedSignupResponse.decode(response);
}

export async function AcceptInvite(
  acceptInviteRequest: AcceptInviteRequest,
  config?: ClientConfiguration
): Promise<AcceptInviteResponse> {
  const response = await PBrequest(
    "/Cloud/AcceptInvite",
    AcceptInviteRequest.encode(acceptInviteRequest),
    config
  );
  return AcceptInviteResponse.decode(response);
}

/**
 * Projects
 */
export async function ListProjects(
  listProjectsRequest: ListProjectsRequest,
  config?: ClientConfiguration
): Promise<ListProjectsResponse> {
  const response = await PBrequest(
    "/Cloud/ListProjects",
    ListProjectsRequest.encode(listProjectsRequest),
    config
  );
  return ListProjectsResponse.decode(response);
}

export async function CreateProject(
  createProjectRequest: CreateProjectRequest,
  config?: ClientConfiguration
): Promise<CreateProjectResponse> {
  const response = await PBrequest(
    "/Cloud/CreateProject",
    CreateProjectRequest.encode(createProjectRequest),
    config
  );
  return CreateProjectResponse.decode(response);
}

export async function GetProject(
  getProjectRequest: GetProjectRequest,
  config?: ClientConfiguration
): Promise<GetProjectResponse> {
  const response = await PBrequest(
    "/Cloud/GetProject",
    GetProjectRequest.encode(getProjectRequest),
    config
  );
  return GetProjectResponse.decode(response);
}

export async function DeleteProject(
  deleteProjectRequest: DeleteProjectRequest,
  config?: ClientConfiguration
): Promise<DeleteProjectResponse> {
  const response = await PBrequest(
    "/Cloud/DeleteProject",
    DeleteProjectRequest.encode(deleteProjectRequest),
    config
  );
  return DeleteProjectResponse.decode(response);
}

export async function GetProjectStats(
  getProjectStatsRequest: GetProjectStatsRequest,
  config?: ClientConfiguration
): Promise<GetProjectStatsResponse> {
  const response = await PBrequest(
    "/Cloud/GetProjectStats",
    GetProjectStatsRequest.encode(getProjectStatsRequest),
    config
  );
  return GetProjectStatsResponse.decode(response);
}

export async function GetProjectSignups(
  getProjectSignupsRequest: GetProjectSignupsRequest,
  config?: ClientConfiguration
): Promise<GetProjectSignupsResponse> {
  const response = await PBrequest(
    "/Cloud/GetProjectSignups",
    GetProjectSignupsRequest.encode(getProjectSignupsRequest),
    config
  );
  return GetProjectSignupsResponse.decode(response);
}

export async function GetProjectWaitlist(
  getProjectWaitlistRequest: GetProjectWaitlistRequest,
  config?: ClientConfiguration
): Promise<GetProjectWaitlistResponse> {
  const response = await PBrequest(
    "/Cloud/GetProjectWaitlist",
    GetProjectWaitlistRequest.encode(getProjectWaitlistRequest),
    config
  );
  return GetProjectWaitlistResponse.decode(response);
}

export async function GetProjectInvites(
  getProjectInvitesRequest: GetProjectInvitesRequest,
  config?: ClientConfiguration
): Promise<GetProjectInvitesResponse> {
  const response = await PBrequest(
    "/Cloud/GetProjectInvites",
    GetProjectInvitesRequest.encode(getProjectInvitesRequest),
    config
  );
  return GetProjectInvitesResponse.decode(response);
}

export async function SendInvite(
  sendInviteRequest: SendInviteRequest,
  config?: ClientConfiguration
): Promise<SendInviteResponse> {
  const response = await PBrequest(
    "/Cloud/SendInvite",
    SendInviteRequest.encode(sendInviteRequest),
    config
  );
  return SendInviteResponse.decode(response);
}

export async function UpdateProjectHandle(
  updateProjectHandleRequest: UpdateProjectHandleRequest,
  config?: ClientConfiguration
): Promise<UpdateProjectHandleResponse> {
  const response = await PBrequest(
    "/Cloud/UpdateProjectHandle",
    UpdateProjectHandleRequest.encode(updateProjectHandleRequest),
    config
  );
  return UpdateProjectHandleResponse.decode(response);
}

export async function UpdateProjectName(
  updateProjectNameRequest: UpdateProjectNameRequest,
  config?: ClientConfiguration
): Promise<UpdateProjectNameResponse> {
  const response = await PBrequest(
    "/Cloud/UpdateProjectName",
    UpdateProjectNameRequest.encode(updateProjectNameRequest),
    config
  );
  return UpdateProjectNameResponse.decode(response);
}

export async function GetProjectSignupDetails(
  getProjectSignupDetailsRequest: GetProjectSignupDetailsRequest,
  config?: ClientConfiguration
): Promise<GetProjectSignupDetailsResponse> {
  const response = await PBrequest(
    "/Cloud/GetProjectSignupDetails",
    GetProjectSignupDetailsRequest.encode(getProjectSignupDetailsRequest),
    config
  );
  return GetProjectSignupDetailsResponse.decode(response);
}

export async function UpdateProjectEmailReplyTo(
  updateProjectEmailReplyToRequest: UpdateProjectEmailReplyToRequest,
  config?: ClientConfiguration
): Promise<UpdateProjectEmailReplyToResponse> {
  const response = await PBrequest(
    "/Cloud/UpdateProjectEmailReplyTo",
    UpdateProjectEmailReplyToRequest.encode(updateProjectEmailReplyToRequest),
    config
  );
  return UpdateProjectEmailReplyToResponse.decode(response);
}

export async function AddProjectDomain(
  addProjectDomainRequest: AddProjectDomainRequest,
  config?: ClientConfiguration
): Promise<AddProjectDomainResponse> {
  const response = await PBrequest(
    "/Cloud/AddProjectDomain",
    AddProjectDomainRequest.encode(addProjectDomainRequest),
    config
  );
  return AddProjectDomainResponse.decode(response);
}

export async function RemoveProjectDomain(
  removeProjectDomainRequest: RemoveProjectDomainRequest,
  config?: ClientConfiguration
): Promise<RemoveProjectDomainResponse> {
  const response = await PBrequest(
    "/Cloud/RemoveProjectDomain",
    RemoveProjectDomainRequest.encode(removeProjectDomainRequest),
    config
  );
  return RemoveProjectDomainResponse.decode(response);
}

export async function GetProjectDomain(
  getProjectDomainRequest: GetProjectDomainRequest,
  config?: ClientConfiguration
): Promise<GetProjectDomainResponse> {
  const response = await PBrequest(
    "/Cloud/GetProjectDomain",
    GetProjectDomainRequest.encode(getProjectDomainRequest),
    config
  );
  return GetProjectDomainResponse.decode(response);
}

export async function CheckProjectDomainVerified(
  checkProjectDomainVerifiedRequest: CheckProjectDomainVerifiedRequest,
  config?: ClientConfiguration
): Promise<CheckProjectDomainVerifiedResponse> {
  const response = await PBrequest(
    "/Cloud/CheckProjectDomainVerified",
    CheckProjectDomainVerifiedRequest.encode(checkProjectDomainVerifiedRequest),
    config
  );
  return CheckProjectDomainVerifiedResponse.decode(response);
}

export async function GetProjectEmailTemplates(
  getProjectEmailTemplatesRequest: GetProjectEmailTemplatesRequest,
  config?: ClientConfiguration
): Promise<GetProjectEmailTemplatesResponse> {
  const response = await PBrequest(
    "/Cloud/GetProjectEmailTemplates",
    GetProjectEmailTemplatesRequest.encode(getProjectEmailTemplatesRequest),
    config
  );
  return GetProjectEmailTemplatesResponse.decode(response);
}

export async function UpsertProjectEmailTemplate(
  upsertProjectEmailTemplateRequest: UpsertProjectEmailTemplateRequest,
  config?: ClientConfiguration
): Promise<UpsertProjectEmailTemplateResponse> {
  const response = await PBrequest(
    "/Cloud/UpsertProjectEmailTemplate",
    UpsertProjectEmailTemplateRequest.encode(upsertProjectEmailTemplateRequest),
    config
  );
  return UpsertProjectEmailTemplateResponse.decode(response);
}

export async function GetDefaultEmailTemplate(
  getDefaultEmailTemplateRequest: GetDefaultEmailTemplateRequest,
  config?: ClientConfiguration
): Promise<GetDefaultEmailTemplateResponse> {
  const response = await PBrequest(
    "/Cloud/GetDefaultEmailTemplate",
    GetDefaultEmailTemplateRequest.encode(getDefaultEmailTemplateRequest),
    config
  );
  return GetDefaultEmailTemplateResponse.decode(response);
}

export async function GetProjectWebhooks(
  getProjectWebhooksRequest: GetProjectWebhooksRequest,
  config?: ClientConfiguration
): Promise<GetProjectWebhooksResponse> {
  const response = await PBrequest(
    "/Cloud/GetProjectWebhooks",
    GetProjectWebhooksRequest.encode(getProjectWebhooksRequest),
    config
  );
  return GetProjectWebhooksResponse.decode(response);
}

export async function UpsertProjectWebhook(
  upsertProjectWebhookRequest: UpsertProjectWebhookRequest,
  config?: ClientConfiguration
): Promise<UpsertProjectWebhookResponse> {
  const response = await PBrequest(
    "/Cloud/UpsertProjectWebhook",
    UpsertProjectWebhookRequest.encode(upsertProjectWebhookRequest),
    config
  );
  return UpsertProjectWebhookResponse.decode(response);
}

export async function GetProjectMembers(
  getProjectMembersRequest: GetProjectMembersRequest,
  config?: ClientConfiguration
): Promise<GetProjectMembersResponse> {
  const response = await PBrequest(
    "/Cloud/GetProjectMembers",
    GetProjectMembersRequest.encode(getProjectMembersRequest),
    config
  );
  return GetProjectMembersResponse.decode(response);
}

export async function DeleteProjectMember(
  deleteProjectMemberRequest: DeleteProjectMemberRequest,
  config?: ClientConfiguration
): Promise<DeleteProjectMemberResponse> {
  const response = await PBrequest(
    "/Cloud/DeleteProjectMember",
    DeleteProjectMemberRequest.encode(deleteProjectMemberRequest),
    config
  );
  return DeleteProjectMemberResponse.decode(response);
}

export async function UpdateProjectMemberAccess(
  updateProjectMemberAccessRequest: UpdateProjectMemberAccessRequest,
  config?: ClientConfiguration
): Promise<UpdateProjectMemberAccessResponse> {
  const response = await PBrequest(
    "/Cloud/UpdateProjectMemberAccess",
    UpdateProjectMemberAccessRequest.encode(updateProjectMemberAccessRequest),
    config
  );
  return UpdateProjectMemberAccessResponse.decode(response);
}

export async function AddProjectMember(
  addProjectMemberRequest: AddProjectMemberRequest,
  config?: ClientConfiguration
): Promise<AddProjectMemberResponse> {
  const response = await PBrequest(
    "/Cloud/AddProjectMember",
    AddProjectMemberRequest.encode(addProjectMemberRequest),
    config
  );
  return AddProjectMemberResponse.decode(response);
}

export async function GetAPIKeys(
  getAPIKeysRequest: GetAPIKeysRequest,
  config?: ClientConfiguration
): Promise<GetAPIKeysResponse> {
  const response = await PBrequest(
    "/Cloud/GetAPIKeys",
    GetAPIKeysRequest.encode(getAPIKeysRequest),
    config
  );
  return GetAPIKeysResponse.decode(response);
}

export async function CreateAPIKey(
  createAPIKeyRequest: CreateAPIKeyRequest,
  config?: ClientConfiguration
): Promise<CreateAPIKeyResponse> {
  const response = await PBrequest(
    "/Cloud/CreateAPIKey",
    CreateAPIKeyRequest.encode(createAPIKeyRequest),
    config
  );
  return CreateAPIKeyResponse.decode(response);
}

export async function DeleteAPIKey(
  deleteAPIKeyRequest: DeleteAPIKeyRequest,
  config?: ClientConfiguration
): Promise<DeleteAPIKeyResponse> {
  const response = await PBrequest(
    "/Cloud/DeleteAPIKey",
    DeleteAPIKeyRequest.encode(deleteAPIKeyRequest),
    config
  );
  return DeleteAPIKeyResponse.decode(response);
}

export async function GetProjectAccessCodes(
  getProjectAccessCodesRequest: GetProjectAccessCodesRequest,
  config?: ClientConfiguration
): Promise<GetProjectAccessCodesResponse> {
  const response = await PBrequest(
    "/Cloud/GetProjectAccessCodes",
    GetProjectAccessCodesRequest.encode(getProjectAccessCodesRequest),
    config
  );
  return GetProjectAccessCodesResponse.decode(response);
}

export async function GenerateProjectAccessCodes(
  generateProjectAccessCodesRequest: GenerateProjectAccessCodesRequest,
  config?: ClientConfiguration
): Promise<GenerateProjectAccessCodesResponse> {
  const response = await PBrequest(
    "/Cloud/GenerateProjectAccessCodes",
    GenerateProjectAccessCodesRequest.encode(generateProjectAccessCodesRequest),
    config
  );
  return GenerateProjectAccessCodesResponse.decode(response);
}

export async function DeleteProjectAccessCode(
  deleteProjectAccessCodeRequest: DeleteProjectAccessCodeRequest,
  config?: ClientConfiguration
): Promise<DeleteProjectAccessCodeResponse> {
  const response = await PBrequest(
    "/Cloud/DeleteProjectAccessCode",
    DeleteProjectAccessCodeRequest.encode(deleteProjectAccessCodeRequest),
    config
  );
  return DeleteProjectAccessCodeResponse.decode(response);
}

export async function UpdateProjectRequiresAccessCode(
  updateProjectRequiresAccessCodeRequest: UpdateProjectRequiresAccessCodeRequest,
  config?: ClientConfiguration
): Promise<UpdateProjectRequiresAccessCodeResponse> {
  const response = await PBrequest(
    "/Cloud/UpdateProjectRequiresAccessCode",
    UpdateProjectRequiresAccessCodeRequest.encode(
      updateProjectRequiresAccessCodeRequest
    ),
    config
  );
  return UpdateProjectRequiresAccessCodeResponse.decode(response);
}

export async function UpdateProjectInviteRedirectUrl(
  updateProjectInviteRedirectUrlRequest: UpdateProjectInviteRedirectUrlRequest,
  config?: ClientConfiguration
): Promise<UpdateProjectInviteRedirectUrlResponse> {
  const response = await PBrequest(
    "/Cloud/UpdateProjectInviteRedirectUrl",
    UpdateProjectInviteRedirectUrlRequest.encode(
      updateProjectInviteRedirectUrlRequest
    ),
    config
  );
  return UpdateProjectInviteRedirectUrlResponse.decode(response);
}

export async function UpdateProjectInviteAcceptedCallback(
  updateProjectInviteAcceptedCallbackRequest: UpdateProjectInviteAcceptedCallbackRequest,
  config?: ClientConfiguration
): Promise<UpdateProjectInviteAcceptedCallbackResponse> {
  const response = await PBrequest(
    "/Cloud/UpdateProjectInviteAcceptedCallback",
    UpdateProjectInviteAcceptedCallbackRequest.encode(
      updateProjectInviteAcceptedCallbackRequest
    ),
    config
  );
  return UpdateProjectInviteAcceptedCallbackResponse.decode(response);
}

/**
 * ProjectForms
 */
export async function GetProjectFormBlocks(
  getProjectFormBlocksRequest: GetProjectFormBlocksRequest,
  config?: ClientConfiguration
): Promise<GetProjectFormBlocksResponse> {
  const response = await PBrequest(
    "/Cloud/GetProjectFormBlocks",
    GetProjectFormBlocksRequest.encode(getProjectFormBlocksRequest),
    config
  );
  return GetProjectFormBlocksResponse.decode(response);
}

export async function UpsertProjectFormBlock(
  upsertProjectFormBlockRequest: UpsertProjectFormBlockRequest,
  config?: ClientConfiguration
): Promise<UpsertProjectFormBlockResponse> {
  const response = await PBrequest(
    "/Cloud/UpsertProjectFormBlock",
    UpsertProjectFormBlockRequest.encode(upsertProjectFormBlockRequest),
    config
  );
  return UpsertProjectFormBlockResponse.decode(response);
}

export async function DeleteProjectFormBlock(
  deleteProjectFormBlockRequest: DeleteProjectFormBlockRequest,
  config?: ClientConfiguration
): Promise<DeleteProjectFormBlockResponse> {
  const response = await PBrequest(
    "/Cloud/DeleteProjectFormBlock",
    DeleteProjectFormBlockRequest.encode(deleteProjectFormBlockRequest),
    config
  );
  return DeleteProjectFormBlockResponse.decode(response);
}

export async function UpdatePublishProjectForm(
  updatePublishProjectFormRequest: UpdatePublishProjectFormRequest,
  config?: ClientConfiguration
): Promise<UpdatePublishProjectFormResponse> {
  const response = await PBrequest(
    "/Cloud/UpdatePublishProjectForm",
    UpdatePublishProjectFormRequest.encode(updatePublishProjectFormRequest),
    config
  );
  return UpdatePublishProjectFormResponse.decode(response);
}

export async function UpdateFormCSS(
  updateFormCSSRequest: UpdateFormCSSRequest,
  config?: ClientConfiguration
): Promise<UpdateFormCSSResponse> {
  const response = await PBrequest(
    "/Cloud/UpdateFormCSS",
    UpdateFormCSSRequest.encode(updateFormCSSRequest),
    config
  );
  return UpdateFormCSSResponse.decode(response);
}

/**
 * ProjectSchedules
 */
export async function GetProjectSchedules(
  getProjectSchedulesRequest: GetProjectSchedulesRequest,
  config?: ClientConfiguration
): Promise<GetProjectSchedulesResponse> {
  const response = await PBrequest(
    "/Cloud/GetProjectSchedules",
    GetProjectSchedulesRequest.encode(getProjectSchedulesRequest),
    config
  );
  return GetProjectSchedulesResponse.decode(response);
}

export async function CreateProjectSchedule(
  createProjectScheduleRequest: CreateProjectScheduleRequest,
  config?: ClientConfiguration
): Promise<CreateProjectScheduleResponse> {
  const response = await PBrequest(
    "/Cloud/CreateProjectSchedule",
    CreateProjectScheduleRequest.encode(createProjectScheduleRequest),
    config
  );
  return CreateProjectScheduleResponse.decode(response);
}

export async function HideProjectSchedule(
  hideProjectScheduleRequest: HideProjectScheduleRequest,
  config?: ClientConfiguration
): Promise<HideProjectScheduleResponse> {
  const response = await PBrequest(
    "/Cloud/HideProjectSchedule",
    HideProjectScheduleRequest.encode(hideProjectScheduleRequest),
    config
  );
  return HideProjectScheduleResponse.decode(response);
}

export async function CancelProjectSchedule(
  cancelProjectScheduleRequest: CancelProjectScheduleRequest,
  config?: ClientConfiguration
): Promise<CancelProjectScheduleResponse> {
  const response = await PBrequest(
    "/Cloud/CancelProjectSchedule",
    CancelProjectScheduleRequest.encode(cancelProjectScheduleRequest),
    config
  );
  return CancelProjectScheduleResponse.decode(response);
}

export async function ResumeProjectSchedule(
  resumeProjectScheduleRequest: ResumeProjectScheduleRequest,
  config?: ClientConfiguration
): Promise<ResumeProjectScheduleResponse> {
  const response = await PBrequest(
    "/Cloud/ResumeProjectSchedule",
    ResumeProjectScheduleRequest.encode(resumeProjectScheduleRequest),
    config
  );
  return ResumeProjectScheduleResponse.decode(response);
}

export async function ReplayProjectSchedule(
  replayProjectScheduleRequest: ReplayProjectScheduleRequest,
  config?: ClientConfiguration
): Promise<ReplayProjectScheduleResponse> {
  const response = await PBrequest(
    "/Cloud/ReplayProjectSchedule",
    ReplayProjectScheduleRequest.encode(replayProjectScheduleRequest),
    config
  );
  return ReplayProjectScheduleResponse.decode(response);
}

//========================================//
//           Cloud JSON Client            //
//========================================//

/**
 * Util
 */
export async function PingJSON(
  pingRequest: types.PingRequest,
  config?: ClientConfiguration
): Promise<types.PingResponse> {
  const response = await JSONrequest(
    "/Cloud/Ping",
    types.PingRequestJSON.encode(pingRequest),
    config
  );
  return types.PingResponseJSON.decode(response);
}

/**
 * Auth
 */
export async function MeJSON(
  meRequest: MeRequest,
  config?: ClientConfiguration
): Promise<MeResponse> {
  const response = await JSONrequest(
    "/Cloud/Me",
    MeRequestJSON.encode(meRequest),
    config
  );
  return MeResponseJSON.decode(response);
}

export async function SigninJSON(
  signinRequest: SigninRequest,
  config?: ClientConfiguration
): Promise<SigninResponse> {
  const response = await JSONrequest(
    "/Cloud/Signin",
    SigninRequestJSON.encode(signinRequest),
    config
  );
  return SigninResponseJSON.decode(response);
}

export async function SignoutJSON(
  signoutRequest: SignoutRequest,
  config?: ClientConfiguration
): Promise<SignoutResponse> {
  const response = await JSONrequest(
    "/Cloud/Signout",
    SignoutRequestJSON.encode(signoutRequest),
    config
  );
  return SignoutResponseJSON.decode(response);
}

export async function SignupJSON(
  signupRequest: SignupRequest,
  config?: ClientConfiguration
): Promise<SignupResponse> {
  const response = await JSONrequest(
    "/Cloud/Signup",
    SignupRequestJSON.encode(signupRequest),
    config
  );
  return SignupResponseJSON.decode(response);
}

export async function CreateSignupTokenJSON(
  createSignupTokenRequest: CreateSignupTokenRequest,
  config?: ClientConfiguration
): Promise<CreateSignupTokenResponse> {
  const response = await JSONrequest(
    "/Cloud/CreateSignupToken",
    CreateSignupTokenRequestJSON.encode(createSignupTokenRequest),
    config
  );
  return CreateSignupTokenResponseJSON.decode(response);
}

export async function CreateSigninTokenJSON(
  createSigninTokenRequest: CreateSigninTokenRequest,
  config?: ClientConfiguration
): Promise<CreateSigninTokenResponse> {
  const response = await JSONrequest(
    "/Cloud/CreateSigninToken",
    CreateSigninTokenRequestJSON.encode(createSigninTokenRequest),
    config
  );
  return CreateSigninTokenResponseJSON.decode(response);
}

/**
 * Signups
 */
export async function GetSignupPositionJSON(
  getSignupPositionRequest: GetSignupPositionRequest,
  config?: ClientConfiguration
): Promise<GetSignupPositionResponse> {
  const response = await JSONrequest(
    "/Cloud/GetSignupPosition",
    GetSignupPositionRequestJSON.encode(getSignupPositionRequest),
    config
  );
  return GetSignupPositionResponseJSON.decode(response);
}

export async function ConfirmSignupEmailJSON(
  confirmSignupEmailRequest: ConfirmSignupEmailRequest,
  config?: ClientConfiguration
): Promise<ConfirmSignupEmailResponse> {
  const response = await JSONrequest(
    "/Cloud/ConfirmSignupEmail",
    ConfirmSignupEmailRequestJSON.encode(confirmSignupEmailRequest),
    config
  );
  return ConfirmSignupEmailResponseJSON.decode(response);
}

/**
 * Invites
 */
export async function GetInvitedSignupJSON(
  getInvitedSignupRequest: GetInvitedSignupRequest,
  config?: ClientConfiguration
): Promise<GetInvitedSignupResponse> {
  const response = await JSONrequest(
    "/Cloud/GetInvitedSignup",
    GetInvitedSignupRequestJSON.encode(getInvitedSignupRequest),
    config
  );
  return GetInvitedSignupResponseJSON.decode(response);
}

export async function AcceptInviteJSON(
  acceptInviteRequest: AcceptInviteRequest,
  config?: ClientConfiguration
): Promise<AcceptInviteResponse> {
  const response = await JSONrequest(
    "/Cloud/AcceptInvite",
    AcceptInviteRequestJSON.encode(acceptInviteRequest),
    config
  );
  return AcceptInviteResponseJSON.decode(response);
}

/**
 * Projects
 */
export async function ListProjectsJSON(
  listProjectsRequest: ListProjectsRequest,
  config?: ClientConfiguration
): Promise<ListProjectsResponse> {
  const response = await JSONrequest(
    "/Cloud/ListProjects",
    ListProjectsRequestJSON.encode(listProjectsRequest),
    config
  );
  return ListProjectsResponseJSON.decode(response);
}

export async function CreateProjectJSON(
  createProjectRequest: CreateProjectRequest,
  config?: ClientConfiguration
): Promise<CreateProjectResponse> {
  const response = await JSONrequest(
    "/Cloud/CreateProject",
    CreateProjectRequestJSON.encode(createProjectRequest),
    config
  );
  return CreateProjectResponseJSON.decode(response);
}

export async function GetProjectJSON(
  getProjectRequest: GetProjectRequest,
  config?: ClientConfiguration
): Promise<GetProjectResponse> {
  const response = await JSONrequest(
    "/Cloud/GetProject",
    GetProjectRequestJSON.encode(getProjectRequest),
    config
  );
  return GetProjectResponseJSON.decode(response);
}

export async function DeleteProjectJSON(
  deleteProjectRequest: DeleteProjectRequest,
  config?: ClientConfiguration
): Promise<DeleteProjectResponse> {
  const response = await JSONrequest(
    "/Cloud/DeleteProject",
    DeleteProjectRequestJSON.encode(deleteProjectRequest),
    config
  );
  return DeleteProjectResponseJSON.decode(response);
}

export async function GetProjectStatsJSON(
  getProjectStatsRequest: GetProjectStatsRequest,
  config?: ClientConfiguration
): Promise<GetProjectStatsResponse> {
  const response = await JSONrequest(
    "/Cloud/GetProjectStats",
    GetProjectStatsRequestJSON.encode(getProjectStatsRequest),
    config
  );
  return GetProjectStatsResponseJSON.decode(response);
}

export async function GetProjectSignupsJSON(
  getProjectSignupsRequest: GetProjectSignupsRequest,
  config?: ClientConfiguration
): Promise<GetProjectSignupsResponse> {
  const response = await JSONrequest(
    "/Cloud/GetProjectSignups",
    GetProjectSignupsRequestJSON.encode(getProjectSignupsRequest),
    config
  );
  return GetProjectSignupsResponseJSON.decode(response);
}

export async function GetProjectWaitlistJSON(
  getProjectWaitlistRequest: GetProjectWaitlistRequest,
  config?: ClientConfiguration
): Promise<GetProjectWaitlistResponse> {
  const response = await JSONrequest(
    "/Cloud/GetProjectWaitlist",
    GetProjectWaitlistRequestJSON.encode(getProjectWaitlistRequest),
    config
  );
  return GetProjectWaitlistResponseJSON.decode(response);
}

export async function GetProjectInvitesJSON(
  getProjectInvitesRequest: GetProjectInvitesRequest,
  config?: ClientConfiguration
): Promise<GetProjectInvitesResponse> {
  const response = await JSONrequest(
    "/Cloud/GetProjectInvites",
    GetProjectInvitesRequestJSON.encode(getProjectInvitesRequest),
    config
  );
  return GetProjectInvitesResponseJSON.decode(response);
}

export async function SendInviteJSON(
  sendInviteRequest: SendInviteRequest,
  config?: ClientConfiguration
): Promise<SendInviteResponse> {
  const response = await JSONrequest(
    "/Cloud/SendInvite",
    SendInviteRequestJSON.encode(sendInviteRequest),
    config
  );
  return SendInviteResponseJSON.decode(response);
}

export async function UpdateProjectHandleJSON(
  updateProjectHandleRequest: UpdateProjectHandleRequest,
  config?: ClientConfiguration
): Promise<UpdateProjectHandleResponse> {
  const response = await JSONrequest(
    "/Cloud/UpdateProjectHandle",
    UpdateProjectHandleRequestJSON.encode(updateProjectHandleRequest),
    config
  );
  return UpdateProjectHandleResponseJSON.decode(response);
}

export async function UpdateProjectNameJSON(
  updateProjectNameRequest: UpdateProjectNameRequest,
  config?: ClientConfiguration
): Promise<UpdateProjectNameResponse> {
  const response = await JSONrequest(
    "/Cloud/UpdateProjectName",
    UpdateProjectNameRequestJSON.encode(updateProjectNameRequest),
    config
  );
  return UpdateProjectNameResponseJSON.decode(response);
}

export async function GetProjectSignupDetailsJSON(
  getProjectSignupDetailsRequest: GetProjectSignupDetailsRequest,
  config?: ClientConfiguration
): Promise<GetProjectSignupDetailsResponse> {
  const response = await JSONrequest(
    "/Cloud/GetProjectSignupDetails",
    GetProjectSignupDetailsRequestJSON.encode(getProjectSignupDetailsRequest),
    config
  );
  return GetProjectSignupDetailsResponseJSON.decode(response);
}

export async function UpdateProjectEmailReplyToJSON(
  updateProjectEmailReplyToRequest: UpdateProjectEmailReplyToRequest,
  config?: ClientConfiguration
): Promise<UpdateProjectEmailReplyToResponse> {
  const response = await JSONrequest(
    "/Cloud/UpdateProjectEmailReplyTo",
    UpdateProjectEmailReplyToRequestJSON.encode(
      updateProjectEmailReplyToRequest
    ),
    config
  );
  return UpdateProjectEmailReplyToResponseJSON.decode(response);
}

export async function AddProjectDomainJSON(
  addProjectDomainRequest: AddProjectDomainRequest,
  config?: ClientConfiguration
): Promise<AddProjectDomainResponse> {
  const response = await JSONrequest(
    "/Cloud/AddProjectDomain",
    AddProjectDomainRequestJSON.encode(addProjectDomainRequest),
    config
  );
  return AddProjectDomainResponseJSON.decode(response);
}

export async function RemoveProjectDomainJSON(
  removeProjectDomainRequest: RemoveProjectDomainRequest,
  config?: ClientConfiguration
): Promise<RemoveProjectDomainResponse> {
  const response = await JSONrequest(
    "/Cloud/RemoveProjectDomain",
    RemoveProjectDomainRequestJSON.encode(removeProjectDomainRequest),
    config
  );
  return RemoveProjectDomainResponseJSON.decode(response);
}

export async function GetProjectDomainJSON(
  getProjectDomainRequest: GetProjectDomainRequest,
  config?: ClientConfiguration
): Promise<GetProjectDomainResponse> {
  const response = await JSONrequest(
    "/Cloud/GetProjectDomain",
    GetProjectDomainRequestJSON.encode(getProjectDomainRequest),
    config
  );
  return GetProjectDomainResponseJSON.decode(response);
}

export async function CheckProjectDomainVerifiedJSON(
  checkProjectDomainVerifiedRequest: CheckProjectDomainVerifiedRequest,
  config?: ClientConfiguration
): Promise<CheckProjectDomainVerifiedResponse> {
  const response = await JSONrequest(
    "/Cloud/CheckProjectDomainVerified",
    CheckProjectDomainVerifiedRequestJSON.encode(
      checkProjectDomainVerifiedRequest
    ),
    config
  );
  return CheckProjectDomainVerifiedResponseJSON.decode(response);
}

export async function GetProjectEmailTemplatesJSON(
  getProjectEmailTemplatesRequest: GetProjectEmailTemplatesRequest,
  config?: ClientConfiguration
): Promise<GetProjectEmailTemplatesResponse> {
  const response = await JSONrequest(
    "/Cloud/GetProjectEmailTemplates",
    GetProjectEmailTemplatesRequestJSON.encode(getProjectEmailTemplatesRequest),
    config
  );
  return GetProjectEmailTemplatesResponseJSON.decode(response);
}

export async function UpsertProjectEmailTemplateJSON(
  upsertProjectEmailTemplateRequest: UpsertProjectEmailTemplateRequest,
  config?: ClientConfiguration
): Promise<UpsertProjectEmailTemplateResponse> {
  const response = await JSONrequest(
    "/Cloud/UpsertProjectEmailTemplate",
    UpsertProjectEmailTemplateRequestJSON.encode(
      upsertProjectEmailTemplateRequest
    ),
    config
  );
  return UpsertProjectEmailTemplateResponseJSON.decode(response);
}

export async function GetDefaultEmailTemplateJSON(
  getDefaultEmailTemplateRequest: GetDefaultEmailTemplateRequest,
  config?: ClientConfiguration
): Promise<GetDefaultEmailTemplateResponse> {
  const response = await JSONrequest(
    "/Cloud/GetDefaultEmailTemplate",
    GetDefaultEmailTemplateRequestJSON.encode(getDefaultEmailTemplateRequest),
    config
  );
  return GetDefaultEmailTemplateResponseJSON.decode(response);
}

export async function GetProjectWebhooksJSON(
  getProjectWebhooksRequest: GetProjectWebhooksRequest,
  config?: ClientConfiguration
): Promise<GetProjectWebhooksResponse> {
  const response = await JSONrequest(
    "/Cloud/GetProjectWebhooks",
    GetProjectWebhooksRequestJSON.encode(getProjectWebhooksRequest),
    config
  );
  return GetProjectWebhooksResponseJSON.decode(response);
}

export async function UpsertProjectWebhookJSON(
  upsertProjectWebhookRequest: UpsertProjectWebhookRequest,
  config?: ClientConfiguration
): Promise<UpsertProjectWebhookResponse> {
  const response = await JSONrequest(
    "/Cloud/UpsertProjectWebhook",
    UpsertProjectWebhookRequestJSON.encode(upsertProjectWebhookRequest),
    config
  );
  return UpsertProjectWebhookResponseJSON.decode(response);
}

export async function GetProjectMembersJSON(
  getProjectMembersRequest: GetProjectMembersRequest,
  config?: ClientConfiguration
): Promise<GetProjectMembersResponse> {
  const response = await JSONrequest(
    "/Cloud/GetProjectMembers",
    GetProjectMembersRequestJSON.encode(getProjectMembersRequest),
    config
  );
  return GetProjectMembersResponseJSON.decode(response);
}

export async function DeleteProjectMemberJSON(
  deleteProjectMemberRequest: DeleteProjectMemberRequest,
  config?: ClientConfiguration
): Promise<DeleteProjectMemberResponse> {
  const response = await JSONrequest(
    "/Cloud/DeleteProjectMember",
    DeleteProjectMemberRequestJSON.encode(deleteProjectMemberRequest),
    config
  );
  return DeleteProjectMemberResponseJSON.decode(response);
}

export async function UpdateProjectMemberAccessJSON(
  updateProjectMemberAccessRequest: UpdateProjectMemberAccessRequest,
  config?: ClientConfiguration
): Promise<UpdateProjectMemberAccessResponse> {
  const response = await JSONrequest(
    "/Cloud/UpdateProjectMemberAccess",
    UpdateProjectMemberAccessRequestJSON.encode(
      updateProjectMemberAccessRequest
    ),
    config
  );
  return UpdateProjectMemberAccessResponseJSON.decode(response);
}

export async function AddProjectMemberJSON(
  addProjectMemberRequest: AddProjectMemberRequest,
  config?: ClientConfiguration
): Promise<AddProjectMemberResponse> {
  const response = await JSONrequest(
    "/Cloud/AddProjectMember",
    AddProjectMemberRequestJSON.encode(addProjectMemberRequest),
    config
  );
  return AddProjectMemberResponseJSON.decode(response);
}

export async function GetAPIKeysJSON(
  getAPIKeysRequest: GetAPIKeysRequest,
  config?: ClientConfiguration
): Promise<GetAPIKeysResponse> {
  const response = await JSONrequest(
    "/Cloud/GetAPIKeys",
    GetAPIKeysRequestJSON.encode(getAPIKeysRequest),
    config
  );
  return GetAPIKeysResponseJSON.decode(response);
}

export async function CreateAPIKeyJSON(
  createAPIKeyRequest: CreateAPIKeyRequest,
  config?: ClientConfiguration
): Promise<CreateAPIKeyResponse> {
  const response = await JSONrequest(
    "/Cloud/CreateAPIKey",
    CreateAPIKeyRequestJSON.encode(createAPIKeyRequest),
    config
  );
  return CreateAPIKeyResponseJSON.decode(response);
}

export async function DeleteAPIKeyJSON(
  deleteAPIKeyRequest: DeleteAPIKeyRequest,
  config?: ClientConfiguration
): Promise<DeleteAPIKeyResponse> {
  const response = await JSONrequest(
    "/Cloud/DeleteAPIKey",
    DeleteAPIKeyRequestJSON.encode(deleteAPIKeyRequest),
    config
  );
  return DeleteAPIKeyResponseJSON.decode(response);
}

export async function GetProjectAccessCodesJSON(
  getProjectAccessCodesRequest: GetProjectAccessCodesRequest,
  config?: ClientConfiguration
): Promise<GetProjectAccessCodesResponse> {
  const response = await JSONrequest(
    "/Cloud/GetProjectAccessCodes",
    GetProjectAccessCodesRequestJSON.encode(getProjectAccessCodesRequest),
    config
  );
  return GetProjectAccessCodesResponseJSON.decode(response);
}

export async function GenerateProjectAccessCodesJSON(
  generateProjectAccessCodesRequest: GenerateProjectAccessCodesRequest,
  config?: ClientConfiguration
): Promise<GenerateProjectAccessCodesResponse> {
  const response = await JSONrequest(
    "/Cloud/GenerateProjectAccessCodes",
    GenerateProjectAccessCodesRequestJSON.encode(
      generateProjectAccessCodesRequest
    ),
    config
  );
  return GenerateProjectAccessCodesResponseJSON.decode(response);
}

export async function DeleteProjectAccessCodeJSON(
  deleteProjectAccessCodeRequest: DeleteProjectAccessCodeRequest,
  config?: ClientConfiguration
): Promise<DeleteProjectAccessCodeResponse> {
  const response = await JSONrequest(
    "/Cloud/DeleteProjectAccessCode",
    DeleteProjectAccessCodeRequestJSON.encode(deleteProjectAccessCodeRequest),
    config
  );
  return DeleteProjectAccessCodeResponseJSON.decode(response);
}

export async function UpdateProjectRequiresAccessCodeJSON(
  updateProjectRequiresAccessCodeRequest: UpdateProjectRequiresAccessCodeRequest,
  config?: ClientConfiguration
): Promise<UpdateProjectRequiresAccessCodeResponse> {
  const response = await JSONrequest(
    "/Cloud/UpdateProjectRequiresAccessCode",
    UpdateProjectRequiresAccessCodeRequestJSON.encode(
      updateProjectRequiresAccessCodeRequest
    ),
    config
  );
  return UpdateProjectRequiresAccessCodeResponseJSON.decode(response);
}

export async function UpdateProjectInviteRedirectUrlJSON(
  updateProjectInviteRedirectUrlRequest: UpdateProjectInviteRedirectUrlRequest,
  config?: ClientConfiguration
): Promise<UpdateProjectInviteRedirectUrlResponse> {
  const response = await JSONrequest(
    "/Cloud/UpdateProjectInviteRedirectUrl",
    UpdateProjectInviteRedirectUrlRequestJSON.encode(
      updateProjectInviteRedirectUrlRequest
    ),
    config
  );
  return UpdateProjectInviteRedirectUrlResponseJSON.decode(response);
}

export async function UpdateProjectInviteAcceptedCallbackJSON(
  updateProjectInviteAcceptedCallbackRequest: UpdateProjectInviteAcceptedCallbackRequest,
  config?: ClientConfiguration
): Promise<UpdateProjectInviteAcceptedCallbackResponse> {
  const response = await JSONrequest(
    "/Cloud/UpdateProjectInviteAcceptedCallback",
    UpdateProjectInviteAcceptedCallbackRequestJSON.encode(
      updateProjectInviteAcceptedCallbackRequest
    ),
    config
  );
  return UpdateProjectInviteAcceptedCallbackResponseJSON.decode(response);
}

/**
 * ProjectForms
 */
export async function GetProjectFormBlocksJSON(
  getProjectFormBlocksRequest: GetProjectFormBlocksRequest,
  config?: ClientConfiguration
): Promise<GetProjectFormBlocksResponse> {
  const response = await JSONrequest(
    "/Cloud/GetProjectFormBlocks",
    GetProjectFormBlocksRequestJSON.encode(getProjectFormBlocksRequest),
    config
  );
  return GetProjectFormBlocksResponseJSON.decode(response);
}

export async function UpsertProjectFormBlockJSON(
  upsertProjectFormBlockRequest: UpsertProjectFormBlockRequest,
  config?: ClientConfiguration
): Promise<UpsertProjectFormBlockResponse> {
  const response = await JSONrequest(
    "/Cloud/UpsertProjectFormBlock",
    UpsertProjectFormBlockRequestJSON.encode(upsertProjectFormBlockRequest),
    config
  );
  return UpsertProjectFormBlockResponseJSON.decode(response);
}

export async function DeleteProjectFormBlockJSON(
  deleteProjectFormBlockRequest: DeleteProjectFormBlockRequest,
  config?: ClientConfiguration
): Promise<DeleteProjectFormBlockResponse> {
  const response = await JSONrequest(
    "/Cloud/DeleteProjectFormBlock",
    DeleteProjectFormBlockRequestJSON.encode(deleteProjectFormBlockRequest),
    config
  );
  return DeleteProjectFormBlockResponseJSON.decode(response);
}

export async function UpdatePublishProjectFormJSON(
  updatePublishProjectFormRequest: UpdatePublishProjectFormRequest,
  config?: ClientConfiguration
): Promise<UpdatePublishProjectFormResponse> {
  const response = await JSONrequest(
    "/Cloud/UpdatePublishProjectForm",
    UpdatePublishProjectFormRequestJSON.encode(updatePublishProjectFormRequest),
    config
  );
  return UpdatePublishProjectFormResponseJSON.decode(response);
}

export async function UpdateFormCSSJSON(
  updateFormCSSRequest: UpdateFormCSSRequest,
  config?: ClientConfiguration
): Promise<UpdateFormCSSResponse> {
  const response = await JSONrequest(
    "/Cloud/UpdateFormCSS",
    UpdateFormCSSRequestJSON.encode(updateFormCSSRequest),
    config
  );
  return UpdateFormCSSResponseJSON.decode(response);
}

/**
 * ProjectSchedules
 */
export async function GetProjectSchedulesJSON(
  getProjectSchedulesRequest: GetProjectSchedulesRequest,
  config?: ClientConfiguration
): Promise<GetProjectSchedulesResponse> {
  const response = await JSONrequest(
    "/Cloud/GetProjectSchedules",
    GetProjectSchedulesRequestJSON.encode(getProjectSchedulesRequest),
    config
  );
  return GetProjectSchedulesResponseJSON.decode(response);
}

export async function CreateProjectScheduleJSON(
  createProjectScheduleRequest: CreateProjectScheduleRequest,
  config?: ClientConfiguration
): Promise<CreateProjectScheduleResponse> {
  const response = await JSONrequest(
    "/Cloud/CreateProjectSchedule",
    CreateProjectScheduleRequestJSON.encode(createProjectScheduleRequest),
    config
  );
  return CreateProjectScheduleResponseJSON.decode(response);
}

export async function HideProjectScheduleJSON(
  hideProjectScheduleRequest: HideProjectScheduleRequest,
  config?: ClientConfiguration
): Promise<HideProjectScheduleResponse> {
  const response = await JSONrequest(
    "/Cloud/HideProjectSchedule",
    HideProjectScheduleRequestJSON.encode(hideProjectScheduleRequest),
    config
  );
  return HideProjectScheduleResponseJSON.decode(response);
}

export async function CancelProjectScheduleJSON(
  cancelProjectScheduleRequest: CancelProjectScheduleRequest,
  config?: ClientConfiguration
): Promise<CancelProjectScheduleResponse> {
  const response = await JSONrequest(
    "/Cloud/CancelProjectSchedule",
    CancelProjectScheduleRequestJSON.encode(cancelProjectScheduleRequest),
    config
  );
  return CancelProjectScheduleResponseJSON.decode(response);
}

export async function ResumeProjectScheduleJSON(
  resumeProjectScheduleRequest: ResumeProjectScheduleRequest,
  config?: ClientConfiguration
): Promise<ResumeProjectScheduleResponse> {
  const response = await JSONrequest(
    "/Cloud/ResumeProjectSchedule",
    ResumeProjectScheduleRequestJSON.encode(resumeProjectScheduleRequest),
    config
  );
  return ResumeProjectScheduleResponseJSON.decode(response);
}

export async function ReplayProjectScheduleJSON(
  replayProjectScheduleRequest: ReplayProjectScheduleRequest,
  config?: ClientConfiguration
): Promise<ReplayProjectScheduleResponse> {
  const response = await JSONrequest(
    "/Cloud/ReplayProjectSchedule",
    ReplayProjectScheduleRequestJSON.encode(replayProjectScheduleRequest),
    config
  );
  return ReplayProjectScheduleResponseJSON.decode(response);
}

//========================================//
//                 Cloud                  //
//========================================//

export interface Cloud<Context = unknown> {
  /**
   * Util
   */
  Ping: (
    pingRequest: types.PingRequest,
    context: Context
  ) => Promise<types.PingResponse> | types.PingResponse;
  /**
   * Auth
   */
  Me: (
    meRequest: MeRequest,
    context: Context
  ) => Promise<MeResponse> | MeResponse;
  Signin: (
    signinRequest: SigninRequest,
    context: Context
  ) => Promise<SigninResponse> | SigninResponse;
  Signout: (
    signoutRequest: SignoutRequest,
    context: Context
  ) => Promise<SignoutResponse> | SignoutResponse;
  Signup: (
    signupRequest: SignupRequest,
    context: Context
  ) => Promise<SignupResponse> | SignupResponse;
  CreateSignupToken: (
    createSignupTokenRequest: CreateSignupTokenRequest,
    context: Context
  ) => Promise<CreateSignupTokenResponse> | CreateSignupTokenResponse;
  CreateSigninToken: (
    createSigninTokenRequest: CreateSigninTokenRequest,
    context: Context
  ) => Promise<CreateSigninTokenResponse> | CreateSigninTokenResponse;
  /**
   * Signups
   */
  GetSignupPosition: (
    getSignupPositionRequest: GetSignupPositionRequest,
    context: Context
  ) => Promise<GetSignupPositionResponse> | GetSignupPositionResponse;
  ConfirmSignupEmail: (
    confirmSignupEmailRequest: ConfirmSignupEmailRequest,
    context: Context
  ) => Promise<ConfirmSignupEmailResponse> | ConfirmSignupEmailResponse;
  /**
   * Invites
   */
  GetInvitedSignup: (
    getInvitedSignupRequest: GetInvitedSignupRequest,
    context: Context
  ) => Promise<GetInvitedSignupResponse> | GetInvitedSignupResponse;
  AcceptInvite: (
    acceptInviteRequest: AcceptInviteRequest,
    context: Context
  ) => Promise<AcceptInviteResponse> | AcceptInviteResponse;
  /**
   * Projects
   */
  ListProjects: (
    listProjectsRequest: ListProjectsRequest,
    context: Context
  ) => Promise<ListProjectsResponse> | ListProjectsResponse;
  CreateProject: (
    createProjectRequest: CreateProjectRequest,
    context: Context
  ) => Promise<CreateProjectResponse> | CreateProjectResponse;
  GetProject: (
    getProjectRequest: GetProjectRequest,
    context: Context
  ) => Promise<GetProjectResponse> | GetProjectResponse;
  DeleteProject: (
    deleteProjectRequest: DeleteProjectRequest,
    context: Context
  ) => Promise<DeleteProjectResponse> | DeleteProjectResponse;
  GetProjectStats: (
    getProjectStatsRequest: GetProjectStatsRequest,
    context: Context
  ) => Promise<GetProjectStatsResponse> | GetProjectStatsResponse;
  GetProjectSignups: (
    getProjectSignupsRequest: GetProjectSignupsRequest,
    context: Context
  ) => Promise<GetProjectSignupsResponse> | GetProjectSignupsResponse;
  GetProjectWaitlist: (
    getProjectWaitlistRequest: GetProjectWaitlistRequest,
    context: Context
  ) => Promise<GetProjectWaitlistResponse> | GetProjectWaitlistResponse;
  GetProjectInvites: (
    getProjectInvitesRequest: GetProjectInvitesRequest,
    context: Context
  ) => Promise<GetProjectInvitesResponse> | GetProjectInvitesResponse;
  SendInvite: (
    sendInviteRequest: SendInviteRequest,
    context: Context
  ) => Promise<SendInviteResponse> | SendInviteResponse;
  UpdateProjectHandle: (
    updateProjectHandleRequest: UpdateProjectHandleRequest,
    context: Context
  ) => Promise<UpdateProjectHandleResponse> | UpdateProjectHandleResponse;
  UpdateProjectName: (
    updateProjectNameRequest: UpdateProjectNameRequest,
    context: Context
  ) => Promise<UpdateProjectNameResponse> | UpdateProjectNameResponse;
  GetProjectSignupDetails: (
    getProjectSignupDetailsRequest: GetProjectSignupDetailsRequest,
    context: Context
  ) =>
    | Promise<GetProjectSignupDetailsResponse>
    | GetProjectSignupDetailsResponse;
  UpdateProjectEmailReplyTo: (
    updateProjectEmailReplyToRequest: UpdateProjectEmailReplyToRequest,
    context: Context
  ) =>
    | Promise<UpdateProjectEmailReplyToResponse>
    | UpdateProjectEmailReplyToResponse;
  AddProjectDomain: (
    addProjectDomainRequest: AddProjectDomainRequest,
    context: Context
  ) => Promise<AddProjectDomainResponse> | AddProjectDomainResponse;
  RemoveProjectDomain: (
    removeProjectDomainRequest: RemoveProjectDomainRequest,
    context: Context
  ) => Promise<RemoveProjectDomainResponse> | RemoveProjectDomainResponse;
  GetProjectDomain: (
    getProjectDomainRequest: GetProjectDomainRequest,
    context: Context
  ) => Promise<GetProjectDomainResponse> | GetProjectDomainResponse;
  CheckProjectDomainVerified: (
    checkProjectDomainVerifiedRequest: CheckProjectDomainVerifiedRequest,
    context: Context
  ) =>
    | Promise<CheckProjectDomainVerifiedResponse>
    | CheckProjectDomainVerifiedResponse;
  GetProjectEmailTemplates: (
    getProjectEmailTemplatesRequest: GetProjectEmailTemplatesRequest,
    context: Context
  ) =>
    | Promise<GetProjectEmailTemplatesResponse>
    | GetProjectEmailTemplatesResponse;
  UpsertProjectEmailTemplate: (
    upsertProjectEmailTemplateRequest: UpsertProjectEmailTemplateRequest,
    context: Context
  ) =>
    | Promise<UpsertProjectEmailTemplateResponse>
    | UpsertProjectEmailTemplateResponse;
  GetDefaultEmailTemplate: (
    getDefaultEmailTemplateRequest: GetDefaultEmailTemplateRequest,
    context: Context
  ) =>
    | Promise<GetDefaultEmailTemplateResponse>
    | GetDefaultEmailTemplateResponse;
  GetProjectWebhooks: (
    getProjectWebhooksRequest: GetProjectWebhooksRequest,
    context: Context
  ) => Promise<GetProjectWebhooksResponse> | GetProjectWebhooksResponse;
  UpsertProjectWebhook: (
    upsertProjectWebhookRequest: UpsertProjectWebhookRequest,
    context: Context
  ) => Promise<UpsertProjectWebhookResponse> | UpsertProjectWebhookResponse;
  GetProjectMembers: (
    getProjectMembersRequest: GetProjectMembersRequest,
    context: Context
  ) => Promise<GetProjectMembersResponse> | GetProjectMembersResponse;
  DeleteProjectMember: (
    deleteProjectMemberRequest: DeleteProjectMemberRequest,
    context: Context
  ) => Promise<DeleteProjectMemberResponse> | DeleteProjectMemberResponse;
  UpdateProjectMemberAccess: (
    updateProjectMemberAccessRequest: UpdateProjectMemberAccessRequest,
    context: Context
  ) =>
    | Promise<UpdateProjectMemberAccessResponse>
    | UpdateProjectMemberAccessResponse;
  AddProjectMember: (
    addProjectMemberRequest: AddProjectMemberRequest,
    context: Context
  ) => Promise<AddProjectMemberResponse> | AddProjectMemberResponse;
  GetAPIKeys: (
    getAPIKeysRequest: GetAPIKeysRequest,
    context: Context
  ) => Promise<GetAPIKeysResponse> | GetAPIKeysResponse;
  CreateAPIKey: (
    createAPIKeyRequest: CreateAPIKeyRequest,
    context: Context
  ) => Promise<CreateAPIKeyResponse> | CreateAPIKeyResponse;
  DeleteAPIKey: (
    deleteAPIKeyRequest: DeleteAPIKeyRequest,
    context: Context
  ) => Promise<DeleteAPIKeyResponse> | DeleteAPIKeyResponse;
  GetProjectAccessCodes: (
    getProjectAccessCodesRequest: GetProjectAccessCodesRequest,
    context: Context
  ) => Promise<GetProjectAccessCodesResponse> | GetProjectAccessCodesResponse;
  GenerateProjectAccessCodes: (
    generateProjectAccessCodesRequest: GenerateProjectAccessCodesRequest,
    context: Context
  ) =>
    | Promise<GenerateProjectAccessCodesResponse>
    | GenerateProjectAccessCodesResponse;
  DeleteProjectAccessCode: (
    deleteProjectAccessCodeRequest: DeleteProjectAccessCodeRequest,
    context: Context
  ) =>
    | Promise<DeleteProjectAccessCodeResponse>
    | DeleteProjectAccessCodeResponse;
  UpdateProjectRequiresAccessCode: (
    updateProjectRequiresAccessCodeRequest: UpdateProjectRequiresAccessCodeRequest,
    context: Context
  ) =>
    | Promise<UpdateProjectRequiresAccessCodeResponse>
    | UpdateProjectRequiresAccessCodeResponse;
  UpdateProjectInviteRedirectUrl: (
    updateProjectInviteRedirectUrlRequest: UpdateProjectInviteRedirectUrlRequest,
    context: Context
  ) =>
    | Promise<UpdateProjectInviteRedirectUrlResponse>
    | UpdateProjectInviteRedirectUrlResponse;
  UpdateProjectInviteAcceptedCallback: (
    updateProjectInviteAcceptedCallbackRequest: UpdateProjectInviteAcceptedCallbackRequest,
    context: Context
  ) =>
    | Promise<UpdateProjectInviteAcceptedCallbackResponse>
    | UpdateProjectInviteAcceptedCallbackResponse;
  /**
   * ProjectForms
   */
  GetProjectFormBlocks: (
    getProjectFormBlocksRequest: GetProjectFormBlocksRequest,
    context: Context
  ) => Promise<GetProjectFormBlocksResponse> | GetProjectFormBlocksResponse;
  UpsertProjectFormBlock: (
    upsertProjectFormBlockRequest: UpsertProjectFormBlockRequest,
    context: Context
  ) => Promise<UpsertProjectFormBlockResponse> | UpsertProjectFormBlockResponse;
  DeleteProjectFormBlock: (
    deleteProjectFormBlockRequest: DeleteProjectFormBlockRequest,
    context: Context
  ) => Promise<DeleteProjectFormBlockResponse> | DeleteProjectFormBlockResponse;
  UpdatePublishProjectForm: (
    updatePublishProjectFormRequest: UpdatePublishProjectFormRequest,
    context: Context
  ) =>
    | Promise<UpdatePublishProjectFormResponse>
    | UpdatePublishProjectFormResponse;
  UpdateFormCSS: (
    updateFormCSSRequest: UpdateFormCSSRequest,
    context: Context
  ) => Promise<UpdateFormCSSResponse> | UpdateFormCSSResponse;
  /**
   * ProjectSchedules
   */
  GetProjectSchedules: (
    getProjectSchedulesRequest: GetProjectSchedulesRequest,
    context: Context
  ) => Promise<GetProjectSchedulesResponse> | GetProjectSchedulesResponse;
  CreateProjectSchedule: (
    createProjectScheduleRequest: CreateProjectScheduleRequest,
    context: Context
  ) => Promise<CreateProjectScheduleResponse> | CreateProjectScheduleResponse;
  HideProjectSchedule: (
    hideProjectScheduleRequest: HideProjectScheduleRequest,
    context: Context
  ) => Promise<HideProjectScheduleResponse> | HideProjectScheduleResponse;
  CancelProjectSchedule: (
    cancelProjectScheduleRequest: CancelProjectScheduleRequest,
    context: Context
  ) => Promise<CancelProjectScheduleResponse> | CancelProjectScheduleResponse;
  ResumeProjectSchedule: (
    resumeProjectScheduleRequest: ResumeProjectScheduleRequest,
    context: Context
  ) => Promise<ResumeProjectScheduleResponse> | ResumeProjectScheduleResponse;
  ReplayProjectSchedule: (
    replayProjectScheduleRequest: ReplayProjectScheduleRequest,
    context: Context
  ) => Promise<ReplayProjectScheduleResponse> | ReplayProjectScheduleResponse;
}

export function createCloud<Context>(service: Cloud<Context>) {
  return {
    name: "Cloud",
    methods: {
      Ping: {
        name: "Ping",
        handler: service.Ping,
        input: { protobuf: types.PingRequest, json: types.PingRequestJSON },
        output: { protobuf: types.PingResponse, json: types.PingResponseJSON },
      },
      Me: {
        name: "Me",
        handler: service.Me,
        input: { protobuf: MeRequest, json: MeRequestJSON },
        output: { protobuf: MeResponse, json: MeResponseJSON },
      },
      Signin: {
        name: "Signin",
        handler: service.Signin,
        input: { protobuf: SigninRequest, json: SigninRequestJSON },
        output: { protobuf: SigninResponse, json: SigninResponseJSON },
      },
      Signout: {
        name: "Signout",
        handler: service.Signout,
        input: { protobuf: SignoutRequest, json: SignoutRequestJSON },
        output: { protobuf: SignoutResponse, json: SignoutResponseJSON },
      },
      Signup: {
        name: "Signup",
        handler: service.Signup,
        input: { protobuf: SignupRequest, json: SignupRequestJSON },
        output: { protobuf: SignupResponse, json: SignupResponseJSON },
      },
      CreateSignupToken: {
        name: "CreateSignupToken",
        handler: service.CreateSignupToken,
        input: {
          protobuf: CreateSignupTokenRequest,
          json: CreateSignupTokenRequestJSON,
        },
        output: {
          protobuf: CreateSignupTokenResponse,
          json: CreateSignupTokenResponseJSON,
        },
      },
      CreateSigninToken: {
        name: "CreateSigninToken",
        handler: service.CreateSigninToken,
        input: {
          protobuf: CreateSigninTokenRequest,
          json: CreateSigninTokenRequestJSON,
        },
        output: {
          protobuf: CreateSigninTokenResponse,
          json: CreateSigninTokenResponseJSON,
        },
      },
      GetSignupPosition: {
        name: "GetSignupPosition",
        handler: service.GetSignupPosition,
        input: {
          protobuf: GetSignupPositionRequest,
          json: GetSignupPositionRequestJSON,
        },
        output: {
          protobuf: GetSignupPositionResponse,
          json: GetSignupPositionResponseJSON,
        },
      },
      ConfirmSignupEmail: {
        name: "ConfirmSignupEmail",
        handler: service.ConfirmSignupEmail,
        input: {
          protobuf: ConfirmSignupEmailRequest,
          json: ConfirmSignupEmailRequestJSON,
        },
        output: {
          protobuf: ConfirmSignupEmailResponse,
          json: ConfirmSignupEmailResponseJSON,
        },
      },
      GetInvitedSignup: {
        name: "GetInvitedSignup",
        handler: service.GetInvitedSignup,
        input: {
          protobuf: GetInvitedSignupRequest,
          json: GetInvitedSignupRequestJSON,
        },
        output: {
          protobuf: GetInvitedSignupResponse,
          json: GetInvitedSignupResponseJSON,
        },
      },
      AcceptInvite: {
        name: "AcceptInvite",
        handler: service.AcceptInvite,
        input: { protobuf: AcceptInviteRequest, json: AcceptInviteRequestJSON },
        output: {
          protobuf: AcceptInviteResponse,
          json: AcceptInviteResponseJSON,
        },
      },
      ListProjects: {
        name: "ListProjects",
        handler: service.ListProjects,
        input: { protobuf: ListProjectsRequest, json: ListProjectsRequestJSON },
        output: {
          protobuf: ListProjectsResponse,
          json: ListProjectsResponseJSON,
        },
      },
      CreateProject: {
        name: "CreateProject",
        handler: service.CreateProject,
        input: {
          protobuf: CreateProjectRequest,
          json: CreateProjectRequestJSON,
        },
        output: {
          protobuf: CreateProjectResponse,
          json: CreateProjectResponseJSON,
        },
      },
      GetProject: {
        name: "GetProject",
        handler: service.GetProject,
        input: { protobuf: GetProjectRequest, json: GetProjectRequestJSON },
        output: { protobuf: GetProjectResponse, json: GetProjectResponseJSON },
      },
      DeleteProject: {
        name: "DeleteProject",
        handler: service.DeleteProject,
        input: {
          protobuf: DeleteProjectRequest,
          json: DeleteProjectRequestJSON,
        },
        output: {
          protobuf: DeleteProjectResponse,
          json: DeleteProjectResponseJSON,
        },
      },
      GetProjectStats: {
        name: "GetProjectStats",
        handler: service.GetProjectStats,
        input: {
          protobuf: GetProjectStatsRequest,
          json: GetProjectStatsRequestJSON,
        },
        output: {
          protobuf: GetProjectStatsResponse,
          json: GetProjectStatsResponseJSON,
        },
      },
      GetProjectSignups: {
        name: "GetProjectSignups",
        handler: service.GetProjectSignups,
        input: {
          protobuf: GetProjectSignupsRequest,
          json: GetProjectSignupsRequestJSON,
        },
        output: {
          protobuf: GetProjectSignupsResponse,
          json: GetProjectSignupsResponseJSON,
        },
      },
      GetProjectWaitlist: {
        name: "GetProjectWaitlist",
        handler: service.GetProjectWaitlist,
        input: {
          protobuf: GetProjectWaitlistRequest,
          json: GetProjectWaitlistRequestJSON,
        },
        output: {
          protobuf: GetProjectWaitlistResponse,
          json: GetProjectWaitlistResponseJSON,
        },
      },
      GetProjectInvites: {
        name: "GetProjectInvites",
        handler: service.GetProjectInvites,
        input: {
          protobuf: GetProjectInvitesRequest,
          json: GetProjectInvitesRequestJSON,
        },
        output: {
          protobuf: GetProjectInvitesResponse,
          json: GetProjectInvitesResponseJSON,
        },
      },
      SendInvite: {
        name: "SendInvite",
        handler: service.SendInvite,
        input: { protobuf: SendInviteRequest, json: SendInviteRequestJSON },
        output: { protobuf: SendInviteResponse, json: SendInviteResponseJSON },
      },
      UpdateProjectHandle: {
        name: "UpdateProjectHandle",
        handler: service.UpdateProjectHandle,
        input: {
          protobuf: UpdateProjectHandleRequest,
          json: UpdateProjectHandleRequestJSON,
        },
        output: {
          protobuf: UpdateProjectHandleResponse,
          json: UpdateProjectHandleResponseJSON,
        },
      },
      UpdateProjectName: {
        name: "UpdateProjectName",
        handler: service.UpdateProjectName,
        input: {
          protobuf: UpdateProjectNameRequest,
          json: UpdateProjectNameRequestJSON,
        },
        output: {
          protobuf: UpdateProjectNameResponse,
          json: UpdateProjectNameResponseJSON,
        },
      },
      GetProjectSignupDetails: {
        name: "GetProjectSignupDetails",
        handler: service.GetProjectSignupDetails,
        input: {
          protobuf: GetProjectSignupDetailsRequest,
          json: GetProjectSignupDetailsRequestJSON,
        },
        output: {
          protobuf: GetProjectSignupDetailsResponse,
          json: GetProjectSignupDetailsResponseJSON,
        },
      },
      UpdateProjectEmailReplyTo: {
        name: "UpdateProjectEmailReplyTo",
        handler: service.UpdateProjectEmailReplyTo,
        input: {
          protobuf: UpdateProjectEmailReplyToRequest,
          json: UpdateProjectEmailReplyToRequestJSON,
        },
        output: {
          protobuf: UpdateProjectEmailReplyToResponse,
          json: UpdateProjectEmailReplyToResponseJSON,
        },
      },
      AddProjectDomain: {
        name: "AddProjectDomain",
        handler: service.AddProjectDomain,
        input: {
          protobuf: AddProjectDomainRequest,
          json: AddProjectDomainRequestJSON,
        },
        output: {
          protobuf: AddProjectDomainResponse,
          json: AddProjectDomainResponseJSON,
        },
      },
      RemoveProjectDomain: {
        name: "RemoveProjectDomain",
        handler: service.RemoveProjectDomain,
        input: {
          protobuf: RemoveProjectDomainRequest,
          json: RemoveProjectDomainRequestJSON,
        },
        output: {
          protobuf: RemoveProjectDomainResponse,
          json: RemoveProjectDomainResponseJSON,
        },
      },
      GetProjectDomain: {
        name: "GetProjectDomain",
        handler: service.GetProjectDomain,
        input: {
          protobuf: GetProjectDomainRequest,
          json: GetProjectDomainRequestJSON,
        },
        output: {
          protobuf: GetProjectDomainResponse,
          json: GetProjectDomainResponseJSON,
        },
      },
      CheckProjectDomainVerified: {
        name: "CheckProjectDomainVerified",
        handler: service.CheckProjectDomainVerified,
        input: {
          protobuf: CheckProjectDomainVerifiedRequest,
          json: CheckProjectDomainVerifiedRequestJSON,
        },
        output: {
          protobuf: CheckProjectDomainVerifiedResponse,
          json: CheckProjectDomainVerifiedResponseJSON,
        },
      },
      GetProjectEmailTemplates: {
        name: "GetProjectEmailTemplates",
        handler: service.GetProjectEmailTemplates,
        input: {
          protobuf: GetProjectEmailTemplatesRequest,
          json: GetProjectEmailTemplatesRequestJSON,
        },
        output: {
          protobuf: GetProjectEmailTemplatesResponse,
          json: GetProjectEmailTemplatesResponseJSON,
        },
      },
      UpsertProjectEmailTemplate: {
        name: "UpsertProjectEmailTemplate",
        handler: service.UpsertProjectEmailTemplate,
        input: {
          protobuf: UpsertProjectEmailTemplateRequest,
          json: UpsertProjectEmailTemplateRequestJSON,
        },
        output: {
          protobuf: UpsertProjectEmailTemplateResponse,
          json: UpsertProjectEmailTemplateResponseJSON,
        },
      },
      GetDefaultEmailTemplate: {
        name: "GetDefaultEmailTemplate",
        handler: service.GetDefaultEmailTemplate,
        input: {
          protobuf: GetDefaultEmailTemplateRequest,
          json: GetDefaultEmailTemplateRequestJSON,
        },
        output: {
          protobuf: GetDefaultEmailTemplateResponse,
          json: GetDefaultEmailTemplateResponseJSON,
        },
      },
      GetProjectWebhooks: {
        name: "GetProjectWebhooks",
        handler: service.GetProjectWebhooks,
        input: {
          protobuf: GetProjectWebhooksRequest,
          json: GetProjectWebhooksRequestJSON,
        },
        output: {
          protobuf: GetProjectWebhooksResponse,
          json: GetProjectWebhooksResponseJSON,
        },
      },
      UpsertProjectWebhook: {
        name: "UpsertProjectWebhook",
        handler: service.UpsertProjectWebhook,
        input: {
          protobuf: UpsertProjectWebhookRequest,
          json: UpsertProjectWebhookRequestJSON,
        },
        output: {
          protobuf: UpsertProjectWebhookResponse,
          json: UpsertProjectWebhookResponseJSON,
        },
      },
      GetProjectMembers: {
        name: "GetProjectMembers",
        handler: service.GetProjectMembers,
        input: {
          protobuf: GetProjectMembersRequest,
          json: GetProjectMembersRequestJSON,
        },
        output: {
          protobuf: GetProjectMembersResponse,
          json: GetProjectMembersResponseJSON,
        },
      },
      DeleteProjectMember: {
        name: "DeleteProjectMember",
        handler: service.DeleteProjectMember,
        input: {
          protobuf: DeleteProjectMemberRequest,
          json: DeleteProjectMemberRequestJSON,
        },
        output: {
          protobuf: DeleteProjectMemberResponse,
          json: DeleteProjectMemberResponseJSON,
        },
      },
      UpdateProjectMemberAccess: {
        name: "UpdateProjectMemberAccess",
        handler: service.UpdateProjectMemberAccess,
        input: {
          protobuf: UpdateProjectMemberAccessRequest,
          json: UpdateProjectMemberAccessRequestJSON,
        },
        output: {
          protobuf: UpdateProjectMemberAccessResponse,
          json: UpdateProjectMemberAccessResponseJSON,
        },
      },
      AddProjectMember: {
        name: "AddProjectMember",
        handler: service.AddProjectMember,
        input: {
          protobuf: AddProjectMemberRequest,
          json: AddProjectMemberRequestJSON,
        },
        output: {
          protobuf: AddProjectMemberResponse,
          json: AddProjectMemberResponseJSON,
        },
      },
      GetAPIKeys: {
        name: "GetAPIKeys",
        handler: service.GetAPIKeys,
        input: { protobuf: GetAPIKeysRequest, json: GetAPIKeysRequestJSON },
        output: { protobuf: GetAPIKeysResponse, json: GetAPIKeysResponseJSON },
      },
      CreateAPIKey: {
        name: "CreateAPIKey",
        handler: service.CreateAPIKey,
        input: { protobuf: CreateAPIKeyRequest, json: CreateAPIKeyRequestJSON },
        output: {
          protobuf: CreateAPIKeyResponse,
          json: CreateAPIKeyResponseJSON,
        },
      },
      DeleteAPIKey: {
        name: "DeleteAPIKey",
        handler: service.DeleteAPIKey,
        input: { protobuf: DeleteAPIKeyRequest, json: DeleteAPIKeyRequestJSON },
        output: {
          protobuf: DeleteAPIKeyResponse,
          json: DeleteAPIKeyResponseJSON,
        },
      },
      GetProjectAccessCodes: {
        name: "GetProjectAccessCodes",
        handler: service.GetProjectAccessCodes,
        input: {
          protobuf: GetProjectAccessCodesRequest,
          json: GetProjectAccessCodesRequestJSON,
        },
        output: {
          protobuf: GetProjectAccessCodesResponse,
          json: GetProjectAccessCodesResponseJSON,
        },
      },
      GenerateProjectAccessCodes: {
        name: "GenerateProjectAccessCodes",
        handler: service.GenerateProjectAccessCodes,
        input: {
          protobuf: GenerateProjectAccessCodesRequest,
          json: GenerateProjectAccessCodesRequestJSON,
        },
        output: {
          protobuf: GenerateProjectAccessCodesResponse,
          json: GenerateProjectAccessCodesResponseJSON,
        },
      },
      DeleteProjectAccessCode: {
        name: "DeleteProjectAccessCode",
        handler: service.DeleteProjectAccessCode,
        input: {
          protobuf: DeleteProjectAccessCodeRequest,
          json: DeleteProjectAccessCodeRequestJSON,
        },
        output: {
          protobuf: DeleteProjectAccessCodeResponse,
          json: DeleteProjectAccessCodeResponseJSON,
        },
      },
      UpdateProjectRequiresAccessCode: {
        name: "UpdateProjectRequiresAccessCode",
        handler: service.UpdateProjectRequiresAccessCode,
        input: {
          protobuf: UpdateProjectRequiresAccessCodeRequest,
          json: UpdateProjectRequiresAccessCodeRequestJSON,
        },
        output: {
          protobuf: UpdateProjectRequiresAccessCodeResponse,
          json: UpdateProjectRequiresAccessCodeResponseJSON,
        },
      },
      UpdateProjectInviteRedirectUrl: {
        name: "UpdateProjectInviteRedirectUrl",
        handler: service.UpdateProjectInviteRedirectUrl,
        input: {
          protobuf: UpdateProjectInviteRedirectUrlRequest,
          json: UpdateProjectInviteRedirectUrlRequestJSON,
        },
        output: {
          protobuf: UpdateProjectInviteRedirectUrlResponse,
          json: UpdateProjectInviteRedirectUrlResponseJSON,
        },
      },
      UpdateProjectInviteAcceptedCallback: {
        name: "UpdateProjectInviteAcceptedCallback",
        handler: service.UpdateProjectInviteAcceptedCallback,
        input: {
          protobuf: UpdateProjectInviteAcceptedCallbackRequest,
          json: UpdateProjectInviteAcceptedCallbackRequestJSON,
        },
        output: {
          protobuf: UpdateProjectInviteAcceptedCallbackResponse,
          json: UpdateProjectInviteAcceptedCallbackResponseJSON,
        },
      },
      GetProjectFormBlocks: {
        name: "GetProjectFormBlocks",
        handler: service.GetProjectFormBlocks,
        input: {
          protobuf: GetProjectFormBlocksRequest,
          json: GetProjectFormBlocksRequestJSON,
        },
        output: {
          protobuf: GetProjectFormBlocksResponse,
          json: GetProjectFormBlocksResponseJSON,
        },
      },
      UpsertProjectFormBlock: {
        name: "UpsertProjectFormBlock",
        handler: service.UpsertProjectFormBlock,
        input: {
          protobuf: UpsertProjectFormBlockRequest,
          json: UpsertProjectFormBlockRequestJSON,
        },
        output: {
          protobuf: UpsertProjectFormBlockResponse,
          json: UpsertProjectFormBlockResponseJSON,
        },
      },
      DeleteProjectFormBlock: {
        name: "DeleteProjectFormBlock",
        handler: service.DeleteProjectFormBlock,
        input: {
          protobuf: DeleteProjectFormBlockRequest,
          json: DeleteProjectFormBlockRequestJSON,
        },
        output: {
          protobuf: DeleteProjectFormBlockResponse,
          json: DeleteProjectFormBlockResponseJSON,
        },
      },
      UpdatePublishProjectForm: {
        name: "UpdatePublishProjectForm",
        handler: service.UpdatePublishProjectForm,
        input: {
          protobuf: UpdatePublishProjectFormRequest,
          json: UpdatePublishProjectFormRequestJSON,
        },
        output: {
          protobuf: UpdatePublishProjectFormResponse,
          json: UpdatePublishProjectFormResponseJSON,
        },
      },
      UpdateFormCSS: {
        name: "UpdateFormCSS",
        handler: service.UpdateFormCSS,
        input: {
          protobuf: UpdateFormCSSRequest,
          json: UpdateFormCSSRequestJSON,
        },
        output: {
          protobuf: UpdateFormCSSResponse,
          json: UpdateFormCSSResponseJSON,
        },
      },
      GetProjectSchedules: {
        name: "GetProjectSchedules",
        handler: service.GetProjectSchedules,
        input: {
          protobuf: GetProjectSchedulesRequest,
          json: GetProjectSchedulesRequestJSON,
        },
        output: {
          protobuf: GetProjectSchedulesResponse,
          json: GetProjectSchedulesResponseJSON,
        },
      },
      CreateProjectSchedule: {
        name: "CreateProjectSchedule",
        handler: service.CreateProjectSchedule,
        input: {
          protobuf: CreateProjectScheduleRequest,
          json: CreateProjectScheduleRequestJSON,
        },
        output: {
          protobuf: CreateProjectScheduleResponse,
          json: CreateProjectScheduleResponseJSON,
        },
      },
      HideProjectSchedule: {
        name: "HideProjectSchedule",
        handler: service.HideProjectSchedule,
        input: {
          protobuf: HideProjectScheduleRequest,
          json: HideProjectScheduleRequestJSON,
        },
        output: {
          protobuf: HideProjectScheduleResponse,
          json: HideProjectScheduleResponseJSON,
        },
      },
      CancelProjectSchedule: {
        name: "CancelProjectSchedule",
        handler: service.CancelProjectSchedule,
        input: {
          protobuf: CancelProjectScheduleRequest,
          json: CancelProjectScheduleRequestJSON,
        },
        output: {
          protobuf: CancelProjectScheduleResponse,
          json: CancelProjectScheduleResponseJSON,
        },
      },
      ResumeProjectSchedule: {
        name: "ResumeProjectSchedule",
        handler: service.ResumeProjectSchedule,
        input: {
          protobuf: ResumeProjectScheduleRequest,
          json: ResumeProjectScheduleRequestJSON,
        },
        output: {
          protobuf: ResumeProjectScheduleResponse,
          json: ResumeProjectScheduleResponseJSON,
        },
      },
      ReplayProjectSchedule: {
        name: "ReplayProjectSchedule",
        handler: service.ReplayProjectSchedule,
        input: {
          protobuf: ReplayProjectScheduleRequest,
          json: ReplayProjectScheduleRequestJSON,
        },
        output: {
          protobuf: ReplayProjectScheduleResponse,
          json: ReplayProjectScheduleResponseJSON,
        },
      },
    },
  } as const;
}

//========================================//
//        Protobuf Encode / Decode        //
//========================================//

export const SignedToken = {
  /**
   * Serializes SignedToken to protobuf.
   */
  encode: function (msg: Partial<SignedToken>): Uint8Array {
    return SignedToken._writeMessage(msg, new BinaryWriter()).getResultBuffer();
  },

  /**
   * Deserializes SignedToken from protobuf.
   */
  decode: function (bytes: ByteSource): SignedToken {
    return SignedToken._readMessage(
      SignedToken.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes SignedToken with all fields set to their default value.
   */
  initialize: function (): SignedToken {
    return {
      signature: new Uint8Array(),
      token: new Uint8Array(),
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<SignedToken>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.signature?.length) {
      writer.writeBytes(1, msg.signature);
    }
    if (msg.token?.length) {
      writer.writeBytes(2, msg.token);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (msg: SignedToken, reader: BinaryReader): SignedToken {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.signature = reader.readBytes();
          break;
        }
        case 2: {
          msg.token = reader.readBytes();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const Token = {
  /**
   * Serializes Token to protobuf.
   */
  encode: function (msg: Partial<Token>): Uint8Array {
    return Token._writeMessage(msg, new BinaryWriter()).getResultBuffer();
  },

  /**
   * Deserializes Token from protobuf.
   */
  decode: function (bytes: ByteSource): Token {
    return Token._readMessage(Token.initialize(), new BinaryReader(bytes));
  },

  /**
   * Initializes Token with all fields set to their default value.
   */
  initialize: function (): Token {
    return {
      expiresAt: 0,
      user: User.initialize(),
      version: 0,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<Token>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.expiresAt) {
      writer.writeInt32(1, msg.expiresAt);
    }
    if (msg.user) {
      writer.writeMessage(2, msg.user, User._writeMessage);
    }
    if (msg.version) {
      writer.writeInt32(3, msg.version);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (msg: Token, reader: BinaryReader): Token {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.expiresAt = reader.readInt32();
          break;
        }
        case 2: {
          reader.readMessage(msg.user, User._readMessage);
          break;
        }
        case 3: {
          msg.version = reader.readInt32();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const User = {
  /**
   * Serializes User to protobuf.
   */
  encode: function (msg: Partial<User>): Uint8Array {
    return User._writeMessage(msg, new BinaryWriter()).getResultBuffer();
  },

  /**
   * Deserializes User from protobuf.
   */
  decode: function (bytes: ByteSource): User {
    return User._readMessage(User.initialize(), new BinaryReader(bytes));
  },

  /**
   * Initializes User with all fields set to their default value.
   */
  initialize: function (): User {
    return {
      id: "",
      email: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<User>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.id) {
      writer.writeString(1, msg.id);
    }
    if (msg.email) {
      writer.writeString(2, msg.email);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (msg: User, reader: BinaryReader): User {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.id = reader.readString();
          break;
        }
        case 2: {
          msg.email = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const Member = {
  /**
   * Serializes Member to protobuf.
   */
  encode: function (msg: Partial<Member>): Uint8Array {
    return Member._writeMessage(msg, new BinaryWriter()).getResultBuffer();
  },

  /**
   * Deserializes Member from protobuf.
   */
  decode: function (bytes: ByteSource): Member {
    return Member._readMessage(Member.initialize(), new BinaryReader(bytes));
  },

  /**
   * Initializes Member with all fields set to their default value.
   */
  initialize: function (): Member {
    return {
      id: "",
      userId: undefined,
      email: undefined,
      access: 0,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<Member>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.id) {
      writer.writeString(1, msg.id);
    }
    if (msg.userId != undefined) {
      writer.writeString(2, msg.userId);
    }
    if (msg.email != undefined) {
      writer.writeString(3, msg.email);
    }
    if (msg.access) {
      writer.writeInt32(4, msg.access);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (msg: Member, reader: BinaryReader): Member {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.id = reader.readString();
          break;
        }
        case 2: {
          msg.userId = reader.readString();
          break;
        }
        case 3: {
          msg.email = reader.readString();
          break;
        }
        case 4: {
          msg.access = reader.readInt32();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const SigninRequest = {
  /**
   * Serializes SigninRequest to protobuf.
   */
  encode: function (msg: Partial<SigninRequest>): Uint8Array {
    return SigninRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes SigninRequest from protobuf.
   */
  decode: function (bytes: ByteSource): SigninRequest {
    return SigninRequest._readMessage(
      SigninRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes SigninRequest with all fields set to their default value.
   */
  initialize: function (): SigninRequest {
    return {
      Token: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<SigninRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.Token) {
      writer.writeString(1, msg.Token);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: SigninRequest,
    reader: BinaryReader
  ): SigninRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.Token = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const SigninResponse = {
  /**
   * Serializes SigninResponse to protobuf.
   */
  encode: function (_msg?: Partial<SigninResponse>): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes SigninResponse from protobuf.
   */
  decode: function (_bytes?: ByteSource): SigninResponse {
    return {};
  },

  /**
   * Initializes SigninResponse with all fields set to their default value.
   */
  initialize: function (): SigninResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<SigninResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: SigninResponse,
    _reader: BinaryReader
  ): SigninResponse {
    return _msg;
  },
};

export const SignoutRequest = {
  /**
   * Serializes SignoutRequest to protobuf.
   */
  encode: function (_msg?: Partial<SignoutRequest>): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes SignoutRequest from protobuf.
   */
  decode: function (_bytes?: ByteSource): SignoutRequest {
    return {};
  },

  /**
   * Initializes SignoutRequest with all fields set to their default value.
   */
  initialize: function (): SignoutRequest {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<SignoutRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: SignoutRequest,
    _reader: BinaryReader
  ): SignoutRequest {
    return _msg;
  },
};

export const SignoutResponse = {
  /**
   * Serializes SignoutResponse to protobuf.
   */
  encode: function (_msg?: Partial<SignoutResponse>): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes SignoutResponse from protobuf.
   */
  decode: function (_bytes?: ByteSource): SignoutResponse {
    return {};
  },

  /**
   * Initializes SignoutResponse with all fields set to their default value.
   */
  initialize: function (): SignoutResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<SignoutResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: SignoutResponse,
    _reader: BinaryReader
  ): SignoutResponse {
    return _msg;
  },
};

export const SignupRequest = {
  /**
   * Serializes SignupRequest to protobuf.
   */
  encode: function (msg: Partial<SignupRequest>): Uint8Array {
    return SignupRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes SignupRequest from protobuf.
   */
  decode: function (bytes: ByteSource): SignupRequest {
    return SignupRequest._readMessage(
      SignupRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes SignupRequest with all fields set to their default value.
   */
  initialize: function (): SignupRequest {
    return {
      Token: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<SignupRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.Token) {
      writer.writeString(1, msg.Token);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: SignupRequest,
    reader: BinaryReader
  ): SignupRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.Token = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const SignupResponse = {
  /**
   * Serializes SignupResponse to protobuf.
   */
  encode: function (_msg?: Partial<SignupResponse>): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes SignupResponse from protobuf.
   */
  decode: function (_bytes?: ByteSource): SignupResponse {
    return {};
  },

  /**
   * Initializes SignupResponse with all fields set to their default value.
   */
  initialize: function (): SignupResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<SignupResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: SignupResponse,
    _reader: BinaryReader
  ): SignupResponse {
    return _msg;
  },
};

export const CreateSignupTokenRequest = {
  /**
   * Serializes CreateSignupTokenRequest to protobuf.
   */
  encode: function (msg: Partial<CreateSignupTokenRequest>): Uint8Array {
    return CreateSignupTokenRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes CreateSignupTokenRequest from protobuf.
   */
  decode: function (bytes: ByteSource): CreateSignupTokenRequest {
    return CreateSignupTokenRequest._readMessage(
      CreateSignupTokenRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes CreateSignupTokenRequest with all fields set to their default value.
   */
  initialize: function (): CreateSignupTokenRequest {
    return {
      Email: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<CreateSignupTokenRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.Email) {
      writer.writeString(1, msg.Email);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: CreateSignupTokenRequest,
    reader: BinaryReader
  ): CreateSignupTokenRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.Email = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const CreateSignupTokenResponse = {
  /**
   * Serializes CreateSignupTokenResponse to protobuf.
   */
  encode: function (_msg?: Partial<CreateSignupTokenResponse>): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes CreateSignupTokenResponse from protobuf.
   */
  decode: function (_bytes?: ByteSource): CreateSignupTokenResponse {
    return {};
  },

  /**
   * Initializes CreateSignupTokenResponse with all fields set to their default value.
   */
  initialize: function (): CreateSignupTokenResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<CreateSignupTokenResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: CreateSignupTokenResponse,
    _reader: BinaryReader
  ): CreateSignupTokenResponse {
    return _msg;
  },
};

export const CreateSigninTokenRequest = {
  /**
   * Serializes CreateSigninTokenRequest to protobuf.
   */
  encode: function (msg: Partial<CreateSigninTokenRequest>): Uint8Array {
    return CreateSigninTokenRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes CreateSigninTokenRequest from protobuf.
   */
  decode: function (bytes: ByteSource): CreateSigninTokenRequest {
    return CreateSigninTokenRequest._readMessage(
      CreateSigninTokenRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes CreateSigninTokenRequest with all fields set to their default value.
   */
  initialize: function (): CreateSigninTokenRequest {
    return {
      Email: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<CreateSigninTokenRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.Email) {
      writer.writeString(1, msg.Email);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: CreateSigninTokenRequest,
    reader: BinaryReader
  ): CreateSigninTokenRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.Email = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const CreateSigninTokenResponse = {
  /**
   * Serializes CreateSigninTokenResponse to protobuf.
   */
  encode: function (_msg?: Partial<CreateSigninTokenResponse>): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes CreateSigninTokenResponse from protobuf.
   */
  decode: function (_bytes?: ByteSource): CreateSigninTokenResponse {
    return {};
  },

  /**
   * Initializes CreateSigninTokenResponse with all fields set to their default value.
   */
  initialize: function (): CreateSigninTokenResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<CreateSigninTokenResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: CreateSigninTokenResponse,
    _reader: BinaryReader
  ): CreateSigninTokenResponse {
    return _msg;
  },
};

export const MeRequest = {
  /**
   * Serializes MeRequest to protobuf.
   */
  encode: function (_msg?: Partial<MeRequest>): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes MeRequest from protobuf.
   */
  decode: function (_bytes?: ByteSource): MeRequest {
    return {};
  },

  /**
   * Initializes MeRequest with all fields set to their default value.
   */
  initialize: function (): MeRequest {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<MeRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (_msg: MeRequest, _reader: BinaryReader): MeRequest {
    return _msg;
  },
};

export const MeResponse = {
  /**
   * Serializes MeResponse to protobuf.
   */
  encode: function (msg: Partial<MeResponse>): Uint8Array {
    return MeResponse._writeMessage(msg, new BinaryWriter()).getResultBuffer();
  },

  /**
   * Deserializes MeResponse from protobuf.
   */
  decode: function (bytes: ByteSource): MeResponse {
    return MeResponse._readMessage(
      MeResponse.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes MeResponse with all fields set to their default value.
   */
  initialize: function (): MeResponse {
    return {
      user: User.initialize(),
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<MeResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.user) {
      writer.writeMessage(1, msg.user, User._writeMessage);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (msg: MeResponse, reader: BinaryReader): MeResponse {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          reader.readMessage(msg.user, User._readMessage);
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const GetInvitedSignupRequest = {
  /**
   * Serializes GetInvitedSignupRequest to protobuf.
   */
  encode: function (msg: Partial<GetInvitedSignupRequest>): Uint8Array {
    return GetInvitedSignupRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetInvitedSignupRequest from protobuf.
   */
  decode: function (bytes: ByteSource): GetInvitedSignupRequest {
    return GetInvitedSignupRequest._readMessage(
      GetInvitedSignupRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetInvitedSignupRequest with all fields set to their default value.
   */
  initialize: function (): GetInvitedSignupRequest {
    return {
      inviteId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetInvitedSignupRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.inviteId) {
      writer.writeString(1, msg.inviteId);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetInvitedSignupRequest,
    reader: BinaryReader
  ): GetInvitedSignupRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.inviteId = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const GetInvitedSignupResponse = {
  /**
   * Serializes GetInvitedSignupResponse to protobuf.
   */
  encode: function (msg: Partial<GetInvitedSignupResponse>): Uint8Array {
    return GetInvitedSignupResponse._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetInvitedSignupResponse from protobuf.
   */
  decode: function (bytes: ByteSource): GetInvitedSignupResponse {
    return GetInvitedSignupResponse._readMessage(
      GetInvitedSignupResponse.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetInvitedSignupResponse with all fields set to their default value.
   */
  initialize: function (): GetInvitedSignupResponse {
    return {
      email: "",
      project: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetInvitedSignupResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.email) {
      writer.writeString(1, msg.email);
    }
    if (msg.project) {
      writer.writeString(2, msg.project);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetInvitedSignupResponse,
    reader: BinaryReader
  ): GetInvitedSignupResponse {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.email = reader.readString();
          break;
        }
        case 2: {
          msg.project = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const AcceptInviteRequest = {
  /**
   * Serializes AcceptInviteRequest to protobuf.
   */
  encode: function (msg: Partial<AcceptInviteRequest>): Uint8Array {
    return AcceptInviteRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes AcceptInviteRequest from protobuf.
   */
  decode: function (bytes: ByteSource): AcceptInviteRequest {
    return AcceptInviteRequest._readMessage(
      AcceptInviteRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes AcceptInviteRequest with all fields set to their default value.
   */
  initialize: function (): AcceptInviteRequest {
    return {
      inviteId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<AcceptInviteRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.inviteId) {
      writer.writeString(1, msg.inviteId);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: AcceptInviteRequest,
    reader: BinaryReader
  ): AcceptInviteRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.inviteId = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const AcceptInviteResponse = {
  /**
   * Serializes AcceptInviteResponse to protobuf.
   */
  encode: function (msg: Partial<AcceptInviteResponse>): Uint8Array {
    return AcceptInviteResponse._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes AcceptInviteResponse from protobuf.
   */
  decode: function (bytes: ByteSource): AcceptInviteResponse {
    return AcceptInviteResponse._readMessage(
      AcceptInviteResponse.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes AcceptInviteResponse with all fields set to their default value.
   */
  initialize: function (): AcceptInviteResponse {
    return {
      ok: false,
      redirectTo: undefined,
      message: undefined,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<AcceptInviteResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.ok) {
      writer.writeBool(1, msg.ok);
    }
    if (msg.redirectTo != undefined) {
      writer.writeString(2, msg.redirectTo);
    }
    if (msg.message != undefined) {
      writer.writeString(3, msg.message);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: AcceptInviteResponse,
    reader: BinaryReader
  ): AcceptInviteResponse {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.ok = reader.readBool();
          break;
        }
        case 2: {
          msg.redirectTo = reader.readString();
          break;
        }
        case 3: {
          msg.message = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const Project = {
  /**
   * Serializes Project to protobuf.
   */
  encode: function (msg: Partial<Project>): Uint8Array {
    return Project._writeMessage(msg, new BinaryWriter()).getResultBuffer();
  },

  /**
   * Deserializes Project from protobuf.
   */
  decode: function (bytes: ByteSource): Project {
    return Project._readMessage(Project.initialize(), new BinaryReader(bytes));
  },

  /**
   * Initializes Project with all fields set to their default value.
   */
  initialize: function (): Project {
    return {
      id: "",
      name: "",
      formId: "",
      createdAt: 0,
      updatedAt: 0,
      handle: "",
      fromEmail: "",
      emailReplyTo: undefined,
      webhookSecret: "",
      requiresAccessCode: false,
      inviteRedirectUrl: undefined,
      inviteAcceptedCallback: undefined,
      access: 0,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<Project>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.id) {
      writer.writeString(1, msg.id);
    }
    if (msg.name) {
      writer.writeString(2, msg.name);
    }
    if (msg.formId) {
      writer.writeString(3, msg.formId);
    }
    if (msg.createdAt) {
      writer.writeInt32(4, msg.createdAt);
    }
    if (msg.updatedAt) {
      writer.writeInt32(5, msg.updatedAt);
    }
    if (msg.handle) {
      writer.writeString(6, msg.handle);
    }
    if (msg.fromEmail) {
      writer.writeString(7, msg.fromEmail);
    }
    if (msg.emailReplyTo != undefined) {
      writer.writeString(8, msg.emailReplyTo);
    }
    if (msg.webhookSecret) {
      writer.writeString(9, msg.webhookSecret);
    }
    if (msg.requiresAccessCode) {
      writer.writeBool(10, msg.requiresAccessCode);
    }
    if (msg.inviteRedirectUrl != undefined) {
      writer.writeString(11, msg.inviteRedirectUrl);
    }
    if (msg.inviteAcceptedCallback != undefined) {
      writer.writeString(12, msg.inviteAcceptedCallback);
    }
    if (msg.access) {
      writer.writeInt32(13, msg.access);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (msg: Project, reader: BinaryReader): Project {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.id = reader.readString();
          break;
        }
        case 2: {
          msg.name = reader.readString();
          break;
        }
        case 3: {
          msg.formId = reader.readString();
          break;
        }
        case 4: {
          msg.createdAt = reader.readInt32();
          break;
        }
        case 5: {
          msg.updatedAt = reader.readInt32();
          break;
        }
        case 6: {
          msg.handle = reader.readString();
          break;
        }
        case 7: {
          msg.fromEmail = reader.readString();
          break;
        }
        case 8: {
          msg.emailReplyTo = reader.readString();
          break;
        }
        case 9: {
          msg.webhookSecret = reader.readString();
          break;
        }
        case 10: {
          msg.requiresAccessCode = reader.readBool();
          break;
        }
        case 11: {
          msg.inviteRedirectUrl = reader.readString();
          break;
        }
        case 12: {
          msg.inviteAcceptedCallback = reader.readString();
          break;
        }
        case 13: {
          msg.access = reader.readInt32();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const ProjectWithSignups = {
  /**
   * Serializes ProjectWithSignups to protobuf.
   */
  encode: function (msg: Partial<ProjectWithSignups>): Uint8Array {
    return ProjectWithSignups._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes ProjectWithSignups from protobuf.
   */
  decode: function (bytes: ByteSource): ProjectWithSignups {
    return ProjectWithSignups._readMessage(
      ProjectWithSignups.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes ProjectWithSignups with all fields set to their default value.
   */
  initialize: function (): ProjectWithSignups {
    return {
      project: Project.initialize(),
      signups: 0,
      members: [],
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<ProjectWithSignups>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.project) {
      writer.writeMessage(1, msg.project, Project._writeMessage);
    }
    if (msg.signups) {
      writer.writeInt32(2, msg.signups);
    }
    if (msg.members?.length) {
      writer.writeRepeatedMessage(3, msg.members as any, Member._writeMessage);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: ProjectWithSignups,
    reader: BinaryReader
  ): ProjectWithSignups {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          reader.readMessage(msg.project, Project._readMessage);
          break;
        }
        case 2: {
          msg.signups = reader.readInt32();
          break;
        }
        case 3: {
          const m = Member.initialize();
          reader.readMessage(m, Member._readMessage);
          msg.members.push(m);
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const ListProjectsRequest = {
  /**
   * Serializes ListProjectsRequest to protobuf.
   */
  encode: function (_msg?: Partial<ListProjectsRequest>): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes ListProjectsRequest from protobuf.
   */
  decode: function (_bytes?: ByteSource): ListProjectsRequest {
    return {};
  },

  /**
   * Initializes ListProjectsRequest with all fields set to their default value.
   */
  initialize: function (): ListProjectsRequest {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<ListProjectsRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: ListProjectsRequest,
    _reader: BinaryReader
  ): ListProjectsRequest {
    return _msg;
  },
};

export const ListProjectsResponse = {
  /**
   * Serializes ListProjectsResponse to protobuf.
   */
  encode: function (msg: Partial<ListProjectsResponse>): Uint8Array {
    return ListProjectsResponse._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes ListProjectsResponse from protobuf.
   */
  decode: function (bytes: ByteSource): ListProjectsResponse {
    return ListProjectsResponse._readMessage(
      ListProjectsResponse.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes ListProjectsResponse with all fields set to their default value.
   */
  initialize: function (): ListProjectsResponse {
    return {
      projects: [],
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<ListProjectsResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projects?.length) {
      writer.writeRepeatedMessage(
        1,
        msg.projects as any,
        ProjectWithSignups._writeMessage
      );
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: ListProjectsResponse,
    reader: BinaryReader
  ): ListProjectsResponse {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          const m = ProjectWithSignups.initialize();
          reader.readMessage(m, ProjectWithSignups._readMessage);
          msg.projects.push(m);
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const CreateProjectRequest = {
  /**
   * Serializes CreateProjectRequest to protobuf.
   */
  encode: function (msg: Partial<CreateProjectRequest>): Uint8Array {
    return CreateProjectRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes CreateProjectRequest from protobuf.
   */
  decode: function (bytes: ByteSource): CreateProjectRequest {
    return CreateProjectRequest._readMessage(
      CreateProjectRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes CreateProjectRequest with all fields set to their default value.
   */
  initialize: function (): CreateProjectRequest {
    return {
      name: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<CreateProjectRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.name) {
      writer.writeString(1, msg.name);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: CreateProjectRequest,
    reader: BinaryReader
  ): CreateProjectRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.name = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const CreateProjectResponse = {
  /**
   * Serializes CreateProjectResponse to protobuf.
   */
  encode: function (msg: Partial<CreateProjectResponse>): Uint8Array {
    return CreateProjectResponse._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes CreateProjectResponse from protobuf.
   */
  decode: function (bytes: ByteSource): CreateProjectResponse {
    return CreateProjectResponse._readMessage(
      CreateProjectResponse.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes CreateProjectResponse with all fields set to their default value.
   */
  initialize: function (): CreateProjectResponse {
    return {
      project: Project.initialize(),
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<CreateProjectResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.project) {
      writer.writeMessage(1, msg.project, Project._writeMessage);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: CreateProjectResponse,
    reader: BinaryReader
  ): CreateProjectResponse {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          reader.readMessage(msg.project, Project._readMessage);
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const GetProjectRequest = {
  /**
   * Serializes GetProjectRequest to protobuf.
   */
  encode: function (msg: Partial<GetProjectRequest>): Uint8Array {
    return GetProjectRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetProjectRequest from protobuf.
   */
  decode: function (bytes: ByteSource): GetProjectRequest {
    return GetProjectRequest._readMessage(
      GetProjectRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetProjectRequest with all fields set to their default value.
   */
  initialize: function (): GetProjectRequest {
    return {
      projectId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectRequest,
    reader: BinaryReader
  ): GetProjectRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const GetProjectResponse = {
  /**
   * Serializes GetProjectResponse to protobuf.
   */
  encode: function (msg: Partial<GetProjectResponse>): Uint8Array {
    return GetProjectResponse._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetProjectResponse from protobuf.
   */
  decode: function (bytes: ByteSource): GetProjectResponse {
    return GetProjectResponse._readMessage(
      GetProjectResponse.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetProjectResponse with all fields set to their default value.
   */
  initialize: function (): GetProjectResponse {
    return {
      project: Project.initialize(),
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.project) {
      writer.writeMessage(1, msg.project, Project._writeMessage);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectResponse,
    reader: BinaryReader
  ): GetProjectResponse {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          reader.readMessage(msg.project, Project._readMessage);
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const DeleteProjectRequest = {
  /**
   * Serializes DeleteProjectRequest to protobuf.
   */
  encode: function (msg: Partial<DeleteProjectRequest>): Uint8Array {
    return DeleteProjectRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes DeleteProjectRequest from protobuf.
   */
  decode: function (bytes: ByteSource): DeleteProjectRequest {
    return DeleteProjectRequest._readMessage(
      DeleteProjectRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes DeleteProjectRequest with all fields set to their default value.
   */
  initialize: function (): DeleteProjectRequest {
    return {
      projectId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<DeleteProjectRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: DeleteProjectRequest,
    reader: BinaryReader
  ): DeleteProjectRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const DeleteProjectResponse = {
  /**
   * Serializes DeleteProjectResponse to protobuf.
   */
  encode: function (_msg?: Partial<DeleteProjectResponse>): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes DeleteProjectResponse from protobuf.
   */
  decode: function (_bytes?: ByteSource): DeleteProjectResponse {
    return {};
  },

  /**
   * Initializes DeleteProjectResponse with all fields set to their default value.
   */
  initialize: function (): DeleteProjectResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<DeleteProjectResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: DeleteProjectResponse,
    _reader: BinaryReader
  ): DeleteProjectResponse {
    return _msg;
  },
};

export const GetProjectStatsRequest = {
  /**
   * Serializes GetProjectStatsRequest to protobuf.
   */
  encode: function (msg: Partial<GetProjectStatsRequest>): Uint8Array {
    return GetProjectStatsRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetProjectStatsRequest from protobuf.
   */
  decode: function (bytes: ByteSource): GetProjectStatsRequest {
    return GetProjectStatsRequest._readMessage(
      GetProjectStatsRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetProjectStatsRequest with all fields set to their default value.
   */
  initialize: function (): GetProjectStatsRequest {
    return {
      projectId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectStatsRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectStatsRequest,
    reader: BinaryReader
  ): GetProjectStatsRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const GetProjectStatsResponse = {
  /**
   * Serializes GetProjectStatsResponse to protobuf.
   */
  encode: function (msg: Partial<GetProjectStatsResponse>): Uint8Array {
    return GetProjectStatsResponse._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetProjectStatsResponse from protobuf.
   */
  decode: function (bytes: ByteSource): GetProjectStatsResponse {
    return GetProjectStatsResponse._readMessage(
      GetProjectStatsResponse.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetProjectStatsResponse with all fields set to their default value.
   */
  initialize: function (): GetProjectStatsResponse {
    return {
      signups: 0,
      invited: 0,
      waitlist: 0,
      accepted: 0,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectStatsResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.signups) {
      writer.writeInt32(1, msg.signups);
    }
    if (msg.invited) {
      writer.writeInt32(2, msg.invited);
    }
    if (msg.waitlist) {
      writer.writeInt32(3, msg.waitlist);
    }
    if (msg.accepted) {
      writer.writeInt32(4, msg.accepted);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectStatsResponse,
    reader: BinaryReader
  ): GetProjectStatsResponse {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.signups = reader.readInt32();
          break;
        }
        case 2: {
          msg.invited = reader.readInt32();
          break;
        }
        case 3: {
          msg.waitlist = reader.readInt32();
          break;
        }
        case 4: {
          msg.accepted = reader.readInt32();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const ProjectSignup = {
  /**
   * Serializes ProjectSignup to protobuf.
   */
  encode: function (msg: Partial<ProjectSignup>): Uint8Array {
    return ProjectSignup._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes ProjectSignup from protobuf.
   */
  decode: function (bytes: ByteSource): ProjectSignup {
    return ProjectSignup._readMessage(
      ProjectSignup.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes ProjectSignup with all fields set to their default value.
   */
  initialize: function (): ProjectSignup {
    return {
      id: "",
      email: "",
      createdAt: 0,
      karma: 0,
      projectId: "",
      invitedAt: undefined,
      acceptedAt: undefined,
      position: 0,
      referral: "",
      pending: false,
      expiresAt: undefined,
      accessCode: undefined,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<ProjectSignup>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.id) {
      writer.writeString(1, msg.id);
    }
    if (msg.email) {
      writer.writeString(2, msg.email);
    }
    if (msg.createdAt) {
      writer.writeInt32(3, msg.createdAt);
    }
    if (msg.karma) {
      writer.writeInt32(4, msg.karma);
    }
    if (msg.projectId) {
      writer.writeString(5, msg.projectId);
    }
    if (msg.invitedAt != undefined) {
      writer.writeInt32(6, msg.invitedAt);
    }
    if (msg.acceptedAt != undefined) {
      writer.writeInt32(7, msg.acceptedAt);
    }
    if (msg.position) {
      writer.writeInt32(8, msg.position);
    }
    if (msg.referral) {
      writer.writeString(9, msg.referral);
    }
    if (msg.pending) {
      writer.writeBool(10, msg.pending);
    }
    if (msg.expiresAt != undefined) {
      writer.writeInt32(11, msg.expiresAt);
    }
    if (msg.accessCode != undefined) {
      writer.writeString(12, msg.accessCode);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: ProjectSignup,
    reader: BinaryReader
  ): ProjectSignup {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.id = reader.readString();
          break;
        }
        case 2: {
          msg.email = reader.readString();
          break;
        }
        case 3: {
          msg.createdAt = reader.readInt32();
          break;
        }
        case 4: {
          msg.karma = reader.readInt32();
          break;
        }
        case 5: {
          msg.projectId = reader.readString();
          break;
        }
        case 6: {
          msg.invitedAt = reader.readInt32();
          break;
        }
        case 7: {
          msg.acceptedAt = reader.readInt32();
          break;
        }
        case 8: {
          msg.position = reader.readInt32();
          break;
        }
        case 9: {
          msg.referral = reader.readString();
          break;
        }
        case 10: {
          msg.pending = reader.readBool();
          break;
        }
        case 11: {
          msg.expiresAt = reader.readInt32();
          break;
        }
        case 12: {
          msg.accessCode = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const GetProjectSignupsRequest = {
  /**
   * Serializes GetProjectSignupsRequest to protobuf.
   */
  encode: function (msg: Partial<GetProjectSignupsRequest>): Uint8Array {
    return GetProjectSignupsRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetProjectSignupsRequest from protobuf.
   */
  decode: function (bytes: ByteSource): GetProjectSignupsRequest {
    return GetProjectSignupsRequest._readMessage(
      GetProjectSignupsRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetProjectSignupsRequest with all fields set to their default value.
   */
  initialize: function (): GetProjectSignupsRequest {
    return {
      projectId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectSignupsRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectSignupsRequest,
    reader: BinaryReader
  ): GetProjectSignupsRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const GetProjectSignupsResponse = {
  /**
   * Serializes GetProjectSignupsResponse to protobuf.
   */
  encode: function (msg: Partial<GetProjectSignupsResponse>): Uint8Array {
    return GetProjectSignupsResponse._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetProjectSignupsResponse from protobuf.
   */
  decode: function (bytes: ByteSource): GetProjectSignupsResponse {
    return GetProjectSignupsResponse._readMessage(
      GetProjectSignupsResponse.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetProjectSignupsResponse with all fields set to their default value.
   */
  initialize: function (): GetProjectSignupsResponse {
    return {
      signups: [],
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectSignupsResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.signups?.length) {
      writer.writeRepeatedMessage(
        1,
        msg.signups as any,
        ProjectSignup._writeMessage
      );
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectSignupsResponse,
    reader: BinaryReader
  ): GetProjectSignupsResponse {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          const m = ProjectSignup.initialize();
          reader.readMessage(m, ProjectSignup._readMessage);
          msg.signups.push(m);
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const GetProjectWaitlistRequest = {
  /**
   * Serializes GetProjectWaitlistRequest to protobuf.
   */
  encode: function (msg: Partial<GetProjectWaitlistRequest>): Uint8Array {
    return GetProjectWaitlistRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetProjectWaitlistRequest from protobuf.
   */
  decode: function (bytes: ByteSource): GetProjectWaitlistRequest {
    return GetProjectWaitlistRequest._readMessage(
      GetProjectWaitlistRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetProjectWaitlistRequest with all fields set to their default value.
   */
  initialize: function (): GetProjectWaitlistRequest {
    return {
      projectId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectWaitlistRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectWaitlistRequest,
    reader: BinaryReader
  ): GetProjectWaitlistRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const GetProjectWaitlistResponse = {
  /**
   * Serializes GetProjectWaitlistResponse to protobuf.
   */
  encode: function (msg: Partial<GetProjectWaitlistResponse>): Uint8Array {
    return GetProjectWaitlistResponse._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetProjectWaitlistResponse from protobuf.
   */
  decode: function (bytes: ByteSource): GetProjectWaitlistResponse {
    return GetProjectWaitlistResponse._readMessage(
      GetProjectWaitlistResponse.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetProjectWaitlistResponse with all fields set to their default value.
   */
  initialize: function (): GetProjectWaitlistResponse {
    return {
      signups: [],
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectWaitlistResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.signups?.length) {
      writer.writeRepeatedMessage(
        1,
        msg.signups as any,
        ProjectSignup._writeMessage
      );
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectWaitlistResponse,
    reader: BinaryReader
  ): GetProjectWaitlistResponse {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          const m = ProjectSignup.initialize();
          reader.readMessage(m, ProjectSignup._readMessage);
          msg.signups.push(m);
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const GetProjectInvitesRequest = {
  /**
   * Serializes GetProjectInvitesRequest to protobuf.
   */
  encode: function (msg: Partial<GetProjectInvitesRequest>): Uint8Array {
    return GetProjectInvitesRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetProjectInvitesRequest from protobuf.
   */
  decode: function (bytes: ByteSource): GetProjectInvitesRequest {
    return GetProjectInvitesRequest._readMessage(
      GetProjectInvitesRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetProjectInvitesRequest with all fields set to their default value.
   */
  initialize: function (): GetProjectInvitesRequest {
    return {
      projectId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectInvitesRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectInvitesRequest,
    reader: BinaryReader
  ): GetProjectInvitesRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const GetProjectInvitesResponse = {
  /**
   * Serializes GetProjectInvitesResponse to protobuf.
   */
  encode: function (msg: Partial<GetProjectInvitesResponse>): Uint8Array {
    return GetProjectInvitesResponse._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetProjectInvitesResponse from protobuf.
   */
  decode: function (bytes: ByteSource): GetProjectInvitesResponse {
    return GetProjectInvitesResponse._readMessage(
      GetProjectInvitesResponse.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetProjectInvitesResponse with all fields set to their default value.
   */
  initialize: function (): GetProjectInvitesResponse {
    return {
      signups: [],
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectInvitesResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.signups?.length) {
      writer.writeRepeatedMessage(
        1,
        msg.signups as any,
        ProjectSignup._writeMessage
      );
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectInvitesResponse,
    reader: BinaryReader
  ): GetProjectInvitesResponse {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          const m = ProjectSignup.initialize();
          reader.readMessage(m, ProjectSignup._readMessage);
          msg.signups.push(m);
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const SendInviteRequest = {
  /**
   * Serializes SendInviteRequest to protobuf.
   */
  encode: function (msg: Partial<SendInviteRequest>): Uint8Array {
    return SendInviteRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes SendInviteRequest from protobuf.
   */
  decode: function (bytes: ByteSource): SendInviteRequest {
    return SendInviteRequest._readMessage(
      SendInviteRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes SendInviteRequest with all fields set to their default value.
   */
  initialize: function (): SendInviteRequest {
    return {
      projectId: "",
      signupId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<SendInviteRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    if (msg.signupId) {
      writer.writeString(2, msg.signupId);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: SendInviteRequest,
    reader: BinaryReader
  ): SendInviteRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        case 2: {
          msg.signupId = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const SendInviteResponse = {
  /**
   * Serializes SendInviteResponse to protobuf.
   */
  encode: function (_msg?: Partial<SendInviteResponse>): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes SendInviteResponse from protobuf.
   */
  decode: function (_bytes?: ByteSource): SendInviteResponse {
    return {};
  },

  /**
   * Initializes SendInviteResponse with all fields set to their default value.
   */
  initialize: function (): SendInviteResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<SendInviteResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: SendInviteResponse,
    _reader: BinaryReader
  ): SendInviteResponse {
    return _msg;
  },
};

export const GetProjectFormBlocksRequest = {
  /**
   * Serializes GetProjectFormBlocksRequest to protobuf.
   */
  encode: function (msg: Partial<GetProjectFormBlocksRequest>): Uint8Array {
    return GetProjectFormBlocksRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetProjectFormBlocksRequest from protobuf.
   */
  decode: function (bytes: ByteSource): GetProjectFormBlocksRequest {
    return GetProjectFormBlocksRequest._readMessage(
      GetProjectFormBlocksRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetProjectFormBlocksRequest with all fields set to their default value.
   */
  initialize: function (): GetProjectFormBlocksRequest {
    return {
      projectId: "",
      formId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectFormBlocksRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    if (msg.formId) {
      writer.writeString(2, msg.formId);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectFormBlocksRequest,
    reader: BinaryReader
  ): GetProjectFormBlocksRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        case 2: {
          msg.formId = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const GetProjectFormBlocksResponse = {
  /**
   * Serializes GetProjectFormBlocksResponse to protobuf.
   */
  encode: function (msg: Partial<GetProjectFormBlocksResponse>): Uint8Array {
    return GetProjectFormBlocksResponse._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetProjectFormBlocksResponse from protobuf.
   */
  decode: function (bytes: ByteSource): GetProjectFormBlocksResponse {
    return GetProjectFormBlocksResponse._readMessage(
      GetProjectFormBlocksResponse.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetProjectFormBlocksResponse with all fields set to their default value.
   */
  initialize: function (): GetProjectFormBlocksResponse {
    return {
      blocks: [],
      published: false,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectFormBlocksResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.blocks?.length) {
      writer.writeRepeatedMessage(
        1,
        msg.blocks as any,
        forms.FormBlock._writeMessage
      );
    }
    if (msg.published) {
      writer.writeBool(2, msg.published);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectFormBlocksResponse,
    reader: BinaryReader
  ): GetProjectFormBlocksResponse {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          const m = forms.FormBlock.initialize();
          reader.readMessage(m, forms.FormBlock._readMessage);
          msg.blocks.push(m);
          break;
        }
        case 2: {
          msg.published = reader.readBool();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const UpsertProjectFormBlockRequest = {
  /**
   * Serializes UpsertProjectFormBlockRequest to protobuf.
   */
  encode: function (msg: Partial<UpsertProjectFormBlockRequest>): Uint8Array {
    return UpsertProjectFormBlockRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes UpsertProjectFormBlockRequest from protobuf.
   */
  decode: function (bytes: ByteSource): UpsertProjectFormBlockRequest {
    return UpsertProjectFormBlockRequest._readMessage(
      UpsertProjectFormBlockRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes UpsertProjectFormBlockRequest with all fields set to their default value.
   */
  initialize: function (): UpsertProjectFormBlockRequest {
    return {
      blockId: "",
      projectId: "",
      formId: "",
      content: forms.FormBlockContent.initialize(),
      position: 0,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<UpsertProjectFormBlockRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.blockId) {
      writer.writeString(1, msg.blockId);
    }
    if (msg.projectId) {
      writer.writeString(2, msg.projectId);
    }
    if (msg.formId) {
      writer.writeString(3, msg.formId);
    }
    if (msg.content) {
      writer.writeMessage(4, msg.content, forms.FormBlockContent._writeMessage);
    }
    if (msg.position) {
      writer.writeInt32(5, msg.position);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpsertProjectFormBlockRequest,
    reader: BinaryReader
  ): UpsertProjectFormBlockRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.blockId = reader.readString();
          break;
        }
        case 2: {
          msg.projectId = reader.readString();
          break;
        }
        case 3: {
          msg.formId = reader.readString();
          break;
        }
        case 4: {
          reader.readMessage(msg.content, forms.FormBlockContent._readMessage);
          break;
        }
        case 5: {
          msg.position = reader.readInt32();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const UpsertProjectFormBlockResponse = {
  /**
   * Serializes UpsertProjectFormBlockResponse to protobuf.
   */
  encode: function (msg: Partial<UpsertProjectFormBlockResponse>): Uint8Array {
    return UpsertProjectFormBlockResponse._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes UpsertProjectFormBlockResponse from protobuf.
   */
  decode: function (bytes: ByteSource): UpsertProjectFormBlockResponse {
    return UpsertProjectFormBlockResponse._readMessage(
      UpsertProjectFormBlockResponse.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes UpsertProjectFormBlockResponse with all fields set to their default value.
   */
  initialize: function (): UpsertProjectFormBlockResponse {
    return {
      block: forms.FormBlock.initialize(),
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<UpsertProjectFormBlockResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.block) {
      writer.writeMessage(1, msg.block, forms.FormBlock._writeMessage);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpsertProjectFormBlockResponse,
    reader: BinaryReader
  ): UpsertProjectFormBlockResponse {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          reader.readMessage(msg.block, forms.FormBlock._readMessage);
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const DeleteProjectFormBlockRequest = {
  /**
   * Serializes DeleteProjectFormBlockRequest to protobuf.
   */
  encode: function (msg: Partial<DeleteProjectFormBlockRequest>): Uint8Array {
    return DeleteProjectFormBlockRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes DeleteProjectFormBlockRequest from protobuf.
   */
  decode: function (bytes: ByteSource): DeleteProjectFormBlockRequest {
    return DeleteProjectFormBlockRequest._readMessage(
      DeleteProjectFormBlockRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes DeleteProjectFormBlockRequest with all fields set to their default value.
   */
  initialize: function (): DeleteProjectFormBlockRequest {
    return {
      projectId: "",
      formId: "",
      blockId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<DeleteProjectFormBlockRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    if (msg.formId) {
      writer.writeString(2, msg.formId);
    }
    if (msg.blockId) {
      writer.writeString(3, msg.blockId);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: DeleteProjectFormBlockRequest,
    reader: BinaryReader
  ): DeleteProjectFormBlockRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        case 2: {
          msg.formId = reader.readString();
          break;
        }
        case 3: {
          msg.blockId = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const DeleteProjectFormBlockResponse = {
  /**
   * Serializes DeleteProjectFormBlockResponse to protobuf.
   */
  encode: function (
    _msg?: Partial<DeleteProjectFormBlockResponse>
  ): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes DeleteProjectFormBlockResponse from protobuf.
   */
  decode: function (_bytes?: ByteSource): DeleteProjectFormBlockResponse {
    return {};
  },

  /**
   * Initializes DeleteProjectFormBlockResponse with all fields set to their default value.
   */
  initialize: function (): DeleteProjectFormBlockResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<DeleteProjectFormBlockResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: DeleteProjectFormBlockResponse,
    _reader: BinaryReader
  ): DeleteProjectFormBlockResponse {
    return _msg;
  },
};

export const ProjectSchedule = {
  /**
   * Serializes ProjectSchedule to protobuf.
   */
  encode: function (msg: Partial<ProjectSchedule>): Uint8Array {
    return ProjectSchedule._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes ProjectSchedule from protobuf.
   */
  decode: function (bytes: ByteSource): ProjectSchedule {
    return ProjectSchedule._readMessage(
      ProjectSchedule.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes ProjectSchedule with all fields set to their default value.
   */
  initialize: function (): ProjectSchedule {
    return {
      id: "",
      at: undefined,
      done: undefined,
      repeat: undefined,
      projectId: "",
      limit: undefined,
      positionLt: undefined,
      ageLt: undefined,
      karmaGt: undefined,
      successScheduledCount: undefined,
      createdAt: 0,
      updatedAt: 0,
      canceled: false,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<ProjectSchedule>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.id) {
      writer.writeString(1, msg.id);
    }
    if (msg.at != undefined) {
      writer.writeInt32(2, msg.at);
    }
    if (msg.done != undefined) {
      writer.writeBool(3, msg.done);
    }
    if (msg.repeat != undefined) {
      writer.writeString(4, msg.repeat);
    }
    if (msg.projectId) {
      writer.writeString(5, msg.projectId);
    }
    if (msg.limit != undefined) {
      writer.writeInt32(6, msg.limit);
    }
    if (msg.positionLt != undefined) {
      writer.writeInt32(7, msg.positionLt);
    }
    if (msg.ageLt != undefined) {
      writer.writeString(8, msg.ageLt);
    }
    if (msg.karmaGt != undefined) {
      writer.writeInt32(9, msg.karmaGt);
    }
    if (msg.successScheduledCount != undefined) {
      writer.writeInt32(10, msg.successScheduledCount);
    }
    if (msg.createdAt) {
      writer.writeInt32(11, msg.createdAt);
    }
    if (msg.updatedAt) {
      writer.writeInt32(12, msg.updatedAt);
    }
    if (msg.canceled) {
      writer.writeBool(13, msg.canceled);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: ProjectSchedule,
    reader: BinaryReader
  ): ProjectSchedule {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.id = reader.readString();
          break;
        }
        case 2: {
          msg.at = reader.readInt32();
          break;
        }
        case 3: {
          msg.done = reader.readBool();
          break;
        }
        case 4: {
          msg.repeat = reader.readString();
          break;
        }
        case 5: {
          msg.projectId = reader.readString();
          break;
        }
        case 6: {
          msg.limit = reader.readInt32();
          break;
        }
        case 7: {
          msg.positionLt = reader.readInt32();
          break;
        }
        case 8: {
          msg.ageLt = reader.readString();
          break;
        }
        case 9: {
          msg.karmaGt = reader.readInt32();
          break;
        }
        case 10: {
          msg.successScheduledCount = reader.readInt32();
          break;
        }
        case 11: {
          msg.createdAt = reader.readInt32();
          break;
        }
        case 12: {
          msg.updatedAt = reader.readInt32();
          break;
        }
        case 13: {
          msg.canceled = reader.readBool();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const GetProjectSchedulesRequest = {
  /**
   * Serializes GetProjectSchedulesRequest to protobuf.
   */
  encode: function (msg: Partial<GetProjectSchedulesRequest>): Uint8Array {
    return GetProjectSchedulesRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetProjectSchedulesRequest from protobuf.
   */
  decode: function (bytes: ByteSource): GetProjectSchedulesRequest {
    return GetProjectSchedulesRequest._readMessage(
      GetProjectSchedulesRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetProjectSchedulesRequest with all fields set to their default value.
   */
  initialize: function (): GetProjectSchedulesRequest {
    return {
      projectId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectSchedulesRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectSchedulesRequest,
    reader: BinaryReader
  ): GetProjectSchedulesRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const GetProjectSchedulesResponse = {
  /**
   * Serializes GetProjectSchedulesResponse to protobuf.
   */
  encode: function (msg: Partial<GetProjectSchedulesResponse>): Uint8Array {
    return GetProjectSchedulesResponse._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetProjectSchedulesResponse from protobuf.
   */
  decode: function (bytes: ByteSource): GetProjectSchedulesResponse {
    return GetProjectSchedulesResponse._readMessage(
      GetProjectSchedulesResponse.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetProjectSchedulesResponse with all fields set to their default value.
   */
  initialize: function (): GetProjectSchedulesResponse {
    return {
      schedules: [],
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectSchedulesResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.schedules?.length) {
      writer.writeRepeatedMessage(
        1,
        msg.schedules as any,
        ProjectSchedule._writeMessage
      );
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectSchedulesResponse,
    reader: BinaryReader
  ): GetProjectSchedulesResponse {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          const m = ProjectSchedule.initialize();
          reader.readMessage(m, ProjectSchedule._readMessage);
          msg.schedules.push(m);
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const CreateProjectScheduleRequest = {
  /**
   * Serializes CreateProjectScheduleRequest to protobuf.
   */
  encode: function (msg: Partial<CreateProjectScheduleRequest>): Uint8Array {
    return CreateProjectScheduleRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes CreateProjectScheduleRequest from protobuf.
   */
  decode: function (bytes: ByteSource): CreateProjectScheduleRequest {
    return CreateProjectScheduleRequest._readMessage(
      CreateProjectScheduleRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes CreateProjectScheduleRequest with all fields set to their default value.
   */
  initialize: function (): CreateProjectScheduleRequest {
    return {
      projectId: "",
      at: undefined,
      repeat: undefined,
      positionLt: undefined,
      ageLt: undefined,
      karmaGt: undefined,
      limit: undefined,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<CreateProjectScheduleRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    if (msg.at != undefined) {
      writer.writeInt32(2, msg.at);
    }
    if (msg.repeat != undefined) {
      writer.writeString(3, msg.repeat);
    }
    if (msg.positionLt != undefined) {
      writer.writeInt32(4, msg.positionLt);
    }
    if (msg.ageLt != undefined) {
      writer.writeString(5, msg.ageLt);
    }
    if (msg.karmaGt != undefined) {
      writer.writeInt32(6, msg.karmaGt);
    }
    if (msg.limit != undefined) {
      writer.writeInt32(7, msg.limit);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: CreateProjectScheduleRequest,
    reader: BinaryReader
  ): CreateProjectScheduleRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        case 2: {
          msg.at = reader.readInt32();
          break;
        }
        case 3: {
          msg.repeat = reader.readString();
          break;
        }
        case 4: {
          msg.positionLt = reader.readInt32();
          break;
        }
        case 5: {
          msg.ageLt = reader.readString();
          break;
        }
        case 6: {
          msg.karmaGt = reader.readInt32();
          break;
        }
        case 7: {
          msg.limit = reader.readInt32();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const CreateProjectScheduleResponse = {
  /**
   * Serializes CreateProjectScheduleResponse to protobuf.
   */
  encode: function (_msg?: Partial<CreateProjectScheduleResponse>): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes CreateProjectScheduleResponse from protobuf.
   */
  decode: function (_bytes?: ByteSource): CreateProjectScheduleResponse {
    return {};
  },

  /**
   * Initializes CreateProjectScheduleResponse with all fields set to their default value.
   */
  initialize: function (): CreateProjectScheduleResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<CreateProjectScheduleResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: CreateProjectScheduleResponse,
    _reader: BinaryReader
  ): CreateProjectScheduleResponse {
    return _msg;
  },
};

export const HideProjectScheduleRequest = {
  /**
   * Serializes HideProjectScheduleRequest to protobuf.
   */
  encode: function (msg: Partial<HideProjectScheduleRequest>): Uint8Array {
    return HideProjectScheduleRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes HideProjectScheduleRequest from protobuf.
   */
  decode: function (bytes: ByteSource): HideProjectScheduleRequest {
    return HideProjectScheduleRequest._readMessage(
      HideProjectScheduleRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes HideProjectScheduleRequest with all fields set to their default value.
   */
  initialize: function (): HideProjectScheduleRequest {
    return {
      projectId: "",
      scheduleId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<HideProjectScheduleRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    if (msg.scheduleId) {
      writer.writeString(2, msg.scheduleId);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: HideProjectScheduleRequest,
    reader: BinaryReader
  ): HideProjectScheduleRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        case 2: {
          msg.scheduleId = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const HideProjectScheduleResponse = {
  /**
   * Serializes HideProjectScheduleResponse to protobuf.
   */
  encode: function (_msg?: Partial<HideProjectScheduleResponse>): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes HideProjectScheduleResponse from protobuf.
   */
  decode: function (_bytes?: ByteSource): HideProjectScheduleResponse {
    return {};
  },

  /**
   * Initializes HideProjectScheduleResponse with all fields set to their default value.
   */
  initialize: function (): HideProjectScheduleResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<HideProjectScheduleResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: HideProjectScheduleResponse,
    _reader: BinaryReader
  ): HideProjectScheduleResponse {
    return _msg;
  },
};

export const CancelProjectScheduleRequest = {
  /**
   * Serializes CancelProjectScheduleRequest to protobuf.
   */
  encode: function (msg: Partial<CancelProjectScheduleRequest>): Uint8Array {
    return CancelProjectScheduleRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes CancelProjectScheduleRequest from protobuf.
   */
  decode: function (bytes: ByteSource): CancelProjectScheduleRequest {
    return CancelProjectScheduleRequest._readMessage(
      CancelProjectScheduleRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes CancelProjectScheduleRequest with all fields set to their default value.
   */
  initialize: function (): CancelProjectScheduleRequest {
    return {
      projectId: "",
      scheduleId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<CancelProjectScheduleRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    if (msg.scheduleId) {
      writer.writeString(2, msg.scheduleId);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: CancelProjectScheduleRequest,
    reader: BinaryReader
  ): CancelProjectScheduleRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        case 2: {
          msg.scheduleId = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const CancelProjectScheduleResponse = {
  /**
   * Serializes CancelProjectScheduleResponse to protobuf.
   */
  encode: function (_msg?: Partial<CancelProjectScheduleResponse>): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes CancelProjectScheduleResponse from protobuf.
   */
  decode: function (_bytes?: ByteSource): CancelProjectScheduleResponse {
    return {};
  },

  /**
   * Initializes CancelProjectScheduleResponse with all fields set to their default value.
   */
  initialize: function (): CancelProjectScheduleResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<CancelProjectScheduleResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: CancelProjectScheduleResponse,
    _reader: BinaryReader
  ): CancelProjectScheduleResponse {
    return _msg;
  },
};

export const ResumeProjectScheduleRequest = {
  /**
   * Serializes ResumeProjectScheduleRequest to protobuf.
   */
  encode: function (msg: Partial<ResumeProjectScheduleRequest>): Uint8Array {
    return ResumeProjectScheduleRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes ResumeProjectScheduleRequest from protobuf.
   */
  decode: function (bytes: ByteSource): ResumeProjectScheduleRequest {
    return ResumeProjectScheduleRequest._readMessage(
      ResumeProjectScheduleRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes ResumeProjectScheduleRequest with all fields set to their default value.
   */
  initialize: function (): ResumeProjectScheduleRequest {
    return {
      projectId: "",
      scheduleId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<ResumeProjectScheduleRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    if (msg.scheduleId) {
      writer.writeString(2, msg.scheduleId);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: ResumeProjectScheduleRequest,
    reader: BinaryReader
  ): ResumeProjectScheduleRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        case 2: {
          msg.scheduleId = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const ResumeProjectScheduleResponse = {
  /**
   * Serializes ResumeProjectScheduleResponse to protobuf.
   */
  encode: function (_msg?: Partial<ResumeProjectScheduleResponse>): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes ResumeProjectScheduleResponse from protobuf.
   */
  decode: function (_bytes?: ByteSource): ResumeProjectScheduleResponse {
    return {};
  },

  /**
   * Initializes ResumeProjectScheduleResponse with all fields set to their default value.
   */
  initialize: function (): ResumeProjectScheduleResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<ResumeProjectScheduleResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: ResumeProjectScheduleResponse,
    _reader: BinaryReader
  ): ResumeProjectScheduleResponse {
    return _msg;
  },
};

export const ReplayProjectScheduleRequest = {
  /**
   * Serializes ReplayProjectScheduleRequest to protobuf.
   */
  encode: function (msg: Partial<ReplayProjectScheduleRequest>): Uint8Array {
    return ReplayProjectScheduleRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes ReplayProjectScheduleRequest from protobuf.
   */
  decode: function (bytes: ByteSource): ReplayProjectScheduleRequest {
    return ReplayProjectScheduleRequest._readMessage(
      ReplayProjectScheduleRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes ReplayProjectScheduleRequest with all fields set to their default value.
   */
  initialize: function (): ReplayProjectScheduleRequest {
    return {
      projectId: "",
      scheduleId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<ReplayProjectScheduleRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    if (msg.scheduleId) {
      writer.writeString(2, msg.scheduleId);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: ReplayProjectScheduleRequest,
    reader: BinaryReader
  ): ReplayProjectScheduleRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        case 2: {
          msg.scheduleId = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const ReplayProjectScheduleResponse = {
  /**
   * Serializes ReplayProjectScheduleResponse to protobuf.
   */
  encode: function (_msg?: Partial<ReplayProjectScheduleResponse>): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes ReplayProjectScheduleResponse from protobuf.
   */
  decode: function (_bytes?: ByteSource): ReplayProjectScheduleResponse {
    return {};
  },

  /**
   * Initializes ReplayProjectScheduleResponse with all fields set to their default value.
   */
  initialize: function (): ReplayProjectScheduleResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<ReplayProjectScheduleResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: ReplayProjectScheduleResponse,
    _reader: BinaryReader
  ): ReplayProjectScheduleResponse {
    return _msg;
  },
};

export const UpdateProjectHandleRequest = {
  /**
   * Serializes UpdateProjectHandleRequest to protobuf.
   */
  encode: function (msg: Partial<UpdateProjectHandleRequest>): Uint8Array {
    return UpdateProjectHandleRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes UpdateProjectHandleRequest from protobuf.
   */
  decode: function (bytes: ByteSource): UpdateProjectHandleRequest {
    return UpdateProjectHandleRequest._readMessage(
      UpdateProjectHandleRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes UpdateProjectHandleRequest with all fields set to their default value.
   */
  initialize: function (): UpdateProjectHandleRequest {
    return {
      projectId: "",
      newHandle: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<UpdateProjectHandleRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    if (msg.newHandle) {
      writer.writeString(2, msg.newHandle);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpdateProjectHandleRequest,
    reader: BinaryReader
  ): UpdateProjectHandleRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        case 2: {
          msg.newHandle = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const UpdateProjectHandleResponse = {
  /**
   * Serializes UpdateProjectHandleResponse to protobuf.
   */
  encode: function (_msg?: Partial<UpdateProjectHandleResponse>): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes UpdateProjectHandleResponse from protobuf.
   */
  decode: function (_bytes?: ByteSource): UpdateProjectHandleResponse {
    return {};
  },

  /**
   * Initializes UpdateProjectHandleResponse with all fields set to their default value.
   */
  initialize: function (): UpdateProjectHandleResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<UpdateProjectHandleResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: UpdateProjectHandleResponse,
    _reader: BinaryReader
  ): UpdateProjectHandleResponse {
    return _msg;
  },
};

export const UpdateProjectEmailReplyToRequest = {
  /**
   * Serializes UpdateProjectEmailReplyToRequest to protobuf.
   */
  encode: function (
    msg: Partial<UpdateProjectEmailReplyToRequest>
  ): Uint8Array {
    return UpdateProjectEmailReplyToRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes UpdateProjectEmailReplyToRequest from protobuf.
   */
  decode: function (bytes: ByteSource): UpdateProjectEmailReplyToRequest {
    return UpdateProjectEmailReplyToRequest._readMessage(
      UpdateProjectEmailReplyToRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes UpdateProjectEmailReplyToRequest with all fields set to their default value.
   */
  initialize: function (): UpdateProjectEmailReplyToRequest {
    return {
      projectId: "",
      newEmailReplyTo: undefined,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<UpdateProjectEmailReplyToRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    if (msg.newEmailReplyTo != undefined) {
      writer.writeString(2, msg.newEmailReplyTo);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpdateProjectEmailReplyToRequest,
    reader: BinaryReader
  ): UpdateProjectEmailReplyToRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        case 2: {
          msg.newEmailReplyTo = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const UpdateProjectEmailReplyToResponse = {
  /**
   * Serializes UpdateProjectEmailReplyToResponse to protobuf.
   */
  encode: function (
    _msg?: Partial<UpdateProjectEmailReplyToResponse>
  ): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes UpdateProjectEmailReplyToResponse from protobuf.
   */
  decode: function (_bytes?: ByteSource): UpdateProjectEmailReplyToResponse {
    return {};
  },

  /**
   * Initializes UpdateProjectEmailReplyToResponse with all fields set to their default value.
   */
  initialize: function (): UpdateProjectEmailReplyToResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<UpdateProjectEmailReplyToResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: UpdateProjectEmailReplyToResponse,
    _reader: BinaryReader
  ): UpdateProjectEmailReplyToResponse {
    return _msg;
  },
};

export const UpdateProjectNameRequest = {
  /**
   * Serializes UpdateProjectNameRequest to protobuf.
   */
  encode: function (msg: Partial<UpdateProjectNameRequest>): Uint8Array {
    return UpdateProjectNameRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes UpdateProjectNameRequest from protobuf.
   */
  decode: function (bytes: ByteSource): UpdateProjectNameRequest {
    return UpdateProjectNameRequest._readMessage(
      UpdateProjectNameRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes UpdateProjectNameRequest with all fields set to their default value.
   */
  initialize: function (): UpdateProjectNameRequest {
    return {
      projectId: "",
      newName: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<UpdateProjectNameRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    if (msg.newName) {
      writer.writeString(2, msg.newName);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpdateProjectNameRequest,
    reader: BinaryReader
  ): UpdateProjectNameRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        case 2: {
          msg.newName = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const UpdateProjectNameResponse = {
  /**
   * Serializes UpdateProjectNameResponse to protobuf.
   */
  encode: function (_msg?: Partial<UpdateProjectNameResponse>): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes UpdateProjectNameResponse from protobuf.
   */
  decode: function (_bytes?: ByteSource): UpdateProjectNameResponse {
    return {};
  },

  /**
   * Initializes UpdateProjectNameResponse with all fields set to their default value.
   */
  initialize: function (): UpdateProjectNameResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<UpdateProjectNameResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: UpdateProjectNameResponse,
    _reader: BinaryReader
  ): UpdateProjectNameResponse {
    return _msg;
  },
};

export const FormBlockContentWithBlockID = {
  /**
   * Serializes FormBlockContentWithBlockID to protobuf.
   */
  encode: function (msg: Partial<FormBlockContentWithBlockID>): Uint8Array {
    return FormBlockContentWithBlockID._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes FormBlockContentWithBlockID from protobuf.
   */
  decode: function (bytes: ByteSource): FormBlockContentWithBlockID {
    return FormBlockContentWithBlockID._readMessage(
      FormBlockContentWithBlockID.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes FormBlockContentWithBlockID with all fields set to their default value.
   */
  initialize: function (): FormBlockContentWithBlockID {
    return {
      id: "",
      content: forms.FormBlockContent.initialize(),
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<FormBlockContentWithBlockID>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.id) {
      writer.writeString(1, msg.id);
    }
    if (msg.content) {
      writer.writeMessage(2, msg.content, forms.FormBlockContent._writeMessage);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: FormBlockContentWithBlockID,
    reader: BinaryReader
  ): FormBlockContentWithBlockID {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.id = reader.readString();
          break;
        }
        case 2: {
          reader.readMessage(msg.content, forms.FormBlockContent._readMessage);
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const ProjectSignupDetails = {
  /**
   * Serializes ProjectSignupDetails to protobuf.
   */
  encode: function (msg: Partial<ProjectSignupDetails>): Uint8Array {
    return ProjectSignupDetails._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes ProjectSignupDetails from protobuf.
   */
  decode: function (bytes: ByteSource): ProjectSignupDetails {
    return ProjectSignupDetails._readMessage(
      ProjectSignupDetails.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes ProjectSignupDetails with all fields set to their default value.
   */
  initialize: function (): ProjectSignupDetails {
    return {
      signup: ProjectSignup.initialize(),
      project: Project.initialize(),
      answers: [],
      blocks: [],
      referrals: [],
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<ProjectSignupDetails>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.signup) {
      writer.writeMessage(1, msg.signup, ProjectSignup._writeMessage);
    }
    if (msg.project) {
      writer.writeMessage(2, msg.project, Project._writeMessage);
    }
    if (msg.answers?.length) {
      writer.writeRepeatedMessage(
        3,
        msg.answers as any,
        forms.Answer._writeMessage
      );
    }
    if (msg.blocks?.length) {
      writer.writeRepeatedMessage(
        4,
        msg.blocks as any,
        FormBlockContentWithBlockID._writeMessage
      );
    }
    if (msg.referrals?.length) {
      writer.writeRepeatedMessage(
        5,
        msg.referrals as any,
        ProjectSignup._writeMessage
      );
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: ProjectSignupDetails,
    reader: BinaryReader
  ): ProjectSignupDetails {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          reader.readMessage(msg.signup, ProjectSignup._readMessage);
          break;
        }
        case 2: {
          reader.readMessage(msg.project, Project._readMessage);
          break;
        }
        case 3: {
          const m = forms.Answer.initialize();
          reader.readMessage(m, forms.Answer._readMessage);
          msg.answers.push(m);
          break;
        }
        case 4: {
          const m = FormBlockContentWithBlockID.initialize();
          reader.readMessage(m, FormBlockContentWithBlockID._readMessage);
          msg.blocks.push(m);
          break;
        }
        case 5: {
          const m = ProjectSignup.initialize();
          reader.readMessage(m, ProjectSignup._readMessage);
          msg.referrals.push(m);
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const GetProjectSignupDetailsRequest = {
  /**
   * Serializes GetProjectSignupDetailsRequest to protobuf.
   */
  encode: function (msg: Partial<GetProjectSignupDetailsRequest>): Uint8Array {
    return GetProjectSignupDetailsRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetProjectSignupDetailsRequest from protobuf.
   */
  decode: function (bytes: ByteSource): GetProjectSignupDetailsRequest {
    return GetProjectSignupDetailsRequest._readMessage(
      GetProjectSignupDetailsRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetProjectSignupDetailsRequest with all fields set to their default value.
   */
  initialize: function (): GetProjectSignupDetailsRequest {
    return {
      projectId: "",
      signupId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectSignupDetailsRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    if (msg.signupId) {
      writer.writeString(2, msg.signupId);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectSignupDetailsRequest,
    reader: BinaryReader
  ): GetProjectSignupDetailsRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        case 2: {
          msg.signupId = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const GetProjectSignupDetailsResponse = {
  /**
   * Serializes GetProjectSignupDetailsResponse to protobuf.
   */
  encode: function (msg: Partial<GetProjectSignupDetailsResponse>): Uint8Array {
    return GetProjectSignupDetailsResponse._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetProjectSignupDetailsResponse from protobuf.
   */
  decode: function (bytes: ByteSource): GetProjectSignupDetailsResponse {
    return GetProjectSignupDetailsResponse._readMessage(
      GetProjectSignupDetailsResponse.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetProjectSignupDetailsResponse with all fields set to their default value.
   */
  initialize: function (): GetProjectSignupDetailsResponse {
    return {
      details: ProjectSignupDetails.initialize(),
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectSignupDetailsResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.details) {
      writer.writeMessage(1, msg.details, ProjectSignupDetails._writeMessage);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectSignupDetailsResponse,
    reader: BinaryReader
  ): GetProjectSignupDetailsResponse {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          reader.readMessage(msg.details, ProjectSignupDetails._readMessage);
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const GetSignupPositionRequest = {
  /**
   * Serializes GetSignupPositionRequest to protobuf.
   */
  encode: function (msg: Partial<GetSignupPositionRequest>): Uint8Array {
    return GetSignupPositionRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetSignupPositionRequest from protobuf.
   */
  decode: function (bytes: ByteSource): GetSignupPositionRequest {
    return GetSignupPositionRequest._readMessage(
      GetSignupPositionRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetSignupPositionRequest with all fields set to their default value.
   */
  initialize: function (): GetSignupPositionRequest {
    return {
      signupCode: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetSignupPositionRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.signupCode) {
      writer.writeString(1, msg.signupCode);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetSignupPositionRequest,
    reader: BinaryReader
  ): GetSignupPositionRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.signupCode = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const GetSignupPositionResponse = {
  /**
   * Serializes GetSignupPositionResponse to protobuf.
   */
  encode: function (msg: Partial<GetSignupPositionResponse>): Uint8Array {
    return GetSignupPositionResponse._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetSignupPositionResponse from protobuf.
   */
  decode: function (bytes: ByteSource): GetSignupPositionResponse {
    return GetSignupPositionResponse._readMessage(
      GetSignupPositionResponse.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetSignupPositionResponse with all fields set to their default value.
   */
  initialize: function (): GetSignupPositionResponse {
    return {
      projectName: "",
      position: 0n,
      referrals: 0,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetSignupPositionResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectName) {
      writer.writeString(1, msg.projectName);
    }
    if (msg.position) {
      writer.writeInt64String(2, msg.position.toString() as any);
    }
    if (msg.referrals) {
      writer.writeInt32(3, msg.referrals);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetSignupPositionResponse,
    reader: BinaryReader
  ): GetSignupPositionResponse {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectName = reader.readString();
          break;
        }
        case 2: {
          msg.position = BigInt(reader.readInt64String());
          break;
        }
        case 3: {
          msg.referrals = reader.readInt32();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const AddProjectDomainRequest = {
  /**
   * Serializes AddProjectDomainRequest to protobuf.
   */
  encode: function (msg: Partial<AddProjectDomainRequest>): Uint8Array {
    return AddProjectDomainRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes AddProjectDomainRequest from protobuf.
   */
  decode: function (bytes: ByteSource): AddProjectDomainRequest {
    return AddProjectDomainRequest._readMessage(
      AddProjectDomainRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes AddProjectDomainRequest with all fields set to their default value.
   */
  initialize: function (): AddProjectDomainRequest {
    return {
      projectId: "",
      domainName: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<AddProjectDomainRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    if (msg.domainName) {
      writer.writeString(2, msg.domainName);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: AddProjectDomainRequest,
    reader: BinaryReader
  ): AddProjectDomainRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        case 2: {
          msg.domainName = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const AddProjectDomainResponse = {
  /**
   * Serializes AddProjectDomainResponse to protobuf.
   */
  encode: function (_msg?: Partial<AddProjectDomainResponse>): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes AddProjectDomainResponse from protobuf.
   */
  decode: function (_bytes?: ByteSource): AddProjectDomainResponse {
    return {};
  },

  /**
   * Initializes AddProjectDomainResponse with all fields set to their default value.
   */
  initialize: function (): AddProjectDomainResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<AddProjectDomainResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: AddProjectDomainResponse,
    _reader: BinaryReader
  ): AddProjectDomainResponse {
    return _msg;
  },
};

export const RemoveProjectDomainRequest = {
  /**
   * Serializes RemoveProjectDomainRequest to protobuf.
   */
  encode: function (msg: Partial<RemoveProjectDomainRequest>): Uint8Array {
    return RemoveProjectDomainRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes RemoveProjectDomainRequest from protobuf.
   */
  decode: function (bytes: ByteSource): RemoveProjectDomainRequest {
    return RemoveProjectDomainRequest._readMessage(
      RemoveProjectDomainRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes RemoveProjectDomainRequest with all fields set to their default value.
   */
  initialize: function (): RemoveProjectDomainRequest {
    return {
      projectId: "",
      domainId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<RemoveProjectDomainRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    if (msg.domainId) {
      writer.writeString(2, msg.domainId);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: RemoveProjectDomainRequest,
    reader: BinaryReader
  ): RemoveProjectDomainRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        case 2: {
          msg.domainId = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const RemoveProjectDomainResponse = {
  /**
   * Serializes RemoveProjectDomainResponse to protobuf.
   */
  encode: function (_msg?: Partial<RemoveProjectDomainResponse>): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes RemoveProjectDomainResponse from protobuf.
   */
  decode: function (_bytes?: ByteSource): RemoveProjectDomainResponse {
    return {};
  },

  /**
   * Initializes RemoveProjectDomainResponse with all fields set to their default value.
   */
  initialize: function (): RemoveProjectDomainResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<RemoveProjectDomainResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: RemoveProjectDomainResponse,
    _reader: BinaryReader
  ): RemoveProjectDomainResponse {
    return _msg;
  },
};

export const GetProjectDomainRequest = {
  /**
   * Serializes GetProjectDomainRequest to protobuf.
   */
  encode: function (msg: Partial<GetProjectDomainRequest>): Uint8Array {
    return GetProjectDomainRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetProjectDomainRequest from protobuf.
   */
  decode: function (bytes: ByteSource): GetProjectDomainRequest {
    return GetProjectDomainRequest._readMessage(
      GetProjectDomainRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetProjectDomainRequest with all fields set to their default value.
   */
  initialize: function (): GetProjectDomainRequest {
    return {
      projectId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectDomainRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectDomainRequest,
    reader: BinaryReader
  ): GetProjectDomainRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const Domain = {
  /**
   * Serializes Domain to protobuf.
   */
  encode: function (msg: Partial<Domain>): Uint8Array {
    return Domain._writeMessage(msg, new BinaryWriter()).getResultBuffer();
  },

  /**
   * Deserializes Domain from protobuf.
   */
  decode: function (bytes: ByteSource): Domain {
    return Domain._readMessage(Domain.initialize(), new BinaryReader(bytes));
  },

  /**
   * Initializes Domain with all fields set to their default value.
   */
  initialize: function (): Domain {
    return {
      id: "",
      domainName: "",
      verified: false,
      verificationCode: "",
      lastCheckedAt: 0,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<Domain>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.id) {
      writer.writeString(1, msg.id);
    }
    if (msg.domainName) {
      writer.writeString(2, msg.domainName);
    }
    if (msg.verified) {
      writer.writeBool(3, msg.verified);
    }
    if (msg.verificationCode) {
      writer.writeString(4, msg.verificationCode);
    }
    if (msg.lastCheckedAt) {
      writer.writeInt32(5, msg.lastCheckedAt);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (msg: Domain, reader: BinaryReader): Domain {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.id = reader.readString();
          break;
        }
        case 2: {
          msg.domainName = reader.readString();
          break;
        }
        case 3: {
          msg.verified = reader.readBool();
          break;
        }
        case 4: {
          msg.verificationCode = reader.readString();
          break;
        }
        case 5: {
          msg.lastCheckedAt = reader.readInt32();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const GetProjectDomainResponse = {
  /**
   * Serializes GetProjectDomainResponse to protobuf.
   */
  encode: function (msg: Partial<GetProjectDomainResponse>): Uint8Array {
    return GetProjectDomainResponse._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetProjectDomainResponse from protobuf.
   */
  decode: function (bytes: ByteSource): GetProjectDomainResponse {
    return GetProjectDomainResponse._readMessage(
      GetProjectDomainResponse.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetProjectDomainResponse with all fields set to their default value.
   */
  initialize: function (): GetProjectDomainResponse {
    return {
      domain: undefined,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectDomainResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.domain != undefined) {
      writer.writeMessage(1, msg.domain, Domain._writeMessage);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectDomainResponse,
    reader: BinaryReader
  ): GetProjectDomainResponse {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.domain = Domain.initialize();
          reader.readMessage(msg.domain, Domain._readMessage);
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const CheckProjectDomainVerifiedRequest = {
  /**
   * Serializes CheckProjectDomainVerifiedRequest to protobuf.
   */
  encode: function (
    msg: Partial<CheckProjectDomainVerifiedRequest>
  ): Uint8Array {
    return CheckProjectDomainVerifiedRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes CheckProjectDomainVerifiedRequest from protobuf.
   */
  decode: function (bytes: ByteSource): CheckProjectDomainVerifiedRequest {
    return CheckProjectDomainVerifiedRequest._readMessage(
      CheckProjectDomainVerifiedRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes CheckProjectDomainVerifiedRequest with all fields set to their default value.
   */
  initialize: function (): CheckProjectDomainVerifiedRequest {
    return {
      domainId: "",
      projectId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<CheckProjectDomainVerifiedRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.domainId) {
      writer.writeString(1, msg.domainId);
    }
    if (msg.projectId) {
      writer.writeString(2, msg.projectId);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: CheckProjectDomainVerifiedRequest,
    reader: BinaryReader
  ): CheckProjectDomainVerifiedRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.domainId = reader.readString();
          break;
        }
        case 2: {
          msg.projectId = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const CheckProjectDomainVerifiedResponse = {
  /**
   * Serializes CheckProjectDomainVerifiedResponse to protobuf.
   */
  encode: function (
    msg: Partial<CheckProjectDomainVerifiedResponse>
  ): Uint8Array {
    return CheckProjectDomainVerifiedResponse._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes CheckProjectDomainVerifiedResponse from protobuf.
   */
  decode: function (bytes: ByteSource): CheckProjectDomainVerifiedResponse {
    return CheckProjectDomainVerifiedResponse._readMessage(
      CheckProjectDomainVerifiedResponse.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes CheckProjectDomainVerifiedResponse with all fields set to their default value.
   */
  initialize: function (): CheckProjectDomainVerifiedResponse {
    return {
      verified: false,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<CheckProjectDomainVerifiedResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.verified) {
      writer.writeBool(1, msg.verified);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: CheckProjectDomainVerifiedResponse,
    reader: BinaryReader
  ): CheckProjectDomainVerifiedResponse {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.verified = reader.readBool();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const EmailTemplate = {
  /**
   * Serializes EmailTemplate to protobuf.
   */
  encode: function (msg: Partial<EmailTemplate>): Uint8Array {
    return EmailTemplate._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes EmailTemplate from protobuf.
   */
  decode: function (bytes: ByteSource): EmailTemplate {
    return EmailTemplate._readMessage(
      EmailTemplate.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes EmailTemplate with all fields set to their default value.
   */
  initialize: function (): EmailTemplate {
    return {
      id: "",
      type: "",
      content: "",
      default: false,
      variables: {},
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<EmailTemplate>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.id) {
      writer.writeString(1, msg.id);
    }
    if (msg.type) {
      writer.writeString(2, msg.type);
    }
    if (msg.content) {
      writer.writeString(3, msg.content);
    }
    if (msg.default) {
      writer.writeBool(4, msg.default);
    }
    if (msg.variables) {
      writer.writeRepeatedMessage(
        5,
        Object.entries(msg.variables).map(([key, value]) => ({
          key: key as any,
          value: value as any,
        })) as any,
        EmailTemplate.Variables._writeMessage
      );
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: EmailTemplate,
    reader: BinaryReader
  ): EmailTemplate {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.id = reader.readString();
          break;
        }
        case 2: {
          msg.type = reader.readString();
          break;
        }
        case 3: {
          msg.content = reader.readString();
          break;
        }
        case 4: {
          msg.default = reader.readBool();
          break;
        }
        case 5: {
          const map = {} as EmailTemplate.Variables;
          reader.readMessage(map, EmailTemplate.Variables._readMessage);
          msg.variables[map.key.toString()] = map.value;
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },

  Variables: {
    /**
     * @private
     */
    _writeMessage: function (
      msg: Partial<EmailTemplate.Variables>,
      writer: BinaryWriter
    ): BinaryWriter {
      if (msg.key) {
        writer.writeString(1, msg.key);
      }
      if (msg.value) {
        writer.writeString(2, msg.value);
      }
      return writer;
    },

    /**
     * @private
     */
    _readMessage: function (
      msg: EmailTemplate.Variables,
      reader: BinaryReader
    ): EmailTemplate.Variables {
      while (reader.nextField()) {
        const field = reader.getFieldNumber();
        switch (field) {
          case 1: {
            msg.key = reader.readString();
            break;
          }
          case 2: {
            msg.value = reader.readString();
            break;
          }
          default: {
            reader.skipField();
            break;
          }
        }
      }
      return msg;
    },
  },
};

export const GetProjectEmailTemplatesRequest = {
  /**
   * Serializes GetProjectEmailTemplatesRequest to protobuf.
   */
  encode: function (msg: Partial<GetProjectEmailTemplatesRequest>): Uint8Array {
    return GetProjectEmailTemplatesRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetProjectEmailTemplatesRequest from protobuf.
   */
  decode: function (bytes: ByteSource): GetProjectEmailTemplatesRequest {
    return GetProjectEmailTemplatesRequest._readMessage(
      GetProjectEmailTemplatesRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetProjectEmailTemplatesRequest with all fields set to their default value.
   */
  initialize: function (): GetProjectEmailTemplatesRequest {
    return {
      projectId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectEmailTemplatesRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectEmailTemplatesRequest,
    reader: BinaryReader
  ): GetProjectEmailTemplatesRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const GetProjectEmailTemplatesResponse = {
  /**
   * Serializes GetProjectEmailTemplatesResponse to protobuf.
   */
  encode: function (
    msg: Partial<GetProjectEmailTemplatesResponse>
  ): Uint8Array {
    return GetProjectEmailTemplatesResponse._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetProjectEmailTemplatesResponse from protobuf.
   */
  decode: function (bytes: ByteSource): GetProjectEmailTemplatesResponse {
    return GetProjectEmailTemplatesResponse._readMessage(
      GetProjectEmailTemplatesResponse.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetProjectEmailTemplatesResponse with all fields set to their default value.
   */
  initialize: function (): GetProjectEmailTemplatesResponse {
    return {
      templates: [],
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectEmailTemplatesResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.templates?.length) {
      writer.writeRepeatedMessage(
        1,
        msg.templates as any,
        EmailTemplate._writeMessage
      );
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectEmailTemplatesResponse,
    reader: BinaryReader
  ): GetProjectEmailTemplatesResponse {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          const m = EmailTemplate.initialize();
          reader.readMessage(m, EmailTemplate._readMessage);
          msg.templates.push(m);
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const UpsertProjectEmailTemplateRequest = {
  /**
   * Serializes UpsertProjectEmailTemplateRequest to protobuf.
   */
  encode: function (
    msg: Partial<UpsertProjectEmailTemplateRequest>
  ): Uint8Array {
    return UpsertProjectEmailTemplateRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes UpsertProjectEmailTemplateRequest from protobuf.
   */
  decode: function (bytes: ByteSource): UpsertProjectEmailTemplateRequest {
    return UpsertProjectEmailTemplateRequest._readMessage(
      UpsertProjectEmailTemplateRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes UpsertProjectEmailTemplateRequest with all fields set to their default value.
   */
  initialize: function (): UpsertProjectEmailTemplateRequest {
    return {
      projectId: "",
      templateId: "",
      content: "",
      type: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<UpsertProjectEmailTemplateRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    if (msg.templateId) {
      writer.writeString(2, msg.templateId);
    }
    if (msg.content) {
      writer.writeString(3, msg.content);
    }
    if (msg.type) {
      writer.writeString(4, msg.type);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpsertProjectEmailTemplateRequest,
    reader: BinaryReader
  ): UpsertProjectEmailTemplateRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        case 2: {
          msg.templateId = reader.readString();
          break;
        }
        case 3: {
          msg.content = reader.readString();
          break;
        }
        case 4: {
          msg.type = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const UpsertProjectEmailTemplateResponse = {
  /**
   * Serializes UpsertProjectEmailTemplateResponse to protobuf.
   */
  encode: function (
    _msg?: Partial<UpsertProjectEmailTemplateResponse>
  ): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes UpsertProjectEmailTemplateResponse from protobuf.
   */
  decode: function (_bytes?: ByteSource): UpsertProjectEmailTemplateResponse {
    return {};
  },

  /**
   * Initializes UpsertProjectEmailTemplateResponse with all fields set to their default value.
   */
  initialize: function (): UpsertProjectEmailTemplateResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<UpsertProjectEmailTemplateResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: UpsertProjectEmailTemplateResponse,
    _reader: BinaryReader
  ): UpsertProjectEmailTemplateResponse {
    return _msg;
  },
};

export const GetProjectWebhooksRequest = {
  /**
   * Serializes GetProjectWebhooksRequest to protobuf.
   */
  encode: function (msg: Partial<GetProjectWebhooksRequest>): Uint8Array {
    return GetProjectWebhooksRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetProjectWebhooksRequest from protobuf.
   */
  decode: function (bytes: ByteSource): GetProjectWebhooksRequest {
    return GetProjectWebhooksRequest._readMessage(
      GetProjectWebhooksRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetProjectWebhooksRequest with all fields set to their default value.
   */
  initialize: function (): GetProjectWebhooksRequest {
    return {
      projectId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectWebhooksRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectWebhooksRequest,
    reader: BinaryReader
  ): GetProjectWebhooksRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const GetProjectWebhooksResponse = {
  /**
   * Serializes GetProjectWebhooksResponse to protobuf.
   */
  encode: function (msg: Partial<GetProjectWebhooksResponse>): Uint8Array {
    return GetProjectWebhooksResponse._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetProjectWebhooksResponse from protobuf.
   */
  decode: function (bytes: ByteSource): GetProjectWebhooksResponse {
    return GetProjectWebhooksResponse._readMessage(
      GetProjectWebhooksResponse.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetProjectWebhooksResponse with all fields set to their default value.
   */
  initialize: function (): GetProjectWebhooksResponse {
    return {
      webhookInviteAccepted: undefined,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectWebhooksResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.webhookInviteAccepted != undefined) {
      writer.writeString(1, msg.webhookInviteAccepted);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectWebhooksResponse,
    reader: BinaryReader
  ): GetProjectWebhooksResponse {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.webhookInviteAccepted = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const UpsertProjectWebhookRequest = {
  /**
   * Serializes UpsertProjectWebhookRequest to protobuf.
   */
  encode: function (msg: Partial<UpsertProjectWebhookRequest>): Uint8Array {
    return UpsertProjectWebhookRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes UpsertProjectWebhookRequest from protobuf.
   */
  decode: function (bytes: ByteSource): UpsertProjectWebhookRequest {
    return UpsertProjectWebhookRequest._readMessage(
      UpsertProjectWebhookRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes UpsertProjectWebhookRequest with all fields set to their default value.
   */
  initialize: function (): UpsertProjectWebhookRequest {
    return {
      projectId: "",
      event: "",
      url: undefined,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<UpsertProjectWebhookRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    if (msg.event) {
      writer.writeString(2, msg.event);
    }
    if (msg.url != undefined) {
      writer.writeString(3, msg.url);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpsertProjectWebhookRequest,
    reader: BinaryReader
  ): UpsertProjectWebhookRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        case 2: {
          msg.event = reader.readString();
          break;
        }
        case 3: {
          msg.url = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const UpsertProjectWebhookResponse = {
  /**
   * Serializes UpsertProjectWebhookResponse to protobuf.
   */
  encode: function (_msg?: Partial<UpsertProjectWebhookResponse>): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes UpsertProjectWebhookResponse from protobuf.
   */
  decode: function (_bytes?: ByteSource): UpsertProjectWebhookResponse {
    return {};
  },

  /**
   * Initializes UpsertProjectWebhookResponse with all fields set to their default value.
   */
  initialize: function (): UpsertProjectWebhookResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<UpsertProjectWebhookResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: UpsertProjectWebhookResponse,
    _reader: BinaryReader
  ): UpsertProjectWebhookResponse {
    return _msg;
  },
};

export const GetAPIKeysRequest = {
  /**
   * Serializes GetAPIKeysRequest to protobuf.
   */
  encode: function (msg: Partial<GetAPIKeysRequest>): Uint8Array {
    return GetAPIKeysRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetAPIKeysRequest from protobuf.
   */
  decode: function (bytes: ByteSource): GetAPIKeysRequest {
    return GetAPIKeysRequest._readMessage(
      GetAPIKeysRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetAPIKeysRequest with all fields set to their default value.
   */
  initialize: function (): GetAPIKeysRequest {
    return {
      projectId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetAPIKeysRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetAPIKeysRequest,
    reader: BinaryReader
  ): GetAPIKeysRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const APIKey = {
  /**
   * Serializes APIKey to protobuf.
   */
  encode: function (msg: Partial<APIKey>): Uint8Array {
    return APIKey._writeMessage(msg, new BinaryWriter()).getResultBuffer();
  },

  /**
   * Deserializes APIKey from protobuf.
   */
  decode: function (bytes: ByteSource): APIKey {
    return APIKey._readMessage(APIKey.initialize(), new BinaryReader(bytes));
  },

  /**
   * Initializes APIKey with all fields set to their default value.
   */
  initialize: function (): APIKey {
    return {
      id: "",
      key: "",
      createdAt: 0,
      lastUsed: undefined,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<APIKey>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.id) {
      writer.writeString(1, msg.id);
    }
    if (msg.key) {
      writer.writeString(2, msg.key);
    }
    if (msg.createdAt) {
      writer.writeInt32(3, msg.createdAt);
    }
    if (msg.lastUsed != undefined) {
      writer.writeInt32(4, msg.lastUsed);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (msg: APIKey, reader: BinaryReader): APIKey {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.id = reader.readString();
          break;
        }
        case 2: {
          msg.key = reader.readString();
          break;
        }
        case 3: {
          msg.createdAt = reader.readInt32();
          break;
        }
        case 4: {
          msg.lastUsed = reader.readInt32();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const GetAPIKeysResponse = {
  /**
   * Serializes GetAPIKeysResponse to protobuf.
   */
  encode: function (msg: Partial<GetAPIKeysResponse>): Uint8Array {
    return GetAPIKeysResponse._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetAPIKeysResponse from protobuf.
   */
  decode: function (bytes: ByteSource): GetAPIKeysResponse {
    return GetAPIKeysResponse._readMessage(
      GetAPIKeysResponse.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetAPIKeysResponse with all fields set to their default value.
   */
  initialize: function (): GetAPIKeysResponse {
    return {
      keys: [],
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetAPIKeysResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.keys?.length) {
      writer.writeRepeatedMessage(1, msg.keys as any, APIKey._writeMessage);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetAPIKeysResponse,
    reader: BinaryReader
  ): GetAPIKeysResponse {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          const m = APIKey.initialize();
          reader.readMessage(m, APIKey._readMessage);
          msg.keys.push(m);
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const CreateAPIKeyRequest = {
  /**
   * Serializes CreateAPIKeyRequest to protobuf.
   */
  encode: function (msg: Partial<CreateAPIKeyRequest>): Uint8Array {
    return CreateAPIKeyRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes CreateAPIKeyRequest from protobuf.
   */
  decode: function (bytes: ByteSource): CreateAPIKeyRequest {
    return CreateAPIKeyRequest._readMessage(
      CreateAPIKeyRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes CreateAPIKeyRequest with all fields set to their default value.
   */
  initialize: function (): CreateAPIKeyRequest {
    return {
      projectId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<CreateAPIKeyRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: CreateAPIKeyRequest,
    reader: BinaryReader
  ): CreateAPIKeyRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const CreateAPIKeyResponse = {
  /**
   * Serializes CreateAPIKeyResponse to protobuf.
   */
  encode: function (_msg?: Partial<CreateAPIKeyResponse>): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes CreateAPIKeyResponse from protobuf.
   */
  decode: function (_bytes?: ByteSource): CreateAPIKeyResponse {
    return {};
  },

  /**
   * Initializes CreateAPIKeyResponse with all fields set to their default value.
   */
  initialize: function (): CreateAPIKeyResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<CreateAPIKeyResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: CreateAPIKeyResponse,
    _reader: BinaryReader
  ): CreateAPIKeyResponse {
    return _msg;
  },
};

export const DeleteAPIKeyRequest = {
  /**
   * Serializes DeleteAPIKeyRequest to protobuf.
   */
  encode: function (msg: Partial<DeleteAPIKeyRequest>): Uint8Array {
    return DeleteAPIKeyRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes DeleteAPIKeyRequest from protobuf.
   */
  decode: function (bytes: ByteSource): DeleteAPIKeyRequest {
    return DeleteAPIKeyRequest._readMessage(
      DeleteAPIKeyRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes DeleteAPIKeyRequest with all fields set to their default value.
   */
  initialize: function (): DeleteAPIKeyRequest {
    return {
      projectId: "",
      apiKeyId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<DeleteAPIKeyRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    if (msg.apiKeyId) {
      writer.writeString(2, msg.apiKeyId);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: DeleteAPIKeyRequest,
    reader: BinaryReader
  ): DeleteAPIKeyRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        case 2: {
          msg.apiKeyId = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const DeleteAPIKeyResponse = {
  /**
   * Serializes DeleteAPIKeyResponse to protobuf.
   */
  encode: function (_msg?: Partial<DeleteAPIKeyResponse>): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes DeleteAPIKeyResponse from protobuf.
   */
  decode: function (_bytes?: ByteSource): DeleteAPIKeyResponse {
    return {};
  },

  /**
   * Initializes DeleteAPIKeyResponse with all fields set to their default value.
   */
  initialize: function (): DeleteAPIKeyResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<DeleteAPIKeyResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: DeleteAPIKeyResponse,
    _reader: BinaryReader
  ): DeleteAPIKeyResponse {
    return _msg;
  },
};

export const ConfirmSignupEmailRequest = {
  /**
   * Serializes ConfirmSignupEmailRequest to protobuf.
   */
  encode: function (msg: Partial<ConfirmSignupEmailRequest>): Uint8Array {
    return ConfirmSignupEmailRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes ConfirmSignupEmailRequest from protobuf.
   */
  decode: function (bytes: ByteSource): ConfirmSignupEmailRequest {
    return ConfirmSignupEmailRequest._readMessage(
      ConfirmSignupEmailRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes ConfirmSignupEmailRequest with all fields set to their default value.
   */
  initialize: function (): ConfirmSignupEmailRequest {
    return {
      code: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<ConfirmSignupEmailRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.code) {
      writer.writeString(1, msg.code);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: ConfirmSignupEmailRequest,
    reader: BinaryReader
  ): ConfirmSignupEmailRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.code = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const ConfirmSignupEmailResponse = {
  /**
   * Serializes ConfirmSignupEmailResponse to protobuf.
   */
  encode: function (_msg?: Partial<ConfirmSignupEmailResponse>): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes ConfirmSignupEmailResponse from protobuf.
   */
  decode: function (_bytes?: ByteSource): ConfirmSignupEmailResponse {
    return {};
  },

  /**
   * Initializes ConfirmSignupEmailResponse with all fields set to their default value.
   */
  initialize: function (): ConfirmSignupEmailResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<ConfirmSignupEmailResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: ConfirmSignupEmailResponse,
    _reader: BinaryReader
  ): ConfirmSignupEmailResponse {
    return _msg;
  },
};

export const AccessCode = {
  /**
   * Serializes AccessCode to protobuf.
   */
  encode: function (msg: Partial<AccessCode>): Uint8Array {
    return AccessCode._writeMessage(msg, new BinaryWriter()).getResultBuffer();
  },

  /**
   * Deserializes AccessCode from protobuf.
   */
  decode: function (bytes: ByteSource): AccessCode {
    return AccessCode._readMessage(
      AccessCode.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes AccessCode with all fields set to their default value.
   */
  initialize: function (): AccessCode {
    return {
      id: "",
      code: "",
      maxUses: 0,
      used: 0,
      createdAt: 0,
      destination: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<AccessCode>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.id) {
      writer.writeString(1, msg.id);
    }
    if (msg.code) {
      writer.writeString(2, msg.code);
    }
    if (msg.maxUses) {
      writer.writeInt32(3, msg.maxUses);
    }
    if (msg.used) {
      writer.writeInt32(4, msg.used);
    }
    if (msg.createdAt) {
      writer.writeInt32(5, msg.createdAt);
    }
    if (msg.destination) {
      writer.writeString(6, msg.destination);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (msg: AccessCode, reader: BinaryReader): AccessCode {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.id = reader.readString();
          break;
        }
        case 2: {
          msg.code = reader.readString();
          break;
        }
        case 3: {
          msg.maxUses = reader.readInt32();
          break;
        }
        case 4: {
          msg.used = reader.readInt32();
          break;
        }
        case 5: {
          msg.createdAt = reader.readInt32();
          break;
        }
        case 6: {
          msg.destination = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const GetProjectAccessCodesRequest = {
  /**
   * Serializes GetProjectAccessCodesRequest to protobuf.
   */
  encode: function (msg: Partial<GetProjectAccessCodesRequest>): Uint8Array {
    return GetProjectAccessCodesRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetProjectAccessCodesRequest from protobuf.
   */
  decode: function (bytes: ByteSource): GetProjectAccessCodesRequest {
    return GetProjectAccessCodesRequest._readMessage(
      GetProjectAccessCodesRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetProjectAccessCodesRequest with all fields set to their default value.
   */
  initialize: function (): GetProjectAccessCodesRequest {
    return {
      projectId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectAccessCodesRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectAccessCodesRequest,
    reader: BinaryReader
  ): GetProjectAccessCodesRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const GetProjectAccessCodesResponse = {
  /**
   * Serializes GetProjectAccessCodesResponse to protobuf.
   */
  encode: function (msg: Partial<GetProjectAccessCodesResponse>): Uint8Array {
    return GetProjectAccessCodesResponse._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetProjectAccessCodesResponse from protobuf.
   */
  decode: function (bytes: ByteSource): GetProjectAccessCodesResponse {
    return GetProjectAccessCodesResponse._readMessage(
      GetProjectAccessCodesResponse.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetProjectAccessCodesResponse with all fields set to their default value.
   */
  initialize: function (): GetProjectAccessCodesResponse {
    return {
      codes: [],
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectAccessCodesResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.codes?.length) {
      writer.writeRepeatedMessage(
        1,
        msg.codes as any,
        AccessCode._writeMessage
      );
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectAccessCodesResponse,
    reader: BinaryReader
  ): GetProjectAccessCodesResponse {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          const m = AccessCode.initialize();
          reader.readMessage(m, AccessCode._readMessage);
          msg.codes.push(m);
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const GenerateProjectAccessCodesRequest = {
  /**
   * Serializes GenerateProjectAccessCodesRequest to protobuf.
   */
  encode: function (
    msg: Partial<GenerateProjectAccessCodesRequest>
  ): Uint8Array {
    return GenerateProjectAccessCodesRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GenerateProjectAccessCodesRequest from protobuf.
   */
  decode: function (bytes: ByteSource): GenerateProjectAccessCodesRequest {
    return GenerateProjectAccessCodesRequest._readMessage(
      GenerateProjectAccessCodesRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GenerateProjectAccessCodesRequest with all fields set to their default value.
   */
  initialize: function (): GenerateProjectAccessCodesRequest {
    return {
      projectId: "",
      count: 0,
      destination: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GenerateProjectAccessCodesRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    if (msg.count) {
      writer.writeInt32(2, msg.count);
    }
    if (msg.destination) {
      writer.writeString(3, msg.destination);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GenerateProjectAccessCodesRequest,
    reader: BinaryReader
  ): GenerateProjectAccessCodesRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        case 2: {
          msg.count = reader.readInt32();
          break;
        }
        case 3: {
          msg.destination = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const GenerateProjectAccessCodesResponse = {
  /**
   * Serializes GenerateProjectAccessCodesResponse to protobuf.
   */
  encode: function (
    msg: Partial<GenerateProjectAccessCodesResponse>
  ): Uint8Array {
    return GenerateProjectAccessCodesResponse._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GenerateProjectAccessCodesResponse from protobuf.
   */
  decode: function (bytes: ByteSource): GenerateProjectAccessCodesResponse {
    return GenerateProjectAccessCodesResponse._readMessage(
      GenerateProjectAccessCodesResponse.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GenerateProjectAccessCodesResponse with all fields set to their default value.
   */
  initialize: function (): GenerateProjectAccessCodesResponse {
    return {
      codes: [],
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GenerateProjectAccessCodesResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.codes?.length) {
      writer.writeRepeatedMessage(
        1,
        msg.codes as any,
        AccessCode._writeMessage
      );
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GenerateProjectAccessCodesResponse,
    reader: BinaryReader
  ): GenerateProjectAccessCodesResponse {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          const m = AccessCode.initialize();
          reader.readMessage(m, AccessCode._readMessage);
          msg.codes.push(m);
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const DeleteProjectAccessCodeRequest = {
  /**
   * Serializes DeleteProjectAccessCodeRequest to protobuf.
   */
  encode: function (msg: Partial<DeleteProjectAccessCodeRequest>): Uint8Array {
    return DeleteProjectAccessCodeRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes DeleteProjectAccessCodeRequest from protobuf.
   */
  decode: function (bytes: ByteSource): DeleteProjectAccessCodeRequest {
    return DeleteProjectAccessCodeRequest._readMessage(
      DeleteProjectAccessCodeRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes DeleteProjectAccessCodeRequest with all fields set to their default value.
   */
  initialize: function (): DeleteProjectAccessCodeRequest {
    return {
      projectId: "",
      codeId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<DeleteProjectAccessCodeRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    if (msg.codeId) {
      writer.writeString(2, msg.codeId);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: DeleteProjectAccessCodeRequest,
    reader: BinaryReader
  ): DeleteProjectAccessCodeRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        case 2: {
          msg.codeId = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const DeleteProjectAccessCodeResponse = {
  /**
   * Serializes DeleteProjectAccessCodeResponse to protobuf.
   */
  encode: function (
    _msg?: Partial<DeleteProjectAccessCodeResponse>
  ): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes DeleteProjectAccessCodeResponse from protobuf.
   */
  decode: function (_bytes?: ByteSource): DeleteProjectAccessCodeResponse {
    return {};
  },

  /**
   * Initializes DeleteProjectAccessCodeResponse with all fields set to their default value.
   */
  initialize: function (): DeleteProjectAccessCodeResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<DeleteProjectAccessCodeResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: DeleteProjectAccessCodeResponse,
    _reader: BinaryReader
  ): DeleteProjectAccessCodeResponse {
    return _msg;
  },
};

export const UpdateProjectRequiresAccessCodeRequest = {
  /**
   * Serializes UpdateProjectRequiresAccessCodeRequest to protobuf.
   */
  encode: function (
    msg: Partial<UpdateProjectRequiresAccessCodeRequest>
  ): Uint8Array {
    return UpdateProjectRequiresAccessCodeRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes UpdateProjectRequiresAccessCodeRequest from protobuf.
   */
  decode: function (bytes: ByteSource): UpdateProjectRequiresAccessCodeRequest {
    return UpdateProjectRequiresAccessCodeRequest._readMessage(
      UpdateProjectRequiresAccessCodeRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes UpdateProjectRequiresAccessCodeRequest with all fields set to their default value.
   */
  initialize: function (): UpdateProjectRequiresAccessCodeRequest {
    return {
      projectId: "",
      value: false,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<UpdateProjectRequiresAccessCodeRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    if (msg.value) {
      writer.writeBool(2, msg.value);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpdateProjectRequiresAccessCodeRequest,
    reader: BinaryReader
  ): UpdateProjectRequiresAccessCodeRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        case 2: {
          msg.value = reader.readBool();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const UpdateProjectRequiresAccessCodeResponse = {
  /**
   * Serializes UpdateProjectRequiresAccessCodeResponse to protobuf.
   */
  encode: function (
    _msg?: Partial<UpdateProjectRequiresAccessCodeResponse>
  ): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes UpdateProjectRequiresAccessCodeResponse from protobuf.
   */
  decode: function (
    _bytes?: ByteSource
  ): UpdateProjectRequiresAccessCodeResponse {
    return {};
  },

  /**
   * Initializes UpdateProjectRequiresAccessCodeResponse with all fields set to their default value.
   */
  initialize: function (): UpdateProjectRequiresAccessCodeResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<UpdateProjectRequiresAccessCodeResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: UpdateProjectRequiresAccessCodeResponse,
    _reader: BinaryReader
  ): UpdateProjectRequiresAccessCodeResponse {
    return _msg;
  },
};

export const UpdateProjectInviteRedirectUrlRequest = {
  /**
   * Serializes UpdateProjectInviteRedirectUrlRequest to protobuf.
   */
  encode: function (
    msg: Partial<UpdateProjectInviteRedirectUrlRequest>
  ): Uint8Array {
    return UpdateProjectInviteRedirectUrlRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes UpdateProjectInviteRedirectUrlRequest from protobuf.
   */
  decode: function (bytes: ByteSource): UpdateProjectInviteRedirectUrlRequest {
    return UpdateProjectInviteRedirectUrlRequest._readMessage(
      UpdateProjectInviteRedirectUrlRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes UpdateProjectInviteRedirectUrlRequest with all fields set to their default value.
   */
  initialize: function (): UpdateProjectInviteRedirectUrlRequest {
    return {
      projectId: "",
      url: undefined,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<UpdateProjectInviteRedirectUrlRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    if (msg.url != undefined) {
      writer.writeString(2, msg.url);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpdateProjectInviteRedirectUrlRequest,
    reader: BinaryReader
  ): UpdateProjectInviteRedirectUrlRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        case 2: {
          msg.url = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const UpdateProjectInviteRedirectUrlResponse = {
  /**
   * Serializes UpdateProjectInviteRedirectUrlResponse to protobuf.
   */
  encode: function (
    _msg?: Partial<UpdateProjectInviteRedirectUrlResponse>
  ): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes UpdateProjectInviteRedirectUrlResponse from protobuf.
   */
  decode: function (
    _bytes?: ByteSource
  ): UpdateProjectInviteRedirectUrlResponse {
    return {};
  },

  /**
   * Initializes UpdateProjectInviteRedirectUrlResponse with all fields set to their default value.
   */
  initialize: function (): UpdateProjectInviteRedirectUrlResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<UpdateProjectInviteRedirectUrlResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: UpdateProjectInviteRedirectUrlResponse,
    _reader: BinaryReader
  ): UpdateProjectInviteRedirectUrlResponse {
    return _msg;
  },
};

export const UpdateProjectInviteAcceptedCallbackRequest = {
  /**
   * Serializes UpdateProjectInviteAcceptedCallbackRequest to protobuf.
   */
  encode: function (
    msg: Partial<UpdateProjectInviteAcceptedCallbackRequest>
  ): Uint8Array {
    return UpdateProjectInviteAcceptedCallbackRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes UpdateProjectInviteAcceptedCallbackRequest from protobuf.
   */
  decode: function (
    bytes: ByteSource
  ): UpdateProjectInviteAcceptedCallbackRequest {
    return UpdateProjectInviteAcceptedCallbackRequest._readMessage(
      UpdateProjectInviteAcceptedCallbackRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes UpdateProjectInviteAcceptedCallbackRequest with all fields set to their default value.
   */
  initialize: function (): UpdateProjectInviteAcceptedCallbackRequest {
    return {
      projectId: "",
      url: undefined,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<UpdateProjectInviteAcceptedCallbackRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    if (msg.url != undefined) {
      writer.writeString(2, msg.url);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpdateProjectInviteAcceptedCallbackRequest,
    reader: BinaryReader
  ): UpdateProjectInviteAcceptedCallbackRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        case 2: {
          msg.url = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const UpdateProjectInviteAcceptedCallbackResponse = {
  /**
   * Serializes UpdateProjectInviteAcceptedCallbackResponse to protobuf.
   */
  encode: function (
    _msg?: Partial<UpdateProjectInviteAcceptedCallbackResponse>
  ): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes UpdateProjectInviteAcceptedCallbackResponse from protobuf.
   */
  decode: function (
    _bytes?: ByteSource
  ): UpdateProjectInviteAcceptedCallbackResponse {
    return {};
  },

  /**
   * Initializes UpdateProjectInviteAcceptedCallbackResponse with all fields set to their default value.
   */
  initialize: function (): UpdateProjectInviteAcceptedCallbackResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<UpdateProjectInviteAcceptedCallbackResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: UpdateProjectInviteAcceptedCallbackResponse,
    _reader: BinaryReader
  ): UpdateProjectInviteAcceptedCallbackResponse {
    return _msg;
  },
};

export const UpdatePublishProjectFormRequest = {
  /**
   * Serializes UpdatePublishProjectFormRequest to protobuf.
   */
  encode: function (msg: Partial<UpdatePublishProjectFormRequest>): Uint8Array {
    return UpdatePublishProjectFormRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes UpdatePublishProjectFormRequest from protobuf.
   */
  decode: function (bytes: ByteSource): UpdatePublishProjectFormRequest {
    return UpdatePublishProjectFormRequest._readMessage(
      UpdatePublishProjectFormRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes UpdatePublishProjectFormRequest with all fields set to their default value.
   */
  initialize: function (): UpdatePublishProjectFormRequest {
    return {
      projectId: "",
      published: false,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<UpdatePublishProjectFormRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    if (msg.published) {
      writer.writeBool(2, msg.published);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpdatePublishProjectFormRequest,
    reader: BinaryReader
  ): UpdatePublishProjectFormRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        case 2: {
          msg.published = reader.readBool();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const UpdatePublishProjectFormResponse = {
  /**
   * Serializes UpdatePublishProjectFormResponse to protobuf.
   */
  encode: function (
    _msg?: Partial<UpdatePublishProjectFormResponse>
  ): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes UpdatePublishProjectFormResponse from protobuf.
   */
  decode: function (_bytes?: ByteSource): UpdatePublishProjectFormResponse {
    return {};
  },

  /**
   * Initializes UpdatePublishProjectFormResponse with all fields set to their default value.
   */
  initialize: function (): UpdatePublishProjectFormResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<UpdatePublishProjectFormResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: UpdatePublishProjectFormResponse,
    _reader: BinaryReader
  ): UpdatePublishProjectFormResponse {
    return _msg;
  },
};

export const GetProjectMembersRequest = {
  /**
   * Serializes GetProjectMembersRequest to protobuf.
   */
  encode: function (msg: Partial<GetProjectMembersRequest>): Uint8Array {
    return GetProjectMembersRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetProjectMembersRequest from protobuf.
   */
  decode: function (bytes: ByteSource): GetProjectMembersRequest {
    return GetProjectMembersRequest._readMessage(
      GetProjectMembersRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetProjectMembersRequest with all fields set to their default value.
   */
  initialize: function (): GetProjectMembersRequest {
    return {
      projectId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectMembersRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectMembersRequest,
    reader: BinaryReader
  ): GetProjectMembersRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const GetProjectMembersResponse = {
  /**
   * Serializes GetProjectMembersResponse to protobuf.
   */
  encode: function (msg: Partial<GetProjectMembersResponse>): Uint8Array {
    return GetProjectMembersResponse._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetProjectMembersResponse from protobuf.
   */
  decode: function (bytes: ByteSource): GetProjectMembersResponse {
    return GetProjectMembersResponse._readMessage(
      GetProjectMembersResponse.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetProjectMembersResponse with all fields set to their default value.
   */
  initialize: function (): GetProjectMembersResponse {
    return {
      members: [],
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectMembersResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.members?.length) {
      writer.writeRepeatedMessage(1, msg.members as any, Member._writeMessage);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectMembersResponse,
    reader: BinaryReader
  ): GetProjectMembersResponse {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          const m = Member.initialize();
          reader.readMessage(m, Member._readMessage);
          msg.members.push(m);
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const DeleteProjectMemberRequest = {
  /**
   * Serializes DeleteProjectMemberRequest to protobuf.
   */
  encode: function (msg: Partial<DeleteProjectMemberRequest>): Uint8Array {
    return DeleteProjectMemberRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes DeleteProjectMemberRequest from protobuf.
   */
  decode: function (bytes: ByteSource): DeleteProjectMemberRequest {
    return DeleteProjectMemberRequest._readMessage(
      DeleteProjectMemberRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes DeleteProjectMemberRequest with all fields set to their default value.
   */
  initialize: function (): DeleteProjectMemberRequest {
    return {
      projectId: "",
      id: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<DeleteProjectMemberRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    if (msg.id) {
      writer.writeString(2, msg.id);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: DeleteProjectMemberRequest,
    reader: BinaryReader
  ): DeleteProjectMemberRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        case 2: {
          msg.id = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const DeleteProjectMemberResponse = {
  /**
   * Serializes DeleteProjectMemberResponse to protobuf.
   */
  encode: function (_msg?: Partial<DeleteProjectMemberResponse>): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes DeleteProjectMemberResponse from protobuf.
   */
  decode: function (_bytes?: ByteSource): DeleteProjectMemberResponse {
    return {};
  },

  /**
   * Initializes DeleteProjectMemberResponse with all fields set to their default value.
   */
  initialize: function (): DeleteProjectMemberResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<DeleteProjectMemberResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: DeleteProjectMemberResponse,
    _reader: BinaryReader
  ): DeleteProjectMemberResponse {
    return _msg;
  },
};

export const AddProjectMemberRequest = {
  /**
   * Serializes AddProjectMemberRequest to protobuf.
   */
  encode: function (msg: Partial<AddProjectMemberRequest>): Uint8Array {
    return AddProjectMemberRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes AddProjectMemberRequest from protobuf.
   */
  decode: function (bytes: ByteSource): AddProjectMemberRequest {
    return AddProjectMemberRequest._readMessage(
      AddProjectMemberRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes AddProjectMemberRequest with all fields set to their default value.
   */
  initialize: function (): AddProjectMemberRequest {
    return {
      projectId: "",
      email: "",
      access: 0,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<AddProjectMemberRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    if (msg.email) {
      writer.writeString(2, msg.email);
    }
    if (msg.access) {
      writer.writeInt32(3, msg.access);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: AddProjectMemberRequest,
    reader: BinaryReader
  ): AddProjectMemberRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        case 2: {
          msg.email = reader.readString();
          break;
        }
        case 3: {
          msg.access = reader.readInt32();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const AddProjectMemberResponse = {
  /**
   * Serializes AddProjectMemberResponse to protobuf.
   */
  encode: function (_msg?: Partial<AddProjectMemberResponse>): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes AddProjectMemberResponse from protobuf.
   */
  decode: function (_bytes?: ByteSource): AddProjectMemberResponse {
    return {};
  },

  /**
   * Initializes AddProjectMemberResponse with all fields set to their default value.
   */
  initialize: function (): AddProjectMemberResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<AddProjectMemberResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: AddProjectMemberResponse,
    _reader: BinaryReader
  ): AddProjectMemberResponse {
    return _msg;
  },
};

export const UpdateProjectMemberAccessRequest = {
  /**
   * Serializes UpdateProjectMemberAccessRequest to protobuf.
   */
  encode: function (
    msg: Partial<UpdateProjectMemberAccessRequest>
  ): Uint8Array {
    return UpdateProjectMemberAccessRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes UpdateProjectMemberAccessRequest from protobuf.
   */
  decode: function (bytes: ByteSource): UpdateProjectMemberAccessRequest {
    return UpdateProjectMemberAccessRequest._readMessage(
      UpdateProjectMemberAccessRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes UpdateProjectMemberAccessRequest with all fields set to their default value.
   */
  initialize: function (): UpdateProjectMemberAccessRequest {
    return {
      projectId: "",
      memberId: "",
      access: 0,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<UpdateProjectMemberAccessRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    if (msg.memberId) {
      writer.writeString(2, msg.memberId);
    }
    if (msg.access) {
      writer.writeInt32(3, msg.access);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpdateProjectMemberAccessRequest,
    reader: BinaryReader
  ): UpdateProjectMemberAccessRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        case 2: {
          msg.memberId = reader.readString();
          break;
        }
        case 3: {
          msg.access = reader.readInt32();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const UpdateProjectMemberAccessResponse = {
  /**
   * Serializes UpdateProjectMemberAccessResponse to protobuf.
   */
  encode: function (
    _msg?: Partial<UpdateProjectMemberAccessResponse>
  ): Uint8Array {
    return new Uint8Array();
  },

  /**
   * Deserializes UpdateProjectMemberAccessResponse from protobuf.
   */
  decode: function (_bytes?: ByteSource): UpdateProjectMemberAccessResponse {
    return {};
  },

  /**
   * Initializes UpdateProjectMemberAccessResponse with all fields set to their default value.
   */
  initialize: function (): UpdateProjectMemberAccessResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<UpdateProjectMemberAccessResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    _msg: UpdateProjectMemberAccessResponse,
    _reader: BinaryReader
  ): UpdateProjectMemberAccessResponse {
    return _msg;
  },
};

export const GetDefaultEmailTemplateRequest = {
  /**
   * Serializes GetDefaultEmailTemplateRequest to protobuf.
   */
  encode: function (msg: Partial<GetDefaultEmailTemplateRequest>): Uint8Array {
    return GetDefaultEmailTemplateRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetDefaultEmailTemplateRequest from protobuf.
   */
  decode: function (bytes: ByteSource): GetDefaultEmailTemplateRequest {
    return GetDefaultEmailTemplateRequest._readMessage(
      GetDefaultEmailTemplateRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetDefaultEmailTemplateRequest with all fields set to their default value.
   */
  initialize: function (): GetDefaultEmailTemplateRequest {
    return {
      projectId: "",
      type: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetDefaultEmailTemplateRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    if (msg.type) {
      writer.writeString(2, msg.type);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetDefaultEmailTemplateRequest,
    reader: BinaryReader
  ): GetDefaultEmailTemplateRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        case 2: {
          msg.type = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const GetDefaultEmailTemplateResponse = {
  /**
   * Serializes GetDefaultEmailTemplateResponse to protobuf.
   */
  encode: function (msg: Partial<GetDefaultEmailTemplateResponse>): Uint8Array {
    return GetDefaultEmailTemplateResponse._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes GetDefaultEmailTemplateResponse from protobuf.
   */
  decode: function (bytes: ByteSource): GetDefaultEmailTemplateResponse {
    return GetDefaultEmailTemplateResponse._readMessage(
      GetDefaultEmailTemplateResponse.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes GetDefaultEmailTemplateResponse with all fields set to their default value.
   */
  initialize: function (): GetDefaultEmailTemplateResponse {
    return {
      template: EmailTemplate.initialize(),
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetDefaultEmailTemplateResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.template) {
      writer.writeMessage(1, msg.template, EmailTemplate._writeMessage);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetDefaultEmailTemplateResponse,
    reader: BinaryReader
  ): GetDefaultEmailTemplateResponse {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          reader.readMessage(msg.template, EmailTemplate._readMessage);
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const UpdateFormCSSRequest = {
  /**
   * Serializes UpdateFormCSSRequest to protobuf.
   */
  encode: function (msg: Partial<UpdateFormCSSRequest>): Uint8Array {
    return UpdateFormCSSRequest._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes UpdateFormCSSRequest from protobuf.
   */
  decode: function (bytes: ByteSource): UpdateFormCSSRequest {
    return UpdateFormCSSRequest._readMessage(
      UpdateFormCSSRequest.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes UpdateFormCSSRequest with all fields set to their default value.
   */
  initialize: function (): UpdateFormCSSRequest {
    return {
      projectId: "",
      css: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<UpdateFormCSSRequest>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.projectId) {
      writer.writeString(1, msg.projectId);
    }
    if (msg.css) {
      writer.writeString(2, msg.css);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpdateFormCSSRequest,
    reader: BinaryReader
  ): UpdateFormCSSRequest {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.projectId = reader.readString();
          break;
        }
        case 2: {
          msg.css = reader.readString();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

export const UpdateFormCSSResponse = {
  /**
   * Serializes UpdateFormCSSResponse to protobuf.
   */
  encode: function (msg: Partial<UpdateFormCSSResponse>): Uint8Array {
    return UpdateFormCSSResponse._writeMessage(
      msg,
      new BinaryWriter()
    ).getResultBuffer();
  },

  /**
   * Deserializes UpdateFormCSSResponse from protobuf.
   */
  decode: function (bytes: ByteSource): UpdateFormCSSResponse {
    return UpdateFormCSSResponse._readMessage(
      UpdateFormCSSResponse.initialize(),
      new BinaryReader(bytes)
    );
  },

  /**
   * Initializes UpdateFormCSSResponse with all fields set to their default value.
   */
  initialize: function (): UpdateFormCSSResponse {
    return {
      ok: false,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<UpdateFormCSSResponse>,
    writer: BinaryWriter
  ): BinaryWriter {
    if (msg.ok) {
      writer.writeBool(1, msg.ok);
    }
    return writer;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpdateFormCSSResponse,
    reader: BinaryReader
  ): UpdateFormCSSResponse {
    while (reader.nextField()) {
      const field = reader.getFieldNumber();
      switch (field) {
        case 1: {
          msg.ok = reader.readBool();
          break;
        }
        default: {
          reader.skipField();
          break;
        }
      }
    }
    return msg;
  },
};

//========================================//
//          JSON Encode / Decode          //
//========================================//

export const SignedTokenJSON = {
  /**
   * Serializes SignedToken to JSON.
   */
  encode: function (msg: Partial<SignedToken>): string {
    return JSON.stringify(SignedTokenJSON._writeMessage(msg));
  },

  /**
   * Deserializes SignedToken from JSON.
   */
  decode: function (json: string): SignedToken {
    return SignedTokenJSON._readMessage(
      SignedTokenJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes SignedToken with all fields set to their default value.
   */
  initialize: function (): SignedToken {
    return {
      signature: new Uint8Array(),
      token: new Uint8Array(),
    };
  },

  /**
   * @private
   */
  _writeMessage: function (msg: Partial<SignedToken>): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.signature?.length) {
      json["signature"] = encodeBase64Bytes(msg.signature);
    }
    if (msg.token?.length) {
      json["token"] = encodeBase64Bytes(msg.token);
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (msg: SignedToken, json: any): SignedToken {
    const _signature_ = json["signature"];
    if (_signature_) {
      msg.signature = decodeBase64Bytes(_signature_);
    }
    const _token_ = json["token"];
    if (_token_) {
      msg.token = decodeBase64Bytes(_token_);
    }
    return msg;
  },
};

export const TokenJSON = {
  /**
   * Serializes Token to JSON.
   */
  encode: function (msg: Partial<Token>): string {
    return JSON.stringify(TokenJSON._writeMessage(msg));
  },

  /**
   * Deserializes Token from JSON.
   */
  decode: function (json: string): Token {
    return TokenJSON._readMessage(TokenJSON.initialize(), JSON.parse(json));
  },

  /**
   * Initializes Token with all fields set to their default value.
   */
  initialize: function (): Token {
    return {
      expiresAt: 0,
      user: UserJSON.initialize(),
      version: 0,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (msg: Partial<Token>): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.expiresAt) {
      json["expiresAt"] = msg.expiresAt;
    }
    if (msg.user) {
      const _user_ = UserJSON._writeMessage(msg.user);
      if (Object.keys(_user_).length > 0) {
        json["user"] = _user_;
      }
    }
    if (msg.version) {
      json["version"] = msg.version;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (msg: Token, json: any): Token {
    const _expiresAt_ = json["expiresAt"];
    if (_expiresAt_) {
      msg.expiresAt = _expiresAt_;
    }
    const _user_ = json["user"];
    if (_user_) {
      const m = User.initialize();
      UserJSON._readMessage(m, _user_);
      msg.user = m;
    }
    const _version_ = json["version"];
    if (_version_) {
      msg.version = _version_;
    }
    return msg;
  },
};

export const UserJSON = {
  /**
   * Serializes User to JSON.
   */
  encode: function (msg: Partial<User>): string {
    return JSON.stringify(UserJSON._writeMessage(msg));
  },

  /**
   * Deserializes User from JSON.
   */
  decode: function (json: string): User {
    return UserJSON._readMessage(UserJSON.initialize(), JSON.parse(json));
  },

  /**
   * Initializes User with all fields set to their default value.
   */
  initialize: function (): User {
    return {
      id: "",
      email: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (msg: Partial<User>): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.id) {
      json["id"] = msg.id;
    }
    if (msg.email) {
      json["email"] = msg.email;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (msg: User, json: any): User {
    const _id_ = json["id"];
    if (_id_) {
      msg.id = _id_;
    }
    const _email_ = json["email"];
    if (_email_) {
      msg.email = _email_;
    }
    return msg;
  },
};

export const MemberJSON = {
  /**
   * Serializes Member to JSON.
   */
  encode: function (msg: Partial<Member>): string {
    return JSON.stringify(MemberJSON._writeMessage(msg));
  },

  /**
   * Deserializes Member from JSON.
   */
  decode: function (json: string): Member {
    return MemberJSON._readMessage(MemberJSON.initialize(), JSON.parse(json));
  },

  /**
   * Initializes Member with all fields set to their default value.
   */
  initialize: function (): Member {
    return {
      id: "",
      userId: undefined,
      email: undefined,
      access: 0,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (msg: Partial<Member>): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.id) {
      json["id"] = msg.id;
    }
    if (msg.userId != undefined) {
      json["userId"] = msg.userId;
    }
    if (msg.email != undefined) {
      json["email"] = msg.email;
    }
    if (msg.access) {
      json["access"] = msg.access;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (msg: Member, json: any): Member {
    const _id_ = json["id"];
    if (_id_) {
      msg.id = _id_;
    }
    const _userId_ = json["userId"];
    if (_userId_) {
      msg.userId = _userId_;
    }
    const _email_ = json["email"];
    if (_email_) {
      msg.email = _email_;
    }
    const _access_ = json["access"];
    if (_access_) {
      msg.access = _access_;
    }
    return msg;
  },
};

export const SigninRequestJSON = {
  /**
   * Serializes SigninRequest to JSON.
   */
  encode: function (msg: Partial<SigninRequest>): string {
    return JSON.stringify(SigninRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes SigninRequest from JSON.
   */
  decode: function (json: string): SigninRequest {
    return SigninRequestJSON._readMessage(
      SigninRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes SigninRequest with all fields set to their default value.
   */
  initialize: function (): SigninRequest {
    return {
      Token: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<SigninRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.Token) {
      json["Token"] = msg.Token;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (msg: SigninRequest, json: any): SigninRequest {
    const _Token_ = json["Token"];
    if (_Token_) {
      msg.Token = _Token_;
    }
    return msg;
  },
};

export const SigninResponseJSON = {
  /**
   * Serializes SigninResponse to JSON.
   */
  encode: function (_msg?: Partial<SigninResponse>): string {
    return "{}";
  },

  /**
   * Deserializes SigninResponse from JSON.
   */
  decode: function (_json?: string): SigninResponse {
    return {};
  },

  /**
   * Initializes SigninResponse with all fields set to their default value.
   */
  initialize: function (): SigninResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<SigninResponse>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (msg: SigninResponse, _json: any): SigninResponse {
    return msg;
  },
};

export const SignoutRequestJSON = {
  /**
   * Serializes SignoutRequest to JSON.
   */
  encode: function (_msg?: Partial<SignoutRequest>): string {
    return "{}";
  },

  /**
   * Deserializes SignoutRequest from JSON.
   */
  decode: function (_json?: string): SignoutRequest {
    return {};
  },

  /**
   * Initializes SignoutRequest with all fields set to their default value.
   */
  initialize: function (): SignoutRequest {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<SignoutRequest>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (msg: SignoutRequest, _json: any): SignoutRequest {
    return msg;
  },
};

export const SignoutResponseJSON = {
  /**
   * Serializes SignoutResponse to JSON.
   */
  encode: function (_msg?: Partial<SignoutResponse>): string {
    return "{}";
  },

  /**
   * Deserializes SignoutResponse from JSON.
   */
  decode: function (_json?: string): SignoutResponse {
    return {};
  },

  /**
   * Initializes SignoutResponse with all fields set to their default value.
   */
  initialize: function (): SignoutResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<SignoutResponse>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (msg: SignoutResponse, _json: any): SignoutResponse {
    return msg;
  },
};

export const SignupRequestJSON = {
  /**
   * Serializes SignupRequest to JSON.
   */
  encode: function (msg: Partial<SignupRequest>): string {
    return JSON.stringify(SignupRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes SignupRequest from JSON.
   */
  decode: function (json: string): SignupRequest {
    return SignupRequestJSON._readMessage(
      SignupRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes SignupRequest with all fields set to their default value.
   */
  initialize: function (): SignupRequest {
    return {
      Token: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<SignupRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.Token) {
      json["Token"] = msg.Token;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (msg: SignupRequest, json: any): SignupRequest {
    const _Token_ = json["Token"];
    if (_Token_) {
      msg.Token = _Token_;
    }
    return msg;
  },
};

export const SignupResponseJSON = {
  /**
   * Serializes SignupResponse to JSON.
   */
  encode: function (_msg?: Partial<SignupResponse>): string {
    return "{}";
  },

  /**
   * Deserializes SignupResponse from JSON.
   */
  decode: function (_json?: string): SignupResponse {
    return {};
  },

  /**
   * Initializes SignupResponse with all fields set to their default value.
   */
  initialize: function (): SignupResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<SignupResponse>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (msg: SignupResponse, _json: any): SignupResponse {
    return msg;
  },
};

export const CreateSignupTokenRequestJSON = {
  /**
   * Serializes CreateSignupTokenRequest to JSON.
   */
  encode: function (msg: Partial<CreateSignupTokenRequest>): string {
    return JSON.stringify(CreateSignupTokenRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes CreateSignupTokenRequest from JSON.
   */
  decode: function (json: string): CreateSignupTokenRequest {
    return CreateSignupTokenRequestJSON._readMessage(
      CreateSignupTokenRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes CreateSignupTokenRequest with all fields set to their default value.
   */
  initialize: function (): CreateSignupTokenRequest {
    return {
      Email: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<CreateSignupTokenRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.Email) {
      json["Email"] = msg.Email;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: CreateSignupTokenRequest,
    json: any
  ): CreateSignupTokenRequest {
    const _Email_ = json["Email"];
    if (_Email_) {
      msg.Email = _Email_;
    }
    return msg;
  },
};

export const CreateSignupTokenResponseJSON = {
  /**
   * Serializes CreateSignupTokenResponse to JSON.
   */
  encode: function (_msg?: Partial<CreateSignupTokenResponse>): string {
    return "{}";
  },

  /**
   * Deserializes CreateSignupTokenResponse from JSON.
   */
  decode: function (_json?: string): CreateSignupTokenResponse {
    return {};
  },

  /**
   * Initializes CreateSignupTokenResponse with all fields set to their default value.
   */
  initialize: function (): CreateSignupTokenResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<CreateSignupTokenResponse>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: CreateSignupTokenResponse,
    _json: any
  ): CreateSignupTokenResponse {
    return msg;
  },
};

export const CreateSigninTokenRequestJSON = {
  /**
   * Serializes CreateSigninTokenRequest to JSON.
   */
  encode: function (msg: Partial<CreateSigninTokenRequest>): string {
    return JSON.stringify(CreateSigninTokenRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes CreateSigninTokenRequest from JSON.
   */
  decode: function (json: string): CreateSigninTokenRequest {
    return CreateSigninTokenRequestJSON._readMessage(
      CreateSigninTokenRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes CreateSigninTokenRequest with all fields set to their default value.
   */
  initialize: function (): CreateSigninTokenRequest {
    return {
      Email: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<CreateSigninTokenRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.Email) {
      json["Email"] = msg.Email;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: CreateSigninTokenRequest,
    json: any
  ): CreateSigninTokenRequest {
    const _Email_ = json["Email"];
    if (_Email_) {
      msg.Email = _Email_;
    }
    return msg;
  },
};

export const CreateSigninTokenResponseJSON = {
  /**
   * Serializes CreateSigninTokenResponse to JSON.
   */
  encode: function (_msg?: Partial<CreateSigninTokenResponse>): string {
    return "{}";
  },

  /**
   * Deserializes CreateSigninTokenResponse from JSON.
   */
  decode: function (_json?: string): CreateSigninTokenResponse {
    return {};
  },

  /**
   * Initializes CreateSigninTokenResponse with all fields set to their default value.
   */
  initialize: function (): CreateSigninTokenResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<CreateSigninTokenResponse>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: CreateSigninTokenResponse,
    _json: any
  ): CreateSigninTokenResponse {
    return msg;
  },
};

export const MeRequestJSON = {
  /**
   * Serializes MeRequest to JSON.
   */
  encode: function (_msg?: Partial<MeRequest>): string {
    return "{}";
  },

  /**
   * Deserializes MeRequest from JSON.
   */
  decode: function (_json?: string): MeRequest {
    return {};
  },

  /**
   * Initializes MeRequest with all fields set to their default value.
   */
  initialize: function (): MeRequest {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (_msg: Partial<MeRequest>): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (msg: MeRequest, _json: any): MeRequest {
    return msg;
  },
};

export const MeResponseJSON = {
  /**
   * Serializes MeResponse to JSON.
   */
  encode: function (msg: Partial<MeResponse>): string {
    return JSON.stringify(MeResponseJSON._writeMessage(msg));
  },

  /**
   * Deserializes MeResponse from JSON.
   */
  decode: function (json: string): MeResponse {
    return MeResponseJSON._readMessage(
      MeResponseJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes MeResponse with all fields set to their default value.
   */
  initialize: function (): MeResponse {
    return {
      user: UserJSON.initialize(),
    };
  },

  /**
   * @private
   */
  _writeMessage: function (msg: Partial<MeResponse>): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.user) {
      const _user_ = UserJSON._writeMessage(msg.user);
      if (Object.keys(_user_).length > 0) {
        json["user"] = _user_;
      }
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (msg: MeResponse, json: any): MeResponse {
    const _user_ = json["user"];
    if (_user_) {
      const m = User.initialize();
      UserJSON._readMessage(m, _user_);
      msg.user = m;
    }
    return msg;
  },
};

export const GetInvitedSignupRequestJSON = {
  /**
   * Serializes GetInvitedSignupRequest to JSON.
   */
  encode: function (msg: Partial<GetInvitedSignupRequest>): string {
    return JSON.stringify(GetInvitedSignupRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes GetInvitedSignupRequest from JSON.
   */
  decode: function (json: string): GetInvitedSignupRequest {
    return GetInvitedSignupRequestJSON._readMessage(
      GetInvitedSignupRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetInvitedSignupRequest with all fields set to their default value.
   */
  initialize: function (): GetInvitedSignupRequest {
    return {
      inviteId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetInvitedSignupRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.inviteId) {
      json["inviteId"] = msg.inviteId;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetInvitedSignupRequest,
    json: any
  ): GetInvitedSignupRequest {
    const _inviteId_ = json["inviteId"];
    if (_inviteId_) {
      msg.inviteId = _inviteId_;
    }
    return msg;
  },
};

export const GetInvitedSignupResponseJSON = {
  /**
   * Serializes GetInvitedSignupResponse to JSON.
   */
  encode: function (msg: Partial<GetInvitedSignupResponse>): string {
    return JSON.stringify(GetInvitedSignupResponseJSON._writeMessage(msg));
  },

  /**
   * Deserializes GetInvitedSignupResponse from JSON.
   */
  decode: function (json: string): GetInvitedSignupResponse {
    return GetInvitedSignupResponseJSON._readMessage(
      GetInvitedSignupResponseJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetInvitedSignupResponse with all fields set to their default value.
   */
  initialize: function (): GetInvitedSignupResponse {
    return {
      email: "",
      project: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetInvitedSignupResponse>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.email) {
      json["email"] = msg.email;
    }
    if (msg.project) {
      json["project"] = msg.project;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetInvitedSignupResponse,
    json: any
  ): GetInvitedSignupResponse {
    const _email_ = json["email"];
    if (_email_) {
      msg.email = _email_;
    }
    const _project_ = json["project"];
    if (_project_) {
      msg.project = _project_;
    }
    return msg;
  },
};

export const AcceptInviteRequestJSON = {
  /**
   * Serializes AcceptInviteRequest to JSON.
   */
  encode: function (msg: Partial<AcceptInviteRequest>): string {
    return JSON.stringify(AcceptInviteRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes AcceptInviteRequest from JSON.
   */
  decode: function (json: string): AcceptInviteRequest {
    return AcceptInviteRequestJSON._readMessage(
      AcceptInviteRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes AcceptInviteRequest with all fields set to their default value.
   */
  initialize: function (): AcceptInviteRequest {
    return {
      inviteId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<AcceptInviteRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.inviteId) {
      json["inviteId"] = msg.inviteId;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: AcceptInviteRequest,
    json: any
  ): AcceptInviteRequest {
    const _inviteId_ = json["inviteId"];
    if (_inviteId_) {
      msg.inviteId = _inviteId_;
    }
    return msg;
  },
};

export const AcceptInviteResponseJSON = {
  /**
   * Serializes AcceptInviteResponse to JSON.
   */
  encode: function (msg: Partial<AcceptInviteResponse>): string {
    return JSON.stringify(AcceptInviteResponseJSON._writeMessage(msg));
  },

  /**
   * Deserializes AcceptInviteResponse from JSON.
   */
  decode: function (json: string): AcceptInviteResponse {
    return AcceptInviteResponseJSON._readMessage(
      AcceptInviteResponseJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes AcceptInviteResponse with all fields set to their default value.
   */
  initialize: function (): AcceptInviteResponse {
    return {
      ok: false,
      redirectTo: undefined,
      message: undefined,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<AcceptInviteResponse>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.ok) {
      json["ok"] = msg.ok;
    }
    if (msg.redirectTo != undefined) {
      json["redirectTo"] = msg.redirectTo;
    }
    if (msg.message != undefined) {
      json["message"] = msg.message;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: AcceptInviteResponse,
    json: any
  ): AcceptInviteResponse {
    const _ok_ = json["ok"];
    if (_ok_) {
      msg.ok = _ok_;
    }
    const _redirectTo_ = json["redirectTo"];
    if (_redirectTo_) {
      msg.redirectTo = _redirectTo_;
    }
    const _message_ = json["message"];
    if (_message_) {
      msg.message = _message_;
    }
    return msg;
  },
};

export const ProjectJSON = {
  /**
   * Serializes Project to JSON.
   */
  encode: function (msg: Partial<Project>): string {
    return JSON.stringify(ProjectJSON._writeMessage(msg));
  },

  /**
   * Deserializes Project from JSON.
   */
  decode: function (json: string): Project {
    return ProjectJSON._readMessage(ProjectJSON.initialize(), JSON.parse(json));
  },

  /**
   * Initializes Project with all fields set to their default value.
   */
  initialize: function (): Project {
    return {
      id: "",
      name: "",
      formId: "",
      createdAt: 0,
      updatedAt: 0,
      handle: "",
      fromEmail: "",
      emailReplyTo: undefined,
      webhookSecret: "",
      requiresAccessCode: false,
      inviteRedirectUrl: undefined,
      inviteAcceptedCallback: undefined,
      access: 0,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (msg: Partial<Project>): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.id) {
      json["id"] = msg.id;
    }
    if (msg.name) {
      json["name"] = msg.name;
    }
    if (msg.formId) {
      json["formId"] = msg.formId;
    }
    if (msg.createdAt) {
      json["createdAt"] = msg.createdAt;
    }
    if (msg.updatedAt) {
      json["updatedAt"] = msg.updatedAt;
    }
    if (msg.handle) {
      json["handle"] = msg.handle;
    }
    if (msg.fromEmail) {
      json["fromEmail"] = msg.fromEmail;
    }
    if (msg.emailReplyTo != undefined) {
      json["emailReplyTo"] = msg.emailReplyTo;
    }
    if (msg.webhookSecret) {
      json["webhookSecret"] = msg.webhookSecret;
    }
    if (msg.requiresAccessCode) {
      json["requiresAccessCode"] = msg.requiresAccessCode;
    }
    if (msg.inviteRedirectUrl != undefined) {
      json["inviteRedirectUrl"] = msg.inviteRedirectUrl;
    }
    if (msg.inviteAcceptedCallback != undefined) {
      json["inviteAcceptedCallback"] = msg.inviteAcceptedCallback;
    }
    if (msg.access) {
      json["access"] = msg.access;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (msg: Project, json: any): Project {
    const _id_ = json["id"];
    if (_id_) {
      msg.id = _id_;
    }
    const _name_ = json["name"];
    if (_name_) {
      msg.name = _name_;
    }
    const _formId_ = json["formId"];
    if (_formId_) {
      msg.formId = _formId_;
    }
    const _createdAt_ = json["createdAt"];
    if (_createdAt_) {
      msg.createdAt = _createdAt_;
    }
    const _updatedAt_ = json["updatedAt"];
    if (_updatedAt_) {
      msg.updatedAt = _updatedAt_;
    }
    const _handle_ = json["handle"];
    if (_handle_) {
      msg.handle = _handle_;
    }
    const _fromEmail_ = json["fromEmail"];
    if (_fromEmail_) {
      msg.fromEmail = _fromEmail_;
    }
    const _emailReplyTo_ = json["emailReplyTo"];
    if (_emailReplyTo_) {
      msg.emailReplyTo = _emailReplyTo_;
    }
    const _webhookSecret_ = json["webhookSecret"] ?? json["webhook_secret"];
    if (_webhookSecret_) {
      msg.webhookSecret = _webhookSecret_;
    }
    const _requiresAccessCode_ = json["requiresAccessCode"];
    if (_requiresAccessCode_) {
      msg.requiresAccessCode = _requiresAccessCode_;
    }
    const _inviteRedirectUrl_ = json["inviteRedirectUrl"];
    if (_inviteRedirectUrl_) {
      msg.inviteRedirectUrl = _inviteRedirectUrl_;
    }
    const _inviteAcceptedCallback_ = json["inviteAcceptedCallback"];
    if (_inviteAcceptedCallback_) {
      msg.inviteAcceptedCallback = _inviteAcceptedCallback_;
    }
    const _access_ = json["access"];
    if (_access_) {
      msg.access = _access_;
    }
    return msg;
  },
};

export const ProjectWithSignupsJSON = {
  /**
   * Serializes ProjectWithSignups to JSON.
   */
  encode: function (msg: Partial<ProjectWithSignups>): string {
    return JSON.stringify(ProjectWithSignupsJSON._writeMessage(msg));
  },

  /**
   * Deserializes ProjectWithSignups from JSON.
   */
  decode: function (json: string): ProjectWithSignups {
    return ProjectWithSignupsJSON._readMessage(
      ProjectWithSignupsJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes ProjectWithSignups with all fields set to their default value.
   */
  initialize: function (): ProjectWithSignups {
    return {
      project: ProjectJSON.initialize(),
      signups: 0,
      members: [],
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<ProjectWithSignups>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.project) {
      const _project_ = ProjectJSON._writeMessage(msg.project);
      if (Object.keys(_project_).length > 0) {
        json["project"] = _project_;
      }
    }
    if (msg.signups) {
      json["signups"] = msg.signups;
    }
    if (msg.members?.length) {
      json["members"] = msg.members.map(MemberJSON._writeMessage);
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: ProjectWithSignups,
    json: any
  ): ProjectWithSignups {
    const _project_ = json["project"];
    if (_project_) {
      const m = Project.initialize();
      ProjectJSON._readMessage(m, _project_);
      msg.project = m;
    }
    const _signups_ = json["signups"];
    if (_signups_) {
      msg.signups = _signups_;
    }
    const _members_ = json["members"];
    if (_members_) {
      for (const item of _members_) {
        const m = Member.initialize();
        MemberJSON._readMessage(m, item);
        msg.members.push(m);
      }
    }
    return msg;
  },
};

export const ListProjectsRequestJSON = {
  /**
   * Serializes ListProjectsRequest to JSON.
   */
  encode: function (_msg?: Partial<ListProjectsRequest>): string {
    return "{}";
  },

  /**
   * Deserializes ListProjectsRequest from JSON.
   */
  decode: function (_json?: string): ListProjectsRequest {
    return {};
  },

  /**
   * Initializes ListProjectsRequest with all fields set to their default value.
   */
  initialize: function (): ListProjectsRequest {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<ListProjectsRequest>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: ListProjectsRequest,
    _json: any
  ): ListProjectsRequest {
    return msg;
  },
};

export const ListProjectsResponseJSON = {
  /**
   * Serializes ListProjectsResponse to JSON.
   */
  encode: function (msg: Partial<ListProjectsResponse>): string {
    return JSON.stringify(ListProjectsResponseJSON._writeMessage(msg));
  },

  /**
   * Deserializes ListProjectsResponse from JSON.
   */
  decode: function (json: string): ListProjectsResponse {
    return ListProjectsResponseJSON._readMessage(
      ListProjectsResponseJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes ListProjectsResponse with all fields set to their default value.
   */
  initialize: function (): ListProjectsResponse {
    return {
      projects: [],
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<ListProjectsResponse>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projects?.length) {
      json["projects"] = msg.projects.map(ProjectWithSignupsJSON._writeMessage);
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: ListProjectsResponse,
    json: any
  ): ListProjectsResponse {
    const _projects_ = json["projects"];
    if (_projects_) {
      for (const item of _projects_) {
        const m = ProjectWithSignups.initialize();
        ProjectWithSignupsJSON._readMessage(m, item);
        msg.projects.push(m);
      }
    }
    return msg;
  },
};

export const CreateProjectRequestJSON = {
  /**
   * Serializes CreateProjectRequest to JSON.
   */
  encode: function (msg: Partial<CreateProjectRequest>): string {
    return JSON.stringify(CreateProjectRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes CreateProjectRequest from JSON.
   */
  decode: function (json: string): CreateProjectRequest {
    return CreateProjectRequestJSON._readMessage(
      CreateProjectRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes CreateProjectRequest with all fields set to their default value.
   */
  initialize: function (): CreateProjectRequest {
    return {
      name: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<CreateProjectRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.name) {
      json["name"] = msg.name;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: CreateProjectRequest,
    json: any
  ): CreateProjectRequest {
    const _name_ = json["name"];
    if (_name_) {
      msg.name = _name_;
    }
    return msg;
  },
};

export const CreateProjectResponseJSON = {
  /**
   * Serializes CreateProjectResponse to JSON.
   */
  encode: function (msg: Partial<CreateProjectResponse>): string {
    return JSON.stringify(CreateProjectResponseJSON._writeMessage(msg));
  },

  /**
   * Deserializes CreateProjectResponse from JSON.
   */
  decode: function (json: string): CreateProjectResponse {
    return CreateProjectResponseJSON._readMessage(
      CreateProjectResponseJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes CreateProjectResponse with all fields set to their default value.
   */
  initialize: function (): CreateProjectResponse {
    return {
      project: ProjectJSON.initialize(),
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<CreateProjectResponse>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.project) {
      const _project_ = ProjectJSON._writeMessage(msg.project);
      if (Object.keys(_project_).length > 0) {
        json["project"] = _project_;
      }
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: CreateProjectResponse,
    json: any
  ): CreateProjectResponse {
    const _project_ = json["project"];
    if (_project_) {
      const m = Project.initialize();
      ProjectJSON._readMessage(m, _project_);
      msg.project = m;
    }
    return msg;
  },
};

export const GetProjectRequestJSON = {
  /**
   * Serializes GetProjectRequest to JSON.
   */
  encode: function (msg: Partial<GetProjectRequest>): string {
    return JSON.stringify(GetProjectRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes GetProjectRequest from JSON.
   */
  decode: function (json: string): GetProjectRequest {
    return GetProjectRequestJSON._readMessage(
      GetProjectRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetProjectRequest with all fields set to their default value.
   */
  initialize: function (): GetProjectRequest {
    return {
      projectId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectRequest,
    json: any
  ): GetProjectRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    return msg;
  },
};

export const GetProjectResponseJSON = {
  /**
   * Serializes GetProjectResponse to JSON.
   */
  encode: function (msg: Partial<GetProjectResponse>): string {
    return JSON.stringify(GetProjectResponseJSON._writeMessage(msg));
  },

  /**
   * Deserializes GetProjectResponse from JSON.
   */
  decode: function (json: string): GetProjectResponse {
    return GetProjectResponseJSON._readMessage(
      GetProjectResponseJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetProjectResponse with all fields set to their default value.
   */
  initialize: function (): GetProjectResponse {
    return {
      project: ProjectJSON.initialize(),
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectResponse>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.project) {
      const _project_ = ProjectJSON._writeMessage(msg.project);
      if (Object.keys(_project_).length > 0) {
        json["project"] = _project_;
      }
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectResponse,
    json: any
  ): GetProjectResponse {
    const _project_ = json["project"];
    if (_project_) {
      const m = Project.initialize();
      ProjectJSON._readMessage(m, _project_);
      msg.project = m;
    }
    return msg;
  },
};

export const DeleteProjectRequestJSON = {
  /**
   * Serializes DeleteProjectRequest to JSON.
   */
  encode: function (msg: Partial<DeleteProjectRequest>): string {
    return JSON.stringify(DeleteProjectRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes DeleteProjectRequest from JSON.
   */
  decode: function (json: string): DeleteProjectRequest {
    return DeleteProjectRequestJSON._readMessage(
      DeleteProjectRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes DeleteProjectRequest with all fields set to their default value.
   */
  initialize: function (): DeleteProjectRequest {
    return {
      projectId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<DeleteProjectRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: DeleteProjectRequest,
    json: any
  ): DeleteProjectRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    return msg;
  },
};

export const DeleteProjectResponseJSON = {
  /**
   * Serializes DeleteProjectResponse to JSON.
   */
  encode: function (_msg?: Partial<DeleteProjectResponse>): string {
    return "{}";
  },

  /**
   * Deserializes DeleteProjectResponse from JSON.
   */
  decode: function (_json?: string): DeleteProjectResponse {
    return {};
  },

  /**
   * Initializes DeleteProjectResponse with all fields set to their default value.
   */
  initialize: function (): DeleteProjectResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<DeleteProjectResponse>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: DeleteProjectResponse,
    _json: any
  ): DeleteProjectResponse {
    return msg;
  },
};

export const GetProjectStatsRequestJSON = {
  /**
   * Serializes GetProjectStatsRequest to JSON.
   */
  encode: function (msg: Partial<GetProjectStatsRequest>): string {
    return JSON.stringify(GetProjectStatsRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes GetProjectStatsRequest from JSON.
   */
  decode: function (json: string): GetProjectStatsRequest {
    return GetProjectStatsRequestJSON._readMessage(
      GetProjectStatsRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetProjectStatsRequest with all fields set to their default value.
   */
  initialize: function (): GetProjectStatsRequest {
    return {
      projectId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectStatsRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectStatsRequest,
    json: any
  ): GetProjectStatsRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    return msg;
  },
};

export const GetProjectStatsResponseJSON = {
  /**
   * Serializes GetProjectStatsResponse to JSON.
   */
  encode: function (msg: Partial<GetProjectStatsResponse>): string {
    return JSON.stringify(GetProjectStatsResponseJSON._writeMessage(msg));
  },

  /**
   * Deserializes GetProjectStatsResponse from JSON.
   */
  decode: function (json: string): GetProjectStatsResponse {
    return GetProjectStatsResponseJSON._readMessage(
      GetProjectStatsResponseJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetProjectStatsResponse with all fields set to their default value.
   */
  initialize: function (): GetProjectStatsResponse {
    return {
      signups: 0,
      invited: 0,
      waitlist: 0,
      accepted: 0,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectStatsResponse>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.signups) {
      json["signups"] = msg.signups;
    }
    if (msg.invited) {
      json["invited"] = msg.invited;
    }
    if (msg.waitlist) {
      json["waitlist"] = msg.waitlist;
    }
    if (msg.accepted) {
      json["accepted"] = msg.accepted;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectStatsResponse,
    json: any
  ): GetProjectStatsResponse {
    const _signups_ = json["signups"];
    if (_signups_) {
      msg.signups = _signups_;
    }
    const _invited_ = json["invited"];
    if (_invited_) {
      msg.invited = _invited_;
    }
    const _waitlist_ = json["waitlist"];
    if (_waitlist_) {
      msg.waitlist = _waitlist_;
    }
    const _accepted_ = json["accepted"];
    if (_accepted_) {
      msg.accepted = _accepted_;
    }
    return msg;
  },
};

export const ProjectSignupJSON = {
  /**
   * Serializes ProjectSignup to JSON.
   */
  encode: function (msg: Partial<ProjectSignup>): string {
    return JSON.stringify(ProjectSignupJSON._writeMessage(msg));
  },

  /**
   * Deserializes ProjectSignup from JSON.
   */
  decode: function (json: string): ProjectSignup {
    return ProjectSignupJSON._readMessage(
      ProjectSignupJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes ProjectSignup with all fields set to their default value.
   */
  initialize: function (): ProjectSignup {
    return {
      id: "",
      email: "",
      createdAt: 0,
      karma: 0,
      projectId: "",
      invitedAt: undefined,
      acceptedAt: undefined,
      position: 0,
      referral: "",
      pending: false,
      expiresAt: undefined,
      accessCode: undefined,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<ProjectSignup>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.id) {
      json["id"] = msg.id;
    }
    if (msg.email) {
      json["email"] = msg.email;
    }
    if (msg.createdAt) {
      json["createdAt"] = msg.createdAt;
    }
    if (msg.karma) {
      json["karma"] = msg.karma;
    }
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    if (msg.invitedAt != undefined) {
      json["invitedAt"] = msg.invitedAt;
    }
    if (msg.acceptedAt != undefined) {
      json["acceptedAt"] = msg.acceptedAt;
    }
    if (msg.position) {
      json["position"] = msg.position;
    }
    if (msg.referral) {
      json["referral"] = msg.referral;
    }
    if (msg.pending) {
      json["pending"] = msg.pending;
    }
    if (msg.expiresAt != undefined) {
      json["expiresAt"] = msg.expiresAt;
    }
    if (msg.accessCode != undefined) {
      json["accessCode"] = msg.accessCode;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (msg: ProjectSignup, json: any): ProjectSignup {
    const _id_ = json["id"];
    if (_id_) {
      msg.id = _id_;
    }
    const _email_ = json["email"];
    if (_email_) {
      msg.email = _email_;
    }
    const _createdAt_ = json["createdAt"];
    if (_createdAt_) {
      msg.createdAt = _createdAt_;
    }
    const _karma_ = json["karma"];
    if (_karma_) {
      msg.karma = _karma_;
    }
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    const _invitedAt_ = json["invitedAt"];
    if (_invitedAt_) {
      msg.invitedAt = _invitedAt_;
    }
    const _acceptedAt_ = json["acceptedAt"];
    if (_acceptedAt_) {
      msg.acceptedAt = _acceptedAt_;
    }
    const _position_ = json["position"];
    if (_position_) {
      msg.position = _position_;
    }
    const _referral_ = json["referral"];
    if (_referral_) {
      msg.referral = _referral_;
    }
    const _pending_ = json["pending"];
    if (_pending_) {
      msg.pending = _pending_;
    }
    const _expiresAt_ = json["expiresAt"];
    if (_expiresAt_) {
      msg.expiresAt = _expiresAt_;
    }
    const _accessCode_ = json["accessCode"];
    if (_accessCode_) {
      msg.accessCode = _accessCode_;
    }
    return msg;
  },
};

export const GetProjectSignupsRequestJSON = {
  /**
   * Serializes GetProjectSignupsRequest to JSON.
   */
  encode: function (msg: Partial<GetProjectSignupsRequest>): string {
    return JSON.stringify(GetProjectSignupsRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes GetProjectSignupsRequest from JSON.
   */
  decode: function (json: string): GetProjectSignupsRequest {
    return GetProjectSignupsRequestJSON._readMessage(
      GetProjectSignupsRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetProjectSignupsRequest with all fields set to their default value.
   */
  initialize: function (): GetProjectSignupsRequest {
    return {
      projectId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectSignupsRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectSignupsRequest,
    json: any
  ): GetProjectSignupsRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    return msg;
  },
};

export const GetProjectSignupsResponseJSON = {
  /**
   * Serializes GetProjectSignupsResponse to JSON.
   */
  encode: function (msg: Partial<GetProjectSignupsResponse>): string {
    return JSON.stringify(GetProjectSignupsResponseJSON._writeMessage(msg));
  },

  /**
   * Deserializes GetProjectSignupsResponse from JSON.
   */
  decode: function (json: string): GetProjectSignupsResponse {
    return GetProjectSignupsResponseJSON._readMessage(
      GetProjectSignupsResponseJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetProjectSignupsResponse with all fields set to their default value.
   */
  initialize: function (): GetProjectSignupsResponse {
    return {
      signups: [],
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectSignupsResponse>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.signups?.length) {
      json["signups"] = msg.signups.map(ProjectSignupJSON._writeMessage);
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectSignupsResponse,
    json: any
  ): GetProjectSignupsResponse {
    const _signups_ = json["signups"];
    if (_signups_) {
      for (const item of _signups_) {
        const m = ProjectSignup.initialize();
        ProjectSignupJSON._readMessage(m, item);
        msg.signups.push(m);
      }
    }
    return msg;
  },
};

export const GetProjectWaitlistRequestJSON = {
  /**
   * Serializes GetProjectWaitlistRequest to JSON.
   */
  encode: function (msg: Partial<GetProjectWaitlistRequest>): string {
    return JSON.stringify(GetProjectWaitlistRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes GetProjectWaitlistRequest from JSON.
   */
  decode: function (json: string): GetProjectWaitlistRequest {
    return GetProjectWaitlistRequestJSON._readMessage(
      GetProjectWaitlistRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetProjectWaitlistRequest with all fields set to their default value.
   */
  initialize: function (): GetProjectWaitlistRequest {
    return {
      projectId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectWaitlistRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectWaitlistRequest,
    json: any
  ): GetProjectWaitlistRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    return msg;
  },
};

export const GetProjectWaitlistResponseJSON = {
  /**
   * Serializes GetProjectWaitlistResponse to JSON.
   */
  encode: function (msg: Partial<GetProjectWaitlistResponse>): string {
    return JSON.stringify(GetProjectWaitlistResponseJSON._writeMessage(msg));
  },

  /**
   * Deserializes GetProjectWaitlistResponse from JSON.
   */
  decode: function (json: string): GetProjectWaitlistResponse {
    return GetProjectWaitlistResponseJSON._readMessage(
      GetProjectWaitlistResponseJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetProjectWaitlistResponse with all fields set to their default value.
   */
  initialize: function (): GetProjectWaitlistResponse {
    return {
      signups: [],
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectWaitlistResponse>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.signups?.length) {
      json["signups"] = msg.signups.map(ProjectSignupJSON._writeMessage);
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectWaitlistResponse,
    json: any
  ): GetProjectWaitlistResponse {
    const _signups_ = json["signups"];
    if (_signups_) {
      for (const item of _signups_) {
        const m = ProjectSignup.initialize();
        ProjectSignupJSON._readMessage(m, item);
        msg.signups.push(m);
      }
    }
    return msg;
  },
};

export const GetProjectInvitesRequestJSON = {
  /**
   * Serializes GetProjectInvitesRequest to JSON.
   */
  encode: function (msg: Partial<GetProjectInvitesRequest>): string {
    return JSON.stringify(GetProjectInvitesRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes GetProjectInvitesRequest from JSON.
   */
  decode: function (json: string): GetProjectInvitesRequest {
    return GetProjectInvitesRequestJSON._readMessage(
      GetProjectInvitesRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetProjectInvitesRequest with all fields set to their default value.
   */
  initialize: function (): GetProjectInvitesRequest {
    return {
      projectId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectInvitesRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectInvitesRequest,
    json: any
  ): GetProjectInvitesRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    return msg;
  },
};

export const GetProjectInvitesResponseJSON = {
  /**
   * Serializes GetProjectInvitesResponse to JSON.
   */
  encode: function (msg: Partial<GetProjectInvitesResponse>): string {
    return JSON.stringify(GetProjectInvitesResponseJSON._writeMessage(msg));
  },

  /**
   * Deserializes GetProjectInvitesResponse from JSON.
   */
  decode: function (json: string): GetProjectInvitesResponse {
    return GetProjectInvitesResponseJSON._readMessage(
      GetProjectInvitesResponseJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetProjectInvitesResponse with all fields set to their default value.
   */
  initialize: function (): GetProjectInvitesResponse {
    return {
      signups: [],
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectInvitesResponse>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.signups?.length) {
      json["signups"] = msg.signups.map(ProjectSignupJSON._writeMessage);
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectInvitesResponse,
    json: any
  ): GetProjectInvitesResponse {
    const _signups_ = json["signups"];
    if (_signups_) {
      for (const item of _signups_) {
        const m = ProjectSignup.initialize();
        ProjectSignupJSON._readMessage(m, item);
        msg.signups.push(m);
      }
    }
    return msg;
  },
};

export const SendInviteRequestJSON = {
  /**
   * Serializes SendInviteRequest to JSON.
   */
  encode: function (msg: Partial<SendInviteRequest>): string {
    return JSON.stringify(SendInviteRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes SendInviteRequest from JSON.
   */
  decode: function (json: string): SendInviteRequest {
    return SendInviteRequestJSON._readMessage(
      SendInviteRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes SendInviteRequest with all fields set to their default value.
   */
  initialize: function (): SendInviteRequest {
    return {
      projectId: "",
      signupId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<SendInviteRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    if (msg.signupId) {
      json["signupId"] = msg.signupId;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: SendInviteRequest,
    json: any
  ): SendInviteRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    const _signupId_ = json["signupId"];
    if (_signupId_) {
      msg.signupId = _signupId_;
    }
    return msg;
  },
};

export const SendInviteResponseJSON = {
  /**
   * Serializes SendInviteResponse to JSON.
   */
  encode: function (_msg?: Partial<SendInviteResponse>): string {
    return "{}";
  },

  /**
   * Deserializes SendInviteResponse from JSON.
   */
  decode: function (_json?: string): SendInviteResponse {
    return {};
  },

  /**
   * Initializes SendInviteResponse with all fields set to their default value.
   */
  initialize: function (): SendInviteResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<SendInviteResponse>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: SendInviteResponse,
    _json: any
  ): SendInviteResponse {
    return msg;
  },
};

export const GetProjectFormBlocksRequestJSON = {
  /**
   * Serializes GetProjectFormBlocksRequest to JSON.
   */
  encode: function (msg: Partial<GetProjectFormBlocksRequest>): string {
    return JSON.stringify(GetProjectFormBlocksRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes GetProjectFormBlocksRequest from JSON.
   */
  decode: function (json: string): GetProjectFormBlocksRequest {
    return GetProjectFormBlocksRequestJSON._readMessage(
      GetProjectFormBlocksRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetProjectFormBlocksRequest with all fields set to their default value.
   */
  initialize: function (): GetProjectFormBlocksRequest {
    return {
      projectId: "",
      formId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectFormBlocksRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    if (msg.formId) {
      json["formId"] = msg.formId;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectFormBlocksRequest,
    json: any
  ): GetProjectFormBlocksRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    const _formId_ = json["formId"];
    if (_formId_) {
      msg.formId = _formId_;
    }
    return msg;
  },
};

export const GetProjectFormBlocksResponseJSON = {
  /**
   * Serializes GetProjectFormBlocksResponse to JSON.
   */
  encode: function (msg: Partial<GetProjectFormBlocksResponse>): string {
    return JSON.stringify(GetProjectFormBlocksResponseJSON._writeMessage(msg));
  },

  /**
   * Deserializes GetProjectFormBlocksResponse from JSON.
   */
  decode: function (json: string): GetProjectFormBlocksResponse {
    return GetProjectFormBlocksResponseJSON._readMessage(
      GetProjectFormBlocksResponseJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetProjectFormBlocksResponse with all fields set to their default value.
   */
  initialize: function (): GetProjectFormBlocksResponse {
    return {
      blocks: [],
      published: false,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectFormBlocksResponse>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.blocks?.length) {
      json["blocks"] = msg.blocks.map(forms.FormBlockJSON._writeMessage);
    }
    if (msg.published) {
      json["published"] = msg.published;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectFormBlocksResponse,
    json: any
  ): GetProjectFormBlocksResponse {
    const _blocks_ = json["blocks"];
    if (_blocks_) {
      for (const item of _blocks_) {
        const m = forms.FormBlock.initialize();
        forms.FormBlockJSON._readMessage(m, item);
        msg.blocks.push(m);
      }
    }
    const _published_ = json["published"];
    if (_published_) {
      msg.published = _published_;
    }
    return msg;
  },
};

export const UpsertProjectFormBlockRequestJSON = {
  /**
   * Serializes UpsertProjectFormBlockRequest to JSON.
   */
  encode: function (msg: Partial<UpsertProjectFormBlockRequest>): string {
    return JSON.stringify(UpsertProjectFormBlockRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes UpsertProjectFormBlockRequest from JSON.
   */
  decode: function (json: string): UpsertProjectFormBlockRequest {
    return UpsertProjectFormBlockRequestJSON._readMessage(
      UpsertProjectFormBlockRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes UpsertProjectFormBlockRequest with all fields set to their default value.
   */
  initialize: function (): UpsertProjectFormBlockRequest {
    return {
      blockId: "",
      projectId: "",
      formId: "",
      content: forms.FormBlockContentJSON.initialize(),
      position: 0,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<UpsertProjectFormBlockRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.blockId) {
      json["blockId"] = msg.blockId;
    }
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    if (msg.formId) {
      json["formId"] = msg.formId;
    }
    if (msg.content) {
      const _content_ = forms.FormBlockContentJSON._writeMessage(msg.content);
      if (Object.keys(_content_).length > 0) {
        json["content"] = _content_;
      }
    }
    if (msg.position) {
      json["position"] = msg.position;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpsertProjectFormBlockRequest,
    json: any
  ): UpsertProjectFormBlockRequest {
    const _blockId_ = json["blockId"];
    if (_blockId_) {
      msg.blockId = _blockId_;
    }
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    const _formId_ = json["formId"];
    if (_formId_) {
      msg.formId = _formId_;
    }
    const _content_ = json["content"];
    if (_content_) {
      const m = forms.FormBlockContent.initialize();
      forms.FormBlockContentJSON._readMessage(m, _content_);
      msg.content = m;
    }
    const _position_ = json["position"];
    if (_position_) {
      msg.position = _position_;
    }
    return msg;
  },
};

export const UpsertProjectFormBlockResponseJSON = {
  /**
   * Serializes UpsertProjectFormBlockResponse to JSON.
   */
  encode: function (msg: Partial<UpsertProjectFormBlockResponse>): string {
    return JSON.stringify(
      UpsertProjectFormBlockResponseJSON._writeMessage(msg)
    );
  },

  /**
   * Deserializes UpsertProjectFormBlockResponse from JSON.
   */
  decode: function (json: string): UpsertProjectFormBlockResponse {
    return UpsertProjectFormBlockResponseJSON._readMessage(
      UpsertProjectFormBlockResponseJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes UpsertProjectFormBlockResponse with all fields set to their default value.
   */
  initialize: function (): UpsertProjectFormBlockResponse {
    return {
      block: forms.FormBlockJSON.initialize(),
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<UpsertProjectFormBlockResponse>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.block) {
      const _block_ = forms.FormBlockJSON._writeMessage(msg.block);
      if (Object.keys(_block_).length > 0) {
        json["block"] = _block_;
      }
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpsertProjectFormBlockResponse,
    json: any
  ): UpsertProjectFormBlockResponse {
    const _block_ = json["block"];
    if (_block_) {
      const m = forms.FormBlock.initialize();
      forms.FormBlockJSON._readMessage(m, _block_);
      msg.block = m;
    }
    return msg;
  },
};

export const DeleteProjectFormBlockRequestJSON = {
  /**
   * Serializes DeleteProjectFormBlockRequest to JSON.
   */
  encode: function (msg: Partial<DeleteProjectFormBlockRequest>): string {
    return JSON.stringify(DeleteProjectFormBlockRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes DeleteProjectFormBlockRequest from JSON.
   */
  decode: function (json: string): DeleteProjectFormBlockRequest {
    return DeleteProjectFormBlockRequestJSON._readMessage(
      DeleteProjectFormBlockRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes DeleteProjectFormBlockRequest with all fields set to their default value.
   */
  initialize: function (): DeleteProjectFormBlockRequest {
    return {
      projectId: "",
      formId: "",
      blockId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<DeleteProjectFormBlockRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    if (msg.formId) {
      json["formId"] = msg.formId;
    }
    if (msg.blockId) {
      json["blockId"] = msg.blockId;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: DeleteProjectFormBlockRequest,
    json: any
  ): DeleteProjectFormBlockRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    const _formId_ = json["formId"];
    if (_formId_) {
      msg.formId = _formId_;
    }
    const _blockId_ = json["blockId"];
    if (_blockId_) {
      msg.blockId = _blockId_;
    }
    return msg;
  },
};

export const DeleteProjectFormBlockResponseJSON = {
  /**
   * Serializes DeleteProjectFormBlockResponse to JSON.
   */
  encode: function (_msg?: Partial<DeleteProjectFormBlockResponse>): string {
    return "{}";
  },

  /**
   * Deserializes DeleteProjectFormBlockResponse from JSON.
   */
  decode: function (_json?: string): DeleteProjectFormBlockResponse {
    return {};
  },

  /**
   * Initializes DeleteProjectFormBlockResponse with all fields set to their default value.
   */
  initialize: function (): DeleteProjectFormBlockResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<DeleteProjectFormBlockResponse>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: DeleteProjectFormBlockResponse,
    _json: any
  ): DeleteProjectFormBlockResponse {
    return msg;
  },
};

export const ProjectScheduleJSON = {
  /**
   * Serializes ProjectSchedule to JSON.
   */
  encode: function (msg: Partial<ProjectSchedule>): string {
    return JSON.stringify(ProjectScheduleJSON._writeMessage(msg));
  },

  /**
   * Deserializes ProjectSchedule from JSON.
   */
  decode: function (json: string): ProjectSchedule {
    return ProjectScheduleJSON._readMessage(
      ProjectScheduleJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes ProjectSchedule with all fields set to their default value.
   */
  initialize: function (): ProjectSchedule {
    return {
      id: "",
      at: undefined,
      done: undefined,
      repeat: undefined,
      projectId: "",
      limit: undefined,
      positionLt: undefined,
      ageLt: undefined,
      karmaGt: undefined,
      successScheduledCount: undefined,
      createdAt: 0,
      updatedAt: 0,
      canceled: false,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<ProjectSchedule>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.id) {
      json["id"] = msg.id;
    }
    if (msg.at != undefined) {
      json["at"] = msg.at;
    }
    if (msg.done != undefined) {
      json["done"] = msg.done;
    }
    if (msg.repeat != undefined) {
      json["repeat"] = msg.repeat;
    }
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    if (msg.limit != undefined) {
      json["limit"] = msg.limit;
    }
    if (msg.positionLt != undefined) {
      json["positionLt"] = msg.positionLt;
    }
    if (msg.ageLt != undefined) {
      json["ageLt"] = msg.ageLt;
    }
    if (msg.karmaGt != undefined) {
      json["karmaGt"] = msg.karmaGt;
    }
    if (msg.successScheduledCount != undefined) {
      json["successScheduledCount"] = msg.successScheduledCount;
    }
    if (msg.createdAt) {
      json["createdAt"] = msg.createdAt;
    }
    if (msg.updatedAt) {
      json["updatedAt"] = msg.updatedAt;
    }
    if (msg.canceled) {
      json["canceled"] = msg.canceled;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (msg: ProjectSchedule, json: any): ProjectSchedule {
    const _id_ = json["id"];
    if (_id_) {
      msg.id = _id_;
    }
    const _at_ = json["at"];
    if (_at_) {
      msg.at = _at_;
    }
    const _done_ = json["done"];
    if (_done_) {
      msg.done = _done_;
    }
    const _repeat_ = json["repeat"];
    if (_repeat_) {
      msg.repeat = _repeat_;
    }
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    const _limit_ = json["limit"];
    if (_limit_) {
      msg.limit = _limit_;
    }
    const _positionLt_ = json["positionLt"];
    if (_positionLt_) {
      msg.positionLt = _positionLt_;
    }
    const _ageLt_ = json["ageLt"];
    if (_ageLt_) {
      msg.ageLt = _ageLt_;
    }
    const _karmaGt_ = json["karmaGt"];
    if (_karmaGt_) {
      msg.karmaGt = _karmaGt_;
    }
    const _successScheduledCount_ = json["successScheduledCount"];
    if (_successScheduledCount_) {
      msg.successScheduledCount = _successScheduledCount_;
    }
    const _createdAt_ = json["createdAt"];
    if (_createdAt_) {
      msg.createdAt = _createdAt_;
    }
    const _updatedAt_ = json["updatedAt"];
    if (_updatedAt_) {
      msg.updatedAt = _updatedAt_;
    }
    const _canceled_ = json["canceled"];
    if (_canceled_) {
      msg.canceled = _canceled_;
    }
    return msg;
  },
};

export const GetProjectSchedulesRequestJSON = {
  /**
   * Serializes GetProjectSchedulesRequest to JSON.
   */
  encode: function (msg: Partial<GetProjectSchedulesRequest>): string {
    return JSON.stringify(GetProjectSchedulesRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes GetProjectSchedulesRequest from JSON.
   */
  decode: function (json: string): GetProjectSchedulesRequest {
    return GetProjectSchedulesRequestJSON._readMessage(
      GetProjectSchedulesRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetProjectSchedulesRequest with all fields set to their default value.
   */
  initialize: function (): GetProjectSchedulesRequest {
    return {
      projectId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectSchedulesRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectSchedulesRequest,
    json: any
  ): GetProjectSchedulesRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    return msg;
  },
};

export const GetProjectSchedulesResponseJSON = {
  /**
   * Serializes GetProjectSchedulesResponse to JSON.
   */
  encode: function (msg: Partial<GetProjectSchedulesResponse>): string {
    return JSON.stringify(GetProjectSchedulesResponseJSON._writeMessage(msg));
  },

  /**
   * Deserializes GetProjectSchedulesResponse from JSON.
   */
  decode: function (json: string): GetProjectSchedulesResponse {
    return GetProjectSchedulesResponseJSON._readMessage(
      GetProjectSchedulesResponseJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetProjectSchedulesResponse with all fields set to their default value.
   */
  initialize: function (): GetProjectSchedulesResponse {
    return {
      schedules: [],
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectSchedulesResponse>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.schedules?.length) {
      json["schedules"] = msg.schedules.map(ProjectScheduleJSON._writeMessage);
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectSchedulesResponse,
    json: any
  ): GetProjectSchedulesResponse {
    const _schedules_ = json["schedules"];
    if (_schedules_) {
      for (const item of _schedules_) {
        const m = ProjectSchedule.initialize();
        ProjectScheduleJSON._readMessage(m, item);
        msg.schedules.push(m);
      }
    }
    return msg;
  },
};

export const CreateProjectScheduleRequestJSON = {
  /**
   * Serializes CreateProjectScheduleRequest to JSON.
   */
  encode: function (msg: Partial<CreateProjectScheduleRequest>): string {
    return JSON.stringify(CreateProjectScheduleRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes CreateProjectScheduleRequest from JSON.
   */
  decode: function (json: string): CreateProjectScheduleRequest {
    return CreateProjectScheduleRequestJSON._readMessage(
      CreateProjectScheduleRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes CreateProjectScheduleRequest with all fields set to their default value.
   */
  initialize: function (): CreateProjectScheduleRequest {
    return {
      projectId: "",
      at: undefined,
      repeat: undefined,
      positionLt: undefined,
      ageLt: undefined,
      karmaGt: undefined,
      limit: undefined,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<CreateProjectScheduleRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    if (msg.at != undefined) {
      json["at"] = msg.at;
    }
    if (msg.repeat != undefined) {
      json["repeat"] = msg.repeat;
    }
    if (msg.positionLt != undefined) {
      json["positionLt"] = msg.positionLt;
    }
    if (msg.ageLt != undefined) {
      json["ageLt"] = msg.ageLt;
    }
    if (msg.karmaGt != undefined) {
      json["karmaGt"] = msg.karmaGt;
    }
    if (msg.limit != undefined) {
      json["limit"] = msg.limit;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: CreateProjectScheduleRequest,
    json: any
  ): CreateProjectScheduleRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    const _at_ = json["at"];
    if (_at_) {
      msg.at = _at_;
    }
    const _repeat_ = json["repeat"];
    if (_repeat_) {
      msg.repeat = _repeat_;
    }
    const _positionLt_ = json["positionLt"];
    if (_positionLt_) {
      msg.positionLt = _positionLt_;
    }
    const _ageLt_ = json["ageLt"];
    if (_ageLt_) {
      msg.ageLt = _ageLt_;
    }
    const _karmaGt_ = json["karmaGt"];
    if (_karmaGt_) {
      msg.karmaGt = _karmaGt_;
    }
    const _limit_ = json["limit"];
    if (_limit_) {
      msg.limit = _limit_;
    }
    return msg;
  },
};

export const CreateProjectScheduleResponseJSON = {
  /**
   * Serializes CreateProjectScheduleResponse to JSON.
   */
  encode: function (_msg?: Partial<CreateProjectScheduleResponse>): string {
    return "{}";
  },

  /**
   * Deserializes CreateProjectScheduleResponse from JSON.
   */
  decode: function (_json?: string): CreateProjectScheduleResponse {
    return {};
  },

  /**
   * Initializes CreateProjectScheduleResponse with all fields set to their default value.
   */
  initialize: function (): CreateProjectScheduleResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<CreateProjectScheduleResponse>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: CreateProjectScheduleResponse,
    _json: any
  ): CreateProjectScheduleResponse {
    return msg;
  },
};

export const HideProjectScheduleRequestJSON = {
  /**
   * Serializes HideProjectScheduleRequest to JSON.
   */
  encode: function (msg: Partial<HideProjectScheduleRequest>): string {
    return JSON.stringify(HideProjectScheduleRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes HideProjectScheduleRequest from JSON.
   */
  decode: function (json: string): HideProjectScheduleRequest {
    return HideProjectScheduleRequestJSON._readMessage(
      HideProjectScheduleRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes HideProjectScheduleRequest with all fields set to their default value.
   */
  initialize: function (): HideProjectScheduleRequest {
    return {
      projectId: "",
      scheduleId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<HideProjectScheduleRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    if (msg.scheduleId) {
      json["scheduleId"] = msg.scheduleId;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: HideProjectScheduleRequest,
    json: any
  ): HideProjectScheduleRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    const _scheduleId_ = json["scheduleId"];
    if (_scheduleId_) {
      msg.scheduleId = _scheduleId_;
    }
    return msg;
  },
};

export const HideProjectScheduleResponseJSON = {
  /**
   * Serializes HideProjectScheduleResponse to JSON.
   */
  encode: function (_msg?: Partial<HideProjectScheduleResponse>): string {
    return "{}";
  },

  /**
   * Deserializes HideProjectScheduleResponse from JSON.
   */
  decode: function (_json?: string): HideProjectScheduleResponse {
    return {};
  },

  /**
   * Initializes HideProjectScheduleResponse with all fields set to their default value.
   */
  initialize: function (): HideProjectScheduleResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<HideProjectScheduleResponse>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: HideProjectScheduleResponse,
    _json: any
  ): HideProjectScheduleResponse {
    return msg;
  },
};

export const CancelProjectScheduleRequestJSON = {
  /**
   * Serializes CancelProjectScheduleRequest to JSON.
   */
  encode: function (msg: Partial<CancelProjectScheduleRequest>): string {
    return JSON.stringify(CancelProjectScheduleRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes CancelProjectScheduleRequest from JSON.
   */
  decode: function (json: string): CancelProjectScheduleRequest {
    return CancelProjectScheduleRequestJSON._readMessage(
      CancelProjectScheduleRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes CancelProjectScheduleRequest with all fields set to their default value.
   */
  initialize: function (): CancelProjectScheduleRequest {
    return {
      projectId: "",
      scheduleId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<CancelProjectScheduleRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    if (msg.scheduleId) {
      json["scheduleId"] = msg.scheduleId;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: CancelProjectScheduleRequest,
    json: any
  ): CancelProjectScheduleRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    const _scheduleId_ = json["scheduleId"];
    if (_scheduleId_) {
      msg.scheduleId = _scheduleId_;
    }
    return msg;
  },
};

export const CancelProjectScheduleResponseJSON = {
  /**
   * Serializes CancelProjectScheduleResponse to JSON.
   */
  encode: function (_msg?: Partial<CancelProjectScheduleResponse>): string {
    return "{}";
  },

  /**
   * Deserializes CancelProjectScheduleResponse from JSON.
   */
  decode: function (_json?: string): CancelProjectScheduleResponse {
    return {};
  },

  /**
   * Initializes CancelProjectScheduleResponse with all fields set to their default value.
   */
  initialize: function (): CancelProjectScheduleResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<CancelProjectScheduleResponse>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: CancelProjectScheduleResponse,
    _json: any
  ): CancelProjectScheduleResponse {
    return msg;
  },
};

export const ResumeProjectScheduleRequestJSON = {
  /**
   * Serializes ResumeProjectScheduleRequest to JSON.
   */
  encode: function (msg: Partial<ResumeProjectScheduleRequest>): string {
    return JSON.stringify(ResumeProjectScheduleRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes ResumeProjectScheduleRequest from JSON.
   */
  decode: function (json: string): ResumeProjectScheduleRequest {
    return ResumeProjectScheduleRequestJSON._readMessage(
      ResumeProjectScheduleRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes ResumeProjectScheduleRequest with all fields set to their default value.
   */
  initialize: function (): ResumeProjectScheduleRequest {
    return {
      projectId: "",
      scheduleId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<ResumeProjectScheduleRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    if (msg.scheduleId) {
      json["scheduleId"] = msg.scheduleId;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: ResumeProjectScheduleRequest,
    json: any
  ): ResumeProjectScheduleRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    const _scheduleId_ = json["scheduleId"];
    if (_scheduleId_) {
      msg.scheduleId = _scheduleId_;
    }
    return msg;
  },
};

export const ResumeProjectScheduleResponseJSON = {
  /**
   * Serializes ResumeProjectScheduleResponse to JSON.
   */
  encode: function (_msg?: Partial<ResumeProjectScheduleResponse>): string {
    return "{}";
  },

  /**
   * Deserializes ResumeProjectScheduleResponse from JSON.
   */
  decode: function (_json?: string): ResumeProjectScheduleResponse {
    return {};
  },

  /**
   * Initializes ResumeProjectScheduleResponse with all fields set to their default value.
   */
  initialize: function (): ResumeProjectScheduleResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<ResumeProjectScheduleResponse>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: ResumeProjectScheduleResponse,
    _json: any
  ): ResumeProjectScheduleResponse {
    return msg;
  },
};

export const ReplayProjectScheduleRequestJSON = {
  /**
   * Serializes ReplayProjectScheduleRequest to JSON.
   */
  encode: function (msg: Partial<ReplayProjectScheduleRequest>): string {
    return JSON.stringify(ReplayProjectScheduleRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes ReplayProjectScheduleRequest from JSON.
   */
  decode: function (json: string): ReplayProjectScheduleRequest {
    return ReplayProjectScheduleRequestJSON._readMessage(
      ReplayProjectScheduleRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes ReplayProjectScheduleRequest with all fields set to their default value.
   */
  initialize: function (): ReplayProjectScheduleRequest {
    return {
      projectId: "",
      scheduleId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<ReplayProjectScheduleRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    if (msg.scheduleId) {
      json["scheduleId"] = msg.scheduleId;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: ReplayProjectScheduleRequest,
    json: any
  ): ReplayProjectScheduleRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    const _scheduleId_ = json["scheduleId"];
    if (_scheduleId_) {
      msg.scheduleId = _scheduleId_;
    }
    return msg;
  },
};

export const ReplayProjectScheduleResponseJSON = {
  /**
   * Serializes ReplayProjectScheduleResponse to JSON.
   */
  encode: function (_msg?: Partial<ReplayProjectScheduleResponse>): string {
    return "{}";
  },

  /**
   * Deserializes ReplayProjectScheduleResponse from JSON.
   */
  decode: function (_json?: string): ReplayProjectScheduleResponse {
    return {};
  },

  /**
   * Initializes ReplayProjectScheduleResponse with all fields set to their default value.
   */
  initialize: function (): ReplayProjectScheduleResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<ReplayProjectScheduleResponse>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: ReplayProjectScheduleResponse,
    _json: any
  ): ReplayProjectScheduleResponse {
    return msg;
  },
};

export const UpdateProjectHandleRequestJSON = {
  /**
   * Serializes UpdateProjectHandleRequest to JSON.
   */
  encode: function (msg: Partial<UpdateProjectHandleRequest>): string {
    return JSON.stringify(UpdateProjectHandleRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes UpdateProjectHandleRequest from JSON.
   */
  decode: function (json: string): UpdateProjectHandleRequest {
    return UpdateProjectHandleRequestJSON._readMessage(
      UpdateProjectHandleRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes UpdateProjectHandleRequest with all fields set to their default value.
   */
  initialize: function (): UpdateProjectHandleRequest {
    return {
      projectId: "",
      newHandle: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<UpdateProjectHandleRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    if (msg.newHandle) {
      json["newHandle"] = msg.newHandle;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpdateProjectHandleRequest,
    json: any
  ): UpdateProjectHandleRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    const _newHandle_ = json["newHandle"];
    if (_newHandle_) {
      msg.newHandle = _newHandle_;
    }
    return msg;
  },
};

export const UpdateProjectHandleResponseJSON = {
  /**
   * Serializes UpdateProjectHandleResponse to JSON.
   */
  encode: function (_msg?: Partial<UpdateProjectHandleResponse>): string {
    return "{}";
  },

  /**
   * Deserializes UpdateProjectHandleResponse from JSON.
   */
  decode: function (_json?: string): UpdateProjectHandleResponse {
    return {};
  },

  /**
   * Initializes UpdateProjectHandleResponse with all fields set to their default value.
   */
  initialize: function (): UpdateProjectHandleResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<UpdateProjectHandleResponse>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpdateProjectHandleResponse,
    _json: any
  ): UpdateProjectHandleResponse {
    return msg;
  },
};

export const UpdateProjectEmailReplyToRequestJSON = {
  /**
   * Serializes UpdateProjectEmailReplyToRequest to JSON.
   */
  encode: function (msg: Partial<UpdateProjectEmailReplyToRequest>): string {
    return JSON.stringify(
      UpdateProjectEmailReplyToRequestJSON._writeMessage(msg)
    );
  },

  /**
   * Deserializes UpdateProjectEmailReplyToRequest from JSON.
   */
  decode: function (json: string): UpdateProjectEmailReplyToRequest {
    return UpdateProjectEmailReplyToRequestJSON._readMessage(
      UpdateProjectEmailReplyToRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes UpdateProjectEmailReplyToRequest with all fields set to their default value.
   */
  initialize: function (): UpdateProjectEmailReplyToRequest {
    return {
      projectId: "",
      newEmailReplyTo: undefined,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<UpdateProjectEmailReplyToRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    if (msg.newEmailReplyTo != undefined) {
      json["newEmailReplyTo"] = msg.newEmailReplyTo;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpdateProjectEmailReplyToRequest,
    json: any
  ): UpdateProjectEmailReplyToRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    const _newEmailReplyTo_ = json["newEmailReplyTo"];
    if (_newEmailReplyTo_) {
      msg.newEmailReplyTo = _newEmailReplyTo_;
    }
    return msg;
  },
};

export const UpdateProjectEmailReplyToResponseJSON = {
  /**
   * Serializes UpdateProjectEmailReplyToResponse to JSON.
   */
  encode: function (_msg?: Partial<UpdateProjectEmailReplyToResponse>): string {
    return "{}";
  },

  /**
   * Deserializes UpdateProjectEmailReplyToResponse from JSON.
   */
  decode: function (_json?: string): UpdateProjectEmailReplyToResponse {
    return {};
  },

  /**
   * Initializes UpdateProjectEmailReplyToResponse with all fields set to their default value.
   */
  initialize: function (): UpdateProjectEmailReplyToResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<UpdateProjectEmailReplyToResponse>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpdateProjectEmailReplyToResponse,
    _json: any
  ): UpdateProjectEmailReplyToResponse {
    return msg;
  },
};

export const UpdateProjectNameRequestJSON = {
  /**
   * Serializes UpdateProjectNameRequest to JSON.
   */
  encode: function (msg: Partial<UpdateProjectNameRequest>): string {
    return JSON.stringify(UpdateProjectNameRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes UpdateProjectNameRequest from JSON.
   */
  decode: function (json: string): UpdateProjectNameRequest {
    return UpdateProjectNameRequestJSON._readMessage(
      UpdateProjectNameRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes UpdateProjectNameRequest with all fields set to their default value.
   */
  initialize: function (): UpdateProjectNameRequest {
    return {
      projectId: "",
      newName: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<UpdateProjectNameRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    if (msg.newName) {
      json["newName"] = msg.newName;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpdateProjectNameRequest,
    json: any
  ): UpdateProjectNameRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    const _newName_ = json["newName"];
    if (_newName_) {
      msg.newName = _newName_;
    }
    return msg;
  },
};

export const UpdateProjectNameResponseJSON = {
  /**
   * Serializes UpdateProjectNameResponse to JSON.
   */
  encode: function (_msg?: Partial<UpdateProjectNameResponse>): string {
    return "{}";
  },

  /**
   * Deserializes UpdateProjectNameResponse from JSON.
   */
  decode: function (_json?: string): UpdateProjectNameResponse {
    return {};
  },

  /**
   * Initializes UpdateProjectNameResponse with all fields set to their default value.
   */
  initialize: function (): UpdateProjectNameResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<UpdateProjectNameResponse>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpdateProjectNameResponse,
    _json: any
  ): UpdateProjectNameResponse {
    return msg;
  },
};

export const FormBlockContentWithBlockIDJSON = {
  /**
   * Serializes FormBlockContentWithBlockID to JSON.
   */
  encode: function (msg: Partial<FormBlockContentWithBlockID>): string {
    return JSON.stringify(FormBlockContentWithBlockIDJSON._writeMessage(msg));
  },

  /**
   * Deserializes FormBlockContentWithBlockID from JSON.
   */
  decode: function (json: string): FormBlockContentWithBlockID {
    return FormBlockContentWithBlockIDJSON._readMessage(
      FormBlockContentWithBlockIDJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes FormBlockContentWithBlockID with all fields set to their default value.
   */
  initialize: function (): FormBlockContentWithBlockID {
    return {
      id: "",
      content: forms.FormBlockContentJSON.initialize(),
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<FormBlockContentWithBlockID>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.id) {
      json["id"] = msg.id;
    }
    if (msg.content) {
      const _content_ = forms.FormBlockContentJSON._writeMessage(msg.content);
      if (Object.keys(_content_).length > 0) {
        json["content"] = _content_;
      }
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: FormBlockContentWithBlockID,
    json: any
  ): FormBlockContentWithBlockID {
    const _id_ = json["id"];
    if (_id_) {
      msg.id = _id_;
    }
    const _content_ = json["content"];
    if (_content_) {
      const m = forms.FormBlockContent.initialize();
      forms.FormBlockContentJSON._readMessage(m, _content_);
      msg.content = m;
    }
    return msg;
  },
};

export const ProjectSignupDetailsJSON = {
  /**
   * Serializes ProjectSignupDetails to JSON.
   */
  encode: function (msg: Partial<ProjectSignupDetails>): string {
    return JSON.stringify(ProjectSignupDetailsJSON._writeMessage(msg));
  },

  /**
   * Deserializes ProjectSignupDetails from JSON.
   */
  decode: function (json: string): ProjectSignupDetails {
    return ProjectSignupDetailsJSON._readMessage(
      ProjectSignupDetailsJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes ProjectSignupDetails with all fields set to their default value.
   */
  initialize: function (): ProjectSignupDetails {
    return {
      signup: ProjectSignupJSON.initialize(),
      project: ProjectJSON.initialize(),
      answers: [],
      blocks: [],
      referrals: [],
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<ProjectSignupDetails>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.signup) {
      const _signup_ = ProjectSignupJSON._writeMessage(msg.signup);
      if (Object.keys(_signup_).length > 0) {
        json["signup"] = _signup_;
      }
    }
    if (msg.project) {
      const _project_ = ProjectJSON._writeMessage(msg.project);
      if (Object.keys(_project_).length > 0) {
        json["project"] = _project_;
      }
    }
    if (msg.answers?.length) {
      json["answers"] = msg.answers.map(forms.AnswerJSON._writeMessage);
    }
    if (msg.blocks?.length) {
      json["blocks"] = msg.blocks.map(
        FormBlockContentWithBlockIDJSON._writeMessage
      );
    }
    if (msg.referrals?.length) {
      json["referrals"] = msg.referrals.map(ProjectSignupJSON._writeMessage);
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: ProjectSignupDetails,
    json: any
  ): ProjectSignupDetails {
    const _signup_ = json["signup"];
    if (_signup_) {
      const m = ProjectSignup.initialize();
      ProjectSignupJSON._readMessage(m, _signup_);
      msg.signup = m;
    }
    const _project_ = json["project"];
    if (_project_) {
      const m = Project.initialize();
      ProjectJSON._readMessage(m, _project_);
      msg.project = m;
    }
    const _answers_ = json["answers"];
    if (_answers_) {
      for (const item of _answers_) {
        const m = forms.Answer.initialize();
        forms.AnswerJSON._readMessage(m, item);
        msg.answers.push(m);
      }
    }
    const _blocks_ = json["blocks"];
    if (_blocks_) {
      for (const item of _blocks_) {
        const m = FormBlockContentWithBlockID.initialize();
        FormBlockContentWithBlockIDJSON._readMessage(m, item);
        msg.blocks.push(m);
      }
    }
    const _referrals_ = json["referrals"];
    if (_referrals_) {
      for (const item of _referrals_) {
        const m = ProjectSignup.initialize();
        ProjectSignupJSON._readMessage(m, item);
        msg.referrals.push(m);
      }
    }
    return msg;
  },
};

export const GetProjectSignupDetailsRequestJSON = {
  /**
   * Serializes GetProjectSignupDetailsRequest to JSON.
   */
  encode: function (msg: Partial<GetProjectSignupDetailsRequest>): string {
    return JSON.stringify(
      GetProjectSignupDetailsRequestJSON._writeMessage(msg)
    );
  },

  /**
   * Deserializes GetProjectSignupDetailsRequest from JSON.
   */
  decode: function (json: string): GetProjectSignupDetailsRequest {
    return GetProjectSignupDetailsRequestJSON._readMessage(
      GetProjectSignupDetailsRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetProjectSignupDetailsRequest with all fields set to their default value.
   */
  initialize: function (): GetProjectSignupDetailsRequest {
    return {
      projectId: "",
      signupId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectSignupDetailsRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    if (msg.signupId) {
      json["signupId"] = msg.signupId;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectSignupDetailsRequest,
    json: any
  ): GetProjectSignupDetailsRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    const _signupId_ = json["signupId"];
    if (_signupId_) {
      msg.signupId = _signupId_;
    }
    return msg;
  },
};

export const GetProjectSignupDetailsResponseJSON = {
  /**
   * Serializes GetProjectSignupDetailsResponse to JSON.
   */
  encode: function (msg: Partial<GetProjectSignupDetailsResponse>): string {
    return JSON.stringify(
      GetProjectSignupDetailsResponseJSON._writeMessage(msg)
    );
  },

  /**
   * Deserializes GetProjectSignupDetailsResponse from JSON.
   */
  decode: function (json: string): GetProjectSignupDetailsResponse {
    return GetProjectSignupDetailsResponseJSON._readMessage(
      GetProjectSignupDetailsResponseJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetProjectSignupDetailsResponse with all fields set to their default value.
   */
  initialize: function (): GetProjectSignupDetailsResponse {
    return {
      details: ProjectSignupDetailsJSON.initialize(),
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectSignupDetailsResponse>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.details) {
      const _details_ = ProjectSignupDetailsJSON._writeMessage(msg.details);
      if (Object.keys(_details_).length > 0) {
        json["details"] = _details_;
      }
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectSignupDetailsResponse,
    json: any
  ): GetProjectSignupDetailsResponse {
    const _details_ = json["details"];
    if (_details_) {
      const m = ProjectSignupDetails.initialize();
      ProjectSignupDetailsJSON._readMessage(m, _details_);
      msg.details = m;
    }
    return msg;
  },
};

export const GetSignupPositionRequestJSON = {
  /**
   * Serializes GetSignupPositionRequest to JSON.
   */
  encode: function (msg: Partial<GetSignupPositionRequest>): string {
    return JSON.stringify(GetSignupPositionRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes GetSignupPositionRequest from JSON.
   */
  decode: function (json: string): GetSignupPositionRequest {
    return GetSignupPositionRequestJSON._readMessage(
      GetSignupPositionRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetSignupPositionRequest with all fields set to their default value.
   */
  initialize: function (): GetSignupPositionRequest {
    return {
      signupCode: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetSignupPositionRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.signupCode) {
      json["signupCode"] = msg.signupCode;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetSignupPositionRequest,
    json: any
  ): GetSignupPositionRequest {
    const _signupCode_ = json["signupCode"];
    if (_signupCode_) {
      msg.signupCode = _signupCode_;
    }
    return msg;
  },
};

export const GetSignupPositionResponseJSON = {
  /**
   * Serializes GetSignupPositionResponse to JSON.
   */
  encode: function (msg: Partial<GetSignupPositionResponse>): string {
    return JSON.stringify(GetSignupPositionResponseJSON._writeMessage(msg));
  },

  /**
   * Deserializes GetSignupPositionResponse from JSON.
   */
  decode: function (json: string): GetSignupPositionResponse {
    return GetSignupPositionResponseJSON._readMessage(
      GetSignupPositionResponseJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetSignupPositionResponse with all fields set to their default value.
   */
  initialize: function (): GetSignupPositionResponse {
    return {
      projectName: "",
      position: 0n,
      referrals: 0,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetSignupPositionResponse>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectName) {
      json["projectName"] = msg.projectName;
    }
    if (msg.position) {
      json["position"] = msg.position.toString();
    }
    if (msg.referrals) {
      json["referrals"] = msg.referrals;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetSignupPositionResponse,
    json: any
  ): GetSignupPositionResponse {
    const _projectName_ = json["projectName"];
    if (_projectName_) {
      msg.projectName = _projectName_;
    }
    const _position_ = json["position"];
    if (_position_) {
      msg.position = BigInt(_position_);
    }
    const _referrals_ = json["referrals"];
    if (_referrals_) {
      msg.referrals = _referrals_;
    }
    return msg;
  },
};

export const AddProjectDomainRequestJSON = {
  /**
   * Serializes AddProjectDomainRequest to JSON.
   */
  encode: function (msg: Partial<AddProjectDomainRequest>): string {
    return JSON.stringify(AddProjectDomainRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes AddProjectDomainRequest from JSON.
   */
  decode: function (json: string): AddProjectDomainRequest {
    return AddProjectDomainRequestJSON._readMessage(
      AddProjectDomainRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes AddProjectDomainRequest with all fields set to their default value.
   */
  initialize: function (): AddProjectDomainRequest {
    return {
      projectId: "",
      domainName: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<AddProjectDomainRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    if (msg.domainName) {
      json["domainName"] = msg.domainName;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: AddProjectDomainRequest,
    json: any
  ): AddProjectDomainRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    const _domainName_ = json["domainName"];
    if (_domainName_) {
      msg.domainName = _domainName_;
    }
    return msg;
  },
};

export const AddProjectDomainResponseJSON = {
  /**
   * Serializes AddProjectDomainResponse to JSON.
   */
  encode: function (_msg?: Partial<AddProjectDomainResponse>): string {
    return "{}";
  },

  /**
   * Deserializes AddProjectDomainResponse from JSON.
   */
  decode: function (_json?: string): AddProjectDomainResponse {
    return {};
  },

  /**
   * Initializes AddProjectDomainResponse with all fields set to their default value.
   */
  initialize: function (): AddProjectDomainResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<AddProjectDomainResponse>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: AddProjectDomainResponse,
    _json: any
  ): AddProjectDomainResponse {
    return msg;
  },
};

export const RemoveProjectDomainRequestJSON = {
  /**
   * Serializes RemoveProjectDomainRequest to JSON.
   */
  encode: function (msg: Partial<RemoveProjectDomainRequest>): string {
    return JSON.stringify(RemoveProjectDomainRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes RemoveProjectDomainRequest from JSON.
   */
  decode: function (json: string): RemoveProjectDomainRequest {
    return RemoveProjectDomainRequestJSON._readMessage(
      RemoveProjectDomainRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes RemoveProjectDomainRequest with all fields set to their default value.
   */
  initialize: function (): RemoveProjectDomainRequest {
    return {
      projectId: "",
      domainId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<RemoveProjectDomainRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    if (msg.domainId) {
      json["domainId"] = msg.domainId;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: RemoveProjectDomainRequest,
    json: any
  ): RemoveProjectDomainRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    const _domainId_ = json["domainId"];
    if (_domainId_) {
      msg.domainId = _domainId_;
    }
    return msg;
  },
};

export const RemoveProjectDomainResponseJSON = {
  /**
   * Serializes RemoveProjectDomainResponse to JSON.
   */
  encode: function (_msg?: Partial<RemoveProjectDomainResponse>): string {
    return "{}";
  },

  /**
   * Deserializes RemoveProjectDomainResponse from JSON.
   */
  decode: function (_json?: string): RemoveProjectDomainResponse {
    return {};
  },

  /**
   * Initializes RemoveProjectDomainResponse with all fields set to their default value.
   */
  initialize: function (): RemoveProjectDomainResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<RemoveProjectDomainResponse>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: RemoveProjectDomainResponse,
    _json: any
  ): RemoveProjectDomainResponse {
    return msg;
  },
};

export const GetProjectDomainRequestJSON = {
  /**
   * Serializes GetProjectDomainRequest to JSON.
   */
  encode: function (msg: Partial<GetProjectDomainRequest>): string {
    return JSON.stringify(GetProjectDomainRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes GetProjectDomainRequest from JSON.
   */
  decode: function (json: string): GetProjectDomainRequest {
    return GetProjectDomainRequestJSON._readMessage(
      GetProjectDomainRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetProjectDomainRequest with all fields set to their default value.
   */
  initialize: function (): GetProjectDomainRequest {
    return {
      projectId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectDomainRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectDomainRequest,
    json: any
  ): GetProjectDomainRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    return msg;
  },
};

export const DomainJSON = {
  /**
   * Serializes Domain to JSON.
   */
  encode: function (msg: Partial<Domain>): string {
    return JSON.stringify(DomainJSON._writeMessage(msg));
  },

  /**
   * Deserializes Domain from JSON.
   */
  decode: function (json: string): Domain {
    return DomainJSON._readMessage(DomainJSON.initialize(), JSON.parse(json));
  },

  /**
   * Initializes Domain with all fields set to their default value.
   */
  initialize: function (): Domain {
    return {
      id: "",
      domainName: "",
      verified: false,
      verificationCode: "",
      lastCheckedAt: 0,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (msg: Partial<Domain>): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.id) {
      json["id"] = msg.id;
    }
    if (msg.domainName) {
      json["domainName"] = msg.domainName;
    }
    if (msg.verified) {
      json["verified"] = msg.verified;
    }
    if (msg.verificationCode) {
      json["verificationCode"] = msg.verificationCode;
    }
    if (msg.lastCheckedAt) {
      json["lastCheckedAt"] = msg.lastCheckedAt;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (msg: Domain, json: any): Domain {
    const _id_ = json["id"];
    if (_id_) {
      msg.id = _id_;
    }
    const _domainName_ = json["domainName"];
    if (_domainName_) {
      msg.domainName = _domainName_;
    }
    const _verified_ = json["verified"];
    if (_verified_) {
      msg.verified = _verified_;
    }
    const _verificationCode_ = json["verificationCode"];
    if (_verificationCode_) {
      msg.verificationCode = _verificationCode_;
    }
    const _lastCheckedAt_ = json["lastCheckedAt"];
    if (_lastCheckedAt_) {
      msg.lastCheckedAt = _lastCheckedAt_;
    }
    return msg;
  },
};

export const GetProjectDomainResponseJSON = {
  /**
   * Serializes GetProjectDomainResponse to JSON.
   */
  encode: function (msg: Partial<GetProjectDomainResponse>): string {
    return JSON.stringify(GetProjectDomainResponseJSON._writeMessage(msg));
  },

  /**
   * Deserializes GetProjectDomainResponse from JSON.
   */
  decode: function (json: string): GetProjectDomainResponse {
    return GetProjectDomainResponseJSON._readMessage(
      GetProjectDomainResponseJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetProjectDomainResponse with all fields set to their default value.
   */
  initialize: function (): GetProjectDomainResponse {
    return {
      domain: undefined,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectDomainResponse>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.domain != undefined) {
      const _domain_ = DomainJSON._writeMessage(msg.domain);
      json["domain"] = _domain_;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectDomainResponse,
    json: any
  ): GetProjectDomainResponse {
    const _domain_ = json["domain"];
    if (_domain_) {
      const m = Domain.initialize();
      DomainJSON._readMessage(m, _domain_);
      msg.domain = m;
    }
    return msg;
  },
};

export const CheckProjectDomainVerifiedRequestJSON = {
  /**
   * Serializes CheckProjectDomainVerifiedRequest to JSON.
   */
  encode: function (msg: Partial<CheckProjectDomainVerifiedRequest>): string {
    return JSON.stringify(
      CheckProjectDomainVerifiedRequestJSON._writeMessage(msg)
    );
  },

  /**
   * Deserializes CheckProjectDomainVerifiedRequest from JSON.
   */
  decode: function (json: string): CheckProjectDomainVerifiedRequest {
    return CheckProjectDomainVerifiedRequestJSON._readMessage(
      CheckProjectDomainVerifiedRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes CheckProjectDomainVerifiedRequest with all fields set to their default value.
   */
  initialize: function (): CheckProjectDomainVerifiedRequest {
    return {
      domainId: "",
      projectId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<CheckProjectDomainVerifiedRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.domainId) {
      json["domainId"] = msg.domainId;
    }
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: CheckProjectDomainVerifiedRequest,
    json: any
  ): CheckProjectDomainVerifiedRequest {
    const _domainId_ = json["domainId"];
    if (_domainId_) {
      msg.domainId = _domainId_;
    }
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    return msg;
  },
};

export const CheckProjectDomainVerifiedResponseJSON = {
  /**
   * Serializes CheckProjectDomainVerifiedResponse to JSON.
   */
  encode: function (msg: Partial<CheckProjectDomainVerifiedResponse>): string {
    return JSON.stringify(
      CheckProjectDomainVerifiedResponseJSON._writeMessage(msg)
    );
  },

  /**
   * Deserializes CheckProjectDomainVerifiedResponse from JSON.
   */
  decode: function (json: string): CheckProjectDomainVerifiedResponse {
    return CheckProjectDomainVerifiedResponseJSON._readMessage(
      CheckProjectDomainVerifiedResponseJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes CheckProjectDomainVerifiedResponse with all fields set to their default value.
   */
  initialize: function (): CheckProjectDomainVerifiedResponse {
    return {
      verified: false,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<CheckProjectDomainVerifiedResponse>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.verified) {
      json["verified"] = msg.verified;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: CheckProjectDomainVerifiedResponse,
    json: any
  ): CheckProjectDomainVerifiedResponse {
    const _verified_ = json["verified"];
    if (_verified_) {
      msg.verified = _verified_;
    }
    return msg;
  },
};

export const EmailTemplateJSON = {
  /**
   * Serializes EmailTemplate to JSON.
   */
  encode: function (msg: Partial<EmailTemplate>): string {
    return JSON.stringify(EmailTemplateJSON._writeMessage(msg));
  },

  /**
   * Deserializes EmailTemplate from JSON.
   */
  decode: function (json: string): EmailTemplate {
    return EmailTemplateJSON._readMessage(
      EmailTemplateJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes EmailTemplate with all fields set to their default value.
   */
  initialize: function (): EmailTemplate {
    return {
      id: "",
      type: "",
      content: "",
      default: false,
      variables: {},
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<EmailTemplate>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.id) {
      json["id"] = msg.id;
    }
    if (msg.type) {
      json["type"] = msg.type;
    }
    if (msg.content) {
      json["content"] = msg.content;
    }
    if (msg.default) {
      json["default"] = msg.default;
    }
    if (msg.variables) {
      const _variables_ = Object.fromEntries(
        Object.entries(msg.variables)
          .map(([key, value]) => ({ key: key as any, value: value as any }))
          .map(EmailTemplateJSON.Variables._writeMessage)
          .map(({ key, value }) => [key, value])
      );
      if (Object.keys(_variables_).length > 0) {
        json["variables"] = _variables_;
      }
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (msg: EmailTemplate, json: any): EmailTemplate {
    const _id_ = json["id"];
    if (_id_) {
      msg.id = _id_;
    }
    const _type_ = json["type"];
    if (_type_) {
      msg.type = _type_;
    }
    const _content_ = json["content"];
    if (_content_) {
      msg.content = _content_;
    }
    const _default_ = json["default"];
    if (_default_) {
      msg.default = _default_;
    }
    const _variables_ = json["variables"];
    if (_variables_) {
      msg.variables = Object.fromEntries(
        Object.entries(_variables_)
          .map(([key, value]) => ({ key: key as any, value: value as any }))
          .map(EmailTemplateJSON.Variables._readMessage)
          .map(({ key, value }) => [key, value])
      );
    }
    return msg;
  },

  Variables: {
    /**
     * @private
     */
    _writeMessage: function (
      msg: Partial<EmailTemplate.Variables>
    ): Record<string, unknown> {
      const json: Record<string, unknown> = {};
      if (msg.key) {
        json["key"] = msg.key;
      }
      if (msg.value) {
        json["value"] = msg.value;
      }
      return json;
    },

    /**
     * @private
     */
    _readMessage: function (
      msg: EmailTemplate.Variables,
      json: any
    ): EmailTemplate.Variables {
      const _key_ = json["key"];
      if (_key_) {
        msg.key = _key_;
      }
      const _value_ = json["value"];
      if (_value_) {
        msg.value = _value_;
      }
      return msg;
    },
  },
};

export const GetProjectEmailTemplatesRequestJSON = {
  /**
   * Serializes GetProjectEmailTemplatesRequest to JSON.
   */
  encode: function (msg: Partial<GetProjectEmailTemplatesRequest>): string {
    return JSON.stringify(
      GetProjectEmailTemplatesRequestJSON._writeMessage(msg)
    );
  },

  /**
   * Deserializes GetProjectEmailTemplatesRequest from JSON.
   */
  decode: function (json: string): GetProjectEmailTemplatesRequest {
    return GetProjectEmailTemplatesRequestJSON._readMessage(
      GetProjectEmailTemplatesRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetProjectEmailTemplatesRequest with all fields set to their default value.
   */
  initialize: function (): GetProjectEmailTemplatesRequest {
    return {
      projectId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectEmailTemplatesRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectEmailTemplatesRequest,
    json: any
  ): GetProjectEmailTemplatesRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    return msg;
  },
};

export const GetProjectEmailTemplatesResponseJSON = {
  /**
   * Serializes GetProjectEmailTemplatesResponse to JSON.
   */
  encode: function (msg: Partial<GetProjectEmailTemplatesResponse>): string {
    return JSON.stringify(
      GetProjectEmailTemplatesResponseJSON._writeMessage(msg)
    );
  },

  /**
   * Deserializes GetProjectEmailTemplatesResponse from JSON.
   */
  decode: function (json: string): GetProjectEmailTemplatesResponse {
    return GetProjectEmailTemplatesResponseJSON._readMessage(
      GetProjectEmailTemplatesResponseJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetProjectEmailTemplatesResponse with all fields set to their default value.
   */
  initialize: function (): GetProjectEmailTemplatesResponse {
    return {
      templates: [],
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectEmailTemplatesResponse>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.templates?.length) {
      json["templates"] = msg.templates.map(EmailTemplateJSON._writeMessage);
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectEmailTemplatesResponse,
    json: any
  ): GetProjectEmailTemplatesResponse {
    const _templates_ = json["templates"];
    if (_templates_) {
      for (const item of _templates_) {
        const m = EmailTemplate.initialize();
        EmailTemplateJSON._readMessage(m, item);
        msg.templates.push(m);
      }
    }
    return msg;
  },
};

export const UpsertProjectEmailTemplateRequestJSON = {
  /**
   * Serializes UpsertProjectEmailTemplateRequest to JSON.
   */
  encode: function (msg: Partial<UpsertProjectEmailTemplateRequest>): string {
    return JSON.stringify(
      UpsertProjectEmailTemplateRequestJSON._writeMessage(msg)
    );
  },

  /**
   * Deserializes UpsertProjectEmailTemplateRequest from JSON.
   */
  decode: function (json: string): UpsertProjectEmailTemplateRequest {
    return UpsertProjectEmailTemplateRequestJSON._readMessage(
      UpsertProjectEmailTemplateRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes UpsertProjectEmailTemplateRequest with all fields set to their default value.
   */
  initialize: function (): UpsertProjectEmailTemplateRequest {
    return {
      projectId: "",
      templateId: "",
      content: "",
      type: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<UpsertProjectEmailTemplateRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    if (msg.templateId) {
      json["templateId"] = msg.templateId;
    }
    if (msg.content) {
      json["content"] = msg.content;
    }
    if (msg.type) {
      json["type"] = msg.type;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpsertProjectEmailTemplateRequest,
    json: any
  ): UpsertProjectEmailTemplateRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    const _templateId_ = json["templateId"];
    if (_templateId_) {
      msg.templateId = _templateId_;
    }
    const _content_ = json["content"];
    if (_content_) {
      msg.content = _content_;
    }
    const _type_ = json["type"];
    if (_type_) {
      msg.type = _type_;
    }
    return msg;
  },
};

export const UpsertProjectEmailTemplateResponseJSON = {
  /**
   * Serializes UpsertProjectEmailTemplateResponse to JSON.
   */
  encode: function (
    _msg?: Partial<UpsertProjectEmailTemplateResponse>
  ): string {
    return "{}";
  },

  /**
   * Deserializes UpsertProjectEmailTemplateResponse from JSON.
   */
  decode: function (_json?: string): UpsertProjectEmailTemplateResponse {
    return {};
  },

  /**
   * Initializes UpsertProjectEmailTemplateResponse with all fields set to their default value.
   */
  initialize: function (): UpsertProjectEmailTemplateResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<UpsertProjectEmailTemplateResponse>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpsertProjectEmailTemplateResponse,
    _json: any
  ): UpsertProjectEmailTemplateResponse {
    return msg;
  },
};

export const GetProjectWebhooksRequestJSON = {
  /**
   * Serializes GetProjectWebhooksRequest to JSON.
   */
  encode: function (msg: Partial<GetProjectWebhooksRequest>): string {
    return JSON.stringify(GetProjectWebhooksRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes GetProjectWebhooksRequest from JSON.
   */
  decode: function (json: string): GetProjectWebhooksRequest {
    return GetProjectWebhooksRequestJSON._readMessage(
      GetProjectWebhooksRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetProjectWebhooksRequest with all fields set to their default value.
   */
  initialize: function (): GetProjectWebhooksRequest {
    return {
      projectId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectWebhooksRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectWebhooksRequest,
    json: any
  ): GetProjectWebhooksRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    return msg;
  },
};

export const GetProjectWebhooksResponseJSON = {
  /**
   * Serializes GetProjectWebhooksResponse to JSON.
   */
  encode: function (msg: Partial<GetProjectWebhooksResponse>): string {
    return JSON.stringify(GetProjectWebhooksResponseJSON._writeMessage(msg));
  },

  /**
   * Deserializes GetProjectWebhooksResponse from JSON.
   */
  decode: function (json: string): GetProjectWebhooksResponse {
    return GetProjectWebhooksResponseJSON._readMessage(
      GetProjectWebhooksResponseJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetProjectWebhooksResponse with all fields set to their default value.
   */
  initialize: function (): GetProjectWebhooksResponse {
    return {
      webhookInviteAccepted: undefined,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectWebhooksResponse>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.webhookInviteAccepted != undefined) {
      json["webhookInviteAccepted"] = msg.webhookInviteAccepted;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectWebhooksResponse,
    json: any
  ): GetProjectWebhooksResponse {
    const _webhookInviteAccepted_ = json["webhookInviteAccepted"];
    if (_webhookInviteAccepted_) {
      msg.webhookInviteAccepted = _webhookInviteAccepted_;
    }
    return msg;
  },
};

export const UpsertProjectWebhookRequestJSON = {
  /**
   * Serializes UpsertProjectWebhookRequest to JSON.
   */
  encode: function (msg: Partial<UpsertProjectWebhookRequest>): string {
    return JSON.stringify(UpsertProjectWebhookRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes UpsertProjectWebhookRequest from JSON.
   */
  decode: function (json: string): UpsertProjectWebhookRequest {
    return UpsertProjectWebhookRequestJSON._readMessage(
      UpsertProjectWebhookRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes UpsertProjectWebhookRequest with all fields set to their default value.
   */
  initialize: function (): UpsertProjectWebhookRequest {
    return {
      projectId: "",
      event: "",
      url: undefined,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<UpsertProjectWebhookRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    if (msg.event) {
      json["event"] = msg.event;
    }
    if (msg.url != undefined) {
      json["url"] = msg.url;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpsertProjectWebhookRequest,
    json: any
  ): UpsertProjectWebhookRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    const _event_ = json["event"];
    if (_event_) {
      msg.event = _event_;
    }
    const _url_ = json["url"];
    if (_url_) {
      msg.url = _url_;
    }
    return msg;
  },
};

export const UpsertProjectWebhookResponseJSON = {
  /**
   * Serializes UpsertProjectWebhookResponse to JSON.
   */
  encode: function (_msg?: Partial<UpsertProjectWebhookResponse>): string {
    return "{}";
  },

  /**
   * Deserializes UpsertProjectWebhookResponse from JSON.
   */
  decode: function (_json?: string): UpsertProjectWebhookResponse {
    return {};
  },

  /**
   * Initializes UpsertProjectWebhookResponse with all fields set to their default value.
   */
  initialize: function (): UpsertProjectWebhookResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<UpsertProjectWebhookResponse>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpsertProjectWebhookResponse,
    _json: any
  ): UpsertProjectWebhookResponse {
    return msg;
  },
};

export const GetAPIKeysRequestJSON = {
  /**
   * Serializes GetAPIKeysRequest to JSON.
   */
  encode: function (msg: Partial<GetAPIKeysRequest>): string {
    return JSON.stringify(GetAPIKeysRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes GetAPIKeysRequest from JSON.
   */
  decode: function (json: string): GetAPIKeysRequest {
    return GetAPIKeysRequestJSON._readMessage(
      GetAPIKeysRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetAPIKeysRequest with all fields set to their default value.
   */
  initialize: function (): GetAPIKeysRequest {
    return {
      projectId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetAPIKeysRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetAPIKeysRequest,
    json: any
  ): GetAPIKeysRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    return msg;
  },
};

export const APIKeyJSON = {
  /**
   * Serializes APIKey to JSON.
   */
  encode: function (msg: Partial<APIKey>): string {
    return JSON.stringify(APIKeyJSON._writeMessage(msg));
  },

  /**
   * Deserializes APIKey from JSON.
   */
  decode: function (json: string): APIKey {
    return APIKeyJSON._readMessage(APIKeyJSON.initialize(), JSON.parse(json));
  },

  /**
   * Initializes APIKey with all fields set to their default value.
   */
  initialize: function (): APIKey {
    return {
      id: "",
      key: "",
      createdAt: 0,
      lastUsed: undefined,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (msg: Partial<APIKey>): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.id) {
      json["id"] = msg.id;
    }
    if (msg.key) {
      json["key"] = msg.key;
    }
    if (msg.createdAt) {
      json["createdAt"] = msg.createdAt;
    }
    if (msg.lastUsed != undefined) {
      json["lastUsed"] = msg.lastUsed;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (msg: APIKey, json: any): APIKey {
    const _id_ = json["id"];
    if (_id_) {
      msg.id = _id_;
    }
    const _key_ = json["key"];
    if (_key_) {
      msg.key = _key_;
    }
    const _createdAt_ = json["createdAt"];
    if (_createdAt_) {
      msg.createdAt = _createdAt_;
    }
    const _lastUsed_ = json["lastUsed"];
    if (_lastUsed_) {
      msg.lastUsed = _lastUsed_;
    }
    return msg;
  },
};

export const GetAPIKeysResponseJSON = {
  /**
   * Serializes GetAPIKeysResponse to JSON.
   */
  encode: function (msg: Partial<GetAPIKeysResponse>): string {
    return JSON.stringify(GetAPIKeysResponseJSON._writeMessage(msg));
  },

  /**
   * Deserializes GetAPIKeysResponse from JSON.
   */
  decode: function (json: string): GetAPIKeysResponse {
    return GetAPIKeysResponseJSON._readMessage(
      GetAPIKeysResponseJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetAPIKeysResponse with all fields set to their default value.
   */
  initialize: function (): GetAPIKeysResponse {
    return {
      keys: [],
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetAPIKeysResponse>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.keys?.length) {
      json["keys"] = msg.keys.map(APIKeyJSON._writeMessage);
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetAPIKeysResponse,
    json: any
  ): GetAPIKeysResponse {
    const _keys_ = json["keys"];
    if (_keys_) {
      for (const item of _keys_) {
        const m = APIKey.initialize();
        APIKeyJSON._readMessage(m, item);
        msg.keys.push(m);
      }
    }
    return msg;
  },
};

export const CreateAPIKeyRequestJSON = {
  /**
   * Serializes CreateAPIKeyRequest to JSON.
   */
  encode: function (msg: Partial<CreateAPIKeyRequest>): string {
    return JSON.stringify(CreateAPIKeyRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes CreateAPIKeyRequest from JSON.
   */
  decode: function (json: string): CreateAPIKeyRequest {
    return CreateAPIKeyRequestJSON._readMessage(
      CreateAPIKeyRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes CreateAPIKeyRequest with all fields set to their default value.
   */
  initialize: function (): CreateAPIKeyRequest {
    return {
      projectId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<CreateAPIKeyRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: CreateAPIKeyRequest,
    json: any
  ): CreateAPIKeyRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    return msg;
  },
};

export const CreateAPIKeyResponseJSON = {
  /**
   * Serializes CreateAPIKeyResponse to JSON.
   */
  encode: function (_msg?: Partial<CreateAPIKeyResponse>): string {
    return "{}";
  },

  /**
   * Deserializes CreateAPIKeyResponse from JSON.
   */
  decode: function (_json?: string): CreateAPIKeyResponse {
    return {};
  },

  /**
   * Initializes CreateAPIKeyResponse with all fields set to their default value.
   */
  initialize: function (): CreateAPIKeyResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<CreateAPIKeyResponse>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: CreateAPIKeyResponse,
    _json: any
  ): CreateAPIKeyResponse {
    return msg;
  },
};

export const DeleteAPIKeyRequestJSON = {
  /**
   * Serializes DeleteAPIKeyRequest to JSON.
   */
  encode: function (msg: Partial<DeleteAPIKeyRequest>): string {
    return JSON.stringify(DeleteAPIKeyRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes DeleteAPIKeyRequest from JSON.
   */
  decode: function (json: string): DeleteAPIKeyRequest {
    return DeleteAPIKeyRequestJSON._readMessage(
      DeleteAPIKeyRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes DeleteAPIKeyRequest with all fields set to their default value.
   */
  initialize: function (): DeleteAPIKeyRequest {
    return {
      projectId: "",
      apiKeyId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<DeleteAPIKeyRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    if (msg.apiKeyId) {
      json["apiKeyId"] = msg.apiKeyId;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: DeleteAPIKeyRequest,
    json: any
  ): DeleteAPIKeyRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    const _apiKeyId_ = json["apiKeyId"];
    if (_apiKeyId_) {
      msg.apiKeyId = _apiKeyId_;
    }
    return msg;
  },
};

export const DeleteAPIKeyResponseJSON = {
  /**
   * Serializes DeleteAPIKeyResponse to JSON.
   */
  encode: function (_msg?: Partial<DeleteAPIKeyResponse>): string {
    return "{}";
  },

  /**
   * Deserializes DeleteAPIKeyResponse from JSON.
   */
  decode: function (_json?: string): DeleteAPIKeyResponse {
    return {};
  },

  /**
   * Initializes DeleteAPIKeyResponse with all fields set to their default value.
   */
  initialize: function (): DeleteAPIKeyResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<DeleteAPIKeyResponse>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: DeleteAPIKeyResponse,
    _json: any
  ): DeleteAPIKeyResponse {
    return msg;
  },
};

export const ConfirmSignupEmailRequestJSON = {
  /**
   * Serializes ConfirmSignupEmailRequest to JSON.
   */
  encode: function (msg: Partial<ConfirmSignupEmailRequest>): string {
    return JSON.stringify(ConfirmSignupEmailRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes ConfirmSignupEmailRequest from JSON.
   */
  decode: function (json: string): ConfirmSignupEmailRequest {
    return ConfirmSignupEmailRequestJSON._readMessage(
      ConfirmSignupEmailRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes ConfirmSignupEmailRequest with all fields set to their default value.
   */
  initialize: function (): ConfirmSignupEmailRequest {
    return {
      code: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<ConfirmSignupEmailRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.code) {
      json["code"] = msg.code;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: ConfirmSignupEmailRequest,
    json: any
  ): ConfirmSignupEmailRequest {
    const _code_ = json["code"];
    if (_code_) {
      msg.code = _code_;
    }
    return msg;
  },
};

export const ConfirmSignupEmailResponseJSON = {
  /**
   * Serializes ConfirmSignupEmailResponse to JSON.
   */
  encode: function (_msg?: Partial<ConfirmSignupEmailResponse>): string {
    return "{}";
  },

  /**
   * Deserializes ConfirmSignupEmailResponse from JSON.
   */
  decode: function (_json?: string): ConfirmSignupEmailResponse {
    return {};
  },

  /**
   * Initializes ConfirmSignupEmailResponse with all fields set to their default value.
   */
  initialize: function (): ConfirmSignupEmailResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<ConfirmSignupEmailResponse>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: ConfirmSignupEmailResponse,
    _json: any
  ): ConfirmSignupEmailResponse {
    return msg;
  },
};

export const AccessCodeJSON = {
  /**
   * Serializes AccessCode to JSON.
   */
  encode: function (msg: Partial<AccessCode>): string {
    return JSON.stringify(AccessCodeJSON._writeMessage(msg));
  },

  /**
   * Deserializes AccessCode from JSON.
   */
  decode: function (json: string): AccessCode {
    return AccessCodeJSON._readMessage(
      AccessCodeJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes AccessCode with all fields set to their default value.
   */
  initialize: function (): AccessCode {
    return {
      id: "",
      code: "",
      maxUses: 0,
      used: 0,
      createdAt: 0,
      destination: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (msg: Partial<AccessCode>): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.id) {
      json["id"] = msg.id;
    }
    if (msg.code) {
      json["code"] = msg.code;
    }
    if (msg.maxUses) {
      json["maxUses"] = msg.maxUses;
    }
    if (msg.used) {
      json["used"] = msg.used;
    }
    if (msg.createdAt) {
      json["createdAt"] = msg.createdAt;
    }
    if (msg.destination) {
      json["destination"] = msg.destination;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (msg: AccessCode, json: any): AccessCode {
    const _id_ = json["id"];
    if (_id_) {
      msg.id = _id_;
    }
    const _code_ = json["code"];
    if (_code_) {
      msg.code = _code_;
    }
    const _maxUses_ = json["maxUses"];
    if (_maxUses_) {
      msg.maxUses = _maxUses_;
    }
    const _used_ = json["used"];
    if (_used_) {
      msg.used = _used_;
    }
    const _createdAt_ = json["createdAt"];
    if (_createdAt_) {
      msg.createdAt = _createdAt_;
    }
    const _destination_ = json["destination"];
    if (_destination_) {
      msg.destination = _destination_;
    }
    return msg;
  },
};

export const GetProjectAccessCodesRequestJSON = {
  /**
   * Serializes GetProjectAccessCodesRequest to JSON.
   */
  encode: function (msg: Partial<GetProjectAccessCodesRequest>): string {
    return JSON.stringify(GetProjectAccessCodesRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes GetProjectAccessCodesRequest from JSON.
   */
  decode: function (json: string): GetProjectAccessCodesRequest {
    return GetProjectAccessCodesRequestJSON._readMessage(
      GetProjectAccessCodesRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetProjectAccessCodesRequest with all fields set to their default value.
   */
  initialize: function (): GetProjectAccessCodesRequest {
    return {
      projectId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectAccessCodesRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectAccessCodesRequest,
    json: any
  ): GetProjectAccessCodesRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    return msg;
  },
};

export const GetProjectAccessCodesResponseJSON = {
  /**
   * Serializes GetProjectAccessCodesResponse to JSON.
   */
  encode: function (msg: Partial<GetProjectAccessCodesResponse>): string {
    return JSON.stringify(GetProjectAccessCodesResponseJSON._writeMessage(msg));
  },

  /**
   * Deserializes GetProjectAccessCodesResponse from JSON.
   */
  decode: function (json: string): GetProjectAccessCodesResponse {
    return GetProjectAccessCodesResponseJSON._readMessage(
      GetProjectAccessCodesResponseJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetProjectAccessCodesResponse with all fields set to their default value.
   */
  initialize: function (): GetProjectAccessCodesResponse {
    return {
      codes: [],
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectAccessCodesResponse>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.codes?.length) {
      json["codes"] = msg.codes.map(AccessCodeJSON._writeMessage);
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectAccessCodesResponse,
    json: any
  ): GetProjectAccessCodesResponse {
    const _codes_ = json["codes"];
    if (_codes_) {
      for (const item of _codes_) {
        const m = AccessCode.initialize();
        AccessCodeJSON._readMessage(m, item);
        msg.codes.push(m);
      }
    }
    return msg;
  },
};

export const GenerateProjectAccessCodesRequestJSON = {
  /**
   * Serializes GenerateProjectAccessCodesRequest to JSON.
   */
  encode: function (msg: Partial<GenerateProjectAccessCodesRequest>): string {
    return JSON.stringify(
      GenerateProjectAccessCodesRequestJSON._writeMessage(msg)
    );
  },

  /**
   * Deserializes GenerateProjectAccessCodesRequest from JSON.
   */
  decode: function (json: string): GenerateProjectAccessCodesRequest {
    return GenerateProjectAccessCodesRequestJSON._readMessage(
      GenerateProjectAccessCodesRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GenerateProjectAccessCodesRequest with all fields set to their default value.
   */
  initialize: function (): GenerateProjectAccessCodesRequest {
    return {
      projectId: "",
      count: 0,
      destination: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GenerateProjectAccessCodesRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    if (msg.count) {
      json["count"] = msg.count;
    }
    if (msg.destination) {
      json["destination"] = msg.destination;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GenerateProjectAccessCodesRequest,
    json: any
  ): GenerateProjectAccessCodesRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    const _count_ = json["count"];
    if (_count_) {
      msg.count = _count_;
    }
    const _destination_ = json["destination"];
    if (_destination_) {
      msg.destination = _destination_;
    }
    return msg;
  },
};

export const GenerateProjectAccessCodesResponseJSON = {
  /**
   * Serializes GenerateProjectAccessCodesResponse to JSON.
   */
  encode: function (msg: Partial<GenerateProjectAccessCodesResponse>): string {
    return JSON.stringify(
      GenerateProjectAccessCodesResponseJSON._writeMessage(msg)
    );
  },

  /**
   * Deserializes GenerateProjectAccessCodesResponse from JSON.
   */
  decode: function (json: string): GenerateProjectAccessCodesResponse {
    return GenerateProjectAccessCodesResponseJSON._readMessage(
      GenerateProjectAccessCodesResponseJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GenerateProjectAccessCodesResponse with all fields set to their default value.
   */
  initialize: function (): GenerateProjectAccessCodesResponse {
    return {
      codes: [],
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GenerateProjectAccessCodesResponse>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.codes?.length) {
      json["codes"] = msg.codes.map(AccessCodeJSON._writeMessage);
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GenerateProjectAccessCodesResponse,
    json: any
  ): GenerateProjectAccessCodesResponse {
    const _codes_ = json["codes"];
    if (_codes_) {
      for (const item of _codes_) {
        const m = AccessCode.initialize();
        AccessCodeJSON._readMessage(m, item);
        msg.codes.push(m);
      }
    }
    return msg;
  },
};

export const DeleteProjectAccessCodeRequestJSON = {
  /**
   * Serializes DeleteProjectAccessCodeRequest to JSON.
   */
  encode: function (msg: Partial<DeleteProjectAccessCodeRequest>): string {
    return JSON.stringify(
      DeleteProjectAccessCodeRequestJSON._writeMessage(msg)
    );
  },

  /**
   * Deserializes DeleteProjectAccessCodeRequest from JSON.
   */
  decode: function (json: string): DeleteProjectAccessCodeRequest {
    return DeleteProjectAccessCodeRequestJSON._readMessage(
      DeleteProjectAccessCodeRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes DeleteProjectAccessCodeRequest with all fields set to their default value.
   */
  initialize: function (): DeleteProjectAccessCodeRequest {
    return {
      projectId: "",
      codeId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<DeleteProjectAccessCodeRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    if (msg.codeId) {
      json["codeId"] = msg.codeId;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: DeleteProjectAccessCodeRequest,
    json: any
  ): DeleteProjectAccessCodeRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    const _codeId_ = json["codeId"];
    if (_codeId_) {
      msg.codeId = _codeId_;
    }
    return msg;
  },
};

export const DeleteProjectAccessCodeResponseJSON = {
  /**
   * Serializes DeleteProjectAccessCodeResponse to JSON.
   */
  encode: function (_msg?: Partial<DeleteProjectAccessCodeResponse>): string {
    return "{}";
  },

  /**
   * Deserializes DeleteProjectAccessCodeResponse from JSON.
   */
  decode: function (_json?: string): DeleteProjectAccessCodeResponse {
    return {};
  },

  /**
   * Initializes DeleteProjectAccessCodeResponse with all fields set to their default value.
   */
  initialize: function (): DeleteProjectAccessCodeResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<DeleteProjectAccessCodeResponse>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: DeleteProjectAccessCodeResponse,
    _json: any
  ): DeleteProjectAccessCodeResponse {
    return msg;
  },
};

export const UpdateProjectRequiresAccessCodeRequestJSON = {
  /**
   * Serializes UpdateProjectRequiresAccessCodeRequest to JSON.
   */
  encode: function (
    msg: Partial<UpdateProjectRequiresAccessCodeRequest>
  ): string {
    return JSON.stringify(
      UpdateProjectRequiresAccessCodeRequestJSON._writeMessage(msg)
    );
  },

  /**
   * Deserializes UpdateProjectRequiresAccessCodeRequest from JSON.
   */
  decode: function (json: string): UpdateProjectRequiresAccessCodeRequest {
    return UpdateProjectRequiresAccessCodeRequestJSON._readMessage(
      UpdateProjectRequiresAccessCodeRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes UpdateProjectRequiresAccessCodeRequest with all fields set to their default value.
   */
  initialize: function (): UpdateProjectRequiresAccessCodeRequest {
    return {
      projectId: "",
      value: false,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<UpdateProjectRequiresAccessCodeRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    if (msg.value) {
      json["value"] = msg.value;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpdateProjectRequiresAccessCodeRequest,
    json: any
  ): UpdateProjectRequiresAccessCodeRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    const _value_ = json["value"];
    if (_value_) {
      msg.value = _value_;
    }
    return msg;
  },
};

export const UpdateProjectRequiresAccessCodeResponseJSON = {
  /**
   * Serializes UpdateProjectRequiresAccessCodeResponse to JSON.
   */
  encode: function (
    _msg?: Partial<UpdateProjectRequiresAccessCodeResponse>
  ): string {
    return "{}";
  },

  /**
   * Deserializes UpdateProjectRequiresAccessCodeResponse from JSON.
   */
  decode: function (_json?: string): UpdateProjectRequiresAccessCodeResponse {
    return {};
  },

  /**
   * Initializes UpdateProjectRequiresAccessCodeResponse with all fields set to their default value.
   */
  initialize: function (): UpdateProjectRequiresAccessCodeResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<UpdateProjectRequiresAccessCodeResponse>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpdateProjectRequiresAccessCodeResponse,
    _json: any
  ): UpdateProjectRequiresAccessCodeResponse {
    return msg;
  },
};

export const UpdateProjectInviteRedirectUrlRequestJSON = {
  /**
   * Serializes UpdateProjectInviteRedirectUrlRequest to JSON.
   */
  encode: function (
    msg: Partial<UpdateProjectInviteRedirectUrlRequest>
  ): string {
    return JSON.stringify(
      UpdateProjectInviteRedirectUrlRequestJSON._writeMessage(msg)
    );
  },

  /**
   * Deserializes UpdateProjectInviteRedirectUrlRequest from JSON.
   */
  decode: function (json: string): UpdateProjectInviteRedirectUrlRequest {
    return UpdateProjectInviteRedirectUrlRequestJSON._readMessage(
      UpdateProjectInviteRedirectUrlRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes UpdateProjectInviteRedirectUrlRequest with all fields set to their default value.
   */
  initialize: function (): UpdateProjectInviteRedirectUrlRequest {
    return {
      projectId: "",
      url: undefined,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<UpdateProjectInviteRedirectUrlRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    if (msg.url != undefined) {
      json["url"] = msg.url;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpdateProjectInviteRedirectUrlRequest,
    json: any
  ): UpdateProjectInviteRedirectUrlRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    const _url_ = json["url"];
    if (_url_) {
      msg.url = _url_;
    }
    return msg;
  },
};

export const UpdateProjectInviteRedirectUrlResponseJSON = {
  /**
   * Serializes UpdateProjectInviteRedirectUrlResponse to JSON.
   */
  encode: function (
    _msg?: Partial<UpdateProjectInviteRedirectUrlResponse>
  ): string {
    return "{}";
  },

  /**
   * Deserializes UpdateProjectInviteRedirectUrlResponse from JSON.
   */
  decode: function (_json?: string): UpdateProjectInviteRedirectUrlResponse {
    return {};
  },

  /**
   * Initializes UpdateProjectInviteRedirectUrlResponse with all fields set to their default value.
   */
  initialize: function (): UpdateProjectInviteRedirectUrlResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<UpdateProjectInviteRedirectUrlResponse>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpdateProjectInviteRedirectUrlResponse,
    _json: any
  ): UpdateProjectInviteRedirectUrlResponse {
    return msg;
  },
};

export const UpdateProjectInviteAcceptedCallbackRequestJSON = {
  /**
   * Serializes UpdateProjectInviteAcceptedCallbackRequest to JSON.
   */
  encode: function (
    msg: Partial<UpdateProjectInviteAcceptedCallbackRequest>
  ): string {
    return JSON.stringify(
      UpdateProjectInviteAcceptedCallbackRequestJSON._writeMessage(msg)
    );
  },

  /**
   * Deserializes UpdateProjectInviteAcceptedCallbackRequest from JSON.
   */
  decode: function (json: string): UpdateProjectInviteAcceptedCallbackRequest {
    return UpdateProjectInviteAcceptedCallbackRequestJSON._readMessage(
      UpdateProjectInviteAcceptedCallbackRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes UpdateProjectInviteAcceptedCallbackRequest with all fields set to their default value.
   */
  initialize: function (): UpdateProjectInviteAcceptedCallbackRequest {
    return {
      projectId: "",
      url: undefined,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<UpdateProjectInviteAcceptedCallbackRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    if (msg.url != undefined) {
      json["url"] = msg.url;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpdateProjectInviteAcceptedCallbackRequest,
    json: any
  ): UpdateProjectInviteAcceptedCallbackRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    const _url_ = json["url"];
    if (_url_) {
      msg.url = _url_;
    }
    return msg;
  },
};

export const UpdateProjectInviteAcceptedCallbackResponseJSON = {
  /**
   * Serializes UpdateProjectInviteAcceptedCallbackResponse to JSON.
   */
  encode: function (
    _msg?: Partial<UpdateProjectInviteAcceptedCallbackResponse>
  ): string {
    return "{}";
  },

  /**
   * Deserializes UpdateProjectInviteAcceptedCallbackResponse from JSON.
   */
  decode: function (
    _json?: string
  ): UpdateProjectInviteAcceptedCallbackResponse {
    return {};
  },

  /**
   * Initializes UpdateProjectInviteAcceptedCallbackResponse with all fields set to their default value.
   */
  initialize: function (): UpdateProjectInviteAcceptedCallbackResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<UpdateProjectInviteAcceptedCallbackResponse>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpdateProjectInviteAcceptedCallbackResponse,
    _json: any
  ): UpdateProjectInviteAcceptedCallbackResponse {
    return msg;
  },
};

export const UpdatePublishProjectFormRequestJSON = {
  /**
   * Serializes UpdatePublishProjectFormRequest to JSON.
   */
  encode: function (msg: Partial<UpdatePublishProjectFormRequest>): string {
    return JSON.stringify(
      UpdatePublishProjectFormRequestJSON._writeMessage(msg)
    );
  },

  /**
   * Deserializes UpdatePublishProjectFormRequest from JSON.
   */
  decode: function (json: string): UpdatePublishProjectFormRequest {
    return UpdatePublishProjectFormRequestJSON._readMessage(
      UpdatePublishProjectFormRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes UpdatePublishProjectFormRequest with all fields set to their default value.
   */
  initialize: function (): UpdatePublishProjectFormRequest {
    return {
      projectId: "",
      published: false,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<UpdatePublishProjectFormRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    if (msg.published) {
      json["published"] = msg.published;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpdatePublishProjectFormRequest,
    json: any
  ): UpdatePublishProjectFormRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    const _published_ = json["published"];
    if (_published_) {
      msg.published = _published_;
    }
    return msg;
  },
};

export const UpdatePublishProjectFormResponseJSON = {
  /**
   * Serializes UpdatePublishProjectFormResponse to JSON.
   */
  encode: function (_msg?: Partial<UpdatePublishProjectFormResponse>): string {
    return "{}";
  },

  /**
   * Deserializes UpdatePublishProjectFormResponse from JSON.
   */
  decode: function (_json?: string): UpdatePublishProjectFormResponse {
    return {};
  },

  /**
   * Initializes UpdatePublishProjectFormResponse with all fields set to their default value.
   */
  initialize: function (): UpdatePublishProjectFormResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<UpdatePublishProjectFormResponse>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpdatePublishProjectFormResponse,
    _json: any
  ): UpdatePublishProjectFormResponse {
    return msg;
  },
};

export const GetProjectMembersRequestJSON = {
  /**
   * Serializes GetProjectMembersRequest to JSON.
   */
  encode: function (msg: Partial<GetProjectMembersRequest>): string {
    return JSON.stringify(GetProjectMembersRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes GetProjectMembersRequest from JSON.
   */
  decode: function (json: string): GetProjectMembersRequest {
    return GetProjectMembersRequestJSON._readMessage(
      GetProjectMembersRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetProjectMembersRequest with all fields set to their default value.
   */
  initialize: function (): GetProjectMembersRequest {
    return {
      projectId: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectMembersRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectMembersRequest,
    json: any
  ): GetProjectMembersRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    return msg;
  },
};

export const GetProjectMembersResponseJSON = {
  /**
   * Serializes GetProjectMembersResponse to JSON.
   */
  encode: function (msg: Partial<GetProjectMembersResponse>): string {
    return JSON.stringify(GetProjectMembersResponseJSON._writeMessage(msg));
  },

  /**
   * Deserializes GetProjectMembersResponse from JSON.
   */
  decode: function (json: string): GetProjectMembersResponse {
    return GetProjectMembersResponseJSON._readMessage(
      GetProjectMembersResponseJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetProjectMembersResponse with all fields set to their default value.
   */
  initialize: function (): GetProjectMembersResponse {
    return {
      members: [],
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetProjectMembersResponse>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.members?.length) {
      json["members"] = msg.members.map(MemberJSON._writeMessage);
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetProjectMembersResponse,
    json: any
  ): GetProjectMembersResponse {
    const _members_ = json["members"];
    if (_members_) {
      for (const item of _members_) {
        const m = Member.initialize();
        MemberJSON._readMessage(m, item);
        msg.members.push(m);
      }
    }
    return msg;
  },
};

export const DeleteProjectMemberRequestJSON = {
  /**
   * Serializes DeleteProjectMemberRequest to JSON.
   */
  encode: function (msg: Partial<DeleteProjectMemberRequest>): string {
    return JSON.stringify(DeleteProjectMemberRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes DeleteProjectMemberRequest from JSON.
   */
  decode: function (json: string): DeleteProjectMemberRequest {
    return DeleteProjectMemberRequestJSON._readMessage(
      DeleteProjectMemberRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes DeleteProjectMemberRequest with all fields set to their default value.
   */
  initialize: function (): DeleteProjectMemberRequest {
    return {
      projectId: "",
      id: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<DeleteProjectMemberRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    if (msg.id) {
      json["id"] = msg.id;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: DeleteProjectMemberRequest,
    json: any
  ): DeleteProjectMemberRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    const _id_ = json["id"];
    if (_id_) {
      msg.id = _id_;
    }
    return msg;
  },
};

export const DeleteProjectMemberResponseJSON = {
  /**
   * Serializes DeleteProjectMemberResponse to JSON.
   */
  encode: function (_msg?: Partial<DeleteProjectMemberResponse>): string {
    return "{}";
  },

  /**
   * Deserializes DeleteProjectMemberResponse from JSON.
   */
  decode: function (_json?: string): DeleteProjectMemberResponse {
    return {};
  },

  /**
   * Initializes DeleteProjectMemberResponse with all fields set to their default value.
   */
  initialize: function (): DeleteProjectMemberResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<DeleteProjectMemberResponse>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: DeleteProjectMemberResponse,
    _json: any
  ): DeleteProjectMemberResponse {
    return msg;
  },
};

export const AddProjectMemberRequestJSON = {
  /**
   * Serializes AddProjectMemberRequest to JSON.
   */
  encode: function (msg: Partial<AddProjectMemberRequest>): string {
    return JSON.stringify(AddProjectMemberRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes AddProjectMemberRequest from JSON.
   */
  decode: function (json: string): AddProjectMemberRequest {
    return AddProjectMemberRequestJSON._readMessage(
      AddProjectMemberRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes AddProjectMemberRequest with all fields set to their default value.
   */
  initialize: function (): AddProjectMemberRequest {
    return {
      projectId: "",
      email: "",
      access: 0,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<AddProjectMemberRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    if (msg.email) {
      json["email"] = msg.email;
    }
    if (msg.access) {
      json["access"] = msg.access;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: AddProjectMemberRequest,
    json: any
  ): AddProjectMemberRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    const _email_ = json["email"];
    if (_email_) {
      msg.email = _email_;
    }
    const _access_ = json["access"];
    if (_access_) {
      msg.access = _access_;
    }
    return msg;
  },
};

export const AddProjectMemberResponseJSON = {
  /**
   * Serializes AddProjectMemberResponse to JSON.
   */
  encode: function (_msg?: Partial<AddProjectMemberResponse>): string {
    return "{}";
  },

  /**
   * Deserializes AddProjectMemberResponse from JSON.
   */
  decode: function (_json?: string): AddProjectMemberResponse {
    return {};
  },

  /**
   * Initializes AddProjectMemberResponse with all fields set to their default value.
   */
  initialize: function (): AddProjectMemberResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<AddProjectMemberResponse>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: AddProjectMemberResponse,
    _json: any
  ): AddProjectMemberResponse {
    return msg;
  },
};

export const UpdateProjectMemberAccessRequestJSON = {
  /**
   * Serializes UpdateProjectMemberAccessRequest to JSON.
   */
  encode: function (msg: Partial<UpdateProjectMemberAccessRequest>): string {
    return JSON.stringify(
      UpdateProjectMemberAccessRequestJSON._writeMessage(msg)
    );
  },

  /**
   * Deserializes UpdateProjectMemberAccessRequest from JSON.
   */
  decode: function (json: string): UpdateProjectMemberAccessRequest {
    return UpdateProjectMemberAccessRequestJSON._readMessage(
      UpdateProjectMemberAccessRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes UpdateProjectMemberAccessRequest with all fields set to their default value.
   */
  initialize: function (): UpdateProjectMemberAccessRequest {
    return {
      projectId: "",
      memberId: "",
      access: 0,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<UpdateProjectMemberAccessRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    if (msg.memberId) {
      json["memberId"] = msg.memberId;
    }
    if (msg.access) {
      json["access"] = msg.access;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpdateProjectMemberAccessRequest,
    json: any
  ): UpdateProjectMemberAccessRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    const _memberId_ = json["memberId"];
    if (_memberId_) {
      msg.memberId = _memberId_;
    }
    const _access_ = json["access"];
    if (_access_) {
      msg.access = _access_;
    }
    return msg;
  },
};

export const UpdateProjectMemberAccessResponseJSON = {
  /**
   * Serializes UpdateProjectMemberAccessResponse to JSON.
   */
  encode: function (_msg?: Partial<UpdateProjectMemberAccessResponse>): string {
    return "{}";
  },

  /**
   * Deserializes UpdateProjectMemberAccessResponse from JSON.
   */
  decode: function (_json?: string): UpdateProjectMemberAccessResponse {
    return {};
  },

  /**
   * Initializes UpdateProjectMemberAccessResponse with all fields set to their default value.
   */
  initialize: function (): UpdateProjectMemberAccessResponse {
    return {};
  },

  /**
   * @private
   */
  _writeMessage: function (
    _msg: Partial<UpdateProjectMemberAccessResponse>
  ): Record<string, unknown> {
    return {};
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpdateProjectMemberAccessResponse,
    _json: any
  ): UpdateProjectMemberAccessResponse {
    return msg;
  },
};

export const GetDefaultEmailTemplateRequestJSON = {
  /**
   * Serializes GetDefaultEmailTemplateRequest to JSON.
   */
  encode: function (msg: Partial<GetDefaultEmailTemplateRequest>): string {
    return JSON.stringify(
      GetDefaultEmailTemplateRequestJSON._writeMessage(msg)
    );
  },

  /**
   * Deserializes GetDefaultEmailTemplateRequest from JSON.
   */
  decode: function (json: string): GetDefaultEmailTemplateRequest {
    return GetDefaultEmailTemplateRequestJSON._readMessage(
      GetDefaultEmailTemplateRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetDefaultEmailTemplateRequest with all fields set to their default value.
   */
  initialize: function (): GetDefaultEmailTemplateRequest {
    return {
      projectId: "",
      type: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetDefaultEmailTemplateRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    if (msg.type) {
      json["type"] = msg.type;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetDefaultEmailTemplateRequest,
    json: any
  ): GetDefaultEmailTemplateRequest {
    const _projectId_ = json["projectId"] ?? json["project_id"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    const _type_ = json["type"];
    if (_type_) {
      msg.type = _type_;
    }
    return msg;
  },
};

export const GetDefaultEmailTemplateResponseJSON = {
  /**
   * Serializes GetDefaultEmailTemplateResponse to JSON.
   */
  encode: function (msg: Partial<GetDefaultEmailTemplateResponse>): string {
    return JSON.stringify(
      GetDefaultEmailTemplateResponseJSON._writeMessage(msg)
    );
  },

  /**
   * Deserializes GetDefaultEmailTemplateResponse from JSON.
   */
  decode: function (json: string): GetDefaultEmailTemplateResponse {
    return GetDefaultEmailTemplateResponseJSON._readMessage(
      GetDefaultEmailTemplateResponseJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes GetDefaultEmailTemplateResponse with all fields set to their default value.
   */
  initialize: function (): GetDefaultEmailTemplateResponse {
    return {
      template: EmailTemplateJSON.initialize(),
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<GetDefaultEmailTemplateResponse>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.template) {
      const _template_ = EmailTemplateJSON._writeMessage(msg.template);
      if (Object.keys(_template_).length > 0) {
        json["template"] = _template_;
      }
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: GetDefaultEmailTemplateResponse,
    json: any
  ): GetDefaultEmailTemplateResponse {
    const _template_ = json["template"];
    if (_template_) {
      const m = EmailTemplate.initialize();
      EmailTemplateJSON._readMessage(m, _template_);
      msg.template = m;
    }
    return msg;
  },
};

export const UpdateFormCSSRequestJSON = {
  /**
   * Serializes UpdateFormCSSRequest to JSON.
   */
  encode: function (msg: Partial<UpdateFormCSSRequest>): string {
    return JSON.stringify(UpdateFormCSSRequestJSON._writeMessage(msg));
  },

  /**
   * Deserializes UpdateFormCSSRequest from JSON.
   */
  decode: function (json: string): UpdateFormCSSRequest {
    return UpdateFormCSSRequestJSON._readMessage(
      UpdateFormCSSRequestJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes UpdateFormCSSRequest with all fields set to their default value.
   */
  initialize: function (): UpdateFormCSSRequest {
    return {
      projectId: "",
      css: "",
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<UpdateFormCSSRequest>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.projectId) {
      json["projectId"] = msg.projectId;
    }
    if (msg.css) {
      json["css"] = msg.css;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpdateFormCSSRequest,
    json: any
  ): UpdateFormCSSRequest {
    const _projectId_ = json["projectId"];
    if (_projectId_) {
      msg.projectId = _projectId_;
    }
    const _css_ = json["css"];
    if (_css_) {
      msg.css = _css_;
    }
    return msg;
  },
};

export const UpdateFormCSSResponseJSON = {
  /**
   * Serializes UpdateFormCSSResponse to JSON.
   */
  encode: function (msg: Partial<UpdateFormCSSResponse>): string {
    return JSON.stringify(UpdateFormCSSResponseJSON._writeMessage(msg));
  },

  /**
   * Deserializes UpdateFormCSSResponse from JSON.
   */
  decode: function (json: string): UpdateFormCSSResponse {
    return UpdateFormCSSResponseJSON._readMessage(
      UpdateFormCSSResponseJSON.initialize(),
      JSON.parse(json)
    );
  },

  /**
   * Initializes UpdateFormCSSResponse with all fields set to their default value.
   */
  initialize: function (): UpdateFormCSSResponse {
    return {
      ok: false,
    };
  },

  /**
   * @private
   */
  _writeMessage: function (
    msg: Partial<UpdateFormCSSResponse>
  ): Record<string, unknown> {
    const json: Record<string, unknown> = {};
    if (msg.ok) {
      json["ok"] = msg.ok;
    }
    return json;
  },

  /**
   * @private
   */
  _readMessage: function (
    msg: UpdateFormCSSResponse,
    json: any
  ): UpdateFormCSSResponse {
    const _ok_ = json["ok"];
    if (_ok_) {
      msg.ok = _ok_;
    }
    return msg;
  },
};
