import { Navigate } from "react-router-dom"
import { useAppStore } from "../lib/appStore"

const UnauthorizedRoute = ({ element }: { element: JSX.Element }) => {
	const { user, ready } = useAppStore(({ user, ready }) => ({ user, ready }))
	if (!ready) {
		return <div />
	}

	if (user != null) {
		return <Navigate replace={true} to="/" />
	}
	return element
}

export default UnauthorizedRoute
