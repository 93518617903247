import cronstrue from "cronstrue"
import moment from "moment"
import { useCallback, useEffect, useMemo, useState } from "react"
import DatePicker from "react-datepicker"
import { useNavigate, useParams } from "react-router-dom"
import { Button, Column, Row } from "../components/UI"
import * as cloud from "../messages/cloud.pb"

import "react-datepicker/dist/react-datepicker.css"
import { ChevronLeft } from "lucide-react"
import { getProject } from "../lib/api"

const CreateSchedulePage = () => {
	const params = useParams<{ id: string }>()
	const [project, setProject] = useState<cloud.Project | null>(null)

	useEffect(() => {
		if (!params.id) {
			return
		}
		getProject(params.id).then(setProject)
	}, [params.id])

	const [frequency, setFrequency] = useState<"once" | "repeats" | "">("")
	const [at, setAt] = useState("")
	const [repeats, setRepeats] = useState("")
	const [positionLt, setPositionLt] = useState<number>(0)
	const [ageLt, setAgeLt] = useState<string>("")
	const [karmaGt, setKarmaGt] = useState<number | null>(null)
	const [limit, setLimit] = useState<number | null>(null)

	const preview = useMemo(() => {
		const targets: string[] = []
		if (positionLt) {
			targets.push(`in the top ${positionLt} positions`)
		}
		if (ageLt) {
			targets.push(`at least ${ageLt} old`)
		}
		if (karmaGt != null) {
			targets.push(`with a karma higher than ${karmaGt}`)
		}
		if (limit != null) {
			targets.push(`and limit the results to the first ${limit}`)
		}
		return `${
			frequency === "once" ? `On ${at}` : repeats ? cronstrue.toString(repeats) : ""
		}, send invites to the signups ${targets.join(", ")}.`
	}, [frequency, at, repeats, positionLt, ageLt, karmaGt, limit])

	useEffect(() => {
		if (frequency === "once") {
			setAt(new Date().toISOString())
		} else {
			setAt("")
		}
		setRepeats("")
	}, [frequency])

	const nav = useNavigate()

	useEffect(() => {
		const h = (e: KeyboardEvent) => {
			if (e.key === "Escape") {
				nav(`/projects/${project.id}/schedules`)
			}
		}
		window.addEventListener("keyup", h)
		return function () {
			window.removeEventListener("keyup", h)
		}
	}, [project, nav])

	const create = useCallback(() => {
		cloud
			.CreateProjectSchedule({
				projectId: project.id,
				ageLt: ageLt || undefined,
				karmaGt: karmaGt || undefined,
				positionLt: positionLt || undefined,
				repeat: repeats || undefined,
				at: at ? moment(at).unix() : undefined,
				limit: limit || undefined,
			})
			.then(() => {
				nav(`/projects/${project.id}/schedules`)
			})
	}, [ageLt, karmaGt, positionLt, repeats, at, nav, project, limit])

	if (!project) {
		return null
	}

	return (
		<Column className="items-center">
			<div className="w-full">
				<Button color="empty">
					<ChevronLeft />
					Back
				</Button>
			</div>

			<h1>Create schedule</h1>
			<Column className="p-10 w-full max-w-[600px] border  rounded-lg">
				<Row>
					Frequency:
					<select
						className="bg-neutral-50  rounded border border-neutral-200  p-2 w-auto"
						value={frequency}
						onChange={(e) => setFrequency(e.target.value as any)}
					>
						<option value=""></option>
						<option value="once">Once</option>
						<option value="repeats">Repeats</option>
					</select>
				</Row>
				{frequency === "once" && (
					<Row>
						At:
						<div className="bg-neutral-50  rounded border border-neutral-200  p-2">
							<DatePicker
								selected={at ? moment(at).toDate() : Date.now()}
								onChange={(date) => setAt(date)}
								showTimeSelect
								dateFormat="Pp"
							/>
						</div>
					</Row>
				)}
				{frequency === "repeats" && (
					<Row>
						When:
						<select
							className="bg-neutral-50  rounded border border-neutral-200  p-2 w-auto"
							value={repeats}
							onChange={(e) => setRepeats(e.target.value as any)}
						>
							<option value=""></option>
							<option value="* * * * *">Every minute</option>
							<option value="*/15 * * * *">Every 15 minutes</option>
							<option value="0 * * * *">Hourly</option>
							<option value="0 0 * * *">Daily</option>
							<option value="0 0 * * 0">Weekly on Sundays</option>
							<option value="0 0 * * 1">Weekly on Mondays</option>
							<option value="0 0 * * 2">Weekly on Tuesdays</option>
							<option value="0 0 * * 3">Weekly on Wednesdays</option>
							<option value="0 0 * * 4">Weekly on Thursdays</option>
							<option value="0 0 * * 5">Weekly on Fridays</option>
							<option value="0 0 * * 6">Weekly on Saturdays</option>
						</select>
					</Row>
				)}
				{frequency && (
					<Column className="gap-10">
						<Column>
							<h2>Conditions</h2>
							<Row>
								The top{" "}
								<input
									type="number"
									className="input-text"
									value={positionLt}
									onChange={(e) => setPositionLt(parseInt(e.target.value))}
								/>{" "}
								signups
							</Row>
							<Row>
								Signups with karma higher than{" "}
								<input
									type="number"
									className="input-text"
									value={karmaGt}
									onChange={(e) => setKarmaGt(parseInt(e.target.value))}
								/>
							</Row>
							<Row>
								Signups created at least
								<input
									type="string"
									className="input-text"
									placeholder={`"3 days"`}
									value={ageLt}
									onChange={(e) => setAgeLt(e.target.value)}
								/>{" "}
								ago.
							</Row>
							<Row>
								Limit the results to the first
								<input
									type="number"
									className="input-text"
									value={limit}
									onChange={(e) => setLimit(parseInt(e.target.value))}
								/>{" "}
								results.
							</Row>
						</Column>
						<div className="p-3 bg-neutral-50  rounded border border-neutral-200  italic text-neutral-400 ">
							{preview}
						</div>
						<Button onClick={create}>Create</Button>
					</Column>
				)}
			</Column>
		</Column>
	)
}

export default CreateSchedulePage
