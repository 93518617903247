import classNames from "classnames"
import { Plus, Trash2 } from "lucide-react"
import { useCallback, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useParams } from "react-router-dom"
import { SidebarMenu } from "../../components/SidebarMenu"
import { Skeleton } from "../../components/Skeleton"
import { Spinner } from "../../components/Spinner"
import { Button, Column, Mono, Row, Tile } from "../../components/UI"
import { isAccessReadOnly, permissionAccessName } from "../../interfaces"
import { getProject } from "../../lib/api"
import { useAppStore } from "../../lib/appStore"
import { settings } from "../../lib/settings"
import * as cloud from "../../messages/cloud.pb"

const ProjectMembersPage = () => {
	const params = useParams<{ id: string }>()
	const [project, setProject] = useState<cloud.Project>()

	useEffect(() => {
		if (!params.id) {
			return
		}
		getProject(params.id).then(setProject)
	}, [params.id])

	if (!project) {
		return null
	}

	return (
		<>
			<Helmet>
				<title>
					{project.name} – {settings.appName}
				</title>
			</Helmet>
			<Skeleton project={project}>
				<SidebarMenu project={project} />
				<Column className="flex-1 h-full items-center p-4">
					<Column className="w-full max-w-[1000px]">
						<SectionMembers project={project} />
					</Column>
				</Column>
			</Skeleton>
		</>
	)
}

const SectionMembers = ({ project }: { project: cloud.Project }) => {
	const user = useAppStore((s) => s.user)
	const [members, setMembers] = useState<cloud.Member[] | null>(null)

	const getMembers = useCallback(() => {
		cloud.GetProjectMembers({ projectId: project.id }).then((r) => setMembers(r.members))
	}, [project])

	useEffect(() => getMembers(), [getMembers])

	const onChangeAccess = useCallback(
		(id: string, access: number) => {
			cloud.UpdateProjectMemberAccess({ projectId: project.id, memberId: id, access }).then(getMembers)
		},
		[getMembers, project]
	)

	if (members == null) {
		return <Spinner />
	}

	return (
		<Column>
			<Tile>
				{project.access < 2 && (
					<div>
						You have <strong>read-only</strong> access to this project.
					</div>
				)}
				{project.access === 2 && (
					<div>
						You have <strong>write</strong> access to this project.
					</div>
				)}
				{project.access === 3 && (
					<div>
						You are an <strong>owner</strong> of this project.
					</div>
				)}
			</Tile>
			<h2>Project members</h2>
			{members.map((m) => (
				<Tile key={m.id} size="small">
					<Row>
						<Row className={classNames("!justify-start gap-2", { "opacity-50": !m.userId })}>
							<div className="w-[70px]">
								<Mono>{permissionAccessName(m.access)}</Mono>
							</div>
							<div>{m.email}</div>
							{!m.userId && <div className="italic">(Pending)</div>}
						</Row>
						<Row className="gap-4">
							{!isAccessReadOnly(project) && !(m.userId === user.id && m.access === 3) && (
								<select
									className="bg-neutral-50 text-sm rounded border border-neutral-200  p-2 w-auto"
									value={m.access}
									onChange={(e) => onChangeAccess(m.id, parseInt(e.target.value))}
								>
									<option value={1}>Read-only</option>
									<option value={2}>Write</option>
									<option value={3}>Owner</option>
								</select>
							)}
							<Button
								disabled={isAccessReadOnly(project)}
								color="danger"
								size="small"
								aspect="square"
								onClick={() => {
									const ok = window.confirm("Are you sure?")
									if (!ok) {
										return
									}
									cloud.DeleteProjectMember({ projectId: project.id, id: m.id }).then(getMembers)
								}}
							>
								<Trash2 />
							</Button>
						</Row>
					</Row>
				</Tile>
			))}
			<Row className="!justify-center">
				<Button
					disabled={isAccessReadOnly(project)}
					onClick={() => {
						const email = window.prompt("Email address:")
						if (!email) {
							return
						}
						cloud
							.AddProjectMember({
								projectId: project.id,
								email,
								access: 1,
							})
							.then(getMembers)
					}}
				>
					<Plus /> Add
				</Button>
			</Row>
		</Column>
	)
}

export default ProjectMembersPage
