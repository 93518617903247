import React, { useCallback, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useNavigate } from "react-router-dom"
import { Button, Column } from "../components/UI"
import { Skeleton } from "../components/Skeleton"
import { settings } from "../lib/settings"
import * as cloud from "../messages/cloud.pb"

const CreateProjectPage = () => {
	const nav = useNavigate()
	const [name, setName] = useState<string>()
	const handleSubmit = useCallback(
		(e: React.FormEvent) => {
			e.preventDefault()
			if (!name) {
				return
			}
			cloud.CreateProject({ name }).then((r) => {
				nav("/")
			})
		},
		[name, nav]
	)
	useEffect(() => {
		const h = (e: KeyboardEvent) => {
			if (e.key === "Escape") {
				nav(`/`)
			}
		}
		window.addEventListener("keyup", h)
		return function () {
			window.removeEventListener("keyup", h)
		}
	}, [nav])
	return (
		<>
			<Helmet>
				<title>New project - {settings.appName}</title>
			</Helmet>
			<Skeleton className="justify-center h-full" backLink="/">
				<Column className="flex-1 h-full items-center p-4">
					<Column className="w-full max-w-[1000px]">
						<form className="flex flex-col gap-2" onSubmit={handleSubmit}>
							<input
								autoFocus={true}
								className="input-text"
								type="text"
								value={name}
								onChange={(e) => setName(e.target.value)}
								placeholder="Project name"
							/>
							<Button type="submit">Create</Button>
						</form>
					</Column>
				</Column>
			</Skeleton>
		</>
	)
}

export default CreateProjectPage
